var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { col: "7" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "name",
                    label: _vm._f("pgettext")("Name", "Company"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "name", required: "", autofocus: "" },
                    model: {
                      value: _vm.item.name,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "name", $$v)
                      },
                      expression: "item.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "number",
                    label: _vm._f("pgettext")("Code", "Company"),
                    labelAlign: _vm.labelAlign,
                    required: ""
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "number", required: "" },
                    model: {
                      value: _vm.item.code,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "code", $$v)
                      },
                      expression: "item.code"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "5",
                    name: "account_type",
                    label: _vm._f("gettext")("Default"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormCheckbox", {
                    attrs: {
                      name: "is_default",
                      inline: "",
                      readonly: _vm.item.isReadOnly
                    },
                    model: {
                      value: _vm.item.is_default,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "is_default", $$v)
                      },
                      expression: "item.is_default"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.role === "partner"
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "4",
                        contentColsMd: "8",
                        name: "relation",
                        label: _vm._f("pgettext")("Type", "Company"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect", {
                        attrs: {
                          name: "relation",
                          required: "",
                          options: _vm.companyRelations
                        },
                        model: {
                          value: _vm.item.relation,
                          callback: function($$v) {
                            _vm.$set(_vm.item, "relation", $$v)
                          },
                          expression: "item.relation"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "groups",
                    label: _vm._f("pgettext")("Groups", "Company"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormSelect2", {
                    attrs: {
                      name: "groups",
                      multiple: "",
                      options: _vm.companyGroups
                    },
                    model: {
                      value: _vm.item.groups,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "groups", $$v)
                      },
                      expression: "item.groups"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "emails",
                    label: _vm._f("pgettext")("Email", "Company"),
                    labelAlign: _vm.labelAlign,
                    description: _vm._f("pgettext")(
                      "Multiple email addresses should be separated by semicolon.",
                      "Company"
                    )
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { type: "text", name: "emails" },
                    model: {
                      value: _vm.item.emails,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "emails", $$v)
                      },
                      expression: "item.emails"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "notes",
                    label: _vm._f("pgettext")("Notes", "Company"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "notes" },
                    model: {
                      value: _vm.item.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "notes", $$v)
                      },
                      expression: "item.notes"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { col: "5" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "tax_number",
                    label: _vm._f("pgettext")("Tax Number", "Company"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "tax_number" },
                    model: {
                      value: _vm.item.tax_number,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "tax_number", $$v)
                      },
                      expression: "item.tax_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "eu_tax_number",
                    label: _vm._f("pgettext")("EU Tax Number", "Company"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "eu_tax_number" },
                    model: {
                      value: _vm.item.eu_tax_number,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "eu_tax_number", $$v)
                      },
                      expression: "item.eu_tax_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "registration_number",
                    label: _vm._f("pgettext")("Registration Number", "Company"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "registration_number" },
                    model: {
                      value: _vm.item.registration_number,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "registration_number", $$v)
                      },
                      expression: "item.registration_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isOwnCompany
                ? _c(
                    "DIV",
                    [
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "payment_period_days",
                            label: _vm._f("pgettext")(
                              "Payment period",
                              "Company"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "5",
                            labelAlign: _vm.labelAlign,
                            contentClass: "input-group"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: {
                              type: "number",
                              name: "payment_period_days"
                            },
                            model: {
                              value: _vm.item.payment_period_days,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "payment_period_days", $$v)
                              },
                              expression: "item.payment_period_days"
                            }
                          }),
                          _vm._v(" "),
                          _c("DIV", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("pgettext")(
                                      "days",
                                      "InputGroupAddon"
                                    )
                                  ) +
                                  "\n            "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "discount",
                            label: _vm._f("pgettext")("Discount", "Company"),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign,
                            contentClass: "input-group"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { type: "number", name: "discount" },
                            model: {
                              value: _vm.item.discount,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "discount", $$v)
                              },
                              expression: "item.discount"
                            }
                          }),
                          _vm._v(" "),
                          _c("DIV", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v("\n              %\n            ")
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "4",
                            name: "is_contractor",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "is_contractor",
                              label: _vm._f("pgettext")("Contractor", "Company")
                            },
                            model: {
                              value: _vm.item.is_contractor,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "is_contractor", $$v)
                              },
                              expression: "item.is_contractor"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "4",
                            name: "is_natural_person",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "is_natural_person",
                              label: _vm._f("pgettext")(
                                "Natural Person",
                                "Company"
                              )
                            },
                            model: {
                              value: _vm.item.is_natural_person,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "is_natural_person", $$v)
                              },
                              expression: "item.is_natural_person"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "4",
                            name: "vat_exempt",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "vat_exempt",
                              label: _vm._f("pgettext")("VAT exempt", "Company")
                            },
                            model: {
                              value: _vm.item.vat_exempt,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "vat_exempt", $$v)
                              },
                              expression: "item.vat_exempt"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "4",
                            name: "excluded_from_claims",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormCheckbox", {
                            attrs: {
                              name: "excluded_from_claims",
                              label: _vm._f("pgettext")(
                                "Exclude from claims",
                                "Company"
                              )
                            },
                            model: {
                              value: _vm.item.excluded_from_claims,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "excluded_from_claims", $$v)
                              },
                              expression: "item.excluded_from_claims"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }