"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceGenerationControlling = class UcInvoiceGenerationControlling extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['pageSize', 'rowHeaderColumnWidth', 'dataColumnWidth', 'resizeTrigger', 'selectedInvoiceGroups', 'selectedItems', 'ts', 'values', 'invoiceGroups', 'groups', 'filterInputValue', 'pager', 'laggingApplyFilters', 'ux', 'b_7315092382398562_style', 'b_4440454779651433_style', 'b_5218831530088670_style', 'b_5119818220584553_style'];
    }
    beforeCreate() {
        this.$$cache_selectedInvoiceGroups = new core_1.ComponentValueCache(this, "selectedInvoiceGroups", "UcInvoiceGenerationControlling.selectedInvoiceGroups");
    }
    getAllRows(...args) {
        return this.values.rows;
    }
    getInvoiceGroups(...args) {
        const [rows] = args;
        const visited = [];
        const result = [];
        for (let row of rows) {
            const value = row.invoice_group_id;
            if (visited.indexOf(value) > -1) {
                continue;
            }
            result.push({ value, text: value ? value.toUpperCase() : "BLANK" });
            visited.push(value);
        }
        return result;
    }
    createGroups(...args) {
        const [rows] = args;
        return _
            .chain(rows)
            .groupBy(p => `${p.partner_id}${p.rental_id}`)
            .map((rows, group) => {
            const visibleRows = _.filter(rows, this.isRowVisible);
            if (!visibleRows.length) {
                return;
            }
            return Object.assign(Object.assign({}, visibleRows[0]), { rows: visibleRows, totals: this.calculateTotals(visibleRows) });
        })
            .filter(p => !!p)
            .value();
    }
    calculateTotals(...args) {
        const [rows] = args;
        const totals = {};
        for (let row of rows) {
            for (let colName of this.values.columns) {
                if (!row[colName] || row[colName].net == null) {
                    continue;
                }
                const net = row[colName].net;
                if (totals[colName] == null) {
                    totals[colName] = net;
                    continue;
                }
                totals[colName] += net;
            }
        }
        return totals;
    }
    applyFilters(...args) {
        this.groups = this.createGroups(this.getAllRows());
        this.pager.setItems(this.groups);
    }
    isRowVisible(...args) {
        const [row] = args;
        const selectedInvoiceGroups = this.selectedInvoiceGroups;
        return selectedInvoiceGroups.length === 0
            || selectedInvoiceGroups.includes(row.invoice_group_id);
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_values() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('view_draft_invoice_lines');
        });
    }
    $$load_invoiceGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getInvoiceGroups(this.getAllRows());
        });
    }
    $$load_groups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createGroups(this.getAllRows());
        });
    }
    $$load_pager() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.pager(this.groups, this.$data.pageSize);
        });
    }
    $$load_laggingApplyFilters() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.debounce(_.throttle(this.applyFilters, 300), 500);
        });
    }
    $$load_b_4440454779651433_style() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                width: this.dataColumnWidth,
            };
        });
    }
    $$load_b_5218831530088670_style() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                width: this.rowHeaderColumnWidth,
            };
        });
    }
    $$load_b_5119818220584553_style() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                width: this.dataColumnWidth,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            pageSize: 10,
            rowHeaderColumnWidth: "auto",
            dataColumnWidth: "200px",
            resizeTrigger: 0,
            selectedInvoiceGroups: [],
            selectedItems: [],
            ts: null,
            values: null,
            invoiceGroups: null,
            groups: null,
            filterInputValue: null,
            pager: null,
            laggingApplyFilters: null,
            ux: null,
            b_7315092382398562_style: {
                "max-width": "1280px",
            },
            b_4440454779651433_style: null,
            b_5218831530088670_style: null,
            b_5119818220584553_style: null,
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    evh_4615685607825208_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4615685607825208_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_4592790460410433_script_5134_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.laggingApplyFilters();
        });
    }
    act_4592790460410433_script_5134_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_4592790460410433_script_5134_getActionArgs_value($event),
            };
        });
    }
    evh_4592790460410433_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4592790460410433_change.executeFromDOM(this, event, scope);
        });
    }
    act_3723353993403825_showModal_5136_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Invoice",
                props: {
                    oid: $event.scope.invoice.invoice_id,
                },
            };
        });
    }
    evh_3723353993403825_clickPerventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3723353993403825_clickPerventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_script_5138_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.pager.setCurrentPage($event.originalEvent);
        });
    }
    act_1548630417156826_script_5138_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_1548630417156826_script_5138_getActionArgs_value($event),
            };
        });
    }
    evh_1548630417156826_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_change.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_4615685607825208_reloadComponentData_5132 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_4615685607825208_clickPreventStop_5131 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_reloadComponentData_5132,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_4615685607825208_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4615685607825208_clickPreventStop_5131],
        });
        const act_4592790460410433_script_5134 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4592790460410433_script_5134_getActionArgs,
            events: [],
        });
        const evh_4592790460410433_change_5133 = new core_1.actions.EventHandlerImpl({
            action: act_4592790460410433_script_5134,
            event: "change",
            displayName: "script",
        });
        const evh_4592790460410433_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4592790460410433_change_5133],
        });
        const act_3723353993403825_showModal_5136 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3723353993403825_showModal_5136_getActionArgs,
            events: [],
        });
        const evh_3723353993403825_clickPerventStop_5135 = new core_1.actions.EventHandlerImpl({
            action: act_3723353993403825_showModal_5136,
            event: "click.pervent.stop",
            displayName: "showModal",
        });
        const evh_3723353993403825_clickPerventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3723353993403825_clickPerventStop_5135],
        });
        const act_1548630417156826_script_5138 = new core_1.actions.ScriptAction({
            actionArgs: this.act_1548630417156826_script_5138_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_change_5137 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_script_5138,
            event: "change",
            displayName: "script",
        });
        const evh_1548630417156826_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_change_5137],
        });
        return {
            act_4615685607825208_reloadComponentData_5132,
            evh_4615685607825208_clickPreventStop_5131,
            evh_4615685607825208_clickPreventStop,
            act_4592790460410433_script_5134,
            evh_4592790460410433_change_5133,
            evh_4592790460410433_change,
            act_3723353993403825_showModal_5136,
            evh_3723353993403825_clickPerventStop_5135,
            evh_3723353993403825_clickPerventStop,
            act_1548630417156826_script_5138,
            evh_1548630417156826_change_5137,
            evh_1548630417156826_change,
            reload: act_4615685607825208_reloadComponentData_5132,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], UcInvoiceGenerationControlling.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceGenerationControlling.prototype, "onSize", null);
UcInvoiceGenerationControlling = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceGenerationControlling);
exports.default = UcInvoiceGenerationControlling;
vue_property_decorator_1.Vue.component("UcInvoiceGenerationControlling", UcInvoiceGenerationControlling);
