<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="JobModal"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <DIV
      class="row"
    >
      <ItpFormGroup
        cols-md="3"
        name="status"
        :label="'Status'|pgettext('Job')"
      >
        <ItpFormInput
          name="status"
          size="md"
          v-model="item.status"
          readonly
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        cols-md="3"
        name="job_type"
        :label="'Job type'|pgettext('Job')"
      >
        <ItpFormInput
          name="job_type"
          size="md"
          v-model="item.job_type"
          readonly
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        cols-md="3"
        name="created_at"
        :label="'Created at'|gettext"
      >
        <ItpFormInput
          name="created_at"
          size="md"
          v-model="item.created_at"
          readonly
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        cols-md="3"
        name="created_by"
        :label="'Created by'|gettext"
      >
        <ItpFormInput
          name="created_by"
          size="md"
          :model="b_5074589820235598_model"
          readonly
        >
        </ItpFormInput>
      </ItpFormGroup>
    </DIV>
    <HR>
    </HR>
    <DIV>
      <label>
        {{ 'Job data'|gettext }}
      </label>
      <ItpJSONToTable
        truncateText="200"
        :json="item.job_data"
      >
      </ItpJSONToTable>
    </DIV>
    <HR>
    </HR>
    <DIV>
      <label>
        {{ 'Job result'|gettext }}
      </label>
      <ItpJSONToTable
        truncateText="200"
        :json="item.job_result"
      >
      </ItpJSONToTable>
    </DIV>
  </UcBody>
  <template
    slot="footer"
    v-if="loaded"
  >
    <DIV
      class="modal-footer"
    >
      <ItpButton
        :text="'Restart'|gettext"
        variant="danger"
        :spinning="actions.restart.isRunning"
        class="mr-auto"
        v-if="item.status == 'Errored'"
        @click.prevent.stop="evh_2140198529698299_clickPreventStop($event, {})"
      >
      </ItpButton>
    </DIV>
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
    default: null,
  })
  id!: string;

  @Watch('id')
  onId(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("id")
  }

  @Prop({
    type: String,
    default: "xl",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.id !== null ? this.$fn.fetch('read_a_job', { parameters: { id: this.id } }) : null
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5074589820235598_model!: any;
  dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_5074589820235598_model'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "xl",
        }
        ,
        b_5074589820235598_model: "{{ item.created_by }",
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: ([this.item.number, this.$fn.gettext('Job')]),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2532_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2532, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_2140198529698299_setData_2538_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2140198529698299_setData_2538, alias=undefined
    return {
      path: "item",
      value: $event.data.response.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_2140198529698299_request_2536_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2140198529698299_request_2536, alias=restart
    return {
      operation: "update_job",
      data: {
        status: "Not Started",
      }
      ,
      parameters: {
        id: this.item.id,
      }
      ,
    }
  }

  async evh_2140198529698299_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2532: actions.CloseModalAction;
    evh_7315092382398562_close_2531: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2534: actions.CloseComponentAction;
    evh_2248226175642056_close_2533: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_2140198529698299_setData_2538: actions.SetDataAction;
    evh_2140198529698299_success_2537: actions.EventHandlerImpl;
    act_2140198529698299_request_2536: actions.RequestAction;
    evh_2140198529698299_clickPreventStop_2535: actions.EventHandlerImpl;
    evh_2140198529698299_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2532 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2532_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2531 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2532,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2531],
      }
    );
    const act_2248226175642056_closeComponent_2534 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2533 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2534,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2533],
      }
    );
    const act_2140198529698299_setData_2538 = new actions.SetDataAction(
      {
        actionArgs: this.act_2140198529698299_setData_2538_getActionArgs,
        events: [],
      }
    );
    const evh_2140198529698299_success_2537 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_setData_2538,
        event: "success",
        displayName: "setData",
      }
    );
    const act_2140198529698299_request_2536 = new actions.RequestAction(
      {
        actionArgs: this.act_2140198529698299_request_2536_getActionArgs,
        displayName: "restart",
        events: [evh_2140198529698299_success_2537],
      }
    );
    const evh_2140198529698299_clickPreventStop_2535 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_request_2536,
        event: "click.prevent.stop",
        displayName: "restart",
      }
    );
    const evh_2140198529698299_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2140198529698299_clickPreventStop_2535],
      }
    );
    return {
      act_7315092382398562_closeModal_2532,
      evh_7315092382398562_close_2531,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2534,
      evh_2248226175642056_close_2533,
      evh_2248226175642056_close,
      act_2140198529698299_setData_2538,
      evh_2140198529698299_success_2537,
      act_2140198529698299_request_2536,
      evh_2140198529698299_clickPreventStop_2535,
      evh_2140198529698299_clickPreventStop,
      restart: act_2140198529698299_request_2536,
    }
  }
}

Vue.component("JobModal", JobModal);

</script>