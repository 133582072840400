<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Groups"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-auto h-100 p-3"
  >
    <ItpDataTable
      tableId="Settings.Groups"
      editable
      :isRowEditable="p => !p.locked"
      :value="items"
      :columns="b_8564662037462133_columns"
      class="h-100"
      ref="table"
      v-if="loaded"
      @save="evh_8564662037462133_save($event, {})"
      @delete="evh_8564662037462133_delete($event, {})"
    >
    </ItpDataTable>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.groups",
    path: "groups",
    component: "Groups"
  }
)

@Component()
export default class Groups extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_group').then(p => p.data)
  }

  groupTypes!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8564662037462133_columns!: any;

  async $$load_b_8564662037462133_columns() {
    return [{
      name: "inactive",
      header: this.$fn.gettext("Inactive"),
      width: "30px",
      textAlign: "center",
      formatter: {
        name: "checkbox",
      }
      ,
      editor: {
        name: "checkbox",
      }
      ,
    }
      ,
    {
      name: "group_type",
      type: "select",
      header: this.$fn.gettext("Group Type"),
      width: "160px",
      required: true,
      options: this.groupTypes,
    }
      ,
    {
      name: "name",
      header: this.$fn.gettext("Name"),
      width: "140px",
      required: true,
    }
      ,
    {
      name: "description",
      header: this.$fn.gettext("Description"),
      width: "320px",
    }
      ,
    ]
  }

  dataMembers = ['items', 'groupTypes', 'ux', 'b_7315092382398562_modalBindings', 'b_8564662037462133_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        groupTypes: [{
          id: "meter",
          text: this.$fn.pgettext("GroupType", "Meter"),
        }
          ,
        {
          id: "client",
          text: this.$fn.pgettext("GroupType", "Company"),
        }
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_8564662037462133_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_4002_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4002, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_4004_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_4004, alias=undefined
    $event.data.waitFor(this.$fn.crud('group').upsert($event.data.row))
  }

  async act_8564662037462133_script_4004_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_4004, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_4004_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_save.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_script_4006_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8564662037462133_script_4006, alias=undefined
    $event.data.waitFor(this.$fn.crud('group').delete($event.data.row))
  }

  async act_8564662037462133_script_4006_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8564662037462133_script_4006, alias=undefined
    return {
      value: () => this.act_8564662037462133_script_4006_getActionArgs_value($event),
    }
  }

  async evh_8564662037462133_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4002: actions.CloseModalAction;
    evh_7315092382398562_close_4001: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_8564662037462133_script_4004: actions.ScriptAction;
    evh_8564662037462133_save_4003: actions.EventHandlerImpl;
    evh_8564662037462133_save: actions.EventHandlerGroup;
    act_8564662037462133_script_4006: actions.ScriptAction;
    evh_8564662037462133_delete_4005: actions.EventHandlerImpl;
    evh_8564662037462133_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4002 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4002_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4001 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4002,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4001],
      }
    );
    const act_8564662037462133_script_4004 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_4004_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_save_4003 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_4004,
        event: "save",
        displayName: "script",
      }
    );
    const evh_8564662037462133_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_save_4003],
      }
    );
    const act_8564662037462133_script_4006 = new actions.ScriptAction(
      {
        actionArgs: this.act_8564662037462133_script_4006_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_delete_4005 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_script_4006,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_8564662037462133_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_delete_4005],
      }
    );
    return {
      act_7315092382398562_closeModal_4002,
      evh_7315092382398562_close_4001,
      evh_7315092382398562_close,
      act_8564662037462133_script_4004,
      evh_8564662037462133_save_4003,
      evh_8564662037462133_save,
      act_8564662037462133_script_4006,
      evh_8564662037462133_delete_4005,
      evh_8564662037462133_delete,
    }
  }
}

Vue.component("Groups", Groups);

</script>