"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractServiceFees = class UcContractServiceFees extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['today', 'displayAll', 'fees', 'hasStaleItems', 'ux'];
    }
    beforeCreate() {
    }
    mapFees(...args) {
        return _
            .chain(args[0])
            .orderBy(['valid_from', 'valid_to'], ['desc', 'desc'])
            .map((item, index, arr) => {
            const future = item.valid_from > this.$fn.today, current = !future && (index == 0 || arr[index - 1].valid_from > this.$fn.today), stale = !future && !current;
            return { item, future, current, stale };
        })
            .value();
    }
    filterFees(...args) {
        const [fees, displayAll] = args;
        return _
            .filter(fees, p => displayAll || p.future || p.current)
            .map(p => p.item);
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onService(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("service");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_today() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Date();
        });
    }
    $$load_fees() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.mapFees(this.service.fees);
        });
    }
    $$load_hasStaleItems() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.some(this.fees, { stale: true });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            today: null,
            displayAll: false,
            fees: null,
            hasStaleItems: null,
            ux: null,
        });
    }
    get canDisplayMoreItems() {
        if (this.loaded) {
            return !this.displayAll && this.hasStaleItems;
        }
        return null;
    }
    get canHideItems() {
        if (this.loaded) {
            return this.displayAll && this.hasStaleItems;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2095089031469157_emit_6808_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2095089031469157_close_6807_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_2095089031469157_showModal_6806_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractServiceEditor",
                props: {
                    mode: "editServiceFee",
                    contract: this.contract,
                    service: _.cloneDeep(this.service),
                    fee: _.cloneDeep($event.scope.fee),
                },
            };
        });
    }
    evh_2095089031469157_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_6810_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: false,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1040167445267876_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_4186377094414664_setData_6812_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "displayAll",
                value: true,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_4186377094414664_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4186377094414664_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_3083451129821185_emit_6816_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3083451129821185_close_6815_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_3083451129821185_showModal_6814_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractServiceEditor",
                props: {
                    mode: "addServiceFee",
                    contract: this.contract,
                    service: _.cloneDeep(this.service),
                },
            };
        });
    }
    evh_3083451129821185_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3083451129821185_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6804 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6803 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6804,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6803],
        });
        const act_2095089031469157_emit_6808 = new core_1.actions.EmitAction({
            actionArgs: this.act_2095089031469157_emit_6808_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_2095089031469157_close_6807 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_emit_6808,
            event: "close",
            when: this.evh_2095089031469157_close_6807_getWhen,
            displayName: "emitReload",
        });
        const act_2095089031469157_showModal_6806 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2095089031469157_showModal_6806_getActionArgs,
            events: [evh_2095089031469157_close_6807],
        });
        const evh_2095089031469157_clickPreventStop_6805 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_showModal_6806,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2095089031469157_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_clickPreventStop_6805],
        });
        const act_1040167445267876_setData_6810 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_6810_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_clickPreventStop_6809 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_6810,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_1040167445267876_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_clickPreventStop_6809],
        });
        const act_4186377094414664_setData_6812 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4186377094414664_setData_6812_getActionArgs,
            events: [],
        });
        const evh_4186377094414664_clickPreventStop_6811 = new core_1.actions.EventHandlerImpl({
            action: act_4186377094414664_setData_6812,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_4186377094414664_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4186377094414664_clickPreventStop_6811],
        });
        const act_3083451129821185_emit_6816 = new core_1.actions.EmitAction({
            actionArgs: this.act_3083451129821185_emit_6816_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_3083451129821185_close_6815 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_emit_6816,
            event: "close",
            when: this.evh_3083451129821185_close_6815_getWhen,
            displayName: "emitReload",
        });
        const act_3083451129821185_showModal_6814 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3083451129821185_showModal_6814_getActionArgs,
            events: [evh_3083451129821185_close_6815],
        });
        const evh_3083451129821185_clickPreventStop_6813 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_showModal_6814,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3083451129821185_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3083451129821185_clickPreventStop_6813],
        });
        return {
            act_7315092382398562_reloadComponentData_6804,
            evh_7315092382398562_reload_6803,
            evh_7315092382398562_reload,
            act_2095089031469157_emit_6808,
            evh_2095089031469157_close_6807,
            act_2095089031469157_showModal_6806,
            evh_2095089031469157_clickPreventStop_6805,
            evh_2095089031469157_clickPreventStop,
            act_1040167445267876_setData_6810,
            evh_1040167445267876_clickPreventStop_6809,
            evh_1040167445267876_clickPreventStop,
            act_4186377094414664_setData_6812,
            evh_4186377094414664_clickPreventStop_6811,
            evh_4186377094414664_clickPreventStop,
            act_3083451129821185_emit_6816,
            evh_3083451129821185_close_6815,
            act_3083451129821185_showModal_6814,
            evh_3083451129821185_clickPreventStop_6813,
            evh_3083451129821185_clickPreventStop,
            emitReload: act_3083451129821185_emit_6816,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractServiceFees.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractServiceFees.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractServiceFees.prototype, "service", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('service'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractServiceFees.prototype, "onService", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcContractServiceFees.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractServiceFees.prototype, "onReadonly", null);
UcContractServiceFees = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractServiceFees);
exports.default = UcContractServiceFees;
vue_property_decorator_1.Vue.component("UcContractServiceFees", UcContractServiceFees);
