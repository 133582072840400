<template>
<VueSlickgrid
  name="draftInvoiceLines"
  :resizeTrigger="resizeTrigger"
  :headerTitle="'Draft invoice lines'|gettext"
  :items="values.rows"
  checkbox-row-selection
  footer-totals
  export-to-csv
  :exportToCsvFilename="'draft-invoice-lines'|pgettext('ExportFilename')"
  :columnDefaults="b_7315092382398562_columnDefaults"
  :columns="values.columns"
  :options="b_7315092382398562_options"
  :grouping="grouping"
  ref="grid"
  v-if="values && grouping"
  @selectedRowsChanged="evh_7315092382398562_selectedRowsChanged($event, {})"
  @command="evh_7315092382398562_command($event, {})"
>
  <DIV
    class="flex-grow-1 d-flex"
    slot="toolbar"
  >
    <ItpButton
      icon="fa-trash"
      :text="'Delete'|gettext"
      size="sm"
      variant="light"
      :disabled="!selectedItems || !selectedItems.length"
      :spinning="actions.deleteItems.isRunning"
      class="mx-2"
      @click="evh_8564662037462133_click($event, {})"
    >
    </ItpButton>
  </DIV>
</VueSlickgrid>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceGenerationInvoiceLines extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  formatDataColumn(...args: any[]) {
    const [r, c, value, columnDefinition, row] = args;
    if (value == null) { return ''; }
    const result = ['<div>'];
    if (value.net != null) { const net = this.$options.filters.number(value.net); result.push(`<div class="mb-1"><strong>${net}</strong></div>`); }
    if (value.quantity != null) { const quantity = this.$options.filters.number(value.quantity); result.push(`${quantity} ${row.unit}`); }
    result.push('</div>'); return result.join('')
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resizeTrigger!: any;
  selectedItems!: any;
  ts!: any;
  stockColumns!: any;
  values!: any;

  async $$load_values() {
    return this.$fn.fetch('view_draft_invoice_lines').then(
      p => _.extend(p, {
        columns: this.stockColumns.concat(p.columns.map(field => ({
          field,
          name: field,
          textAlign: 'right',
          formatter: this.formatDataColumn
        })))
      })
    )
  }

  grouping!: any;

  async $$load_grouping() {
    return [{
      getter: "partner",
      formatter: g => `<strong>${g.value}</strong>`,
      aggregateCollapsed: false,
      lazyTotalsCalculation: true,
    }
      ,
    {
      getter: "rental",
      formatter: g => `<strong>${g.value}</strong>`,
      aggregateCollapsed: "false,",
      lazyTotalsCalculation: true,
    }
      ,
    ]
  }

  ux!: any;
  b_7315092382398562_columnDefaults!: any;
  b_7315092382398562_options!: any;
  dataMembers = ['resizeTrigger', 'selectedItems', 'ts', 'stockColumns', 'values', 'grouping', 'ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resizeTrigger: 0,
        selectedItems: [
        ]
        ,
        ts: null,
        stockColumns: [{
          field: "partner",
          name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Partner"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            navigateOnIconClick: true,
            params: {
              id: "partner_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "rental",
          name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "site",
          name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Site"),
          width: "30px",
        }
          ,
        {
          field: "item",
          name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Item"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "product",
            navigateOnIconClick: true,
            params: {
              id: "product_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "serial_number",
          name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Serial"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "meter",
            navigateOnIconClick: true,
            params: {
              id: "meter_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
        values: null,
        grouping: null,
        ux: null,
        b_7315092382398562_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_7315092382398562_options: {
          rowHeight: 40,
          rowTopOffsetRenderType: "transform",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_setData_5148_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7315092382398562_setData_5148, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7315092382398562_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_showModal_5150_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7315092382398562_showModal_5150, alias=undefined
    return {
      name: "Invoice",
      props: {
        oid: $event.data.data.id,
        modal: true,
      }
      ,
    }
  }

  async evh_7315092382398562_command_5149_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7315092382398562_command_5149, alias=undefined
    return $event.data.column.id === 'invoice_number'
  }

  async evh_7315092382398562_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_5154_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_5154, alias=undefined
    return {
      grid: "draftInvoices",
    }
  }

  async act_8564662037462133_request_5152_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8564662037462133_request_5152, alias=deleteItems
    return {
      operation: "delete_draft_invoices",
      data: {
        invoices: this.selectedItems.map(p => p.id)
      },
    }
  }

  async evh_8564662037462133_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_setData_5148: actions.SetDataAction;
    evh_7315092382398562_selectedRowsChanged_5147: actions.EventHandlerImpl;
    evh_7315092382398562_selectedRowsChanged: actions.EventHandlerGroup;
    act_7315092382398562_showModal_5150: actions.ShowModalAction;
    evh_7315092382398562_command_5149: actions.EventHandlerImpl;
    evh_7315092382398562_command: actions.EventHandlerGroup;
    act_8564662037462133_reloadSlickgrid_5154: actions.ReloadSlickgridAction;
    evh_8564662037462133_success_5153: actions.EventHandlerImpl;
    act_8564662037462133_request_5152: actions.RequestAction;
    evh_8564662037462133_click_5151: actions.EventHandlerImpl;
    evh_8564662037462133_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_setData_5148 = new actions.SetDataAction(
      {
        actionArgs: this.act_7315092382398562_setData_5148_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_selectedRowsChanged_5147 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_setData_5148,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_7315092382398562_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_selectedRowsChanged_5147],
      }
    );
    const act_7315092382398562_showModal_5150 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7315092382398562_showModal_5150_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_command_5149 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_showModal_5150,
        event: "command",
        when: this.evh_7315092382398562_command_5149_getWhen,
        displayName: "showModal",
      }
    );
    const evh_7315092382398562_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_command_5149],
      }
    );
    const act_8564662037462133_reloadSlickgrid_5154 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_5154_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_success_5153 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_5154,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8564662037462133_request_5152 = new actions.RequestAction(
      {
        actionArgs: this.act_8564662037462133_request_5152_getActionArgs,
        displayName: "deleteItems",
        events: [evh_8564662037462133_success_5153],
      }
    );
    const evh_8564662037462133_click_5151 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_request_5152,
        event: "click",
        displayName: "deleteItems",
      }
    );
    const evh_8564662037462133_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_click_5151],
      }
    );
    return {
      act_7315092382398562_setData_5148,
      evh_7315092382398562_selectedRowsChanged_5147,
      evh_7315092382398562_selectedRowsChanged,
      act_7315092382398562_showModal_5150,
      evh_7315092382398562_command_5149,
      evh_7315092382398562_command,
      act_8564662037462133_reloadSlickgrid_5154,
      evh_8564662037462133_success_5153,
      act_8564662037462133_request_5152,
      evh_8564662037462133_click_5151,
      evh_8564662037462133_click,
      deleteItems: act_8564662037462133_request_5152,
    }
  }
}

Vue.component("UcInvoiceGenerationInvoiceLines", UcInvoiceGenerationInvoiceLines);

</script>