"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let ContractTermEditor = class ContractTermEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'labelAlign', 'isEditable', 'isDeletable', 'isTermReadonly', 'messages', 'item', 'currencies', 'services', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource'];
    }
    beforeCreate() {
    }
    getConfiguration(...args) {
        switch (this.mode) {
            case 'add':
                return {
                    isEditable: true,
                    isDeletable: false,
                    isTermReadonly: false,
                };
            case 'edit':
                return {
                    isEditable: !this.term.locked,
                    isDeletable: !this.term.locked,
                    isTermReadonly: this.term.locked,
                };
        }
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onTerm(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("term");
    }
    onMode(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("mode");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_isEditable() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isEditable;
        });
    }
    $$load_isDeletable() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isDeletable;
        });
    }
    $$load_isTermReadonly() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getConfiguration().isTermReadonly;
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.term || this.$fn.schemaDefaults('CreateContractTerm', {
                contract_id: this.contract.id
            });
        });
    }
    $$load_currencies() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_currency');
        });
    }
    $$load_services() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('suggest_product', {
                parameters: { query: '*', top: 50, skip: 0 }
            }).then(data => _.orderBy(data, [p => p.name.toLowerCase()]));
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            labelAlign: "right",
            isEditable: null,
            isDeletable: null,
            isTermReadonly: null,
            messages: {
                add: {
                    title: this.$fn.pgettext("Contract|ModalHeader", "Add term"),
                },
                edit: {
                    title: this.$fn.pgettext("Contract|ModalHeader", "Edit term"),
                },
            },
            item: null,
            currencies: null,
            services: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
            b_5731695935601903_dataSource: {
                name: "suggest_product",
            },
        });
    }
    get currentObjectType() {
        if (this.loaded) {
            return "contract_term";
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.messages[this.mode].title,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6830_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_6836_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_6834_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: this.currentObjectType,
                op: "upsert",
                data: this.item,
                options: {
                    parameters: {
                        rev: this.contract.revision_number,
                    },
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_5731695935601903_setData_6838_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.name",
                value: $event.data.option.name,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5731695935601903_setData_6840_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.service_id",
                value: $event.data.option.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5731695935601903_setData_6842_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.unit",
                value: $event.data.option.unit,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5731695935601903_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5731695935601903_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_1548630417156826_closeModal_6846_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                },
            };
        });
    }
    act_1548630417156826_request_6844_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_contract_term",
                data: this.item,
            };
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_2186679009640457_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6830 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6830_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6829 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6830,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6829],
        });
        const act_2248226175642056_closeComponent_6832 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6831 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6832,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6831],
        });
        const act_1040167445267876_closeModal_6836 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_6836_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6835 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_6836,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_6834 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6834_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6835],
        });
        const evh_1040167445267876_submit_6833 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6834,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6833],
        });
        const act_5731695935601903_setData_6838 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5731695935601903_setData_6838_getActionArgs,
            events: [],
        });
        const evh_5731695935601903_optionSelected_6837 = new core_1.actions.EventHandlerImpl({
            action: act_5731695935601903_setData_6838,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_5731695935601903_setData_6840 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5731695935601903_setData_6840_getActionArgs,
            events: [],
        });
        const evh_5731695935601903_optionSelected_6839 = new core_1.actions.EventHandlerImpl({
            action: act_5731695935601903_setData_6840,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_5731695935601903_setData_6842 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5731695935601903_setData_6842_getActionArgs,
            events: [],
        });
        const evh_5731695935601903_optionSelected_6841 = new core_1.actions.EventHandlerImpl({
            action: act_5731695935601903_setData_6842,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_5731695935601903_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5731695935601903_optionSelected_6837, evh_5731695935601903_optionSelected_6839, evh_5731695935601903_optionSelected_6841],
        });
        const act_1548630417156826_closeModal_6846 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1548630417156826_closeModal_6846_getActionArgs,
            events: [],
        });
        const evh_1548630417156826_success_6845 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeModal_6846,
            event: "success",
            displayName: "closeModal",
        });
        const act_1548630417156826_request_6844 = new core_1.actions.RequestAction({
            actionArgs: this.act_1548630417156826_request_6844_getActionArgs,
            displayName: "deleteTerm",
            events: [evh_1548630417156826_success_6845],
        });
        const evh_1548630417156826_clickPreventStop_6843 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_request_6844,
            event: "click.prevent.stop",
            displayName: "deleteTerm",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_6843],
        });
        const act_2186679009640457_closeComponent_6848 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2186679009640457_clickPreventStop_6847 = new core_1.actions.EventHandlerImpl({
            action: act_2186679009640457_closeComponent_6848,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_2186679009640457_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2186679009640457_clickPreventStop_6847],
        });
        return {
            act_7315092382398562_closeModal_6830,
            evh_7315092382398562_close_6829,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6832,
            evh_2248226175642056_close_6831,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_6836,
            evh_1040167445267876_success_6835,
            act_1040167445267876_crud_6834,
            evh_1040167445267876_submit_6833,
            evh_1040167445267876_submit,
            act_5731695935601903_setData_6838,
            evh_5731695935601903_optionSelected_6837,
            act_5731695935601903_setData_6840,
            evh_5731695935601903_optionSelected_6839,
            act_5731695935601903_setData_6842,
            evh_5731695935601903_optionSelected_6841,
            evh_5731695935601903_optionSelected,
            act_1548630417156826_closeModal_6846,
            evh_1548630417156826_success_6845,
            act_1548630417156826_request_6844,
            evh_1548630417156826_clickPreventStop_6843,
            evh_1548630417156826_clickPreventStop,
            act_2186679009640457_closeComponent_6848,
            evh_2186679009640457_clickPreventStop_6847,
            evh_2186679009640457_clickPreventStop,
            save: act_1040167445267876_crud_6834,
            deleteTerm: act_1548630417156826_request_6844,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractTermEditor.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractTermEditor.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], ContractTermEditor.prototype, "term", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('term'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractTermEditor.prototype, "onTerm", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], ContractTermEditor.prototype, "mode", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('mode'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractTermEditor.prototype, "onMode", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], ContractTermEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], ContractTermEditor.prototype, "onSize", null);
ContractTermEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], ContractTermEditor);
exports.default = ContractTermEditor;
vue_property_decorator_1.Vue.component("ContractTermEditor", ContractTermEditor);
