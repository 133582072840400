<template>
<div>
  <DIV
    v-if="!items || !items.length"
  >
    <ItpAlert
      show
      :message="'This meter has no related invoices.'|pgettext('Meter')"
    >
    </ItpAlert>
  </DIV>
  <ItpDataTable
    tableId="MeterInvoices"
    :editable="b_6804155476809633_editable"
    paging
    :value="_.orderBy(items, ['invoice_date', 'reading_time', 'created_at'], ['desc', 'desc', 'desc'])"
    :columns="b_6804155476809633_columns"
    v-if="items && items.length"
  >
  </ItpDataTable>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcMeterInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_meter_invoices', { parameters: { meter_id: this.meter.id } })
  }

  ux!: any;
  b_6804155476809633_editable!: any;
  b_6804155476809633_columns!: any;
  dataMembers = ['items', 'ux', 'b_6804155476809633_editable', 'b_6804155476809633_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_6804155476809633_editable: false,
        b_6804155476809633_columns: [{
          name: "invoice_number",
          type: "template",
          header: this.$fn.pgettext("MeterInvoices", "Invoice"),
          width: "130px",
          value: "<DIV>\n\
  <ItpLink :to=\"{name: 'app.invoice', params: { id: row.invoice_id }}\" >\n\
    {{ row.invoice_number }}\n\
  </ItpLink>\n\
  <ItpText muted>{{ row.invoice_date|date }}</ItpText>\n\
  <ItpLink :to=\"{name: 'app.company', params: { id: row.client_id }}\" >\n\
    {{ row.client_name }}\n\
  </ItpLink>\n\
</DIV>\n\
",
        }
          ,
        {
          name: "contract_number",
          type: "template",
          header: this.$fn.pgettext("MeterInvoices", "Contract / Rental"),
          width: "130px",
          value: "<DIV>\n\
  <ItpLink :to=\"{name: 'app.rental', params: { id: row.contract_id }}\" >\n\
    {{ row.contract_number }}\n\
  </ItpLink>\n\
  <ItpLink :to=\"{name: 'app.rental', params: { id: row.rental_id }}\" >\n\
    {{ row.rental_name }}\n\
  </ItpLink>\n\
</DIV>\n\
",
        }
          ,
        {
          name: "from_date",
          header: this.$fn.pgettext("MeterInvoices", "From"),
          type: "template",
          value: "<DIV>\n\
  {{ row.from_amount|number }} {{ row.from_unit }}<br />\n\
  <ItpText muted>{{ row.from_date|date }}</ItpText>\n\
</DIV>\n\
",
          width: "150px",
        }
          ,
        {
          name: "to_date",
          header: this.$fn.pgettext("MeterInvoices", "To"),
          type: "template",
          value: "<DIV>\n\
  {{ row.to_amount|number }} {{ row.to_unit }}<br />\n\
  <ItpText muted>{{ row.to_date|date }}</ItpText>\n\
</DIV>\n\
",
          width: "150px",
        }
          ,
        {
          name: "real_consumption_amount",
          header: this.$fn.pgettext("MeterInvoices", "Consumption"),
          type: "template",
          value: "<DIV>\n\
  {{ row.real_consumption_amount|number }} {{ row.real_consumption_unit }}\n\
</DIV>\n\
",
          width: "110px",
        }
          ,
        {
          name: "billing_multiplier",
          header: this.$fn.pgettext("MeterInvoices", "Mp."),
          tooltip: this.$fn.pgettext("MeterInvoices", "Multiplier"),
          type: "number",
          width: "70px",
        }
          ,
        {
          name: "quantity",
          header: this.$fn.pgettext("MeterInvoices", "Quantity"),
          type: "template",
          value: "<span>{{ row.quantity|number }} {{ row.unit }}</span>",
          width: "100px",
        }
          ,
        {
          name: "unit_price",
          header: this.$fn.pgettext("MeterInvoices", "Price"),
          type: "number",
          width: "70px",
        }
          ,
        {
          name: "net_amount",
          header: this.$fn.pgettext("MeterInvoices", "Net"),
          type: "number",
          width: "80px",
        }
          ,
        {
          name: "amount",
          header: this.$fn.pgettext("MeterInvoices", "Amount"),
          type: "number",
          width: "80px",
        }
          ,
        {
          name: "currency",
          header: this.$fn.pgettext("MeterInvoices", "Currency"),
          type: "number",
          width: "80px",
        }
          ,
        ]
        ,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcMeterInvoices", UcMeterInvoices);

</script>