var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.item.object === "company"
        ? _c("UcCompanyMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "contract"
        ? _c("UcContractMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "property" || _vm.item.object == "property_unit"
        ? _c("UcPropertyMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "rental"
        ? _c("UcRentalMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.object === "worksheet"
        ? _c("UcWorksheetMiniCard", {
            attrs: { item: _vm.item, noLink: _vm.noLink }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }