var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "BListGroup",
        { attrs: { flush: "" } },
        _vm._l(_vm.filterFees(_vm.fees, _vm.displayAll), function(fee) {
          return _c(
            "BListGroupItem",
            { key: fee.id },
            [
              _c(
                "ItpBox",
                [
                  _c(
                    "ItpBox",
                    [
                      _c(
                        "ItpText",
                        [
                          _c("ItpText", [
                            _vm._v(_vm._s(_vm._f("date")(fee.valid_from)))
                          ]),
                          _vm._v(" "),
                          fee.valid_to
                            ? _c("ItpText", [
                                _vm._v(
                                  " - " + _vm._s(_vm._f("date")(fee.valid_to))
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("ItpText", { staticClass: "px-3" }, [
                            fee.quantity != null
                              ? _c("span", [
                                  _vm._v(
                                    "\n    " +
                                      _vm._s(_vm._f("number")(fee.quantity)) +
                                      " " +
                                      _vm._s(fee.unit_of_quantity || "") +
                                      " x\n  "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n  " +
                                _vm._s(_vm._f("number")(fee.amount)) +
                                " " +
                                _vm._s(fee.currency) +
                                "/" +
                                _vm._s(fee.unit) +
                                "\n"
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      fee.notes
                        ? _c("ItpText", { attrs: { text: fee.notes, pre: "" } })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.readonly
                    ? _c("ItpButton", {
                        attrs: {
                          icon: "fa-pen",
                          tooltip: _vm._f("gettext")("Edit"),
                          variant: "light",
                          size: "sm"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_2095089031469157_clickPreventStop(
                              $event,
                              { fee: fee }
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "ItpBox",
        { staticClass: "pl-4" },
        [
          _vm.canHideItems
            ? _c("ItpLink", {
                attrs: { text: _vm._f("gettext")("Show less") },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1040167445267876_clickPreventStop($event, {})
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canDisplayMoreItems
            ? _c("ItpLink", {
                attrs: { text: _vm._f("gettext")("Show more") },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_4186377094414664_clickPreventStop($event, {})
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.readonly
            ? _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("pgettext")("Add service fee...", "Contract"),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_3083451129821185_clickPreventStop($event, {})
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }