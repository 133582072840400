var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { staticClass: "mb-2", attrs: { alignItems: "center" } },
        [
          _c("strong", [
            _vm._v(
              "\n      " +
                _vm._s(_vm._f("pgettext")("Invoices", "Payment")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          !_vm.payment.isReadonly
            ? _c("ItpButton", {
                staticClass: "mx-4",
                attrs: {
                  icon: _vm.$config.addIcon,
                  text: _vm._f("pgettext")("Add invoice...", "Payment"),
                  size: "sm",
                  variant: "light"
                },
                on: {
                  click: function($event) {
                    return _vm.evh_3343864469950044_click($event, {})
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ItpBox",
            { attrs: { "wrap-items": "", spacing: "px-2" } },
            [
              _vm.invoiceTotalsDiff
                ? _c(
                    "DIV",
                    {
                      staticClass: "text-nowrap",
                      attrs: {
                        title: _vm._f("pgettext")(
                          "Invoice amount mismatch",
                          "Payment"
                        )
                      }
                    },
                    [
                      _c("Icon", { attrs: { icon: "emojione-v1:warning" } }),
                      _vm._v(" "),
                      _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Mismatch between the amount of payment and invoices.",
                            "payment"
                          )
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.invoiceTotalsDiff) +
                            " " +
                            _vm._s(_vm.payment.amount) +
                            " " +
                            _vm._s(_vm.invoiceTotals.value()) +
                            "\n\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.payment.isReadonly
                ? _c(
                    "ItpFormCheckbox",
                    {
                      attrs: { name: "autocalcAmount", inline: "" },
                      model: {
                        value: _vm.autocalcAmount,
                        callback: function($$v) {
                          _vm.autocalcAmount = $$v
                        },
                        expression: "autocalcAmount"
                      }
                    },
                    [
                      _c("span", { staticClass: "text-nowrap" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "Autocalc total amount.",
                                "PaymentInvoices"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.payment.invoices.data
        ? _c("ItpDataTable", {
            attrs: {
              rowKeyField: "invoice_id",
              tableId: "paymentInvoices",
              paging: _vm.b_5962862420439144_paging,
              editable: !_vm.payment.isReadonly,
              sortable: _vm.b_5962862420439144_sortable,
              globs: _vm.messages,
              value: _vm.payment.invoices.data,
              "no-create-row": "",
              "no-confirm-delete-row": "",
              "footer-totals": "",
              columns: _vm.b_5962862420439144_columns
            },
            on: {
              "save-completed": function($event) {
                return _vm.evh_5962862420439144_saveCompleted($event, {})
              },
              delete: function($event) {
                return _vm.evh_5962862420439144_delete($event, {})
              },
              "delete-completed": function($event) {
                return _vm.evh_5962862420439144_deleteCompleted($event, {})
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }