<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpRow>
    <ItpCol
      col="7"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="name"
        :label="'Name'|pgettext('Company')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="name"
          v-model="item.name"
          required
          autofocus
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="number"
        :label="'Code'|pgettext('Company')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="number"
          v-model="item.code"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="5"
        name="account_type"
        :label="'Default'|gettext"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="is_default"
          inline
          v-model="item.is_default"
          :readonly="item.isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="relation"
        :label="'Type'|pgettext('Company')"
        :labelAlign="labelAlign"
        required
        v-if="item.role === 'partner'"
      >
        <ItpFormSelect
          name="relation"
          v-model="item.relation"
          required
          :options="companyRelations"
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="groups"
        :label="'Groups'|pgettext('Company')"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect2
          name="groups"
          v-model="item.groups"
          multiple
          :options="companyGroups"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="emails"
        :label="'Email'|pgettext('Company')"
        :labelAlign="labelAlign"
        :description="'Multiple email addresses should be separated by semicolon.'|pgettext('Company')"
      >
        <ItpFormInput
          type="text"
          name="emails"
          v-model="item.emails"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="notes"
        :label="'Notes'|pgettext('Company')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="notes"
          v-model="item.notes"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpCol>
    <ItpCol
      col="5"
    >
      <ItpFormGroup
        labelColsMd="4"
        name="tax_number"
        :label="'Tax Number'|pgettext('Company')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="tax_number"
          v-model="item.tax_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="eu_tax_number"
        :label="'EU Tax Number'|pgettext('Company')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="eu_tax_number"
          v-model="item.eu_tax_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="registration_number"
        :label="'Registration Number'|pgettext('Company')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="registration_number"
          v-model="item.registration_number"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <DIV
        v-if="!isOwnCompany"
      >
        <hr>
        </hr>
        <ItpFormGroup
          name="payment_period_days"
          :label="'Payment period'|pgettext('Company')"
          labelColsMd="4"
          contentColsMd="5"
          :labelAlign="labelAlign"
          contentClass="input-group"
          v-if=""
        >
          <ItpFormInput
            type="number"
            name="payment_period_days"
            v-model="item.payment_period_days"
          >
          </ItpFormInput>
          <DIV
            class="input-group-append"
          >
            <span
              class="input-group-text"
            >
              {{ 'days'|pgettext('InputGroupAddon') }}
            </span>
          </DIV>
        </ItpFormGroup>
        <ItpFormGroup
          name="discount"
          :label="'Discount'|pgettext('Company')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
        >
          <ItpFormInput
            type="number"
            name="discount"
            v-model="item.discount"
          >
          </ItpFormInput>
          <DIV
            class="input-group-append"
          >
            <span
              class="input-group-text"
            >
              %
            </span>
          </DIV>
        </ItpFormGroup>
        <hr>
        </hr>
        <ItpFormGroup
          labelColsMd="4"
          name="is_contractor"
          :labelAlign="labelAlign"
        >
          <ItpFormCheckbox
            name="is_contractor"
            :label="'Contractor'|pgettext('Company')"
            v-model="item.is_contractor"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="4"
          name="is_natural_person"
          :labelAlign="labelAlign"
        >
          <ItpFormCheckbox
            name="is_natural_person"
            :label="'Natural Person'|pgettext('Company')"
            v-model="item.is_natural_person"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="4"
          name="vat_exempt"
          :labelAlign="labelAlign"
        >
          <ItpFormCheckbox
            name="vat_exempt"
            :label="'VAT exempt'|pgettext('Company')"
            v-model="item.vat_exempt"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
        <ItpFormGroup
          labelColsMd="4"
          name="excluded_from_claims"
          :labelAlign="labelAlign"
        >
          <ItpFormCheckbox
            name="excluded_from_claims"
            :label="'Exclude from claims'|pgettext('Company')"
            v-model="item.excluded_from_claims"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
      </DIV>
    </ItpCol>
  </ItpRow>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetCompanyGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  companyRelations!: any;

  async $$load_companyRelations() {
    return this.$fn.getEnumValuesFromSchema('CompanyRelation', { sort: false })
  }

  companyGroups!: any;

  async $$load_companyGroups() {
    return this.$fn.fetch('list_group', { parameters: { type: 'company' } })
      .then(data => data.data.map(p => p.name))
  }

  ux!: any;
  dataMembers = ['companyRelations', 'companyGroups', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        companyRelations: null,
        companyGroups: null,
        ux: null,
      },
    }
  }

  get isOwnCompany() {
    if (this.loaded) {

      return this.item.role === 'own'
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4934: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4933: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4934 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4933 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4934,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4933],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4934,
      evh_7315092382398562_reload_4933,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetCompanyGeneral", FieldsetCompanyGeneral);

</script>