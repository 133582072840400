"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "worksheet",
    path: "/worksheets/:id",
    component: "Worksheet",
    params: [{
            name: "id",
            prop: "oid",
        },
    ]
});
let Worksheet = class Worksheet extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return (this.oid === 'create' || this.oid == null)
                ? this.$fn.fetch('get_worksheet_template', { asResource: true })
                : this.$fn.fetch('read_worksheet', { parameters: { id: this.oid }, asResource: true });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "full-screen",
            },
        });
    }
    get isNew() {
        if (this.loaded) {
            return this.item.isNew;
        }
        return null;
    }
    get isReadonly() {
        if (this.loaded) {
            return false;
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.snapshot('number'),
                    this.$fn.gettext("Worksheet"),
                ],
                icon: this.$config.worksheet.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_4180_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_4188_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "worksheets",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_reloadSlickgrid_4192_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "worksheets",
            };
        });
    }
    evh_1040167445267876_success_4191_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.id;
        });
    }
    act_1040167445267876_closeComponent_4194_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1040167445267876_success_4193_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !this.item.id;
        });
    }
    act_1040167445267876_navigate_4196_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.worksheet",
                    params: {
                        id: $event.data.id,
                    },
                },
            };
        });
    }
    evh_1040167445267876_success_4195_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !this.item.id;
        });
    }
    act_1040167445267876_setData_4198_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1040167445267876_success_4197_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.id && !!$event.data && !!$event.data.id;
        });
    }
    act_1040167445267876_form_4200_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.formId,
                reset: true,
            };
        });
    }
    evh_1040167445267876_success_4199_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.id && !!$event.data && !!$event.data.id;
        });
    }
    act_1040167445267876_crud_4190_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "worksheet",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1468474219929533_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1468474219929533_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_1306227577716690_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1306227577716690_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_8723442106880637_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8723442106880637_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_4180 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_4180_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_4179 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_4180,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_4179],
        });
        const act_2248226175642056_closeModal_4182 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_4181 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_4182,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_4181],
        });
        const act_8564662037462133_reloadComponentData_4184 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_4183 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_4184,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_4183],
        });
        const act_8564662037462133_closeModal_4186 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_8564662037462133_deleted_4185 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_4186,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_8564662037462133_reloadSlickgrid_4188 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_4188_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_4187 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_4188,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_4185, evh_8564662037462133_deleted_4187],
        });
        const act_1040167445267876_reloadSlickgrid_4192 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_4192_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4191 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_4192,
            event: "success",
            when: this.evh_1040167445267876_success_4191_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_closeComponent_4194 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_4194_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4193 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_4194,
            event: "success",
            when: this.evh_1040167445267876_success_4193_getWhen,
            displayName: "closeComponent",
        });
        const act_1040167445267876_navigate_4196 = new core_1.actions.NavigateAction({
            actionArgs: this.act_1040167445267876_navigate_4196_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4195 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_navigate_4196,
            event: "success",
            when: this.evh_1040167445267876_success_4195_getWhen,
            displayName: "navigate",
        });
        const act_1040167445267876_setData_4198 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_4198_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4197 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_4198,
            event: "success",
            when: this.evh_1040167445267876_success_4197_getWhen,
            displayName: "setData",
        });
        const act_1040167445267876_form_4200 = new core_1.actions.FormAction({
            actionArgs: this.act_1040167445267876_form_4200_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_4199 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_form_4200,
            event: "success",
            when: this.evh_1040167445267876_success_4199_getWhen,
            displayName: "form",
        });
        const act_1040167445267876_crud_4190 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_4190_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_4191, evh_1040167445267876_success_4193, evh_1040167445267876_success_4195, evh_1040167445267876_success_4197, evh_1040167445267876_success_4199],
        });
        const evh_1040167445267876_submit_4189 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_4190,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_4189],
        });
        const act_1468474219929533_reloadComponentData_4202 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_1468474219929533_reload_4201 = new core_1.actions.EventHandlerImpl({
            action: act_1468474219929533_reloadComponentData_4202,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_1468474219929533_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1468474219929533_reload_4201],
        });
        const act_1306227577716690_reloadComponentData_4204 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_1306227577716690_reload_4203 = new core_1.actions.EventHandlerImpl({
            action: act_1306227577716690_reloadComponentData_4204,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_1306227577716690_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1306227577716690_reload_4203],
        });
        const act_8723442106880637_reloadComponentData_4206 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8723442106880637_reload_4205 = new core_1.actions.EventHandlerImpl({
            action: act_8723442106880637_reloadComponentData_4206,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8723442106880637_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8723442106880637_reload_4205],
        });
        return {
            act_7315092382398562_closeModal_4180,
            evh_7315092382398562_close_4179,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_4182,
            evh_2248226175642056_close_4181,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_4184,
            evh_8564662037462133_reload_4183,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_4186,
            evh_8564662037462133_deleted_4185,
            act_8564662037462133_reloadSlickgrid_4188,
            evh_8564662037462133_deleted_4187,
            evh_8564662037462133_deleted,
            act_1040167445267876_reloadSlickgrid_4192,
            evh_1040167445267876_success_4191,
            act_1040167445267876_closeComponent_4194,
            evh_1040167445267876_success_4193,
            act_1040167445267876_navigate_4196,
            evh_1040167445267876_success_4195,
            act_1040167445267876_setData_4198,
            evh_1040167445267876_success_4197,
            act_1040167445267876_form_4200,
            evh_1040167445267876_success_4199,
            act_1040167445267876_crud_4190,
            evh_1040167445267876_submit_4189,
            evh_1040167445267876_submit,
            act_1468474219929533_reloadComponentData_4202,
            evh_1468474219929533_reload_4201,
            evh_1468474219929533_reload,
            act_1306227577716690_reloadComponentData_4204,
            evh_1306227577716690_reload_4203,
            evh_1306227577716690_reload,
            act_8723442106880637_reloadComponentData_4206,
            evh_8723442106880637_reload_4205,
            evh_8723442106880637_reload,
            save: act_1040167445267876_crud_4190,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Worksheet.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Worksheet.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], Worksheet.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Worksheet.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], Worksheet.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Worksheet.prototype, "onSize", null);
Worksheet = __decorate([
    (0, vue_property_decorator_1.Component)()
], Worksheet);
exports.default = Worksheet;
vue_property_decorator_1.Vue.component("Worksheet", Worksheet);
