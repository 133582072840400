<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoicesView"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="invoices"
    :headerTitle="titles[kind]"
    :items="getItems"
    :headerShowReloadButton="b_2248226175642056_headerShowReloadButton"
    checkbox-row-selection
    footer-totals
    export-to-csv
    :exportToCsvFilename="'invoices'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex flex-wrap"
      slot="toolbar"
    >
      <ItpButton
        icon="fa-plus"
        :text="'New Invoice...'|pgettext('InvoicesView')"
        size="sm"
        variant="light"
        class="mx-2 my-1"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        :text="'Import Invoices...'|pgettext('InvoicesView')"
        :icon="$config.uploadIcon"
        variant="light"
        class="mx-2 my-1"
        v-if="kind === 'incoming'"
        @click.prevent="evh_8320016629450276_clickPrevent($event, {})"
      >
      </ItpButton>
      <JobHandlerControl
        jobType="invoice_print"
        load-current
        :text="'Print invoices...'|pgettext('InvoicesView')"
        icon="icon-park-outline:printer-one"
        :payload="() => onPrintInvoicesPayload()"
        :disabled="!selectedItems || !selectedItems.length"
        class="mx-2 my-1"
      >
      </JobHandlerControl>
      <InvoicesViewFilters
        :kind="kind"
        @submit="evh_6806515264385235_submit($event, {})"
      >
      </InvoicesViewFilters>
      <PageHelp
        path="/finance/invoices.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoicesView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getItems(...args: any[]) {
    const parameters = this.params;
    return parameters ? this.$fn.fetch('list_invoice', { parameters }) : []
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  onPrintInvoicesPayload(...args: any[]) {
    return this.$imodal
      .show({ name: 'CompleteInvoicesAlt' })
      .then(result => {
        if (!result || result.cancel || !result.value) {
          return;
        }
        return this.selectedItems.map(p => ({ ...result.value, invoice_id: p.id }))
      }
      )
  }

  @Prop({
    required: true,
    type: String,
  })
  kind!: string;

  @Watch('kind')
  onKind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("kind")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  titles!: any;
  selectedItems!: any;
  params!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_headerShowReloadButton!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "invoice_number",
      name: this.$fn.pgettext("InvoicesView", "Invoice nr"),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "routeLink",
          to: "invoice",
          params: {
            id: "id",
          }
          ,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked,
            'bg-yellow-500 text-yellow-800': row.is_corrected,
            'bg-red-300 text-red-800': row.is_reversed,
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("InvoicesView", "Type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "invoice_status",
      name: this.$fn.pgettext("InvoicesView", "Status"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "Status",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          variantmap: this.$config.invoice.invoiceStatusVariants,
          variantMapSource: "fieldValue",
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "invoice_register_entry",
      name: this.$fn.pgettext("InvoicesView", "Register entry"),
      type: "command",
      formatter: {
        name: "chain",
        mode: "mapReduce",
        separator: ", ",
        formatterContext: "item",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "entry_number",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (value) => ({
            'font-weight-bold': true,
            'bg-yellow-200': value && !value.locked
          }),
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "invoice_date",
      name: this.$fn.pgettext("InvoicesView", "Date"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("InvoicesView", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("InvoicesView", "Due date"),
      type: "date",
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("InvoicesView", "Client"),
      formatter: {
        name: "routeLink",
        to: "company",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("InvoicesView", "Description"),
    }
      ,
    {
      field: "net_total",
      name: this.$fn.pgettext("InvoicesView", "Net"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "vat_total",
      name: this.$fn.pgettext("InvoicesView", "VAT"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "total",
      name: this.$fn.pgettext("InvoicesView", "Total"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "paid",
      name: this.$fn.pgettext("InvoicesView", "Paid"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "unpaid",
      name: this.$fn.pgettext("InvoicesView", "Claim"),
      type: "number",
      formatter: {
        name: "chain",
        formatters: [{
          name: "localestring",
          locale: "hu-HU",
        }
          ,
        {
          name: "cellclass",
          value: (row) => {
            if (row.flags && row.flags.no_payment_required) {
              return 'font-weight-bold text-decoration-line-through';
            }

            if (row.unpaid != null && row.unpaid > 0) {
              return 'font-weight-bold text-red-500';
            }

            if (row.unpaid != null && row.unpaid < 0) {
              return 'font-weight-bold text-blue-500';
            }

            if (row.unpaid == 0) {
              return 'font-weight-bold text-green-500';
            }
          },
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("InvoicesView", "Currency"),
    }
      ,
    {
      field: "tags",
      name: this.$fn.pgettext("InvoicesView", "Tags"),
    }
      ,
    {
      field: "rentals",
      name: this.$fn.pgettext("InvoicesView", "Rentals"),
      formatter: {
        name: "chain",
        mode: "mapReduce",
        separator: ", ",
        formatterContext: "value",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "name",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "routeLink",
          to: "rental",
          navigateOnIconClick: true,
          params: {
            id: "id",
          }
          ,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "contracts",
      name: this.$fn.pgettext("InvoicesView", "Contracts"),
      formatter: {
        name: "chain",
        mode: "mapReduce",
        separator: ", ",
        formatterContext: "value",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "contract_number",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "routeLink",
          to: "contract",
          navigateOnIconClick: true,
          params: {
            id: "id",
          }
          ,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    ]
  }

  dataMembers = ['titles', 'selectedItems', 'params', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_headerShowReloadButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        titles: {
          incoming: this.$fn.pgettext("InvoicesView", "Vendor invoices"),
          outgoing: this.$fn.pgettext("InvoicesView", "Customer invoices"),
        }
        ,
        selectedItems: [
        ]
        ,
        params: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_headerShowReloadButton: false,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.titles[this.kind],
        icon: this.$config.invoice.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5564_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5564, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5566_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5566, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_script_5570_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2248226175642056_script_5570, alias=undefined
    this.$refs.grid.reload()
  }

  async act_2248226175642056_script_5570_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2248226175642056_script_5570, alias=undefined
    return {
      value: () => this.act_2248226175642056_script_5570_getActionArgs_value($event),
    }
  }

  async act_2248226175642056_showModal_5568_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_5568, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.invoice_register_entry.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_5567_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_5567, alias=undefined
    return $event.data.column.id === 'invoice_register_entry' && $event.data.data.invoice_register_entry != null
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_5572_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_5572, alias=undefined
    return {
      name: "CreateInvoice",
      props: {
        invoiceKind: this.kind,
      }
      ,
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_5574_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_5574, alias=undefined
    return {
      name: "ImportInvoices",
    }
  }

  async evh_8320016629450276_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_6806515264385235_setData_5576_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6806515264385235_setData_5576, alias=undefined
    return {
      path: "params",
      value: { ...$event.data, kind: this.kind },
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_6806515264385235_script_5578_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_6806515264385235_script_5578, alias=undefined
    this.$refs.grid.reload()
  }

  async act_6806515264385235_script_5578_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_6806515264385235_script_5578, alias=undefined
    return {
      value: () => this.act_6806515264385235_script_5578_getActionArgs_value($event),
    }
  }

  async evh_6806515264385235_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6806515264385235_submit.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5564: actions.CloseModalAction;
    evh_7315092382398562_close_5563: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_5566: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_5565: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_script_5570: actions.ScriptAction;
    evh_2248226175642056_saved_5569: actions.EventHandlerImpl;
    act_2248226175642056_showModal_5568: actions.ShowModalAction;
    evh_2248226175642056_command_5567: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_6466705138206980_showModal_5572: actions.ShowModalAction;
    evh_6466705138206980_click_5571: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
    act_8320016629450276_showModal_5574: actions.ShowModalAction;
    evh_8320016629450276_clickPrevent_5573: actions.EventHandlerImpl;
    evh_8320016629450276_clickPrevent: actions.EventHandlerGroup;
    act_6806515264385235_setData_5576: actions.SetDataAction;
    evh_6806515264385235_submit_5575: actions.EventHandlerImpl;
    act_6806515264385235_script_5578: actions.ScriptAction;
    evh_6806515264385235_submit_5577: actions.EventHandlerImpl;
    evh_6806515264385235_submit: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5564 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5564_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5563 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5564,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5563],
      }
    );
    const act_2248226175642056_setData_5566 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5566_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_5565 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5566,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_5565],
      }
    );
    const act_2248226175642056_script_5570 = new actions.ScriptAction(
      {
        actionArgs: this.act_2248226175642056_script_5570_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_saved_5569 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_script_5570,
        event: "saved",
        displayName: "script",
      }
    );
    const act_2248226175642056_showModal_5568 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_5568_getActionArgs,
        events: [evh_2248226175642056_saved_5569],
      }
    );
    const evh_2248226175642056_command_5567 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_5568,
        event: "command",
        when: this.evh_2248226175642056_command_5567_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_5567],
      }
    );
    const act_6466705138206980_showModal_5572 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_5572_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_5571 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_5572,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_5571],
      }
    );
    const act_8320016629450276_showModal_5574 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_5574_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_clickPrevent_5573 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_5574,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPrevent_5573],
      }
    );
    const act_6806515264385235_setData_5576 = new actions.SetDataAction(
      {
        actionArgs: this.act_6806515264385235_setData_5576_getActionArgs,
        events: [],
      }
    );
    const evh_6806515264385235_submit_5575 = new actions.EventHandlerImpl(
      {
        action: act_6806515264385235_setData_5576,
        event: "submit",
        displayName: "setData",
      }
    );
    const act_6806515264385235_script_5578 = new actions.ScriptAction(
      {
        actionArgs: this.act_6806515264385235_script_5578_getActionArgs,
        events: [],
      }
    );
    const evh_6806515264385235_submit_5577 = new actions.EventHandlerImpl(
      {
        action: act_6806515264385235_script_5578,
        event: "submit",
        displayName: "script",
      }
    );
    const evh_6806515264385235_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_6806515264385235_submit_5575, evh_6806515264385235_submit_5577],
      }
    );
    return {
      act_7315092382398562_closeModal_5564,
      evh_7315092382398562_close_5563,
      evh_7315092382398562_close,
      act_2248226175642056_setData_5566,
      evh_2248226175642056_selectedRowsChanged_5565,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_script_5570,
      evh_2248226175642056_saved_5569,
      act_2248226175642056_showModal_5568,
      evh_2248226175642056_command_5567,
      evh_2248226175642056_command,
      act_6466705138206980_showModal_5572,
      evh_6466705138206980_click_5571,
      evh_6466705138206980_click,
      act_8320016629450276_showModal_5574,
      evh_8320016629450276_clickPrevent_5573,
      evh_8320016629450276_clickPrevent,
      act_6806515264385235_setData_5576,
      evh_6806515264385235_submit_5575,
      act_6806515264385235_script_5578,
      evh_6806515264385235_submit_5577,
      evh_6806515264385235_submit,
    }
  }
}

Vue.component("InvoicesView", InvoicesView);

</script>