<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateDunningLetter"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="create-options"
      id="om71edxw7f"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="lastNumber"
        :label="'Last reference number'|gettext"
        label-cols-md="5"
        content-cols-md="7"
      >
        <b-form-input
          plaintext
          v-model="numbering.info.last_number"
          class="font-weight-bold no-focus-border"
        >
        </b-form-input>
      </ItpFormGroup>
      <ItpFormGroup
        row
        name="startingReferenceNumber"
        :label="'Next reference number'|gettext"
        label-cols-md="5"
        content-cols-md="3"
      >
        <ItpFormInput
          type="number"
          name="startingReferenceNumber"
          size="md"
          v-model="startingReferenceNumber"
          number
          :min="numbering.info.next_value"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpAlert
        show
      >
        <ItpIcon
          name="fa-info-circle"
          class="mr-1"
        >
        </ItpIcon>
        <span>
          {{ 'Next reference numbers will be generated from the given value. It must be higher than the current value.'|gettext }}
        </span>
      </ItpAlert>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      form="om71edxw7f"
      :text="'Generate'|pgettext('DunningLetterCreatePage')"
      type="submit"
      variant="primary"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateDunningLetter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    default: null,
  })
  items!: list;

  @Watch('items')
  onItems(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("items")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  numbering!: any;

  async $$load_numbering() {
    return this.$fn.fetch('read_numbering', { parameters: { id: 'sys.dunning_letter' } })
  }

  startingReferenceNumber!: any;

  async $$load_startingReferenceNumber() {
    return this.numbering.info.next_value
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['numbering', 'startingReferenceNumber', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        numbering: null,
        startingReferenceNumber: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Create dunning letters"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5280_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5280, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_register_5286_getActionArgs($event: actions.ActionEvent): Promise<actions.RegisterActionArgs> {
    // parameterResolver name=act_1040167445267876_register_5286, alias=undefined
    return {
      name: "resultLength",
      value: $event.data.response.data ? $event.data.response.data.length : 0,
    }
  }

  async act_1040167445267876_notification_5288_getActionArgs($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_5288, alias=undefined
    return {
      message: this.$fn.ngettext("One dunning letter created.", "${n} dunning letters created.", $event.data.resultLength),
    }
  }

  async act_1040167445267876_notification_5288_getWhen($event: actions.ActionEvent): Promise<actions.NotificationActionArgs> {
    // parameterResolver name=act_1040167445267876_notification_5288, alias=undefined
    return $event.data.resultLength > 0
  }

  async act_1040167445267876_closeModal_5290_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_5290, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_5292_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_5292, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_1040167445267876_request_5284_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_5284, alias=submit
    return {
      operation: "create_dunning_letter",
      data: _.chain(this.items)
        .groupBy(p => `${p.issuer.id}${p.client_id}`)
        .map((items, g) => ({
          company_id: items[0].issuer.id,
          client_id: items[0].client_id,
          items: _.map(items, p => ({ invoice_number: p.invoice_number }))
        }))
        .value(),
      parameters: {
        "starting-reference-number": this.startingReferenceNumber,
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5280: actions.CloseModalAction;
    evh_7315092382398562_close_5279: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5282: actions.CloseComponentAction;
    evh_2248226175642056_close_5281: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_register_5286: actions.RegisterAction;
    evh_1040167445267876_success_5285: actions.EventHandlerImpl;
    act_1040167445267876_notification_5288: actions.NotificationAction;
    evh_1040167445267876_success_5287: actions.EventHandlerImpl;
    act_1040167445267876_closeModal_5290: actions.CloseModalAction;
    evh_1040167445267876_success_5289: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_5292: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_5291: actions.EventHandlerImpl;
    act_1040167445267876_request_5284: actions.RequestAction;
    evh_1040167445267876_submit_5283: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_5294: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_5293: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5280 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5280_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5279 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5280,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5279],
      }
    );
    const act_2248226175642056_closeComponent_5282 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5281 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5282,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5281],
      }
    );
    const act_1040167445267876_register_5286 = new actions.RegisterAction(
      {
        actionArgs: this.act_1040167445267876_register_5286_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5285 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_register_5286,
        event: "success",
        displayName: "register",
      }
    );
    const act_1040167445267876_notification_5288 = new actions.NotificationAction(
      {
        actionArgs: this.act_1040167445267876_notification_5288_getActionArgs,
        when: this.act_1040167445267876_notification_5288_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_5287 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_notification_5288,
        event: "success",
        displayName: "notification",
      }
    );
    const act_1040167445267876_closeModal_5290 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_5290_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5289 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_5290,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_5292 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_5292_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_5291 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_5292,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_request_5284 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_5284_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_5285, evh_1040167445267876_success_5287, evh_1040167445267876_success_5289, evh_1040167445267876_success_5291],
      }
    );
    const evh_1040167445267876_submit_5283 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_5284,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_5283],
      }
    );
    const act_1419464017721962_closeComponent_5294 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_5293 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_5294,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_5293],
      }
    );
    return {
      act_7315092382398562_closeModal_5280,
      evh_7315092382398562_close_5279,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5282,
      evh_2248226175642056_close_5281,
      evh_2248226175642056_close,
      act_1040167445267876_register_5286,
      evh_1040167445267876_success_5285,
      act_1040167445267876_notification_5288,
      evh_1040167445267876_success_5287,
      act_1040167445267876_closeModal_5290,
      evh_1040167445267876_success_5289,
      act_1040167445267876_reloadSlickgrid_5292,
      evh_1040167445267876_success_5291,
      act_1040167445267876_request_5284,
      evh_1040167445267876_submit_5283,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_5294,
      evh_1419464017721962_clickPreventStop_5293,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_request_5284,
    }
  }
}

Vue.component("CreateDunningLetter", CreateDunningLetter);

</script>