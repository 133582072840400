var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniCard", {
    attrs: {
      colorCode: _vm.$config.company.colors[_vm.effectiveRole],
      badge: _vm.badge,
      noIcon: _vm.noIcon,
      title: _vm.messages.title[_vm.effectiveRole],
      icon: _vm.$config.company.icons[_vm.effectiveRole],
      text: _vm.item.name,
      linkTo: {
        name: "app.company",
        params: {
          id: _vm.item.id
        }
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }