"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcAccountSelector = class UcAccountSelector extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['accounts', 'hasInactiveItem', 'accountGroups', 'states', 'ux'];
    }
    beforeCreate() {
        this.$$cache_states = new core_1.ComponentValueCache(this, "states", () => this.$$load_states_cache_key());
    }
    $$load_states_cache_key() {
        return this.cacheKey ? ('AccountSelector.' + this.cacheKey) : null;
    }
    createAccountGrups(...args) {
        const [accounts] = args;
        const showInactive = this.states.showInactive;
        const accountType = this.accountType;
        return _
            .chain(accounts)
            .filter(p => accountType ? p.account_type === accountType : true)
            .groupBy(p => p.owner_id)
            .map((items, owner_id) => ({
            id: owner_id,
            name: items[0].owner_code + ' - ' + items[0].owner_name,
            items: !showInactive ? _.filter(items, { inactive: false }) : items
        }))
            .filter(p => p.items.length)
            .value();
    }
    itemChanged(...args) {
        const [item, value] = args;
        if (value == null || item == null) {
            return true;
        }
        return value.id != item.id;
    }
    onValue(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("value");
    }
    onDisplay_owner(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("displayOwner");
    }
    onCache_key(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("cacheKey");
    }
    onAccount_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("accountType");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']));
        });
    }
    $$load_hasInactiveItem() {
        return __awaiter(this, void 0, void 0, function* () {
            return _.some(this.$data.accounts, { inactive: true });
        });
    }
    $$load_accountGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createAccountGrups(this.$data.accounts);
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            accounts: null,
            hasInactiveItem: null,
            accountGroups: null,
            states: {
                selectedAccountId: null,
                showInactive: false,
            },
            ux: null,
        });
    }
    get selectedAccountType() {
        if (this.loaded) {
            return this.value && this.value.account_type;
        }
        return null;
    }
    get selectedAccount() {
        if (this.loaded) {
            return _.isObject(this.value) ? this.value : null;
        }
        return null;
    }
    act_1717672417478439_setData_4850_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "accountGroups",
                value: this.createAccountGrups(this.accounts),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1717672417478439_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1717672417478439_change.executeFromDOM(this, event, scope);
        });
    }
    act_8127633238173630_emit_4852_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "input",
                value: $event.scope.item,
            };
        });
    }
    evh_8127633238173630_clickPrevent_4851_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.itemChanged($event.scope.item, this.value);
        });
    }
    act_8127633238173630_setData_4854_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "states.selectedAccountId",
                value: $event.scope.item.id,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8127633238173630_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8127633238173630_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_1717672417478439_setData_4850 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1717672417478439_setData_4850_getActionArgs,
            events: [],
        });
        const evh_1717672417478439_change_4849 = new core_1.actions.EventHandlerImpl({
            action: act_1717672417478439_setData_4850,
            event: "change",
            displayName: "setData",
        });
        const evh_1717672417478439_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1717672417478439_change_4849],
        });
        const act_8127633238173630_emit_4852 = new core_1.actions.EmitAction({
            actionArgs: this.act_8127633238173630_emit_4852_getActionArgs,
            events: [],
        });
        const evh_8127633238173630_clickPrevent_4851 = new core_1.actions.EventHandlerImpl({
            action: act_8127633238173630_emit_4852,
            event: "click.prevent",
            when: this.evh_8127633238173630_clickPrevent_4851_getWhen,
            displayName: "emit",
        });
        const act_8127633238173630_setData_4854 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8127633238173630_setData_4854_getActionArgs,
            events: [],
        });
        const evh_8127633238173630_clickPrevent_4853 = new core_1.actions.EventHandlerImpl({
            action: act_8127633238173630_setData_4854,
            event: "click.prevent",
            displayName: "setData",
        });
        const evh_8127633238173630_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8127633238173630_clickPrevent_4851, evh_8127633238173630_clickPrevent_4853],
        });
        return {
            act_1717672417478439_setData_4850,
            evh_1717672417478439_change_4849,
            evh_1717672417478439_change,
            act_8127633238173630_emit_4852,
            evh_8127633238173630_clickPrevent_4851,
            act_8127633238173630_setData_4854,
            evh_8127633238173630_clickPrevent_4853,
            evh_8127633238173630_clickPrevent,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({}),
    __metadata("design:type", Object)
], UcAccountSelector.prototype, "value", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAccountSelector.prototype, "onValue", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcAccountSelector.prototype, "displayOwner", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('displayOwner'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAccountSelector.prototype, "onDisplay_owner", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcAccountSelector.prototype, "cacheKey", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('cacheKey'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAccountSelector.prototype, "onCache_key", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcAccountSelector.prototype, "accountType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('accountType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAccountSelector.prototype, "onAccount_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcAccountSelector.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcAccountSelector.prototype, "onReadonly", null);
UcAccountSelector = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcAccountSelector);
exports.default = UcAccountSelector;
vue_property_decorator_1.Vue.component("UcAccountSelector", UcAccountSelector);
