"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let PaymentsViewFilters = class PaymentsViewFilters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'accounts', 'accountGroups', 'ux', 'b_5962862420439144_style'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "PaymentsViewFilters");
    }
    createAccountGrups(...args) {
        const [accounts] = args;
        const showInactiveAccounts = this.item.showInactiveAccounts;
        return _
            .chain(accounts)
            .groupBy(p => p.owner_id)
            .map((items, owner_id) => ({
            id: owner_id,
            name: items[0].owner_name,
            items: !showInactiveAccounts ? _.filter(items, { inactive: false }) : items
        }))
            .filter(p => p.items.length)
            .value();
    }
    $$load_accounts() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_account')
                .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']));
        });
    }
    $$load_accountGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.createAccountGrups(this.$data.accounts);
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: {
                date_from: null,
                date_to: null,
                account: null,
                showInactiveAccounts: false,
            },
            accounts: null,
            accountGroups: null,
            ux: null,
            b_5962862420439144_style: {
                width: "500px",
            },
        });
    }
    get selectedAccountType() {
        if (this.loaded) {
            return this.item.account && this.item.account.account_type;
        }
        return null;
    }
    get selectedAccount() {
        if (this.loaded) {
            return _.isObject(this.item.account) ? this.item.account : null;
        }
        return null;
    }
    act_5962862420439144_setData_5802_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5962862420439144_setData_5804_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5962862420439144_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_changed.executeFromDOM(this, event, scope);
        });
    }
    act_8358196963355853_emit_5806_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: Object.assign(Object.assign({}, this.item), { account: this.item.account.id }),
            };
        });
    }
    evh_8358196963355853_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8358196963355853_click.executeFromDOM(this, event, scope);
        });
    }
    act_6424600534600827_showModal_5808_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    transactionKind: "cash_book",
                    transactionType: "deposit",
                },
            };
        });
    }
    evh_6424600534600827_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6424600534600827_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8249915319319009_showModal_5810_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    transactionKind: "cash_book",
                    transactionType: "payment",
                },
            };
        });
    }
    evh_8249915319319009_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8249915319319009_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2276125547171722_showModal_5812_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    accountId: this.item.account ? this.item.account.id : null,
                    transactionKind: this.selectedAccountType === 'bank_account' ? 'bank' : this.selectedAccountType,
                },
            };
        });
    }
    evh_2276125547171722_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2276125547171722_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1836715606397834_showModal_5814_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ImportBankStatement",
            };
        });
    }
    evh_1836715606397834_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1836715606397834_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_5962862420439144_setData_5802 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5962862420439144_setData_5802_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_changed_5801 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_setData_5802,
            event: "changed",
            displayName: "setData",
        });
        const act_5962862420439144_setData_5804 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5962862420439144_setData_5804_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_changed_5803 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_setData_5804,
            event: "changed",
            displayName: "setData",
        });
        const evh_5962862420439144_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_changed_5801, evh_5962862420439144_changed_5803],
        });
        const act_8358196963355853_emit_5806 = new core_1.actions.EmitAction({
            actionArgs: this.act_8358196963355853_emit_5806_getActionArgs,
            displayName: "load",
            events: [],
        });
        const evh_8358196963355853_click_5805 = new core_1.actions.EventHandlerImpl({
            action: act_8358196963355853_emit_5806,
            event: "click",
            displayName: "load",
        });
        const evh_8358196963355853_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8358196963355853_click_5805],
        });
        const act_6424600534600827_showModal_5808 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6424600534600827_showModal_5808_getActionArgs,
            events: [],
        });
        const evh_6424600534600827_clickPrevent_5807 = new core_1.actions.EventHandlerImpl({
            action: act_6424600534600827_showModal_5808,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6424600534600827_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6424600534600827_clickPrevent_5807],
        });
        const act_8249915319319009_showModal_5810 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8249915319319009_showModal_5810_getActionArgs,
            events: [],
        });
        const evh_8249915319319009_clickPrevent_5809 = new core_1.actions.EventHandlerImpl({
            action: act_8249915319319009_showModal_5810,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8249915319319009_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8249915319319009_clickPrevent_5809],
        });
        const act_2276125547171722_showModal_5812 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2276125547171722_showModal_5812_getActionArgs,
            events: [],
        });
        const evh_2276125547171722_clickPrevent_5811 = new core_1.actions.EventHandlerImpl({
            action: act_2276125547171722_showModal_5812,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2276125547171722_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2276125547171722_clickPrevent_5811],
        });
        const act_1836715606397834_showModal_5814 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1836715606397834_showModal_5814_getActionArgs,
            events: [],
        });
        const evh_1836715606397834_clickPrevent_5813 = new core_1.actions.EventHandlerImpl({
            action: act_1836715606397834_showModal_5814,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_1836715606397834_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1836715606397834_clickPrevent_5813],
        });
        return {
            act_5962862420439144_setData_5802,
            evh_5962862420439144_changed_5801,
            act_5962862420439144_setData_5804,
            evh_5962862420439144_changed_5803,
            evh_5962862420439144_changed,
            act_8358196963355853_emit_5806,
            evh_8358196963355853_click_5805,
            evh_8358196963355853_click,
            act_6424600534600827_showModal_5808,
            evh_6424600534600827_clickPrevent_5807,
            evh_6424600534600827_clickPrevent,
            act_8249915319319009_showModal_5810,
            evh_8249915319319009_clickPrevent_5809,
            evh_8249915319319009_clickPrevent,
            act_2276125547171722_showModal_5812,
            evh_2276125547171722_clickPrevent_5811,
            evh_2276125547171722_clickPrevent,
            act_1836715606397834_showModal_5814,
            evh_1836715606397834_clickPrevent_5813,
            evh_1836715606397834_clickPrevent,
            load: act_8358196963355853_emit_5806,
        };
    }
};
PaymentsViewFilters = __decorate([
    (0, vue_property_decorator_1.Component)()
], PaymentsViewFilters);
exports.default = PaymentsViewFilters;
vue_property_decorator_1.Vue.component("PaymentsViewFilters", PaymentsViewFilters);
