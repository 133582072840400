var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpRow",
            [
              _c(
                "ItpCol",
                { attrs: { w: "12" } },
                [
                  _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "2",
                        contentColsMd: "5",
                        name: "account",
                        label: _vm._f("pgettext")("Account", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("UcAccountSelector", {
                        staticClass: "mr-2",
                        attrs: {
                          name: "account",
                          cacheKey: "Payment",
                          accountType: _vm.payment.account.account_type,
                          readonly: _vm.isFieldReadonly("account")
                        },
                        on: {
                          input: function($event) {
                            return _vm.evh_4615685607825208_input($event, {})
                          }
                        },
                        model: {
                          value: _vm.payment.account,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "account", $$v)
                          },
                          expression: "payment.account"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isFieldEnabled("transaction_title")
                    ? _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "2",
                            contentColsMd: "10",
                            name: "transaction_title",
                            label: _vm._f("pgettext")("Title", "Payment"),
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "transaction_title",
                              valueField: "name",
                              textField: "name",
                              options: _vm.transactionTitles,
                              taggable: "",
                              clearable: "",
                              readonly: _vm.isFieldReadonly("transaction_title")
                            },
                            model: {
                              value: _vm.payment.transaction_title,
                              callback: function($$v) {
                                _vm.$set(_vm.payment, "transaction_title", $$v)
                              },
                              expression: "payment.transaction_title"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFieldEnabled("partner")
                    ? _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "2",
                            contentColsMd: "10",
                            name: "client",
                            label: _vm._f("pgettext")("Client", "Payment"),
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "client",
                              "bind-object": "",
                              dataSource: _vm.b_8697745105058001_dataSource,
                              optionTemplate:
                                "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
                              "use-option-template-for-selected-option": "",
                              textField: "name",
                              clearable: "",
                              readonly: _vm.isFieldReadonly("partner")
                            },
                            model: {
                              value: _vm.payment.partner,
                              callback: function($$v) {
                                _vm.$set(_vm.payment, "partner", $$v)
                              },
                              expression: "payment.partner"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFieldEnabled("employee")
                    ? _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            labelColsMd: "2",
                            contentColsMd: "6",
                            name: "client",
                            label: _vm._f("pgettext")("Employee", "Payment"),
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormSelect2", {
                            attrs: {
                              name: "client",
                              "bind-object": "",
                              options: _vm.employees,
                              textField: "name",
                              clearable: "",
                              readonly: _vm.isFieldReadonly("employee")
                            },
                            model: {
                              value: _vm.payment.employee,
                              callback: function($$v) {
                                _vm.$set(_vm.payment, "employee", $$v)
                              },
                              expression: "payment.employee"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("HR"),
      _vm._v(" "),
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "5", form: "" } },
            [
              _vm.isFieldEnabled("transaction_number")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "transaction_number",
                        label: _vm._f("pgettext")("Number", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "transaction_number",
                          readonly: _vm.isFieldReadonly("transaction_number"),
                          placeholder: _vm._f("pgettext")(
                            "Enter payment number",
                            "Payment|transaction_number|placeholder"
                          ),
                          required: ""
                        },
                        model: {
                          value: _vm.payment.transaction_number,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "transaction_number", $$v)
                          },
                          expression: "payment.transaction_number"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFieldEnabled("transaction_date")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "transaction_date",
                        label: _vm._f("pgettext")("Payment Date", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormDatetime", {
                        attrs: {
                          name: "transaction_date",
                          readonly: _vm.isFieldReadonly("transaction_date"),
                          required: ""
                        },
                        model: {
                          value: _vm.payment.transaction_date,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "transaction_date", $$v)
                          },
                          expression: "payment.transaction_date"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFieldEnabled("payment_method")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "payment_method",
                        label: _vm._f("pgettext")("Method", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect", {
                        attrs: {
                          name: "payment_method",
                          options: _vm.paymentMethods,
                          readonly: _vm.isFieldReadonly("payment_method"),
                          required: ""
                        },
                        model: {
                          value: _vm.payment.payment_method,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "payment_method", $$v)
                          },
                          expression: "payment.payment_method"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFieldEnabled("transaction_type") && _vm.transactionTypes
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "transaction_type",
                        label: _vm._f("pgettext")("Payment type", "Payment"),
                        labelAlign: _vm.labelAlign,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormSelect", {
                        attrs: {
                          name: "transaction_type",
                          options: _vm.transactionTypes,
                          readonly: _vm.isFieldReadonly("transaction_type"),
                          required: ""
                        },
                        model: {
                          value: _vm.payment.transaction_type,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "transaction_type", $$v)
                          },
                          expression: "payment.transaction_type"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFieldEnabled("amount")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "5",
                        contentColsMd: "7",
                        name: "amount",
                        label: _vm._f("pgettext")("Amount", "Payment"),
                        labelAlign: _vm.labelAlign,
                        appendText: _vm.payment.currency,
                        required: ""
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          autofocus: "",
                          name: "amount",
                          readonly: _vm.isFieldReadonly("amount"),
                          step: "any",
                          required: "",
                          min: "0",
                          mask: "number",
                          unmask: "typed",
                          scale: "2"
                        },
                        model: {
                          value: _vm.payment.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "amount", $$v)
                          },
                          expression: "payment.amount"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ItpCol",
            { attrs: { w: "7" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    contentColsMd: "8",
                    name: "description",
                    label: _vm._f("pgettext")("Description", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "description", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.description,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "description", $$v)
                      },
                      expression: "payment.description"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "reference_number",
                    label: _vm._f("pgettext")("Reference number", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: {
                      name: "reference_number",
                      readonly: _vm.isReadOnly
                    },
                    model: {
                      value: _vm.payment.reference_number,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "reference_number", $$v)
                      },
                      expression: "payment.reference_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.isFieldEnabled("statement_number")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "4",
                        name: "statement_number",
                        label: _vm._f("pgettext")(
                          "Statement number",
                          "Payment"
                        ),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "statement_number",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.payment.statement_number,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "statement_number", $$v)
                          },
                          expression: "payment.statement_number"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFieldEnabled("transaction_code")
                ? _c(
                    "ItpFormGroup",
                    {
                      attrs: {
                        labelColsMd: "4",
                        contentColsMd: "4",
                        name: "transaction_code",
                        label: _vm._f("pgettext")(
                          "Transaction code",
                          "Payment"
                        ),
                        labelAlign: _vm.labelAlign
                      }
                    },
                    [
                      _c("ItpFormInput", {
                        attrs: {
                          name: "transaction_code",
                          readonly: _vm.isReadOnly
                        },
                        model: {
                          value: _vm.payment.transaction_code,
                          callback: function($$v) {
                            _vm.$set(_vm.payment, "transaction_code", $$v)
                          },
                          expression: "payment.transaction_code"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "4",
                    name: "file_number",
                    label: _vm._f("pgettext")("File number", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormInput", {
                    attrs: { name: "file_number", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.file_number,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "file_number", $$v)
                      },
                      expression: "payment.file_number"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("HR"),
      _vm._v(" "),
      _c(
        "ItpRow",
        [
          _c(
            "ItpCol",
            { attrs: { w: "12", form: "" } },
            [
              _c(
                "ItpFormGroup",
                {
                  attrs: {
                    labelColsMd: "2",
                    contentColsMd: "10",
                    name: "notes",
                    label: _vm._f("pgettext")("Message", "Payment"),
                    labelAlign: _vm.labelAlign
                  }
                },
                [
                  _c("ItpFormTextarea", {
                    attrs: { name: "notes", readonly: _vm.isReadOnly },
                    model: {
                      value: _vm.payment.notes,
                      callback: function($$v) {
                        _vm.$set(_vm.payment, "notes", $$v)
                      },
                      expression: "payment.notes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }