var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Product",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "MaterialTabs",
            [
              _c(
                "ItpBox",
                {
                  staticClass: "mr-3",
                  attrs: { slot: "tabs-start", alignItems: "center" },
                  slot: "tabs-start"
                },
                [
                  _vm.loaded
                    ? _c(
                        "b-breadcrumb",
                        { staticClass: "m-0 bg-white" },
                        [
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text: _vm._f("gettext")("Products"),
                              to: _vm.b_4615685607825208_to
                            }
                          }),
                          _vm._v(" "),
                          _c("b-breadcrumb-item", {
                            attrs: {
                              text:
                                _vm.item.snapshot("number") +
                                " " +
                                _vm.item.snapshot("name"),
                              active: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "px-3",
                    attrs: {
                      variant: "default",
                      icon: "fa-sync-alt",
                      text: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("BtnDeleteResource", {
                    staticClass: "mr-2",
                    attrs: {
                      resource: _vm.item,
                      "no-text": "",
                      dialogHeader: _vm._f("pgettext")(
                        "Delete product",
                        "Modal|Header"
                      )
                    },
                    on: {
                      success: function($event) {
                        return _vm.evh_6705964765214521_success($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.loaded
                    ? _c("BtnSyncResource", {
                        staticClass: "ml-auto mr-2",
                        attrs: { resource: _vm.item, size: "sm" },
                        on: {
                          success: function($event) {
                            return _vm.evh_6806515264385235_success($event, {})
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container float-left",
                  attrs: { title: _vm._f("gettext")("General") }
                },
                [
                  _c(
                    "ItpRow",
                    [
                      _c(
                        "ItpCol",
                        { attrs: { w: "6" } },
                        [
                          _c("UcProductGeneral", {
                            staticClass: "pb-5",
                            attrs: {
                              product: _vm.item,
                              reload: _vm.lastLoaded
                            },
                            on: {
                              reload: function($event) {
                                return _vm.evh_5477420070495986_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpCol",
                        { attrs: { w: "6" } },
                        [
                          _c("UcCommentsList", {
                            staticClass: "pb-5",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded }
                          }),
                          _vm._v(" "),
                          _c("UcAttachmentsList", {
                            staticClass: "pb-5",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: { title: _vm._f("gettext")("Prices") }
                },
                [
                  _c("UcProductPrices", {
                    staticClass: "h-100",
                    attrs: { product: _vm.item, reload: _vm.lastLoaded }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }