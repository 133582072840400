"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Payment = class Payment extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'item', 'allConfig', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    recalculateAmountFromLines(...args) {
        const item = this.item;
        const [invoiceData] = args;
        if (invoiceData) {
            const idx = _.findIndex(item.invoices.data, { invoice_id: invoiceData.invoice_id });
            if (idx > -1) {
                _.extend(item.invoices.data[idx], invoiceData);
            }
        }
        const summa = this.$fn.numeral(0);
        if (!item.invoices || !item.invoices.data) {
            return;
        }
        for (let d of item.invoices.data) {
            summa.add(d.amount);
            console.log(d.amount, summa);
        }
        item.amount = summa.value();
        if (this.isPettyCashPayment) {
            item.amount = Math.abs(item.amount);
        }
    }
    reloadTemplate(...args) {
        this.loadData('item');
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onInvoices(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoices");
    }
    onAccount_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("accountId");
    }
    onTransaction_kind(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionKind");
    }
    onTransaction_type(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionType");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.oid == null
                ? this.$fn.fetch('get_payment_template', {
                    asResource: true,
                    parameters: {
                        kind: this.transactionKind,
                        type: this.transactionType,
                        invoices: this.invoices ? this.invoices.join(',') : null,
                        account_id: (this.$data.item && this.$data.item.account && this.$data.item.account.id) || this.accountId
                    }
                })
                : this.$fn.fetch('read_payment', { asResource: true, parameters: { id: this.oid } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            item: null,
            allConfig: {
                transactionTypes: {
                    bank: {
                        credit: this.$fn.pgettext("TransactionType", "credit"),
                        debit: this.$fn.pgettext("TransactionType", "debit"),
                    },
                    cash_book: {
                        deposit: this.$fn.pgettext("TransactionType", "deposit"),
                        payment: this.$fn.pgettext("TransactionType", "payment"),
                    },
                    technical: {
                        invoice_reconciliation: this.$fn.pgettext("TransactionType", "invoice reconciliation"),
                        write_off: this.$fn.pgettext("TransactionType", "write off"),
                    },
                },
                bank: {
                    credit: {
                        fields: {},
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Bank credit"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                    debit: {
                        fields: {},
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Bank debit"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                },
                cash_book: {
                    deposit: {
                        fields: {
                            transaction_number: "ro",
                            transaction_type: "ro",
                            exchange_rate: false,
                            statement_number: false,
                            transaction_code: false,
                        },
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Cash-In"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                    payment: {
                        fields: {
                            transaction_number: "ro",
                            transaction_type: "ro",
                            exchange_rate: false,
                        },
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Cash-Out"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                },
                technical: {
                    invoice_reconciliation: {
                        fields: {
                            amount: false,
                            exchange_rate: false,
                            transaction_number: false,
                            payment_method: false,
                            employee: false,
                            statement_number: false,
                            transaction_code: false,
                        },
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Invoice reconciliation"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                    write_off: {
                        fields: {
                            amount: false,
                            exchange_rate: false,
                            transaction_number: false,
                            payment_method: false,
                            employee: false,
                            statement_number: false,
                            transaction_code: false,
                        },
                        resourceTitle: this.$fn.pgettext("Payment|Header", "Write off"),
                        newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
                    },
                },
            },
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get isDeletable() {
        if (this.loaded) {
            return this.oid != null;
        }
        return null;
    }
    get isNew() {
        if (this.loaded) {
            return this.item && this.item.id == null;
        }
        return null;
    }
    get isPettyCashPayment() {
        if (this.loaded) {
            return this.item.account && this.item.account.account_type === 'cash_book';
        }
        return null;
    }
    get config() {
        if (this.loaded) {
            return this.allConfig[this.item.transaction_kind][this.item.transaction_type];
        }
        return null;
    }
    get transactionTypes() {
        if (this.loaded) {
            return this.allConfig.transactionTypes[this.item.transaction_kind];
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_3020_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_3028_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_setData_3032_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event && $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_1040167445267876_setData_3032_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1040167445267876_reloadSlickgrid_3034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_3034_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1040167445267876_crud_3030_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "payment",
                op: "upsert",
                data: this.item,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_5534025912102772_script_3036_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.reloadTemplate($event.data);
        });
    }
    act_5534025912102772_script_3036_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5534025912102772_script_3036_getActionArgs_value($event),
            };
        });
    }
    evh_5534025912102772_accountChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5534025912102772_accountChanged.executeFromDOM(this, event, scope);
        });
    }
    act_3083451129821185_script_3038_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.recalculateAmountFromLines($event.data);
        });
    }
    act_3083451129821185_script_3038_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3083451129821185_script_3038_getActionArgs_value($event),
            };
        });
    }
    evh_3083451129821185_recalculate(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3083451129821185_recalculate.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_setData_3042_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event && $event.data,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_1419464017721962_success_3041_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1419464017721962_reloadSlickgrid_3044_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "payments",
            };
        });
    }
    evh_1419464017721962_success_3043_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data;
        });
    }
    act_1419464017721962_rest_3040_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                method: "save",
                container: {
                    self: this.item.links.parent,
                    object: 'list',
                    data: []
                },
                resource: this.item,
                data: {
                    etag: this.item.etag,
                    complete: true,
                },
            };
        });
    }
    act_1419464017721962_rest_3040_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: this.dialogHeader,
                message: this.$fn.gettext("Completing prevents further modifications. Please confirm this operation."),
                btnOk: this.$fn.gettext("Complete"),
                severity: "warning",
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5566617329548203_setData_3046_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: this.item.clone({
                    id: null,
                    rev: null,
                    transaction_number: this.isPettyCashPayment ? null : this.item.transaction_number,
                    amount: 0,
                    links: null,
                    etag: null,
                    invoices: { data: [] },
                    preview_url: null,
                    locked: false,
                    notes: null,
                    internal_notes: null,
                }),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5566617329548203_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_3020 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_3020_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_3019 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_3020,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_3019],
        });
        const act_2248226175642056_closeModal_3022 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_3021 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_3022,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_3021],
        });
        const act_8564662037462133_reloadComponentData_3024 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_3023 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_3024,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_3023],
        });
        const act_8564662037462133_closeModal_3026 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_8564662037462133_deleted_3025 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_3026,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_8564662037462133_reloadSlickgrid_3028 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_3028_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_3027 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_3028,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_3025, evh_8564662037462133_deleted_3027],
        });
        const act_1040167445267876_setData_3032 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1040167445267876_setData_3032_getActionArgs,
            when: this.act_1040167445267876_setData_3032_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_3031 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_setData_3032,
            event: "success",
            displayName: "setData",
        });
        const act_1040167445267876_reloadSlickgrid_3034 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_3034_getActionArgs,
            when: this.act_1040167445267876_reloadSlickgrid_3034_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_3033 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_3034,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_crud_3030 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_3030_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_3031, evh_1040167445267876_success_3033],
        });
        const evh_1040167445267876_submit_3029 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_3030,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_3029],
        });
        const act_5534025912102772_script_3036 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5534025912102772_script_3036_getActionArgs,
            events: [],
        });
        const evh_5534025912102772_accountChanged_3035 = new core_1.actions.EventHandlerImpl({
            action: act_5534025912102772_script_3036,
            event: "accountChanged",
            displayName: "script",
        });
        const evh_5534025912102772_accountChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5534025912102772_accountChanged_3035],
        });
        const act_3083451129821185_script_3038 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3083451129821185_script_3038_getActionArgs,
            events: [],
        });
        const evh_3083451129821185_recalculate_3037 = new core_1.actions.EventHandlerImpl({
            action: act_3083451129821185_script_3038,
            event: "recalculate",
            displayName: "script",
        });
        const evh_3083451129821185_recalculate = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3083451129821185_recalculate_3037],
        });
        const act_1419464017721962_setData_3042 = new core_1.actions.SetDataAction({
            actionArgs: this.act_1419464017721962_setData_3042_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_3041 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_setData_3042,
            event: "success",
            when: this.evh_1419464017721962_success_3041_getWhen,
            displayName: "setData",
        });
        const act_1419464017721962_reloadSlickgrid_3044 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1419464017721962_reloadSlickgrid_3044_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_success_3043 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_reloadSlickgrid_3044,
            event: "success",
            when: this.evh_1419464017721962_success_3043_getWhen,
            displayName: "reloadSlickgrid",
        });
        const act_1419464017721962_rest_3040 = new core_1.actions.RestAction({
            actionArgs: this.act_1419464017721962_rest_3040_getActionArgs,
            confirm: this.act_1419464017721962_rest_3040_getConfirm,
            displayName: "complete",
            events: [evh_1419464017721962_success_3041, evh_1419464017721962_success_3043],
        });
        const evh_1419464017721962_clickPreventStop_3039 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_rest_3040,
            event: "click.prevent.stop",
            displayName: "complete",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_3039],
        });
        const act_5566617329548203_setData_3046 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5566617329548203_setData_3046_getActionArgs,
            events: [],
        });
        const evh_5566617329548203_clickPreventStop_3045 = new core_1.actions.EventHandlerImpl({
            action: act_5566617329548203_setData_3046,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_5566617329548203_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5566617329548203_clickPreventStop_3045],
        });
        return {
            act_7315092382398562_closeModal_3020,
            evh_7315092382398562_close_3019,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_3022,
            evh_2248226175642056_close_3021,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_3024,
            evh_8564662037462133_reload_3023,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_3026,
            evh_8564662037462133_deleted_3025,
            act_8564662037462133_reloadSlickgrid_3028,
            evh_8564662037462133_deleted_3027,
            evh_8564662037462133_deleted,
            act_1040167445267876_setData_3032,
            evh_1040167445267876_success_3031,
            act_1040167445267876_reloadSlickgrid_3034,
            evh_1040167445267876_success_3033,
            act_1040167445267876_crud_3030,
            evh_1040167445267876_submit_3029,
            evh_1040167445267876_submit,
            act_5534025912102772_script_3036,
            evh_5534025912102772_accountChanged_3035,
            evh_5534025912102772_accountChanged,
            act_3083451129821185_script_3038,
            evh_3083451129821185_recalculate_3037,
            evh_3083451129821185_recalculate,
            act_1419464017721962_setData_3042,
            evh_1419464017721962_success_3041,
            act_1419464017721962_reloadSlickgrid_3044,
            evh_1419464017721962_success_3043,
            act_1419464017721962_rest_3040,
            evh_1419464017721962_clickPreventStop_3039,
            evh_1419464017721962_clickPreventStop,
            act_5566617329548203_setData_3046,
            evh_5566617329548203_clickPreventStop_3045,
            evh_5566617329548203_clickPreventStop,
            save: act_1040167445267876_crud_3030,
            complete: act_1419464017721962_rest_3040,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Payment.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Array,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof array !== "undefined" && array) === "function" ? _a : Object)
], Payment.prototype, "invoices", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoices'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onInvoices", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Payment.prototype, "accountId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('accountId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onAccount_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Payment.prototype, "transactionKind", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('transactionKind'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onTransaction_kind", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: null,
    }),
    __metadata("design:type", String)
], Payment.prototype, "transactionType", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('transactionType'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onTransaction_type", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], Payment.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Payment.prototype, "onSize", null);
Payment = __decorate([
    (0, vue_property_decorator_1.Component)()
], Payment);
exports.default = Payment;
vue_property_decorator_1.Vue.component("Payment", Payment);
