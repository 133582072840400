"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "contract",
    path: "/contracts/:id",
    component: "Contract",
    params: [{
            name: "id",
            prop: "contractId",
        },
        {
            name: "rev",
            prop: "revision_number",
        },
    ]
});
let Contract = class Contract extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];
    }
    beforeCreate() {
    }
    onContract_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contractId");
    }
    onRevision_number(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("revision_number");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_tabManager() {
        return __awaiter(this, void 0, void 0, function* () {
            return new this.$app.utils.LazyTabManager();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_contract', { asResource: true, parameters: { id: this.contractId, rev: this.revision_number } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            tabManager: null,
            item: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2285343489561581_to: {
                name: "app.meters",
            },
        });
    }
    get readonly() {
        if (this.loaded) {
            return this.item.locked;
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.snapshot('contract_number'),
                    this.$fn.gettext("Contract"),
                ],
                icon: this.$config.contract.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1956_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2095089031469157_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_9004123373732307_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_9004123373732307_success.executeFromDOM(this, event, scope);
        });
    }
    evh_2679161326024595_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_7987652045662005_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7987652045662005_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6490121676898844_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6490121676898844_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_2789477278097085_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2789477278097085_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6182083303232630_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6182083303232630_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_5361955607147334_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5361955607147334_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_384677887161204_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_384677887161204_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1956 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1956_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1955 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1956,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1955],
        });
        const act_2095089031469157_reloadComponentData_1958 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_2095089031469157_clickPreventStop_1957 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_1958,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_2095089031469157_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_clickPreventStop_1957],
        });
        const act_9004123373732307_closeComponent_1960 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_9004123373732307_success_1959 = new core_1.actions.EventHandlerImpl({
            action: act_9004123373732307_closeComponent_1960,
            event: "success",
            displayName: "closeComponent",
        });
        const evh_9004123373732307_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_9004123373732307_success_1959],
        });
        const act_2679161326024595_reloadComponentData_1962 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2679161326024595_reload_1961 = new core_1.actions.EventHandlerImpl({
            action: act_2679161326024595_reloadComponentData_1962,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2679161326024595_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2679161326024595_reload_1961],
        });
        const act_7987652045662005_reloadComponentData_1964 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7987652045662005_reload_1963 = new core_1.actions.EventHandlerImpl({
            action: act_7987652045662005_reloadComponentData_1964,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7987652045662005_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7987652045662005_reload_1963],
        });
        const act_6490121676898844_reloadComponentData_1966 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6490121676898844_reload_1965 = new core_1.actions.EventHandlerImpl({
            action: act_6490121676898844_reloadComponentData_1966,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_6490121676898844_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6490121676898844_reload_1965],
        });
        const act_2789477278097085_reloadComponentData_1968 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2789477278097085_reload_1967 = new core_1.actions.EventHandlerImpl({
            action: act_2789477278097085_reloadComponentData_1968,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2789477278097085_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2789477278097085_reload_1967],
        });
        const act_6182083303232630_reloadComponentData_1970 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6182083303232630_reload_1969 = new core_1.actions.EventHandlerImpl({
            action: act_6182083303232630_reloadComponentData_1970,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_6182083303232630_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6182083303232630_reload_1969],
        });
        const act_5361955607147334_reloadComponentData_1972 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5361955607147334_reload_1971 = new core_1.actions.EventHandlerImpl({
            action: act_5361955607147334_reloadComponentData_1972,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5361955607147334_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5361955607147334_reload_1971],
        });
        const act_384677887161204_reloadComponentData_1974 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_384677887161204_reload_1973 = new core_1.actions.EventHandlerImpl({
            action: act_384677887161204_reloadComponentData_1974,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_384677887161204_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_384677887161204_reload_1973],
        });
        return {
            act_7315092382398562_closeModal_1956,
            evh_7315092382398562_close_1955,
            evh_7315092382398562_close,
            act_2095089031469157_reloadComponentData_1958,
            evh_2095089031469157_clickPreventStop_1957,
            evh_2095089031469157_clickPreventStop,
            act_9004123373732307_closeComponent_1960,
            evh_9004123373732307_success_1959,
            evh_9004123373732307_success,
            act_2679161326024595_reloadComponentData_1962,
            evh_2679161326024595_reload_1961,
            evh_2679161326024595_reload,
            act_7987652045662005_reloadComponentData_1964,
            evh_7987652045662005_reload_1963,
            evh_7987652045662005_reload,
            act_6490121676898844_reloadComponentData_1966,
            evh_6490121676898844_reload_1965,
            evh_6490121676898844_reload,
            act_2789477278097085_reloadComponentData_1968,
            evh_2789477278097085_reload_1967,
            evh_2789477278097085_reload,
            act_6182083303232630_reloadComponentData_1970,
            evh_6182083303232630_reload_1969,
            evh_6182083303232630_reload,
            act_5361955607147334_reloadComponentData_1972,
            evh_5361955607147334_reload_1971,
            evh_5361955607147334_reload,
            act_384677887161204_reloadComponentData_1974,
            evh_384677887161204_reload_1973,
            evh_384677887161204_reload,
            reload: act_2095089031469157_reloadComponentData_1958,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Contract.prototype, "contractId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contractId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Contract.prototype, "onContract_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Contract.prototype, "revision_number", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('revision_number'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Contract.prototype, "onRevision_number", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Contract.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Contract.prototype, "onSize", null);
Contract = __decorate([
    (0, vue_property_decorator_1.Component)()
], Contract);
exports.default = Contract;
vue_property_decorator_1.Vue.component("Contract", Contract);
