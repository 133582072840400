<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="TaskViewModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="page d-flex flex-column"
    v-if="loaded"
  >
    <DIV
      class="page__content container-fluid pt-3 h-100"
    >
      <ItpBox
        direction="column"
      >
        <ItpBadge
          :text="task.status"
          :value="task.status"
          :variantMap="b_4615685607825208_variantMap"
        >
        </ItpBadge>
        <CodeMirror
          :options="b_2095089031469157_options"
          v-model="task.result_text"
          v-if="task.result_text"
        >
        </CodeMirror>
      </ItpBox>
    </DIV>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class TaskViewModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  task!: object;

  @Watch('task')
  onTask(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("task")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  ux!: any;
  b_4615685607825208_variantMap!: any;
  b_2095089031469157_options!: any;
  dataMembers = ['ux', 'b_4615685607825208_variantMap', 'b_2095089031469157_options'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_4615685607825208_variantMap: {
          PENDING: "warning",
          STARTED: "primary",
          FAILURE: "danger",
        }
        ,
        b_2095089031469157_options: {
          lineNumbers: true,
          matchBrackets: true,
          continueComments: "Enter",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Task"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_4074_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_4074, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_4074: actions.CloseModalAction;
    evh_7315092382398562_close_4073: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_4074 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_4074_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_4073 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_4074,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_4073],
      }
    );
    return {
      act_7315092382398562_closeModal_4074,
      evh_7315092382398562_close_4073,
      evh_7315092382398562_close,
    }
  }
}

Vue.component("TaskViewModal", TaskViewModal);

</script>