<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Account"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcAccountHeader
      :account="item"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcAccountHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="account"
      :readonly="item.locked"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetAccountGeneral
          :item="item"
          :formId="formId"
          no-submit-button
          @saved="evh_7789867021953041_saved($event, {})"
        >
        </FieldsetAccountGeneral>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      type="submit"
      :text="'Save'|gettext"
      :form="forms.account"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Account extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
    default: null,
  })
  ownerId!: string;

  @Watch('ownerId')
  onOwner_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("ownerId")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.oid == null
      ? this.$fn.schemaDefaults('CreateAccountCommand', { owner_id: this.ownerId })
      : this.$fn.fetch('read_account', { asResource: true, parameters: { id: this.oid } })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'item', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid == null ?
          this.$fn.gettext("New Cash or Bank Account") :
          [this.item.snapshot("name"), this.$fn.gettext("Account")],
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1338_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1338, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_1346_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_1346, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_1350_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1350, alias=undefined
    return {
      path: "item",
      value: $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_setData_1350_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_1350, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_emit_1352_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_1352, alias=undefined
    return {
      event: "saved",
      value: $event.data,
    }
  }

  async act_1040167445267876_closeModal_1354_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_1354, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_1356_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_1356, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async act_1040167445267876_crud_1348_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_1348, alias=save
    return {
      objectType: "account",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_reloadSlickgrid_1360_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_7789867021953041_reloadSlickgrid_1360, alias=undefined
    return {
      grid: "accounts",
    }
  }

  async evh_7789867021953041_saved(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_saved.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_1362_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_1362, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1338: actions.CloseModalAction;
    evh_7315092382398562_close_1337: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1340: actions.CloseModalAction;
    evh_2248226175642056_close_1339: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_1342: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_1341: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeComponent_1344: actions.CloseComponentAction;
    evh_8564662037462133_deleted_1343: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_1346: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_1345: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_setData_1350: actions.SetDataAction;
    evh_1040167445267876_success_1349: actions.EventHandlerImpl;
    act_1040167445267876_emit_1352: actions.EmitAction;
    evh_1040167445267876_success_1351: actions.EventHandlerImpl;
    act_1040167445267876_closeModal_1354: actions.CloseModalAction;
    evh_1040167445267876_success_1353: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_1356: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_1355: actions.EventHandlerImpl;
    act_1040167445267876_crud_1348: actions.CRUDAction;
    evh_1040167445267876_submit_1347: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_7789867021953041_closeModal_1358: actions.CloseModalAction;
    evh_7789867021953041_saved_1357: actions.EventHandlerImpl;
    act_7789867021953041_reloadSlickgrid_1360: actions.ReloadSlickgridAction;
    evh_7789867021953041_saved_1359: actions.EventHandlerImpl;
    evh_7789867021953041_saved: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_1362: actions.CloseModalAction;
    evh_1419464017721962_clickPreventStop_1361: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1338 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1338_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1337 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1338,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1337],
      }
    );
    const act_2248226175642056_closeModal_1340 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1339 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1340,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1339],
      }
    );
    const act_8564662037462133_reloadComponentData_1342 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_1341 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_1342,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_1341],
      }
    );
    const act_8564662037462133_closeComponent_1344 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1343 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeComponent_1344,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_8564662037462133_reloadSlickgrid_1346 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_1346_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_1345 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_1346,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_1343, evh_8564662037462133_deleted_1345],
      }
    );
    const act_1040167445267876_setData_1350 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_1350_getActionArgs,
        when: this.act_1040167445267876_setData_1350_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_1349 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_1350,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_emit_1352 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_1352_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1351 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_1352,
        event: "success",
        displayName: "emit",
      }
    );
    const act_1040167445267876_closeModal_1354 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_1354_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1353 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_1354,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_reloadSlickgrid_1356 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_1356_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_1355 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_1356,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_crud_1348 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_1348_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_1349, evh_1040167445267876_success_1351, evh_1040167445267876_success_1353, evh_1040167445267876_success_1355],
      }
    );
    const evh_1040167445267876_submit_1347 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_1348,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_1347],
      }
    );
    const act_7789867021953041_closeModal_1358 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_7789867021953041_saved_1357 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_closeModal_1358,
        event: "saved",
        displayName: "closeModal",
      }
    );
    const act_7789867021953041_reloadSlickgrid_1360 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_7789867021953041_reloadSlickgrid_1360_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_saved_1359 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_reloadSlickgrid_1360,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_7789867021953041_saved = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_saved_1357, evh_7789867021953041_saved_1359],
      }
    );
    const act_1419464017721962_closeModal_1362 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_1362_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_1361 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_1362,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_1361],
      }
    );
    return {
      act_7315092382398562_closeModal_1338,
      evh_7315092382398562_close_1337,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1340,
      evh_2248226175642056_close_1339,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_1342,
      evh_8564662037462133_reload_1341,
      evh_8564662037462133_reload,
      act_8564662037462133_closeComponent_1344,
      evh_8564662037462133_deleted_1343,
      act_8564662037462133_reloadSlickgrid_1346,
      evh_8564662037462133_deleted_1345,
      evh_8564662037462133_deleted,
      act_1040167445267876_setData_1350,
      evh_1040167445267876_success_1349,
      act_1040167445267876_emit_1352,
      evh_1040167445267876_success_1351,
      act_1040167445267876_closeModal_1354,
      evh_1040167445267876_success_1353,
      act_1040167445267876_reloadSlickgrid_1356,
      evh_1040167445267876_success_1355,
      act_1040167445267876_crud_1348,
      evh_1040167445267876_submit_1347,
      evh_1040167445267876_submit,
      act_7789867021953041_closeModal_1358,
      evh_7789867021953041_saved_1357,
      act_7789867021953041_reloadSlickgrid_1360,
      evh_7789867021953041_saved_1359,
      evh_7789867021953041_saved,
      act_1419464017721962_closeModal_1362,
      evh_1419464017721962_clickPreventStop_1361,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_1348,
    }
  }
}

Vue.component("Account", Account);

</script>