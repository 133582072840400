"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MeterEditor = class MeterEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['formId', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("Meter", "Edit General Information"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_6708_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeComponent_6714_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_6712_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "meter",
                op: "upsert",
                data: this.meter,
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_6708 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_6708_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_6707 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_6708,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_6707],
        });
        const act_2248226175642056_closeComponent_6710 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_6709 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_6710,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_6709],
        });
        const act_1040167445267876_closeComponent_6714 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_1040167445267876_closeComponent_6714_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_6713 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeComponent_6714,
            event: "success",
            displayName: "closeComponent",
        });
        const act_1040167445267876_crud_6712 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_6712_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_6713],
        });
        const evh_1040167445267876_submit_6711 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_6712,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_6711],
        });
        const act_1419464017721962_closeComponent_6716 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_6715 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_6716,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_6715],
        });
        return {
            act_7315092382398562_closeModal_6708,
            evh_7315092382398562_close_6707,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_6710,
            evh_2248226175642056_close_6709,
            evh_2248226175642056_close,
            act_1040167445267876_closeComponent_6714,
            evh_1040167445267876_success_6713,
            act_1040167445267876_crud_6712,
            evh_1040167445267876_submit_6711,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_6716,
            evh_1419464017721962_clickPreventStop_6715,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_crud_6712,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MeterEditor.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MeterEditor.prototype, "onMeter", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], MeterEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MeterEditor.prototype, "onSize", null);
MeterEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], MeterEditor);
exports.default = MeterEditor;
vue_property_decorator_1.Vue.component("MeterEditor", MeterEditor);
