<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <JobFiles
    :item="item"
    v-if="hasFiles"
  >
  </JobFiles>
  <HR
    v-if="hasFiles"
  >
  </HR>
  <ChildJobs
    :parent="item"
    v-if="hasChildJobs"
  >
  </ChildJobs>
  <JobRuns
    :item="item"
  >
  </JobRuns>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class JobView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  noDisplayHeader!: boolean;

  @Watch('noDisplayHeader')
  onNo_display_header(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noDisplayHeader")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get hasFiles() {
    if (this.loaded) {

      return this.item.files && this.item.files.length
    }
    return null;
  }

  get hasChildJobs() {
    if (this.loaded) {

      return this.item.jobs && this.item.jobs.length
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5690: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5689: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5690 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5689 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5690,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5689],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5690,
      evh_7315092382398562_reload_5689,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("JobView", JobView);

</script>