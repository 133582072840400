var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c("legend", { staticClass: "text-muted" }, [
        _vm._v(
          "\n    " + _vm._s(_vm._f("pgettext")("General", "Property")) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "owner_id",
            label: _vm._f("pgettext")("Own company", "Property"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "owner_id",
              dataSource: _vm.b_1040167445267876_dataSource,
              prefetch: "",
              valueField: "id",
              textField: "name",
              required: "",
              optionTemplate:
                "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
              "use-option-template-for-selected-option": ""
            },
            model: {
              value: _vm.property.owner_id,
              callback: function($$v) {
                _vm.$set(_vm.property, "owner_id", $$v)
              },
              expression: "property.owner_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "4",
            name: "name",
            label: _vm._f("pgettext")("Name", "Property"),
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "name", required: "", autofocus: "" },
            model: {
              value: _vm.property.name,
              callback: function($$v) {
                _vm.$set(_vm.property, "name", $$v)
              },
              expression: "property.name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isPropertyUnit
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                labelColsMd: "4",
                contentColsMd: "4",
                name: "code",
                label: _vm._f("pgettext")("Code", "Property"),
                labelAlign: _vm.labelAlign,
                required: _vm.isPropertyUnit
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "code", required: _vm.isPropertyUnit },
                model: {
                  value: _vm.property.code,
                  callback: function($$v) {
                    _vm.$set(_vm.property, "code", $$v)
                  },
                  expression: "property.code"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Description", "Property"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "description" },
            model: {
              value: _vm.property.description,
              callback: function($$v) {
                _vm.$set(_vm.property, "description", $$v)
              },
              expression: "property.description"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "description",
            label: _vm._f("pgettext")("Type", "Property"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "property_type",
              options: _vm.types,
              valueField: "name",
              textField: "name"
            },
            model: {
              value: _vm.property.property_type,
              callback: function($$v) {
                _vm.$set(_vm.property, "property_type", $$v)
              },
              expression: "property.property_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "location_code",
            label: _vm._f("pgettext")("Location", "Property"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "location_code" },
            model: {
              value: _vm.property.location_code,
              callback: function($$v) {
                _vm.$set(_vm.property, "location_code", $$v)
              },
              expression: "property.location_code"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "lot_number",
            label: _vm._f("pgettext")("Lot number", "Property"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "lot_number" },
            model: {
              value: _vm.property.lot_number,
              callback: function($$v) {
                _vm.$set(_vm.property, "lot_number", $$v)
              },
              expression: "property.lot_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            name: "house_number",
            label: _vm._f("pgettext")("Building number", "Property"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "house_number" },
            model: {
              value: _vm.property.house_number,
              callback: function($$v) {
                _vm.$set(_vm.property, "house_number", $$v)
              },
              expression: "property.house_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            labelColsMd: "4",
            label: _vm._f("pgettext")("Area", "Property"),
            labelAlign: _vm.labelAlign,
            contentClass: "input-group"
          }
        },
        [
          _c("ItpFormInput", {
            staticClass: "col-8",
            attrs: { type: "number", name: "area" },
            model: {
              value: _vm.property.area,
              callback: function($$v) {
                _vm.$set(_vm.property, "area", $$v)
              },
              expression: "property.area"
            }
          }),
          _vm._v(" "),
          _c("ItpFormSelect", {
            staticClass: "col-4",
            attrs: { name: "unit_of_area", options: _vm.units },
            model: {
              value: _vm.property.unit_of_area,
              callback: function($$v) {
                _vm.$set(_vm.property, "unit_of_area", $$v)
              },
              expression: "property.unit_of_area"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }