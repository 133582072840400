"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoiceLogMessageViewer = class InvoiceLogMessageViewer extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'ux', 'b_7315092382398562_modalBindings', 'b_1040167445267876_options'];
    }
    beforeCreate() {
    }
    onInvoice_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_invoice', { parameters: { id: this.invoiceId } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                noPrimaryButton: true,
                closeButtonText: this.$fn.gettext("Close"),
                size: "full-screen",
            },
            b_1040167445267876_options: {
                lineNumbers: true,
                matchBrackets: true,
                continueComments: "Enter",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.item ? this.item.invoice_number : '-',
                icon: this.$config.invoice.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2344_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2344 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2344_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2343 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2344,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2343],
        });
        const act_2248226175642056_closeModal_2346 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_2345 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_2346,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2345],
        });
        return {
            act_7315092382398562_closeModal_2344,
            evh_7315092382398562_close_2343,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_2346,
            evh_2248226175642056_close_2345,
            evh_2248226175642056_close,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], InvoiceLogMessageViewer.prototype, "invoiceId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLogMessageViewer.prototype, "onInvoice_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], InvoiceLogMessageViewer.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], InvoiceLogMessageViewer.prototype, "onSize", null);
InvoiceLogMessageViewer = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoiceLogMessageViewer);
exports.default = InvoiceLogMessageViewer;
vue_property_decorator_1.Vue.component("InvoiceLogMessageViewer", InvoiceLogMessageViewer);
