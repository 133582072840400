<template>
<b-tabs
  nav-class="material px-3"
  content-class="flex-fill overflow-auto px-3 py-4 h-100 bg-page"
  class="h-100 d-flex flex-column tabs-material overflow-hidden"
  @input="evh_7315092382398562_input($event, {})"
>
  <slot>
  </slot>
  <template
    slot="tabs-start"
  >
    <slot
      name="tabs-start"
    >
    </slot>
  </template>
  <template
    slot="tabs-end"
    v-if="closeButton"
  >
    <button
      data-dismiss="modal"
      aria-label="Close"
      class="close ml-auto mr-2"
      @click="evh_1548630417156826_click($event, {})"
    >
      <span aria-hidden="true">&times;</span>

    </button>
  </template>
</b-tabs>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MaterialTabs extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
  })
  closeButton!: boolean;

  @Watch('closeButton')
  onClose_button(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("closeButton")
  }

  @Prop({
    type: Object,
  })
  tabManager!: object;

  @Watch('tabManager')
  onTab_manager(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("tabManager")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async act_7315092382398562_emit_1662_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7315092382398562_emit_1662, alias=undefined
    return {
      event: "input",
      value: {
        tabIndex: $event.originalEvent,
      }
      ,
    }
  }

  async act_7315092382398562_script_1664_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_1664, alias=undefined
    this.tabManager.visited($event.originalEvent, true)
  }

  async act_7315092382398562_script_1664_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_1664, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_1664_getActionArgs_value($event),
    }
  }

  async act_7315092382398562_script_1664_getWhen($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_1664, alias=undefined
    return !!this.tabManager
  }

  async evh_7315092382398562_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_input.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_emit_1666_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1548630417156826_emit_1666, alias=undefined
    return {
      event: "close",
    }
  }

  async evh_1548630417156826_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_emit_1662: actions.EmitAction;
    evh_7315092382398562_input_1661: actions.EventHandlerImpl;
    act_7315092382398562_script_1664: actions.ScriptAction;
    evh_7315092382398562_input_1663: actions.EventHandlerImpl;
    evh_7315092382398562_input: actions.EventHandlerGroup;
    act_1548630417156826_emit_1666: actions.EmitAction;
    evh_1548630417156826_click_1665: actions.EventHandlerImpl;
    evh_1548630417156826_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_emit_1662 = new actions.EmitAction(
      {
        actionArgs: this.act_7315092382398562_emit_1662_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_input_1661 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_emit_1662,
        event: "input",
        displayName: "emit",
      }
    );
    const act_7315092382398562_script_1664 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_1664_getActionArgs,
        when: this.act_7315092382398562_script_1664_getWhen,
        events: [],
      }
    );
    const evh_7315092382398562_input_1663 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_1664,
        event: "input",
        displayName: "script",
      }
    );
    const evh_7315092382398562_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_input_1661, evh_7315092382398562_input_1663],
      }
    );
    const act_1548630417156826_emit_1666 = new actions.EmitAction(
      {
        actionArgs: this.act_1548630417156826_emit_1666_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_click_1665 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_emit_1666,
        event: "click",
        displayName: "emit",
      }
    );
    const evh_1548630417156826_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_click_1665],
      }
    );
    return {
      act_7315092382398562_emit_1662,
      evh_7315092382398562_input_1661,
      act_7315092382398562_script_1664,
      evh_7315092382398562_input_1663,
      evh_7315092382398562_input,
      act_1548630417156826_emit_1666,
      evh_1548630417156826_click_1665,
      evh_1548630417156826_click,
    }
  }
}

Vue.component("MaterialTabs", MaterialTabs);

</script>