<template>
<ItpBox
  alignItems="center"
  v-if="loaded"
>
  <UcAccountSelector
    cacheKey="Payments"
    v-model="item.account"
    class="mr-2"
  >
  </UcAccountSelector>
  <DateRangeSelector
    name="paymentFilters"
    :from="item.date_from"
    :to="item.date_to"
    class="mr-2"
    :style="b_5962862420439144_style"
    @changed="evh_5962862420439144_changed($event, {})"
  >
  </DateRangeSelector>
  <BtnLoad
    :action="actions.load"
    class="mr-2"
    @click="evh_8358196963355853_click($event, {})"
  >
  </BtnLoad>
  <vr>
  </vr>
  <b-dropdown
    :text="'Commands'|gettext"
    variant="light"
    class="ml-auto border rounded"
  >
    <ItpButton
      :text="'Add deposit'|pgettext('Payments')"
      :icon="$config.payment.icon"
      dropdown-item
      v-if="selectedAccountType && selectedAccountType == 'cash_book'"
      @click.prevent="evh_6424600534600827_clickPrevent($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'New payment'|pgettext('Payments')"
      :icon="$config.payment.icon"
      dropdown-item
      v-if="selectedAccountType && selectedAccountType == 'cash_book'"
      @click.prevent="evh_8249915319319009_clickPrevent($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'New transaction...'|pgettext('Payments')"
      :icon="$config.payment.icon"
      dropdown-item
      class="mr-2"
      v-if="selectedAccountType && selectedAccountType !== 'cash_book'"
      @click.prevent="evh_2276125547171722_clickPrevent($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Import Bank Statement...'|pgettext('Payments')"
      :icon="$config.uploadIcon"
      dropdown-item
      class="mr-2"
      @click.prevent="evh_1836715606397834_clickPrevent($event, {})"
    >
    </ItpButton>
  </b-dropdown>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class PaymentsViewFilters extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "PaymentsViewFilters");
  }

  createAccountGrups(...args: any[]) {
    const [accounts] = args;
    const showInactiveAccounts = this.item.showInactiveAccounts;

    return _
      .chain(accounts)
      .groupBy(p => p.owner_id)
      .map((items, owner_id) => ({
        id: owner_id,
        name: items[0].owner_name,
        items: !showInactiveAccounts ? _.filter(items, { inactive: false }) : items
      }))
      .filter(p => p.items.length)
      .value()
  }

  item!: any;
  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  accountGroups!: any;

  async $$load_accountGroups() {
    return this.createAccountGrups(this.$data.accounts)
  }

  ux!: any;
  b_5962862420439144_style!: any;
  dataMembers = ['item', 'accounts', 'accountGroups', 'ux', 'b_5962862420439144_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: {
          date_from: null,
          date_to: null,
          account: null,
          showInactiveAccounts: false,
        }
        ,
        accounts: null,
        accountGroups: null,
        ux: null,
        b_5962862420439144_style: {
          width: "500px",
        }
        ,
      },
    }
  }

  get selectedAccountType() {
    if (this.loaded) {

      return this.item.account && this.item.account.account_type
    }
    return null;
  }

  get selectedAccount() {
    if (this.loaded) {

      return _.isObject(this.item.account) ? this.item.account : null
    }
    return null;
  }

  async act_5962862420439144_setData_5802_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5962862420439144_setData_5802, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5962862420439144_setData_5804_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5962862420439144_setData_5804, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5962862420439144_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_changed.executeFromDOM(this, event, scope);
  }

  async act_8358196963355853_emit_5806_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8358196963355853_emit_5806, alias=load
    return {
      event: "submit",
      value: { ...this.item, account: this.item.account.id },
    }
  }

  async evh_8358196963355853_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8358196963355853_click.executeFromDOM(this, event, scope);
  }

  async act_6424600534600827_showModal_5808_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6424600534600827_showModal_5808, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        transactionKind: "cash_book",
        transactionType: "deposit",
      }
      ,
    }
  }

  async evh_6424600534600827_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6424600534600827_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_8249915319319009_showModal_5810_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8249915319319009_showModal_5810, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        transactionKind: "cash_book",
        transactionType: "payment",
      }
      ,
    }
  }

  async evh_8249915319319009_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8249915319319009_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_2276125547171722_showModal_5812_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2276125547171722_showModal_5812, alias=undefined
    return {
      name: "Payment",
      props: {
        accountId: this.item.account ? this.item.account.id : null,
        transactionKind: this.selectedAccountType === 'bank_account' ? 'bank' : this.selectedAccountType,
      }
      ,
    }
  }

  async evh_2276125547171722_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2276125547171722_clickPrevent.executeFromDOM(this, event, scope);
  }

  async act_1836715606397834_showModal_5814_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1836715606397834_showModal_5814, alias=undefined
    return {
      name: "ImportBankStatement",
    }
  }

  async evh_1836715606397834_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1836715606397834_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_5962862420439144_setData_5802: actions.SetDataAction;
    evh_5962862420439144_changed_5801: actions.EventHandlerImpl;
    act_5962862420439144_setData_5804: actions.SetDataAction;
    evh_5962862420439144_changed_5803: actions.EventHandlerImpl;
    evh_5962862420439144_changed: actions.EventHandlerGroup;
    act_8358196963355853_emit_5806: actions.EmitAction;
    evh_8358196963355853_click_5805: actions.EventHandlerImpl;
    evh_8358196963355853_click: actions.EventHandlerGroup;
    act_6424600534600827_showModal_5808: actions.ShowModalAction;
    evh_6424600534600827_clickPrevent_5807: actions.EventHandlerImpl;
    evh_6424600534600827_clickPrevent: actions.EventHandlerGroup;
    act_8249915319319009_showModal_5810: actions.ShowModalAction;
    evh_8249915319319009_clickPrevent_5809: actions.EventHandlerImpl;
    evh_8249915319319009_clickPrevent: actions.EventHandlerGroup;
    act_2276125547171722_showModal_5812: actions.ShowModalAction;
    evh_2276125547171722_clickPrevent_5811: actions.EventHandlerImpl;
    evh_2276125547171722_clickPrevent: actions.EventHandlerGroup;
    act_1836715606397834_showModal_5814: actions.ShowModalAction;
    evh_1836715606397834_clickPrevent_5813: actions.EventHandlerImpl;
    evh_1836715606397834_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_5962862420439144_setData_5802 = new actions.SetDataAction(
      {
        actionArgs: this.act_5962862420439144_setData_5802_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_changed_5801 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_setData_5802,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_5962862420439144_setData_5804 = new actions.SetDataAction(
      {
        actionArgs: this.act_5962862420439144_setData_5804_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_changed_5803 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_setData_5804,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_5962862420439144_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_changed_5801, evh_5962862420439144_changed_5803],
      }
    );
    const act_8358196963355853_emit_5806 = new actions.EmitAction(
      {
        actionArgs: this.act_8358196963355853_emit_5806_getActionArgs,
        displayName: "load",
        events: [],
      }
    );
    const evh_8358196963355853_click_5805 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_emit_5806,
        event: "click",
        displayName: "load",
      }
    );
    const evh_8358196963355853_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8358196963355853_click_5805],
      }
    );
    const act_6424600534600827_showModal_5808 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6424600534600827_showModal_5808_getActionArgs,
        events: [],
      }
    );
    const evh_6424600534600827_clickPrevent_5807 = new actions.EventHandlerImpl(
      {
        action: act_6424600534600827_showModal_5808,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_6424600534600827_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_6424600534600827_clickPrevent_5807],
      }
    );
    const act_8249915319319009_showModal_5810 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8249915319319009_showModal_5810_getActionArgs,
        events: [],
      }
    );
    const evh_8249915319319009_clickPrevent_5809 = new actions.EventHandlerImpl(
      {
        action: act_8249915319319009_showModal_5810,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_8249915319319009_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8249915319319009_clickPrevent_5809],
      }
    );
    const act_2276125547171722_showModal_5812 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2276125547171722_showModal_5812_getActionArgs,
        events: [],
      }
    );
    const evh_2276125547171722_clickPrevent_5811 = new actions.EventHandlerImpl(
      {
        action: act_2276125547171722_showModal_5812,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_2276125547171722_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_2276125547171722_clickPrevent_5811],
      }
    );
    const act_1836715606397834_showModal_5814 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1836715606397834_showModal_5814_getActionArgs,
        events: [],
      }
    );
    const evh_1836715606397834_clickPrevent_5813 = new actions.EventHandlerImpl(
      {
        action: act_1836715606397834_showModal_5814,
        event: "click.prevent",
        displayName: "showModal",
      }
    );
    const evh_1836715606397834_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_1836715606397834_clickPrevent_5813],
      }
    );
    return {
      act_5962862420439144_setData_5802,
      evh_5962862420439144_changed_5801,
      act_5962862420439144_setData_5804,
      evh_5962862420439144_changed_5803,
      evh_5962862420439144_changed,
      act_8358196963355853_emit_5806,
      evh_8358196963355853_click_5805,
      evh_8358196963355853_click,
      act_6424600534600827_showModal_5808,
      evh_6424600534600827_clickPrevent_5807,
      evh_6424600534600827_clickPrevent,
      act_8249915319319009_showModal_5810,
      evh_8249915319319009_clickPrevent_5809,
      evh_8249915319319009_clickPrevent,
      act_2276125547171722_showModal_5812,
      evh_2276125547171722_clickPrevent_5811,
      evh_2276125547171722_clickPrevent,
      act_1836715606397834_showModal_5814,
      evh_1836715606397834_clickPrevent_5813,
      evh_1836715606397834_clickPrevent,
      load: act_8358196963355853_emit_5806,
    }
  }
}

Vue.component("PaymentsViewFilters", PaymentsViewFilters);

</script>