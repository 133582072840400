var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Payments",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "ItpBox",
            {
              staticClass: "h-100 w-100 overflow-hidden",
              attrs: { direction: "column" }
            },
            [
              _c(
                "DIV",
                {
                  staticClass: "h-100 w-100 overflow-hidden position-relative"
                },
                [
                  _c(
                    "VueSlickgrid",
                    {
                      ref: "grid",
                      staticClass: "page--grid",
                      attrs: {
                        name: "payments",
                        headerTitle: _vm._f("pgettext")(
                          "Payments",
                          "PaymentsView"
                        ),
                        items: _vm.getItems,
                        "checkbox-row-selection": "",
                        headerShowColumnSelectorButton:
                          _vm.b_6466705138206980_headerShowColumnSelectorButton,
                        headerShowReloadButton:
                          _vm.b_6466705138206980_headerShowReloadButton,
                        "footer-totals": "",
                        "export-to-csv": "",
                        exportToCsvFilename: _vm._f("pgettext")(
                          "payments",
                          "ExportFilename"
                        ),
                        columnDefaults: _vm.b_6466705138206980_columnDefaults,
                        columns: _vm.b_6466705138206980_columns
                      },
                      on: {
                        selectedRowsChanged: function($event) {
                          return _vm.evh_6466705138206980_selectedRowsChanged(
                            $event,
                            {}
                          )
                        },
                        command: function($event) {
                          return _vm.evh_6466705138206980_command($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "DIV",
                        {
                          staticClass: "flex-grow-1 d-flex",
                          attrs: { slot: "toolbar" },
                          slot: "toolbar"
                        },
                        [
                          _c("PaymentsViewFilters", {
                            on: {
                              submit: function($event) {
                                return _vm.evh_2285343489561581_submit(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }