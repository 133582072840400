<template>
<ItpWidgetCard>
  <ItpBox
    alignItems="center"
    slot="header"
  >
    <strong>
      {{ 'Rental Services'|pgettext('MeterRentalServices') }}
    </strong>
    <ItpButton
      :text="'Add rental service...'|pgettext('UcRentalServices')"
      variant="light"
      size="sm"
      class="ml-auto"
      @click.prevent.stop="evh_3343864469950044_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
  <BListGroup
    v-if="loaded"
  >
    <BListGroupItem
      v-if="!items.length"
    >
      <span>
        {{ 'No rental services.'|pgettext('MeterRentalServices') }}
      </span>
    </BListGroupItem>
    <BListGroupItem
      :key="item.id"
      class="d-flex flex-column"
      v-for="item in sortItems(items)"
    >
      <ItpBox
        alignItems="start"
      >
        <UcInactive
          :value="item.inactive"
        >
        </UcInactive>
        <ItpBox
          direction="column"
          class="w-100"
        >
          <ItpBox
            class="W-100"
          >
            <ItpBox
              class="col-md-3"
            >
              <ItpText
                :strikethrough="isServiceStale(item)"
                class="font-weight-bold mr-2"
              >
                {{ item.service.name }} ({{ item.billing_unit }})
              </ItpText>
              <ItpLink
                :icon="$config.externalLinkIcon"
                :to="{ name: 'app.product', params: { id: item.service.id } }"
              >
              </ItpLink>
            </ItpBox>
            <ItpBox
              class="col-md-3"
            >
              <UcValidityPeriod
                :from="item.valid_from"
                :to="item.valid_to"
                class="pl-3"
              >
              </UcValidityPeriod>
            </ItpBox>
            <ItpBox
              alignItems="center"
              direction="column"
              class="col-md-1"
            >
              <ItpBadge
                :title="'Billing multiplier'|gettext"
                no-box-shadow
                variant="light"
                class="mx-3"
              >
                <ItpIcon
                  name="fa-times"
                >
                </ItpIcon>
                <ItpText
                  :text="item.meter_factor"
                >
                </ItpText>
              </ItpBadge>
              <ItpBadge
                variant="warning"
                :text="'Non billable'|pgettext('RentalService')"
                no-box-shadow
                class="mx-3 mt-2"
                v-if="item.non_billable"
              >
              </ItpBadge>
            </ItpBox>
            <ItpBox
              alignItems="start"
              wrap-items
              class="col-md-5"
            >
              <UcCompactMeterDisplay
                :payload="item.meter"
                class="pl-3"
              >
              </UcCompactMeterDisplay>
            </ItpBox>
          </ItpBox>
          <ItpBox>
            <div
              class="col-md-7"
            >
              <ItpText
                :text="item.notes"
                pre
                v-if="item.notes"
              >
              </ItpText>
            </div>
            <UcRentalServiceCostAllocators
              :service="item"
              class="col-md-5"
            >
            </UcRentalServiceCostAllocators>
          </ItpBox>
        </ItpBox>
        <ItpButton
          icon="fa-pen"
          :tooltip="'Edit'|gettext"
          variant="light"
          size="sm"
          class="ml-auto"
          @click.prevent.stop="evh_8855792642156263_clickPreventStop($event, {item})"
        >
        </ItpButton>
      </ItpBox>
    </BListGroupItem>
  </BListGroup>
</ItpWidgetCard>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcRentalServices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  sortItems(...args: any[]) {
    return _.sortBy(args[0], p => (!this.isServiceStale(p) ? "_" + p.service.name : p.service.name).toLowerCase() + p.id)
  }

  isServiceStale(...args: any[]) {
    const [service] = args;
    return service.inactive ||
      (service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today)
  }

  @Prop({
    required: true,
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_rental_service', { parameters: { rental_id: this.rental.id } })
  }

  ux!: any;
  dataMembers = ['items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
      },
    }
  }

  async act_3343864469950044_emit_7274_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_7274, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3343864469950044_close_7273_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_7273, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_3343864469950044_showModal_7272_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_7272, alias=undefined
    return {
      name: "RentalService",
      props: {
        rental: this.rental,
        assignToRental: false,
        preventEditMeter: false,
      }
      ,
    }
  }

  async evh_3343864469950044_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8855792642156263_emit_7278_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8855792642156263_emit_7278, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_8855792642156263_close_7277_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8855792642156263_close_7277, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8855792642156263_showModal_7276_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8855792642156263_showModal_7276, alias=undefined
    return {
      name: "RentalService",
      props: {
        oid: $event.scope.item.id,
        mode: "edit",
        rental: this.rental,
        preventEditRental: true,
        preventEditMeter: true,
      }
      ,
    }
  }

  async evh_8855792642156263_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8855792642156263_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_3343864469950044_emit_7274: actions.EmitAction;
    evh_3343864469950044_close_7273: actions.EventHandlerImpl;
    act_3343864469950044_showModal_7272: actions.ShowModalAction;
    evh_3343864469950044_clickPreventStop_7271: actions.EventHandlerImpl;
    evh_3343864469950044_clickPreventStop: actions.EventHandlerGroup;
    act_8855792642156263_emit_7278: actions.EmitAction;
    evh_8855792642156263_close_7277: actions.EventHandlerImpl;
    act_8855792642156263_showModal_7276: actions.ShowModalAction;
    evh_8855792642156263_clickPreventStop_7275: actions.EventHandlerImpl;
    evh_8855792642156263_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_3343864469950044_emit_7274 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_7274_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_7273 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_7274,
        event: "close",
        when: this.evh_3343864469950044_close_7273_getWhen,
        displayName: "emit",
      }
    );
    const act_3343864469950044_showModal_7272 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_7272_getActionArgs,
        events: [evh_3343864469950044_close_7273],
      }
    );
    const evh_3343864469950044_clickPreventStop_7271 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_7272,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickPreventStop_7271],
      }
    );
    const act_8855792642156263_emit_7278 = new actions.EmitAction(
      {
        actionArgs: this.act_8855792642156263_emit_7278_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_8855792642156263_close_7277 = new actions.EventHandlerImpl(
      {
        action: act_8855792642156263_emit_7278,
        event: "close",
        when: this.evh_8855792642156263_close_7277_getWhen,
        displayName: "emitReload",
      }
    );
    const act_8855792642156263_showModal_7276 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8855792642156263_showModal_7276_getActionArgs,
        events: [evh_8855792642156263_close_7277],
      }
    );
    const evh_8855792642156263_clickPreventStop_7275 = new actions.EventHandlerImpl(
      {
        action: act_8855792642156263_showModal_7276,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8855792642156263_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8855792642156263_clickPreventStop_7275],
      }
    );
    return {
      act_3343864469950044_emit_7274,
      evh_3343864469950044_close_7273,
      act_3343864469950044_showModal_7272,
      evh_3343864469950044_clickPreventStop_7271,
      evh_3343864469950044_clickPreventStop,
      act_8855792642156263_emit_7278,
      evh_8855792642156263_close_7277,
      act_8855792642156263_showModal_7276,
      evh_8855792642156263_clickPreventStop_7275,
      evh_8855792642156263_clickPreventStop,
      emitReload: act_8855792642156263_emit_7278,
    }
  }
}

Vue.component("UcRentalServices", UcRentalServices);

</script>