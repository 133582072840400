<template>
<ItpButton
  variant="light"
  :icon="$config.reloadIcon"
  :text="'Load'|pgettext('Button')"
  :title="'Load data'|pgettext('Button|Title')"
  :spinning="action.isRunning"
  @click.prevent.stop="evh_7315092382398562_clickPreventStop($event, {})"
>
</ItpButton>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class BtnLoad extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  action!: object;

  @Watch('action')
  onAction(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("action")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async act_7315092382398562_script_1574_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_1574, alias=undefined
    this.$emit('click', $event)
  }

  async act_7315092382398562_script_1574_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_1574, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_1574_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_1574: actions.ScriptAction;
    evh_7315092382398562_clickPreventStop_1573: actions.EventHandlerImpl;
    evh_7315092382398562_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_1574 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_1574_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_clickPreventStop_1573 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_1574,
        event: "click.prevent.stop",
        displayName: "script",
      }
    );
    const evh_7315092382398562_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_clickPreventStop_1573],
      }
    );
    return {
      act_7315092382398562_script_1574,
      evh_7315092382398562_clickPreventStop_1573,
      evh_7315092382398562_clickPreventStop,
    }
  }
}

Vue.component("BtnLoad", BtnLoad);

</script>