<template>
<DIV
  class="card h-100 overflow-hidden"
  v-if="loaded"
  :style="b_7315092382398562_style"
>
  <div
    class="card-header"
  >
    <ItpBox>
      <ItpBox>
        <ItpButton
          icon="fa-sync-alt"
          :tooltip="'Reload data from the server'|gettext"
          :spinning="actions.reload && actions.reload.isRunning"
          variant="light"
          :text="'Reload data'|pgettext('InvoiceGenerationControlling')"
          class="mr-2"
          @click.prevent.stop="evh_4615685607825208_clickPreventStop($event, {})"
        >
        </ItpButton>
        <BDropdown
          :text="'Invoice groups'|pgettext('InvoiceGenerationControlling')"
          variant="light"
        >
          <BDropdownForm>
            <BFormCheckbox
              :value="item.value"
              :key="item.value"
              v-model="selectedInvoiceGroups"
              :name="item.value"
              v-for="item in invoiceGroups"
              @change="evh_4592790460410433_change($event, {item})"
            >
              {{ item.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>
      </ItpBox>
      <div
        class="d-flex flex-row align-items-center ml-auto text-center"
      >
        <DIV
          :key="colName"
          class="px-2"
          v-for="colName in values.columns"
          :style="b_4440454779651433_style"
        >
          {{ colName }}
        </DIV>
      </div>
    </ItpBox>
  </div>
  <ul
    class="list-group list-group-flush h-100 overflow-auto"
  >
    <li
      :key="item.id"
      :class="{
    'list-group-item': true,
    'd-flex': true,
    'flex-row': true,
    'bg-gray-100': (pager.startIndex + index + 1) % 2 === 0
  }"
      v-for="(item, index) in pager.items"
    >
      <ItpText
        class="pr-2"
      >
        #{{ pager.startIndex + index + 1 }}
      </ItpText>
      <ItpBox
        direction="column"
        class="flex-fill"
      >
        <ItpBox
          direction="row"
          class="app-group-header mb-2"
        >
          <UcCompanyMiniCard
            no-icon
            :item="{
    id: item.partner_id,
    name: item.partner
  }"
            class="mr-3 border rounded px-2"
          >
          </UcCompanyMiniCard>
          <UcRentalMiniCard
            no-icon
            :item="{
    id: item.rental_id,
    name: item.rental
  }"
            class="mr-3 border rounded px-2"
          >
          </UcRentalMiniCard>
          <ItpText
            strong
            uppercase
            :text="item.site"
            class="border rounded px-2"
          >
          </ItpText>
        </ItpBox>
        <table
          class="app-group-rows table"
        >
          <thead>
          </thead>
          <tbody>
            <tr
              :key="row.id"
              v-for="(row, index) in item.rows"
            >
              <td
                :style="{
    width: rowHeaderColumnWidth,
    'border-top-style': 'dotted'
  }"
              >
                <ItpBox
                  direction="column"
                  alignItems="end"
                >
                  <DIV>
                    <strong>{{ row.item }}</strong>
                  </DIV>
                  <DIV
                    v-if="row.invoice_group_id"
                  >
                    {{ row.invoice_group_id }}
                  </DIV>
                  <UcMeterMiniCard
                    :item="{
    id: row.meter_id,
    serial_number: row.serial_number,
    meter_type: row.meter_type
  }"
                    v-if="row.meter_id"
                  >
                  </UcMeterMiniCard>
                </ItpBox>
              </td>
              <td
                :key="colName"
                class="px-2"
                v-for="colName in values.columns"
                :style="{
    width: dataColumnWidth,
    'border-top-style': 'dotted'
  }"
              >
                <DIV
                  class="text-right"
                  v-if="row[colName] && row[colName].net != null"
                >
                  <strong>
                    {{ row[colName].net|number }} {{ row.currency }}

                  </strong>
                </DIV>
                <DIV
                  class="text-right"
                  v-if="row[colName] && row[colName].quantity != null"
                >
                  <span>
                    {{ row[colName].quantity|number }} {{ row.unit }}
                  </span>
                </DIV>
                <ItpBox
                  direction="column"
                  alignItems="end"
                  v-if="row[colName] && row[colName].invoices && row[colName].invoices.length"
                >
                  <ItpBox
                    :key="invoice.invoice_id"
                    direction="column"
                    alignItems="end"
                    :class="{
    'px-2 py-1 border shadow-sm': true,
    'border-light': invoice.invoice_status !== 'draft',
    'bg-yellow-200 border-yellow-600 rounded': invoice.invoice_status === 'draft',
  }"
                    v-for="invoice in row[colName].invoices"
                  >
                    <DIV
                      class="text-right"
                      v-if="invoice.prior_reading_date != null"
                    >
                      <small>{{ invoice.prior_reading_date|date }}</small> &bull; {{ invoice.prior_reading_amount|number }} {{ invoice.prior_reading_unit }} <br/>
<small>{{ invoice.current_reading_date|date }}</small> &bull; {{ invoice.current_reading_amount|number }} {{ invoice.current_reading_unit }} <br/>
x{{ invoice.billing_multiplier|number }}

                    </DIV>
                    <ItpBox
                      alignItems="center"
                    >
                      <UcInvoiceTypeBadge
                        :value="invoice.invoice_type"
                        :kind="invoice.invoice_kind"
                        abbreviated
                        class="mr-2"
                      >
                      </UcInvoiceTypeBadge>
                      <a
                        href="#"
                        @click.pervent.stop="evh_3723353993403825_clickPerventStop($event, {item, index, row, colName, invoice})"
                      >
                        <small
                          class="text-muted text-nowrap"
                        >
                          {{ invoice.invoice_number }}
                        </small>
                      </a>
                    </ItpBox>
                    <UcContractMiniCard
                      no-icon
                      :item="{
    id: invoice.contract_id,
    contract_number: invoice.contract_number
  }"
                    >
                    </UcContractMiniCard>
                  </ItpBox>
                </ItpBox>
              </td>
            </tr>
            <tr>
              <td
                class="text-right"
                :style="b_5218831530088670_style"
              >
                Total:
              </td>
              <td
                :key="colName"
                direction="row"
                class="px-2"
                v-for="colName in values.columns"
                :style="b_5119818220584553_style"
              >
                <DIV
                  class="text-right"
                  v-if="item.totals[colName] != null"
                >
                  <strong>
                    {{ item.totals[colName]|number }} {{ item.currency }}

                  </strong>
                </DIV>
              </td>
            </tr>
          </tbody>
        </table>
      </ItpBox>
    </li>
  </ul>
  <div
    class="card-footer d-flex"
  >
    <b-pagination
      v-model="pager.currentPage"
      :totalRows="pager.totalRows"
      :perPage="pager.perPage"
      last-number
      first-number
      class="m-0 p-0 ml-auto"
      @change="evh_1548630417156826_change($event, {})"
    >
    </b-pagination>
  </div>
</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceGenerationControlling extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedInvoiceGroups = new ComponentValueCache(this, "selectedInvoiceGroups", "UcInvoiceGenerationControlling.selectedInvoiceGroups");
  }

  getAllRows(...args: any[]) {
    return this.values.rows
  }

  getInvoiceGroups(...args: any[]) {
    const [rows] = args;
    const visited = []
    const result = [];
    for (let row of rows) {
      const value = row.invoice_group_id;

      if (visited.indexOf(value) > -1) {
        continue;
      }

      result.push({ value, text: value ? value.toUpperCase() : "BLANK" })
      visited.push(value);
    }
    return result;
  }

  createGroups(...args: any[]) {
    const [rows] = args;

    return _
      .chain(rows)
      .groupBy(p => `${p.partner_id}${p.rental_id}`)
      .map((rows, group) => {
        const visibleRows = _.filter(rows, this.isRowVisible);
        if (!visibleRows.length) {
          return;
        }

        return {
          ...visibleRows[0],
          rows: visibleRows,
          totals: this.calculateTotals(visibleRows)
        };
      })
      .filter(p => !!p)
      .value()
  }

  calculateTotals(...args: any[]) {
    const [rows] = args;
    const totals = {};

    for (let row of rows) {
      for (let colName of this.values.columns) {
        if (!row[colName] || row[colName].net == null) {
          continue;
        }

        const net = row[colName].net;

        if (totals[colName] == null) {
          totals[colName] = net;
          continue;
        }

        totals[colName] += net;
      }
    }

    return totals;
  }

  applyFilters(...args: any[]) {
    this.groups = this.createGroups(this.getAllRows())
    this.pager.setItems(this.groups);
  }

  isRowVisible(...args: any[]) {
    const [row] = args;
    const selectedInvoiceGroups = this.selectedInvoiceGroups;

    return selectedInvoiceGroups.length === 0
      || selectedInvoiceGroups.includes(row.invoice_group_id);
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  pageSize!: any;
  rowHeaderColumnWidth!: any;
  dataColumnWidth!: any;
  resizeTrigger!: any;
  selectedInvoiceGroups!: any;
  selectedItems!: any;
  ts!: any;
  values!: any;

  async $$load_values() {
    return this.$fn.fetch('view_draft_invoice_lines')
  }

  invoiceGroups!: any;

  async $$load_invoiceGroups() {
    return this.getInvoiceGroups(this.getAllRows())
  }

  groups!: any;

  async $$load_groups() {
    return this.createGroups(this.getAllRows())
  }

  filterInputValue!: any;
  pager!: any;

  async $$load_pager() {
    return this.$fn.pager(this.groups, this.$data.pageSize)
  }

  laggingApplyFilters!: any;

  async $$load_laggingApplyFilters() {
    return _.debounce(_.throttle(this.applyFilters, 300), 500)
  }

  ux!: any;
  b_7315092382398562_style!: any;
  b_4440454779651433_style!: any;

  async $$load_b_4440454779651433_style() {
    return {
      width: this.dataColumnWidth,
    }
  }

  b_5218831530088670_style!: any;

  async $$load_b_5218831530088670_style() {
    return {
      width: this.rowHeaderColumnWidth,
    }
  }

  b_5119818220584553_style!: any;

  async $$load_b_5119818220584553_style() {
    return {
      width: this.dataColumnWidth,
    }
  }

  dataMembers = ['pageSize', 'rowHeaderColumnWidth', 'dataColumnWidth', 'resizeTrigger', 'selectedInvoiceGroups', 'selectedItems', 'ts', 'values', 'invoiceGroups', 'groups', 'filterInputValue', 'pager', 'laggingApplyFilters', 'ux', 'b_7315092382398562_style', 'b_4440454779651433_style', 'b_5218831530088670_style', 'b_5119818220584553_style'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        pageSize: 10,
        rowHeaderColumnWidth: "auto",
        dataColumnWidth: "200px",
        resizeTrigger: 0,
        selectedInvoiceGroups: [
        ]
        ,
        selectedItems: [
        ]
        ,
        ts: null,
        values: null,
        invoiceGroups: null,
        groups: null,
        filterInputValue: null,
        pager: null,
        laggingApplyFilters: null,
        ux: null,
        b_7315092382398562_style: {
          "max-width": "1280px",
        }
        ,
        b_4440454779651433_style: null,
        b_5218831530088670_style: null,
        b_5119818220584553_style: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async evh_4615685607825208_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4615685607825208_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4592790460410433_script_5134_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4592790460410433_script_5134, alias=undefined
    this.laggingApplyFilters()
  }

  async act_4592790460410433_script_5134_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4592790460410433_script_5134, alias=undefined
    return {
      value: () => this.act_4592790460410433_script_5134_getActionArgs_value($event),
    }
  }

  async evh_4592790460410433_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4592790460410433_change.executeFromDOM(this, event, scope);
  }

  async act_3723353993403825_showModal_5136_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3723353993403825_showModal_5136, alias=undefined
    return {
      name: "Invoice",
      props: {
        oid: $event.scope.invoice.invoice_id,
      }
      ,
    }
  }

  async evh_3723353993403825_clickPerventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3723353993403825_clickPerventStop.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_script_5138_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1548630417156826_script_5138, alias=undefined
    this.pager.setCurrentPage($event.originalEvent)
  }

  async act_1548630417156826_script_5138_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1548630417156826_script_5138, alias=undefined
    return {
      value: () => this.act_1548630417156826_script_5138_getActionArgs_value($event),
    }
  }

  async evh_1548630417156826_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_change.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_4615685607825208_reloadComponentData_5132: actions.ReloadComponentDataAction;
    evh_4615685607825208_clickPreventStop_5131: actions.EventHandlerImpl;
    evh_4615685607825208_clickPreventStop: actions.EventHandlerGroup;
    act_4592790460410433_script_5134: actions.ScriptAction;
    evh_4592790460410433_change_5133: actions.EventHandlerImpl;
    evh_4592790460410433_change: actions.EventHandlerGroup;
    act_3723353993403825_showModal_5136: actions.ShowModalAction;
    evh_3723353993403825_clickPerventStop_5135: actions.EventHandlerImpl;
    evh_3723353993403825_clickPerventStop: actions.EventHandlerGroup;
    act_1548630417156826_script_5138: actions.ScriptAction;
    evh_1548630417156826_change_5137: actions.EventHandlerImpl;
    evh_1548630417156826_change: actions.EventHandlerGroup;
  }

  getActions() {
    const act_4615685607825208_reloadComponentData_5132 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_4615685607825208_clickPreventStop_5131 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_reloadComponentData_5132,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_4615685607825208_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_4615685607825208_clickPreventStop_5131],
      }
    );
    const act_4592790460410433_script_5134 = new actions.ScriptAction(
      {
        actionArgs: this.act_4592790460410433_script_5134_getActionArgs,
        events: [],
      }
    );
    const evh_4592790460410433_change_5133 = new actions.EventHandlerImpl(
      {
        action: act_4592790460410433_script_5134,
        event: "change",
        displayName: "script",
      }
    );
    const evh_4592790460410433_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_4592790460410433_change_5133],
      }
    );
    const act_3723353993403825_showModal_5136 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3723353993403825_showModal_5136_getActionArgs,
        events: [],
      }
    );
    const evh_3723353993403825_clickPerventStop_5135 = new actions.EventHandlerImpl(
      {
        action: act_3723353993403825_showModal_5136,
        event: "click.pervent.stop",
        displayName: "showModal",
      }
    );
    const evh_3723353993403825_clickPerventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3723353993403825_clickPerventStop_5135],
      }
    );
    const act_1548630417156826_script_5138 = new actions.ScriptAction(
      {
        actionArgs: this.act_1548630417156826_script_5138_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_change_5137 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_script_5138,
        event: "change",
        displayName: "script",
      }
    );
    const evh_1548630417156826_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_change_5137],
      }
    );
    return {
      act_4615685607825208_reloadComponentData_5132,
      evh_4615685607825208_clickPreventStop_5131,
      evh_4615685607825208_clickPreventStop,
      act_4592790460410433_script_5134,
      evh_4592790460410433_change_5133,
      evh_4592790460410433_change,
      act_3723353993403825_showModal_5136,
      evh_3723353993403825_clickPerventStop_5135,
      evh_3723353993403825_clickPerventStop,
      act_1548630417156826_script_5138,
      evh_1548630417156826_change_5137,
      evh_1548630417156826_change,
      reload: act_4615685607825208_reloadComponentData_5132,
    }
  }
}

Vue.component("UcInvoiceGenerationControlling", UcInvoiceGenerationControlling);

</script>