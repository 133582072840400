"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceData = class UcInvoiceData extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onSaveNotes(...args) {
        console.log(this.invoice.notes);
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get isReadonly() {
        if (this.loaded) {
            return false;
        }
        return null;
    }
    get invoiceTypeName() {
        if (this.loaded) {
            return this.invoice.invoice_type === "correction" ? this.$fn.pgettext("InvoiceType", "Correction") :
                (this.invoice.invoice_type === "storno" ? this.$fn.pgettext("InvoiceType", "Storno") :
                    (this.invoice.invoice_type === "proforma" ? this.$fn.pgettext("InvoiceType", "Pro Forma") : "?"));
        }
        return null;
    }
    act_2596562525078121_emit_5454_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_2596562525078121_request_5452_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "update_invoice_notes",
                data: { id: this.invoice.id, text: $event.data.value.text },
            };
        });
    }
    evh_2596562525078121_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2596562525078121_save.executeFromDOM(this, event, scope);
        });
    }
    act_4152404563918957_emit_5456_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_4152404563918957_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4152404563918957_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3855552110905499_emit_5458_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3855552110905499_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3855552110905499_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_2596562525078121_emit_5454 = new core_1.actions.EmitAction({
            actionArgs: this.act_2596562525078121_emit_5454_getActionArgs,
            events: [],
        });
        const evh_2596562525078121_success_5453 = new core_1.actions.EventHandlerImpl({
            action: act_2596562525078121_emit_5454,
            event: "success",
            displayName: "emit",
        });
        const act_2596562525078121_request_5452 = new core_1.actions.RequestAction({
            actionArgs: this.act_2596562525078121_request_5452_getActionArgs,
            displayName: "saveNotes",
            events: [evh_2596562525078121_success_5453],
        });
        const evh_2596562525078121_save_5451 = new core_1.actions.EventHandlerImpl({
            action: act_2596562525078121_request_5452,
            event: "save",
            displayName: "saveNotes",
        });
        const evh_2596562525078121_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2596562525078121_save_5451],
        });
        const act_4152404563918957_emit_5456 = new core_1.actions.EmitAction({
            actionArgs: this.act_4152404563918957_emit_5456_getActionArgs,
            events: [],
        });
        const evh_4152404563918957_reload_5455 = new core_1.actions.EventHandlerImpl({
            action: act_4152404563918957_emit_5456,
            event: "reload",
            displayName: "emit",
        });
        const evh_4152404563918957_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4152404563918957_reload_5455],
        });
        const act_3855552110905499_emit_5458 = new core_1.actions.EmitAction({
            actionArgs: this.act_3855552110905499_emit_5458_getActionArgs,
            events: [],
        });
        const evh_3855552110905499_reload_5457 = new core_1.actions.EventHandlerImpl({
            action: act_3855552110905499_emit_5458,
            event: "reload",
            displayName: "emit",
        });
        const evh_3855552110905499_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3855552110905499_reload_5457],
        });
        return {
            act_2596562525078121_emit_5454,
            evh_2596562525078121_success_5453,
            act_2596562525078121_request_5452,
            evh_2596562525078121_save_5451,
            evh_2596562525078121_save,
            act_4152404563918957_emit_5456,
            evh_4152404563918957_reload_5455,
            evh_4152404563918957_reload,
            act_3855552110905499_emit_5458,
            evh_3855552110905499_reload_5457,
            evh_3855552110905499_reload,
            saveNotes: act_2596562525078121_request_5452,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceData.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceData.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], UcInvoiceData.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceData.prototype, "onForm_id", null);
UcInvoiceData = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceData);
exports.default = UcInvoiceData;
vue_property_decorator_1.Vue.component("UcInvoiceData", UcInvoiceData);
