var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.readonly
        ? _c(
            "DIV",
            { staticClass: "form-control form-control-readonly" },
            [
              _c("UcAccountSelectOption", {
                attrs: { payload: _vm.value, "display-owner": "" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.readonly
        ? _c(
            "b-dropdown",
            {
              staticClass: "apply-inline-button",
              attrs: { "toggle-class": "border", variant: "light" }
            },
            [
              _c(
                "template",
                { slot: "button-content" },
                [
                  !_vm.selectedAccount
                    ? _c("ItpText", {
                        attrs: {
                          text: _vm._f("pgettext")(
                            "Select account",
                            "AccountSelector"
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedAccount
                    ? _c("UcAccountSelectOption", {
                        attrs: { payload: _vm.value, "display-owner": "" }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasInactiveItem
                ? _c(
                    "b-dropdown-form",
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          on: {
                            change: function($event) {
                              return _vm.evh_1717672417478439_change($event, {})
                            }
                          },
                          model: {
                            value: _vm.states.showInactive,
                            callback: function($$v) {
                              _vm.$set(_vm.states, "showInactive", $$v)
                            },
                            expression: "states.showInactive"
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "Show inactive",
                                  "AccountSelector"
                                )
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasInactiveItem ? _c("b-dropdown-divider") : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.accountGroups, function(group) {
                return _c(
                  "b-dropdown-group",
                  {
                    key: group.id,
                    attrs: { id: group.id, header: group.name }
                  },
                  _vm._l(group.items, function(item) {
                    return _c(
                      "b-dropdown-item-button",
                      {
                        key: item.id,
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.evh_8127633238173630_clickPrevent(
                              $event,
                              { group: group, item: item }
                            )
                          }
                        }
                      },
                      [
                        _c("UcAccountSelectOption", {
                          attrs: { payload: item }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }