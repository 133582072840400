var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Payment",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "UcHeader",
            {
              attrs: {
                slot: "header",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.payment.color
              },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              },
              slot: "header"
            },
            [
              _c("UcPaymentHeader", {
                attrs: { payment: _vm.item, config: _vm.config },
                on: {
                  reload: function($event) {
                    return _vm.evh_8564662037462133_reload($event, {})
                  },
                  deleted: function($event) {
                    return _vm.evh_8564662037462133_deleted($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  attrs: { id: _vm.formId, name: "payment" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c("FieldsetPaymentGeneral", {
                    attrs: {
                      config: _vm.config,
                      payment: _vm.item,
                      transactionTypes: _vm.transactionTypes,
                      formId: _vm.formId,
                      reload: _vm.lastLoaded
                    },
                    on: {
                      accountChanged: function($event) {
                        return _vm.evh_5534025912102772_accountChanged(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("HR"),
              _vm._v(" "),
              _c("UcPaymentInvoices", {
                attrs: { payment: _vm.item, reload: _vm.lastLoaded },
                on: {
                  recalculate: function($event) {
                    return _vm.evh_3083451129821185_recalculate($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: {
                slot: "footer",
                card: !_vm.modal,
                modal: _vm.modal,
                color: _vm.$config.payment.color
              },
              slot: "footer"
            },
            [
              !_vm.isNew && !_vm.item.isReadonly
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")("Save changes", "Button"),
                      disabled:
                        !_vm.forms.payment || !_vm.forms.payment.submittable,
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.save.isRunning
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew && !_vm.item.isReadonly
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")("Complete", "Command"),
                      disabled: !_vm.isNew && _vm.item.readonly,
                      variant: "success",
                      icon: "fa-check"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1419464017721962_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isNew
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")("Create payment", "Button"),
                      disabled:
                        !_vm.forms.payment || !_vm.forms.payment.submittable,
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.save.isRunning
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNew
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")(
                        "Create another payment",
                        "Button"
                      ),
                      disabled:
                        !_vm.forms.payment || !_vm.forms.payment.submittable,
                      variant: "light",
                      icon: "mdi:forward",
                      spinning: _vm.actions.save.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_5566617329548203_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }