<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <VueSlickgrid
    name="payments"
    :headerTitle="'Payments'|pgettext('PaymentsView')"
    :items="getItems"
    checkbox-row-selection
    :headerShowColumnSelectorButton="b_2248226175642056_headerShowColumnSelectorButton"
    header-show-reload-button
    footer-totals
    export-to-csv
    :exportToCsvFilename="'payments'|pgettext('ExportFilename')"
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
    @command="evh_2248226175642056_command($event, {})"
  >
    <slot>
    </slot>
  </VueSlickgrid>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class PaymentsView extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  getItems(...args: any[]) {
    if (this.parameters) {
      return this.$fn.fetch('list_payment', { parameters: this.parameters })
    }

    const parameters = this.params;
    return parameters && parameters.account && parameters.date_from
      ? this.$fn.fetch('list_payment', { parameters })
      : []
  }

  getInvoiceBadgeClass(...args: any[]) {
    const data = args[0];

    if (_.isEmpty(data)) {
      return;
    }

    const payment_balance = data.payment_balance || 0;
    const invoice_gross_amount = data.invoice_gross_amount

    if (payment_balance == 0) {
      return 'bg-green-200';
    }

    if (payment_balance < 0) {
      return 'bg-yellow-200';
    }

    if (payment_balance < invoice_gross_amount) {
      return 'bg-blue-200';
    }
  }

  @Prop({
    type: Object,
  })
  parameters!: object;

  @Watch('parameters')
  onParameters(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parameters")
  }

  selectedItems!: any;
  params!: any;
  totals!: any;
  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _.reduce(data, (m, v, k) => _.set(m, v.id, this.$fn.pgettext('PaymentMethod', v.name))))
  }

  ux!: any;
  b_2248226175642056_headerShowColumnSelectorButton!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "payment_method",
      name: this.$fn.pgettext("PaymentBrowserView", "Method"),
      formatter: {
        name: "gettext",
        context: "PaymentMethod",
        conversion: ["Unsnake"
          ,
          "Sentence"
          ,
        ]
        ,
        filterInput: true,
      }
      ,
    }
      ,
    {
      field: "transaction_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Number"),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "transaction_type",
      name: this.$fn.pgettext("PaymentBrowserView", "Type"),
      formatter: {
        name: "gettext",
        context: "TransactionType",
        conversion: "Unsnake",
        filterInput: true,
      }
      ,
    }
      ,
    {
      field: "transaction_date",
      name: this.$fn.pgettext("PaymentBrowserView", "Date"),
      type: "date",
    }
      ,
    {
      field: "transaction_title",
      name: this.$fn.pgettext("PaymentBrowserView", "Title"),
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("PaymentBrowserView", "Description"),
    }
      ,
    {
      field: "notes",
      name: this.$fn.pgettext("PaymentBrowserView", "Notes"),
    }
      ,
    {
      field: "opening_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Opening balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "amount_in",
      name: this.$fn.pgettext("PaymentBrowserView", "In"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "amount_out",
      name: this.$fn.pgettext("PaymentBrowserView", "Out"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "closing_balance",
      name: this.$fn.pgettext("PaymentBrowserView", "Closing balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("PaymentBrowserView", "Currency"),
    }
      ,
    {
      field: "exchange_rate",
      name: this.$fn.pgettext("PaymentBrowserView", "Exchange Rate"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    {
      field: "reference_number",
      name: this.$fn.pgettext("PaymentBrowserView", "Ref Nr."),
    }
      ,
    {
      field: "file_number",
      name: this.$fn.pgettext("PaymentBrowserView", "File Nr."),
    }
      ,
    {
      field: "company_name",
      name: this.$fn.pgettext("PaymentBrowserView", "Own company"),
      formatter: {
        name: "routeLink",
        to: "company",
        navigateOnIconClick: true,
        params: {
          id: "company_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("PaymentBrowserView", "Client"),
      formatter: {
        name: "routeLink",
        to: "company",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "invoices",
      name: this.$fn.pgettext("PaymentBrowserView", "Invoices"),
      formatter: [{
        name: "chain",
        mode: "mapReduce",
        separator: ", ",
        formatterContext: "value",
        formatters: [{
          name: "getvalue",
          opts: {
            field: "invoice_number",
            source: "value",
          }
          ,
        }
          ,
        {
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "id",
          }
          ,
        }
          ,
        {
          name: "badge",
          badgeClass: this.getInvoiceBadgeClass,
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    ]
  }

  dataMembers = ['selectedItems', 'params', 'totals', 'paymentMethods', 'ux', 'b_2248226175642056_headerShowColumnSelectorButton', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        params: {
        }
        ,
        totals: [{
          id: 0,
          amount_in: 0,
          amount_out: 0,
        }
          ,
        ]
        ,
        paymentMethods: null,
        ux: null,
        b_2248226175642056_headerShowColumnSelectorButton: false,
        b_2248226175642056_columnDefaults: {
          sortable: false,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_5784_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5784, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_showModal_5786_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_5786, alias=undefined
    return {
      name: "Payment",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_5785_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_5785, alias=undefined
    return $event.data.column.id === 'transaction_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5782: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5781: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_setData_5784: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_5783: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_2248226175642056_showModal_5786: actions.ShowModalAction;
    evh_2248226175642056_command_5785: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5782 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5781 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5782,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5781],
      }
    );
    const act_2248226175642056_setData_5784 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5784_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_5783 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5784,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_5783],
      }
    );
    const act_2248226175642056_showModal_5786 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_5786_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_command_5785 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_5786,
        event: "command",
        when: this.evh_2248226175642056_command_5785_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_5785],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5782,
      evh_7315092382398562_reload_5781,
      evh_7315092382398562_reload,
      act_2248226175642056_setData_5784,
      evh_2248226175642056_selectedRowsChanged_5783,
      evh_2248226175642056_selectedRowsChanged,
      act_2248226175642056_showModal_5786,
      evh_2248226175642056_command_5785,
      evh_2248226175642056_command,
    }
  }
}

Vue.component("PaymentsView", PaymentsView);

</script>