var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpModal",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "CompanyDataImportModal"
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "DIV",
                { staticClass: "container" },
                [
                  _vm.loaded
                    ? _c(
                        "ItpForm",
                        {
                          ref: "form",
                          attrs: { name: "clientDataImport" },
                          on: {
                            submit: function($event) {
                              return _vm.evh_5534025912102772_submit($event, {})
                            }
                          }
                        },
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                row: "",
                                name: "file",
                                label: _vm._f("gettext")("File"),
                                labelFor: "file",
                                "label-cols-md": "2",
                                "content-cols-md": "10"
                              }
                            },
                            [
                              _c("ItpFormFile", {
                                attrs: {
                                  name: "file",
                                  size: "md",
                                  accept: ".xlsx, .csv",
                                  required: ""
                                },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                row: "",
                                name: "header",
                                "label-cols-md": "0",
                                "content-cols-md": "12"
                              }
                            },
                            [
                              _c("ItpFormCheckbox", {
                                attrs: {
                                  name: "headered",
                                  label: _vm._f("gettext")(
                                    "Import file has header"
                                  ),
                                  size: "md"
                                },
                                model: {
                                  value: _vm.headered,
                                  callback: function($$v) {
                                    _vm.headered = $$v
                                  },
                                  expression: "headered"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                row: "",
                                name: "colnum_number",
                                label: _vm._f("gettext")(
                                  "Number column number"
                                ),
                                "label-cols-md": "5",
                                "content-cols-md": "3"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "colnum_number",
                                  size: "md",
                                  number: "",
                                  required: "",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.colnum_number,
                                  callback: function($$v) {
                                    _vm.colnum_number = $$v
                                  },
                                  expression: "colnum_number"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                row: "",
                                name: "colnum_name",
                                label: _vm._f("gettext")("Name column number"),
                                "label-cols-md": "5",
                                "content-cols-md": "3"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "colnum_name",
                                  size: "md",
                                  number: "",
                                  required: "",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.colnum_name,
                                  callback: function($$v) {
                                    _vm.colnum_name = $$v
                                  },
                                  expression: "colnum_name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                row: "",
                                name: "colnum_email",
                                label: _vm._f("gettext")("Email column number"),
                                "label-cols-md": "5",
                                "content-cols-md": "3"
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  type: "number",
                                  name: "colnum_email",
                                  size: "md",
                                  number: "",
                                  required: "",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.colnum_email,
                                  callback: function($$v) {
                                    _vm.colnum_email = $$v
                                  },
                                  expression: "colnum_email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _c("ItpButton", {
                attrs: {
                  form:
                    _vm.forms.clientDataImport && _vm.forms.clientDataImport.id,
                  text: _vm._f("gettext")("Import"),
                  type: "submit",
                  variant: "primary",
                  icon: "fa-file-import",
                  spinning: _vm.actions.submit.isRunning,
                  disabled:
                    !_vm.forms.clientDataImport ||
                    !_vm.forms.clientDataImport.submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Cancel"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_1419464017721962_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }