var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { alignItems: "center" } },
    [
      !_vm.noIcon
        ? _c("ItpIcon", {
            staticClass: "mr-2",
            attrs: {
              name: _vm.$config.invoiceRegister.icon,
              spacing: "mr-3",
              title: _vm._f("pgettext")("Invoice register", "InvoiceRegister")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ItpText", {
        staticClass: "mr-2",
        attrs: { strong: "", text: _vm.item.entry_number }
      }),
      _vm._v(" "),
      _c("ItpLink", {
        attrs: { icon: _vm.$config.externalLinkIcon },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.evh_8358196963355853_clickPreventStop($event, {})
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }