var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { staticClass: "w-100", attrs: { direction: "row" } },
    [
      _c(
        "ItpText",
        { attrs: { strong: "" } },
        [
          _c("ItpIcon", { attrs: { icon: "material-symbols:ad-group-sharp" } }),
          _vm._v(" "),
          _c("ItpText", {
            attrs: { spacing: "mx-1", text: _vm.group.data.description }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.group.data.expense_type
        ? _c(
            "ItpBadge",
            {
              staticClass: "mx-2 bg-gray-100 border",
              attrs: { strong: "", "no-box-shadow": "" }
            },
            [
              _c("ItpText", {
                attrs: { strong: "", text: _vm.group.data.expense_type }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.group.data.rental
        ? _c("UcRentalMiniCard", {
            staticClass: "mx-2",
            attrs: { badge: "", item: _vm.group.data.rental }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.group.data.property
        ? _c("UcPropertyMiniCard", {
            staticClass: "mx-2",
            attrs: { badge: "", item: _vm.group.data.property }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.group.data.worksheet
        ? _c("UcWorksheetMiniCard", {
            staticClass: "mx-2",
            attrs: { badge: "", item: _vm.group.data.worksheet }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.group.data.contractor
        ? _c("UcCompanyMiniCard", {
            staticClass: "mx-2",
            attrs: { badge: "", item: _vm.group.data.contractor }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ItpLink", {
        staticClass: "mx-2",
        attrs: {
          icon: _vm.$config.editIcon,
          text: _vm._f("pgettext")("Edit group...", "InvoiceLineGroup")
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.evh_4280711203779102_clickPreventStop($event, {})
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }