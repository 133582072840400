var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Invoices",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "VueSlickgrid",
            {
              ref: "grid",
              staticClass: "page--grid",
              attrs: {
                name: _vm.gridName,
                headerTitle: _vm.titles[_vm.kind],
                items: _vm.getItems,
                headerShowReloadButton:
                  _vm.b_2248226175642056_headerShowReloadButton,
                "checkbox-row-selection": "",
                "footer-totals": "",
                "export-to-csv": "",
                exportToCsvFilename: _vm._f("pgettext")(
                  "invoices",
                  "ExportFilename"
                ),
                columnDefaults: _vm.b_2248226175642056_columnDefaults,
                columns: _vm.b_2248226175642056_columns
              },
              on: {
                selectedRowsChanged: function($event) {
                  return _vm.evh_2248226175642056_selectedRowsChanged(
                    $event,
                    {}
                  )
                },
                command: function($event) {
                  return _vm.evh_2248226175642056_command($event, {})
                }
              }
            },
            [
              _c(
                "ItpBox",
                {
                  attrs: { slot: "toolbar", alignItems: "center" },
                  slot: "toolbar"
                },
                [
                  _c("InvoicesFilters", {
                    attrs: { kind: _vm.kind },
                    on: {
                      submit: function($event) {
                        return _vm.evh_6466705138206980_submit($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("vr"),
                  _vm._v(" "),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "border rounded",
                      attrs: {
                        text: _vm._f("gettext")("Commands"),
                        variant: "light"
                      }
                    },
                    [
                      _c("ItpButton", {
                        attrs: {
                          icon: "fa-plus",
                          text: _vm._f("pgettext")(
                            "New Invoice...",
                            "Invoices"
                          ),
                          "dropdown-item": ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.evh_8697745105058001_click($event, {})
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.kind === "incoming"
                        ? _c("ItpButton", {
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Import Invoices...",
                                "Invoices"
                              ),
                              icon: _vm.$config.uploadIcon,
                              "dropdown-item": ""
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.evh_5382094220310827_clickPrevent(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("JobHandlerControl", {
                    staticClass: "ml-2",
                    attrs: {
                      jobType: "invoice_print",
                      "load-current": "",
                      text: _vm._f("pgettext")("Print invoices...", "Invoices"),
                      icon: "icon-park-outline:printer-one",
                      payload: function() {
                        return _vm.onPrintInvoicesPayload()
                      },
                      disabled: !_vm.selectedItems || !_vm.selectedItems.length
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }