<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    v-if="item.head_office"
  >
    <ItpText muted>{{ 'Head Office'|pgettext('Client') }}:</ItpText> {{ item.head_office }}

  </DIV>
  <DIV
    v-if="item.branch_office"
  >
    <ItpText muted>{{ 'Branch Office'|pgettext('Client') }}:</ItpText> {{ item.branch_office }}

  </DIV>
  <DIV
    v-if="item.statistical_code"
  >
    <ItpText muted>{{ 'Statistical Code'|pgettext('Client') }}:</ItpText> {{ item.statistical_code }}

  </DIV>
  <DIV
    v-if="item.registration_number"
  >
    <ItpText muted>{{ 'Registration Number'|pgettext('Client') }}:</ItpText> {{ item.registration_number }}

  </DIV>
  <DIV
    v-if="item.account_number"
  >
    <ItpText muted>{{ 'Bank Account'|pgettext('Client') }}:</ItpText> {{ item.account_number }}

  </DIV>
  <DIV
    v-if="item.representative"
  >
    <ItpText muted>{{ 'Representatives'|pgettext('Contract') }}:</ItpText> {{ item.representative }}

  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractClientCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6672: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6671: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6672 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6671 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6672,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6671],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6672,
      evh_7315092382398562_reload_6671,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContractClientCard", UcContractClientCard);

</script>