<template>
<ItpWidgetCard>
  <ItpBox
    alignItems="center"
    slot="header"
  >
    <strong>
      {{ 'Rental Services'|pgettext('MeterRentalServices') }}
    </strong>
    <ItpButton
      :text="'Add rental service...'|pgettext('UcMeterRentalServices')"
      variant="light"
      size="sm"
      class="ml-auto"
      @click.prevent.stop="evh_3343864469950044_clickPreventStop($event, {})"
    >
    </ItpButton>
  </ItpBox>
  <BListGroup
    v-if="loaded"
  >
    <BListGroupItem
      v-if="!items.length"
    >
      <span>
        {{ 'No rental services.'|pgettext('MeterRentalServices') }}
      </span>
    </BListGroupItem>
    <BListGroupItem
      :key="item.id"
      class="d-flex flex-column"
      v-for="item in sortItems(items)"
    >
      <ItpBox
        alignItems="center"
      >
        <ItpBox
          alignItems="center"
          wrap-items
        >
          <ItpLink
            :to="{
    name: 'app.rental',
    params: {id: item.rental_id}
  }"
          >
            <ItpText
              strong
              :text="item.rental.name"
            >
            </ItpText>
          </ItpLink>
          <ItpText
            :text="item.service.name"
            :strikethrough="isServiceStale(item)"
            class="px-3 font-weight-bold"
          >
          </ItpText>
          <ItpLink
            :icon="$config.externalLinkIcon"
            :to="{ name: 'app.product', params: { id: item.service_id } }"
          >
          </ItpLink>
          <UcValidityPeriod
            :from="item.valid_from"
            :to="item.valid_to"
            class="px-3"
          >
          </UcValidityPeriod>
          <ItpBadge
            :title="'Billing multiplier'|gettext"
            no-box-shadow
            variant="light"
            class="px-3"
          >
            <ItpIcon
              name="fa-times"
            >
            </ItpIcon>
            <ItpText
              :text="item.meter_factor"
              class="pl-2"
            >
            </ItpText>
          </ItpBadge>
          <UcInactive
            :value="item.inactive"
            class="px-3"
          >
          </UcInactive>
        </ItpBox>
        <ItpButton
          icon="fa-pen"
          :tooltip="'Edit'|gettext"
          variant="light"
          size="sm"
          class="ml-auto"
          @click.prevent.stop="evh_7989141091456081_clickPreventStop($event, {item})"
        >
        </ItpButton>
      </ItpBox>
      <ItpBox>
        <ItpText
          :text="item.notes"
          pre
          v-if="item.notes"
        >
        </ItpText>
      </ItpBox>
      <UcRentalServiceCostAllocators
        :service="item"
      >
      </UcRentalServiceCostAllocators>
    </BListGroupItem>
  </BListGroup>
</ItpWidgetCard>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcMeterRentalServices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  sortItems(...args: any[]) {
    return _.sortBy(args[0], p => (!this.isServiceStale(p) ? "_" + p.service.name : p.service.name).toLowerCase() + p.id)
  }

  isServiceStale(...args: any[]) {
    const [service] = args;
    return service.inactive ||
      (service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today)
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_meter_rental_services', { parameters: { meter_id: this.meter.id } })
  }

  ux!: any;
  dataMembers = ['items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
      },
    }
  }

  async act_3343864469950044_emit_7034_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_7034, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3343864469950044_close_7033_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_7033, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_3343864469950044_showModal_7032_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_7032, alias=undefined
    return {
      name: "RentalService",
      props: {
        meter: this.meter,
        assignToRental: true,
        preventEditMeter: true,
      }
      ,
    }
  }

  async evh_3343864469950044_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7989141091456081_emit_7038_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7989141091456081_emit_7038, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_7989141091456081_close_7037_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7989141091456081_close_7037, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_7989141091456081_showModal_7036_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7989141091456081_showModal_7036, alias=undefined
    return {
      name: "RentalService",
      props: {
        oid: $event.scope.item.id,
        mode: "edit",
        meter: this.meter,
        assignToRental: true,
        preventEditMeter: true,
        preventEditRental: true,
      }
      ,
    }
  }

  async evh_7989141091456081_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7989141091456081_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_3343864469950044_emit_7034: actions.EmitAction;
    evh_3343864469950044_close_7033: actions.EventHandlerImpl;
    act_3343864469950044_showModal_7032: actions.ShowModalAction;
    evh_3343864469950044_clickPreventStop_7031: actions.EventHandlerImpl;
    evh_3343864469950044_clickPreventStop: actions.EventHandlerGroup;
    act_7989141091456081_emit_7038: actions.EmitAction;
    evh_7989141091456081_close_7037: actions.EventHandlerImpl;
    act_7989141091456081_showModal_7036: actions.ShowModalAction;
    evh_7989141091456081_clickPreventStop_7035: actions.EventHandlerImpl;
    evh_7989141091456081_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_3343864469950044_emit_7034 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_7034_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_7033 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_7034,
        event: "close",
        when: this.evh_3343864469950044_close_7033_getWhen,
        displayName: "emit",
      }
    );
    const act_3343864469950044_showModal_7032 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_7032_getActionArgs,
        events: [evh_3343864469950044_close_7033],
      }
    );
    const evh_3343864469950044_clickPreventStop_7031 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_7032,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3343864469950044_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_clickPreventStop_7031],
      }
    );
    const act_7989141091456081_emit_7038 = new actions.EmitAction(
      {
        actionArgs: this.act_7989141091456081_emit_7038_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_7989141091456081_close_7037 = new actions.EventHandlerImpl(
      {
        action: act_7989141091456081_emit_7038,
        event: "close",
        when: this.evh_7989141091456081_close_7037_getWhen,
        displayName: "emitReload",
      }
    );
    const act_7989141091456081_showModal_7036 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7989141091456081_showModal_7036_getActionArgs,
        events: [evh_7989141091456081_close_7037],
      }
    );
    const evh_7989141091456081_clickPreventStop_7035 = new actions.EventHandlerImpl(
      {
        action: act_7989141091456081_showModal_7036,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_7989141091456081_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7989141091456081_clickPreventStop_7035],
      }
    );
    return {
      act_3343864469950044_emit_7034,
      evh_3343864469950044_close_7033,
      act_3343864469950044_showModal_7032,
      evh_3343864469950044_clickPreventStop_7031,
      evh_3343864469950044_clickPreventStop,
      act_7989141091456081_emit_7038,
      evh_7989141091456081_close_7037,
      act_7989141091456081_showModal_7036,
      evh_7989141091456081_clickPreventStop_7035,
      evh_7989141091456081_clickPreventStop,
      emitReload: act_7989141091456081_emit_7038,
    }
  }
}

Vue.component("UcMeterRentalServices", UcMeterRentalServices);

</script>