<template>
<ItpBox
  alignItems="center"
>
  <ItpIcon
    :name="$config.invoice.icon"
    spacing="mr-3"
    :title="'Invoice'|pgettext('Invoice')"
    class="mr-2"
    v-if="!noIcon"
  >
  </ItpIcon>
  <ItpText
    strong
    :text="item.invoice_number"
    class="mr-2"
  >
  </ItpText>
  <UcInvoiceTypeBadge
    :value="item.invoice_type"
    :kind="item.invoice_kind"
    abbreviated
    class="mr-2"
  >
  </UcInvoiceTypeBadge>
  <ItpLink
    :icon="$config.externalLinkIcon"
    :to="{
    name: 'app.invoice',
    params: {id: item.id}
  }"
  >
  </ItpLink>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcInvoiceMiniCard", UcInvoiceMiniCard);

</script>