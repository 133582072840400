import AboutModal from './AboutModal.yaml?component';
import BtnDeleteResource from './BtnDeleteResource.yaml?component';
import BtnLoad from './BtnLoad.yaml?component';
import BtnLockResource from './BtnLockResource.yaml?component';
import BtnSyncResource from './BtnSyncResource.yaml?component';
import CardTabs from './CardTabs.yaml?component';
import DataViewerModal from './DataViewerModal.yaml?component';
import DateRangeSelector from './DateRangeSelector.yaml?component';
import EntityEvents from './EntityEvents.yaml?component';
import FileSelectorModal from './FileSelectorModal.yaml?component';
import MiniCard from './MiniCard.yaml?component';
import MaterialTabs from './MaterialTabs.yaml?component';
import PageHelp from './PageHelp.yaml?component';
import PageTabs from './PageTabs.yaml?component';

import UcBody from './UcBody.yaml?component';
import UcFileIcon from './UcFileIcon.yaml?component';
import UcFooter from './UcFooter.yaml?component';
import UcHeader from './UcHeader.yaml?component';
import UcInactive from './UcInactive.yaml?component';
import UcResourceStatusCard from './UcResourceStatusCard.yaml?component';
import UcValidityPeriod from './UcValidityPeriod.yaml?component';

import WAttachmentModal from './WAttachmentModal.yaml?component';
import UcAttachmentsList from './UcAttachmentsList.yaml?component';

import WComment from './WComment.yaml?component';
import UcCommentsList from './UcCommentsList.yaml?component';

import UcStatusBadge from './UcStatusBadge.yaml?component';

export {
  AboutModal,
  BtnDeleteResource,
  BtnLoad,
  BtnLockResource,
  BtnSyncResource,
  CardTabs,
  DataViewerModal,
  DateRangeSelector,
  EntityEvents,
  FileSelectorModal,
  MiniCard,
  MaterialTabs,
  PageHelp,
  PageTabs,
  UcBody,
  UcFileIcon,
  UcFooter,
  UcHeader,
  UcInactive,
  UcResourceStatusCard,
  UcValidityPeriod,
  WAttachmentModal,
  UcAttachmentsList,
  WComment,
  UcCommentsList,
  UcStatusBadge
};
