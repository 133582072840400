var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("address", [
        _vm.item.address
          ? _c("div", [
              _vm._v(
                _vm._s(_vm.item.address.postal_code) +
                  " " +
                  _vm._s(_vm.item.address.city)
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.address
          ? _c("div", [_vm._v(_vm._s(_vm.item.address.address))])
          : _vm._e(),
        _vm._v(
          "\n" +
            _vm._s(_vm._f("gettext")("Tax Number")) +
            ": " +
            _vm._s(_vm.item.tax_number)
        ),
        _c("br"),
        _vm._v(
          "\n" +
            _vm._s(_vm._f("gettext")("Account Number")) +
            ": " +
            _vm._s(_vm.item.account_number) +
            "\n\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }