var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "DIV",
        {
          staticClass: "card h-100 overflow-hidden",
          style: _vm.b_7315092382398562_style
        },
        [
          _c(
            "div",
            { staticClass: "card-header" },
            [
              _c(
                "ItpBox",
                [
                  _c(
                    "ItpBox",
                    [
                      _c("ItpButton", {
                        staticClass: "mr-2",
                        attrs: {
                          icon: "fa-sync-alt",
                          tooltip: _vm._f("gettext")(
                            "Reload data from the server"
                          ),
                          spinning:
                            _vm.actions.reload && _vm.actions.reload.isRunning,
                          variant: "light",
                          text: _vm._f("pgettext")(
                            "Reload data",
                            "InvoiceGenerationControlling"
                          )
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.evh_4615685607825208_clickPreventStop(
                              $event,
                              {}
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "BDropdown",
                        {
                          attrs: {
                            text: _vm._f("pgettext")(
                              "Invoice groups",
                              "InvoiceGenerationControlling"
                            ),
                            variant: "light"
                          }
                        },
                        [
                          _c(
                            "BDropdownForm",
                            _vm._l(_vm.invoiceGroups, function(item) {
                              return _c(
                                "BFormCheckbox",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    name: item.value
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.evh_4592790460410433_change(
                                        $event,
                                        { item: item }
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.selectedInvoiceGroups,
                                    callback: function($$v) {
                                      _vm.selectedInvoiceGroups = $$v
                                    },
                                    expression: "selectedInvoiceGroups"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.text) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center ml-auto text-center"
                    },
                    _vm._l(_vm.values.columns, function(colName) {
                      return _c(
                        "DIV",
                        {
                          key: colName,
                          staticClass: "px-2",
                          style: _vm.b_4440454779651433_style
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(colName) + "\n        "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-group list-group-flush h-100 overflow-auto" },
            _vm._l(_vm.pager.items, function(item, index) {
              return _c(
                "li",
                {
                  key: item.id,
                  class: {
                    "list-group-item": true,
                    "d-flex": true,
                    "flex-row": true,
                    "bg-gray-100": (_vm.pager.startIndex + index + 1) % 2 === 0
                  }
                },
                [
                  _c("ItpText", { staticClass: "pr-2" }, [
                    _vm._v(
                      "\n        #" +
                        _vm._s(_vm.pager.startIndex + index + 1) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ItpBox",
                    {
                      staticClass: "flex-fill",
                      attrs: { direction: "column" }
                    },
                    [
                      _c(
                        "ItpBox",
                        {
                          staticClass: "app-group-header mb-2",
                          attrs: { direction: "row" }
                        },
                        [
                          _c("UcCompanyMiniCard", {
                            staticClass: "mr-3 border rounded px-2",
                            attrs: {
                              "no-icon": "",
                              item: {
                                id: item.partner_id,
                                name: item.partner
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UcRentalMiniCard", {
                            staticClass: "mr-3 border rounded px-2",
                            attrs: {
                              "no-icon": "",
                              item: {
                                id: item.rental_id,
                                name: item.rental
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "border rounded px-2",
                            attrs: {
                              strong: "",
                              uppercase: "",
                              text: item.site
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("table", { staticClass: "app-group-rows table" }, [
                        _c("thead"),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(item.rows, function(row, index) {
                              return _c(
                                "tr",
                                { key: row.id },
                                [
                                  _c(
                                    "td",
                                    {
                                      style: {
                                        width: _vm.rowHeaderColumnWidth,
                                        "border-top-style": "dotted"
                                      }
                                    },
                                    [
                                      _c(
                                        "ItpBox",
                                        {
                                          attrs: {
                                            direction: "column",
                                            alignItems: "end"
                                          }
                                        },
                                        [
                                          _c("DIV", [
                                            _c("strong", [
                                              _vm._v(_vm._s(row.item))
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          row.invoice_group_id
                                            ? _c("DIV", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      row.invoice_group_id
                                                    ) +
                                                    "\n                  "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.meter_id
                                            ? _c("UcMeterMiniCard", {
                                                attrs: {
                                                  item: {
                                                    id: row.meter_id,
                                                    serial_number:
                                                      row.serial_number,
                                                    meter_type: row.meter_type
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.values.columns, function(colName) {
                                    return _c(
                                      "td",
                                      {
                                        key: colName,
                                        staticClass: "px-2",
                                        style: {
                                          width: _vm.dataColumnWidth,
                                          "border-top-style": "dotted"
                                        }
                                      },
                                      [
                                        row[colName] && row[colName].net != null
                                          ? _c(
                                              "DIV",
                                              { staticClass: "text-right" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("number")(
                                                          row[colName].net
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(row.currency) +
                                                      "\n\n                  "
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row[colName] &&
                                        row[colName].quantity != null
                                          ? _c(
                                              "DIV",
                                              { staticClass: "text-right" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("number")(
                                                          row[colName].quantity
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(row.unit) +
                                                      "\n                  "
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        row[colName] &&
                                        row[colName].invoices &&
                                        row[colName].invoices.length
                                          ? _c(
                                              "ItpBox",
                                              {
                                                attrs: {
                                                  direction: "column",
                                                  alignItems: "end"
                                                }
                                              },
                                              _vm._l(
                                                row[colName].invoices,
                                                function(invoice) {
                                                  return _c(
                                                    "ItpBox",
                                                    {
                                                      key: invoice.invoice_id,
                                                      class: {
                                                        "px-2 py-1 border shadow-sm": true,
                                                        "border-light":
                                                          invoice.invoice_status !==
                                                          "draft",
                                                        "bg-yellow-200 border-yellow-600 rounded":
                                                          invoice.invoice_status ===
                                                          "draft"
                                                      },
                                                      attrs: {
                                                        direction: "column",
                                                        alignItems: "end"
                                                      }
                                                    },
                                                    [
                                                      invoice.prior_reading_date !=
                                                      null
                                                        ? _c(
                                                            "DIV",
                                                            {
                                                              staticClass:
                                                                "text-right"
                                                            },
                                                            [
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "date"
                                                                    )(
                                                                      invoice.prior_reading_date
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " • " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "number"
                                                                    )(
                                                                      invoice.prior_reading_amount
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    invoice.prior_reading_unit
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(" "),
                                                              _c("small", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "date"
                                                                    )(
                                                                      invoice.current_reading_date
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " • " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "number"
                                                                    )(
                                                                      invoice.current_reading_amount
                                                                    )
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    invoice.current_reading_unit
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                              _vm._v(
                                                                "\nx" +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "number"
                                                                    )(
                                                                      invoice.billing_multiplier
                                                                    )
                                                                  ) +
                                                                  "\n\n                    "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "ItpBox",
                                                        {
                                                          attrs: {
                                                            alignItems: "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "UcInvoiceTypeBadge",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                value:
                                                                  invoice.invoice_type,
                                                                kind:
                                                                  invoice.invoice_kind,
                                                                abbreviated: ""
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: "#"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "pervent",
                                                                      undefined,
                                                                      $event.key,
                                                                      undefined
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  $event.stopPropagation()
                                                                  return _vm.evh_3723353993403825_clickPerventStop(
                                                                    $event,
                                                                    {
                                                                      item: item,
                                                                      index: index,
                                                                      row: row,
                                                                      colName: colName,
                                                                      invoice: invoice
                                                                    }
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-muted text-nowrap"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        invoice.invoice_number
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("UcContractMiniCard", {
                                                        attrs: {
                                                          "no-icon": "",
                                                          item: {
                                                            id:
                                                              invoice.contract_id,
                                                            contract_number:
                                                              invoice.contract_number
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "tr",
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "text-right",
                                    style: _vm.b_5218831530088670_style
                                  },
                                  [
                                    _vm._v(
                                      "\n                Total:\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.values.columns, function(colName) {
                                  return _c(
                                    "td",
                                    {
                                      key: colName,
                                      staticClass: "px-2",
                                      style: _vm.b_5119818220584553_style,
                                      attrs: { direction: "row" }
                                    },
                                    [
                                      item.totals[colName] != null
                                        ? _c(
                                            "DIV",
                                            { staticClass: "text-right" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("number")(
                                                        item.totals[colName]
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(item.currency) +
                                                    "\n\n                  "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-footer d-flex" },
            [
              _c("b-pagination", {
                staticClass: "m-0 p-0 ml-auto",
                attrs: {
                  totalRows: _vm.pager.totalRows,
                  perPage: _vm.pager.perPage,
                  "last-number": "",
                  "first-number": ""
                },
                on: {
                  change: function($event) {
                    return _vm.evh_1548630417156826_change($event, {})
                  }
                },
                model: {
                  value: _vm.pager.currentPage,
                  callback: function($$v) {
                    _vm.$set(_vm.pager, "currentPage", $$v)
                  },
                  expression: "pager.currentPage"
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }