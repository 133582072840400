<template>
<div>
  <DIV
    class="form-control form-control-readonly"
    v-if="readonly"
  >
    <UcAccountSelectOption
      :payload="value"
      display-owner
    >
    </UcAccountSelectOption>
  </DIV>
  <b-dropdown
    toggle-class="border"
    variant="light"
    class="apply-inline-button"
    v-if="!readonly"
  >
    <template
      slot="button-content"
    >
      <ItpText
        :text="'Select account'|pgettext('AccountSelector')"
        v-if="!selectedAccount"
      >
      </ItpText>
      <UcAccountSelectOption
        :payload="value"
        display-owner
        v-if="selectedAccount"
      >
      </UcAccountSelectOption>
    </template>
    <b-dropdown-form
      v-if="hasInactiveItem"
    >
      <b-form-checkbox
        v-model="states.showInactive"
        @change="evh_1717672417478439_change($event, {})"
      >
        {{ 'Show inactive'|pgettext('AccountSelector') }}
      </b-form-checkbox>
    </b-dropdown-form>
    <b-dropdown-divider
      v-if="hasInactiveItem"
    >
    </b-dropdown-divider>
    <b-dropdown-group
      :id="group.id"
      :key="group.id"
      :header="group.name"
      v-for="group in accountGroups"
    >
      <b-dropdown-item-button
        :key="item.id"
        v-for="item in group.items"
        @click.prevent="evh_8127633238173630_clickPrevent($event, {group, item})"
      >
        <UcAccountSelectOption
          :payload="item"
        >
        </UcAccountSelectOption>
      </b-dropdown-item-button>
    </b-dropdown-group>
  </b-dropdown>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcAccountSelector extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
    this.$$cache_states = new ComponentValueCache(this, "states", () => this.$$load_states_cache_key());
  }

  $$load_states_cache_key() {
    return this.cacheKey ? ('AccountSelector.' + this.cacheKey) : null
  }

  createAccountGrups(...args: any[]) {
    const [accounts] = args;
    const showInactive = this.states.showInactive;
    const accountType = this.accountType;

    return _
      .chain(accounts)
      .filter(p => accountType ? p.account_type === accountType : true)
      .groupBy(p => p.owner_id)
      .map((items, owner_id) => ({
        id: owner_id,
        name: items[0].owner_code + ' - ' + items[0].owner_name,
        items: !showInactive ? _.filter(items, { inactive: false }) : items
      }))
      .filter(p => p.items.length)
      .value()
  }

  itemChanged(...args: any[]) {
    const [item, value] = args;

    if (value == null || item == null) {
      return true;
    }
    return value.id != item.id
  }

  @Prop({
  })
  value!: any;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  @Prop({
    type: Boolean,
  })
  displayOwner!: boolean;

  @Watch('displayOwner')
  onDisplay_owner(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("displayOwner")
  }

  @Prop({
    type: String,
  })
  cacheKey!: string;

  @Watch('cacheKey')
  onCache_key(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("cacheKey")
  }

  @Prop({
    type: String,
  })
  accountType!: string;

  @Watch('accountType')
  onAccount_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("accountType")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  hasInactiveItem!: any;

  async $$load_hasInactiveItem() {
    return _.some(this.$data.accounts, { inactive: true })
  }

  accountGroups!: any;

  async $$load_accountGroups() {
    return this.createAccountGrups(this.$data.accounts)
  }

  states!: any;
  ux!: any;
  dataMembers = ['accounts', 'hasInactiveItem', 'accountGroups', 'states', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        accounts: null,
        hasInactiveItem: null,
        accountGroups: null,
        states: {
          selectedAccountId: null,
          showInactive: false,
        }
        ,
        ux: null,
      },
    }
  }

  get selectedAccountType() {
    if (this.loaded) {

      return this.value && this.value.account_type
    }
    return null;
  }

  get selectedAccount() {
    if (this.loaded) {

      return _.isObject(this.value) ? this.value : null
    }
    return null;
  }

  async act_1717672417478439_setData_4850_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1717672417478439_setData_4850, alias=undefined
    return {
      path: "accountGroups",
      value: this.createAccountGrups(this.accounts),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1717672417478439_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1717672417478439_change.executeFromDOM(this, event, scope);
  }

  async act_8127633238173630_emit_4852_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8127633238173630_emit_4852, alias=undefined
    return {
      event: "input",
      value: $event.scope.item,
    }
  }

  async evh_8127633238173630_clickPrevent_4851_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8127633238173630_clickPrevent_4851, alias=undefined
    return this.itemChanged($event.scope.item, this.value)
  }

  async act_8127633238173630_setData_4854_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8127633238173630_setData_4854, alias=undefined
    return {
      path: "states.selectedAccountId",
      value: $event.scope.item.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8127633238173630_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8127633238173630_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1717672417478439_setData_4850: actions.SetDataAction;
    evh_1717672417478439_change_4849: actions.EventHandlerImpl;
    evh_1717672417478439_change: actions.EventHandlerGroup;
    act_8127633238173630_emit_4852: actions.EmitAction;
    evh_8127633238173630_clickPrevent_4851: actions.EventHandlerImpl;
    act_8127633238173630_setData_4854: actions.SetDataAction;
    evh_8127633238173630_clickPrevent_4853: actions.EventHandlerImpl;
    evh_8127633238173630_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1717672417478439_setData_4850 = new actions.SetDataAction(
      {
        actionArgs: this.act_1717672417478439_setData_4850_getActionArgs,
        events: [],
      }
    );
    const evh_1717672417478439_change_4849 = new actions.EventHandlerImpl(
      {
        action: act_1717672417478439_setData_4850,
        event: "change",
        displayName: "setData",
      }
    );
    const evh_1717672417478439_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_1717672417478439_change_4849],
      }
    );
    const act_8127633238173630_emit_4852 = new actions.EmitAction(
      {
        actionArgs: this.act_8127633238173630_emit_4852_getActionArgs,
        events: [],
      }
    );
    const evh_8127633238173630_clickPrevent_4851 = new actions.EventHandlerImpl(
      {
        action: act_8127633238173630_emit_4852,
        event: "click.prevent",
        when: this.evh_8127633238173630_clickPrevent_4851_getWhen,
        displayName: "emit",
      }
    );
    const act_8127633238173630_setData_4854 = new actions.SetDataAction(
      {
        actionArgs: this.act_8127633238173630_setData_4854_getActionArgs,
        events: [],
      }
    );
    const evh_8127633238173630_clickPrevent_4853 = new actions.EventHandlerImpl(
      {
        action: act_8127633238173630_setData_4854,
        event: "click.prevent",
        displayName: "setData",
      }
    );
    const evh_8127633238173630_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8127633238173630_clickPrevent_4851, evh_8127633238173630_clickPrevent_4853],
      }
    );
    return {
      act_1717672417478439_setData_4850,
      evh_1717672417478439_change_4849,
      evh_1717672417478439_change,
      act_8127633238173630_emit_4852,
      evh_8127633238173630_clickPrevent_4851,
      act_8127633238173630_setData_4854,
      evh_8127633238173630_clickPrevent_4853,
      evh_8127633238173630_clickPrevent,
    }
  }
}

Vue.component("UcAccountSelector", UcAccountSelector);

</script>