<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WasteBinsBrowser"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="wasteBins"
    :headerTitle="'Waste bins'|gettext"
    :items="$fn.slickDataSource('browse_waste_bins')"
    export-to-csv
    :exportToCsvFilename="'waste-bins'|pgettext('ExportFilename')"
    checkbox-row-selection
    footer-totals
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpButton
        :icon="$config.wasteBin.createWasteBinIcon"
        :text="'Create Waste Bin...'|pgettext('WasteBins')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_6466705138206980_click($event, {})"
      >
      </ItpButton>
      <PageHelp
        path="/assets/fixed-assets.html"
      >
      </PageHelp>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "wasteBins",
    path: "/waste-bins",
    component: "WasteBinsBrowser"
  }
)

@Component()
export default class WasteBinsBrowser extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedItems!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;
  dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedItems: [
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: [{
          field: "name",
          name: this.$fn.pgettext("WasteBinsBrowser", "Name"),
          formatter: {
            name: "routeLink",
            to: "fixedAsset",
            params: {
              id: "id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "bin_capacity_liters",
          type: "number",
          name: this.$fn.pgettext("WasteBinsBrowser", "Capacity (liters)"),
          formatter: {
            name: "localestring",
            locale: "hu-HU",
          }
          ,
        }
          ,
        {
          field: "waste_bin_name",
          name: this.$fn.pgettext("WasteBinsBrowser", "Bin Name"),
        }
          ,
        {
          field: "worksheets",
          name: this.$fn.pgettext("WasteBinsBrowser", "Worksheets"),
        }
          ,
        {
          field: "bin_type",
          name: this.$fn.pgettext("WasteBinsBrowser", "Bin Type"),
          formatter: {
            name: "gettext",
            context: "WasteBinTypes",
            conversion: "StartCase",
          }
          ,
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("WasteBinsBrowser", "Rental"),
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "rental_client_name",
          name: this.$fn.pgettext("WasteBinsBrowser", "Tenant"),
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "rental_client_id",
            }
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Waste Bins"),
        icon: this.$config.wasteBin.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1554_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1554, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_1556_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_1556, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_showModal_1558_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6466705138206980_showModal_1558, alias=undefined
    return {
      name: "MCreateFixedAsset",
      props: {
        assetType: "waste_bin",
      }
      ,
    }
  }

  async evh_6466705138206980_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1554: actions.CloseModalAction;
    evh_7315092382398562_close_1553: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_setData_1556: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_1555: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_showModal_1558: actions.ShowModalAction;
    evh_6466705138206980_click_1557: actions.EventHandlerImpl;
    evh_6466705138206980_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1554 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1554_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1553 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1554,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1553],
      }
    );
    const act_2248226175642056_setData_1556 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_1556_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_1555 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_1556,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_1555],
      }
    );
    const act_6466705138206980_showModal_1558 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6466705138206980_showModal_1558_getActionArgs,
        events: [],
      }
    );
    const evh_6466705138206980_click_1557 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_showModal_1558,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_6466705138206980_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_click_1557],
      }
    );
    return {
      act_7315092382398562_closeModal_1554,
      evh_7315092382398562_close_1553,
      evh_7315092382398562_close,
      act_2248226175642056_setData_1556,
      evh_2248226175642056_selectedRowsChanged_1555,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_showModal_1558,
      evh_6466705138206980_click_1557,
      evh_6466705138206980_click,
    }
  }
}

Vue.component("WasteBinsBrowser", WasteBinsBrowser);

</script>