import { render, staticRenderFns } from "./UcContractTerms.yaml?vue&type=template&id=2c84ce1e&component"
import script from "./UcContractTerms.yaml?vue&type=script&lang=ts&component"
export * from "./UcContractTerms.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports