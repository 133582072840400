<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    alignItems="center"
    class="mb-2"
  >
    <strong>
      {{ 'Invoices'|pgettext('Payment') }}
    </strong>
    <ItpButton
      :icon="$config.addIcon"
      :text="'Add invoice...'|pgettext('Payment')"
      size="sm"
      variant="light"
      class="mx-4"
      v-if="!payment.isReadonly"
      @click="evh_3343864469950044_click($event, {})"
    >
    </ItpButton>
    <ItpBox
      wrap-items
      spacing="px-2"
    >
      <DIV
        :title="'Invoice amount mismatch'|pgettext('Payment')"
        class="text-nowrap"
        v-if="invoiceTotalsDiff"
      >
        <Icon
          icon="emojione-v1:warning"
        >
        </Icon>
        <ItpText
          :text="'Mismatch between the amount of payment and invoices.'|pgettext('payment')"
        >
        </ItpText>
        <span>
          {{ invoiceTotalsDiff }} {{ payment.amount }} {{ invoiceTotals.value() }}

        </span>
      </DIV>
      <ItpFormCheckbox
        name="autocalcAmount"
        inline
        v-model="autocalcAmount"
        v-if="!payment.isReadonly"
      >
        <span
          class="text-nowrap"
        >
          {{ 'Autocalc total amount.'|pgettext('PaymentInvoices') }}
        </span>
      </ItpFormCheckbox>
    </ItpBox>
  </ItpBox>
  <ItpDataTable
    rowKeyField="invoice_id"
    tableId="paymentInvoices"
    :paging="b_5962862420439144_paging"
    :editable="!payment.isReadonly"
    :sortable="b_5962862420439144_sortable"
    :globs="messages"
    :value="payment.invoices.data"
    no-create-row
    no-confirm-delete-row
    footer-totals
    :columns="b_5962862420439144_columns"
    v-if="payment.invoices.data"
    @save-completed="evh_5962862420439144_saveCompleted($event, {})"
    @delete="evh_5962862420439144_delete($event, {})"
    @delete-completed="evh_5962862420439144_deleteCompleted($event, {})"
  >
  </ItpDataTable>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPaymentInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  createInvoicePaymentData(...args: any[]) {
    const [data] = args;
    return {
      invoice_id: data.id,
      invoice_number: data.invoice_number,
      currency: data.currency,
      amount: data.unpaid,
      exchange_rate: 1,
      client: data.client,
      invoice: data
    };
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  messages!: any;
  autocalcAmount!: any;
  ux!: any;
  b_5962862420439144_paging!: any;
  b_5962862420439144_sortable!: any;
  b_5962862420439144_columns!: any;
  dataMembers = ['messages', 'autocalcAmount', 'ux', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          grossAmount: this.$fn.pgettext("Invoice", "Gross amount"),
          paidAmount: this.$fn.pgettext("Invoice", "Payments"),
        }
        ,
        autocalcAmount: true,
        ux: null,
        b_5962862420439144_paging: false,
        b_5962862420439144_sortable: false,
        b_5962862420439144_columns: [{
          name: "invoice_number",
          width: "210px",
          header: this.$fn.pgettext("InvoicePayment", "Invoice"),
          readonly: true,
          textAlign: "left",
          type: "template",
          value: "<DIV>\n\
  <strong>{{ row.invoice.invoice_number }}</strong> <UcInvoiceTypeBadge :value=\"row.invoice.invoice_type\" :kind=\"row.invoice.invoice_kind\"></UcInvoiceTypeBadge><br/>\n\
  {{ row.invoice.invoice_date || date }}<br/>\n\
  <div>\n\
    <ItpText>{{ globs.grossAmount }}:</ItpText>\n\
    <strong>{{ row.invoice.amount || row.invoice.total | number }} {{ row.invoice.currency }}</strong><br/>\n\
  </div>\n\
\n\
  <div v-if=\"row.invoice.invoice_payment\">\n\
    <ItpText>{{ globs.paidAmount }}:</ItpText>\n\
    <strong>{{ row.invoice.invoice_payment | number }} {{ row.invoice.currency }}</strong><br/>\n\
  </div>\n\
  <UcCompanyMiniCard :item=\"row.invoice.client\" no-link></UcCompanyMiniCard>\n\
</DIV>\n\
",
        }
          ,
        {
          name: "amount",
          header: this.$fn.pgettext("InvoicePayment", "Amount"),
          type: "number",
          width: "110px",
          textAlign: "right",
          required: true,
        }
          ,
        {
          name: "exchange_rate",
          width: "70px",
          header: this.$fn.pgettext("InvoicePayment", "Exch. Rate"),
          type: "number",
          textAlign: "right",
          required: true,
          noFooterTotals: true,
        }
          ,
        {
          name: "notes",
          width: "210px",
          header: this.$fn.pgettext("InvoicePayment", "Notes"),
        }
          ,
        ]
        ,
      },
    }
  }

  get invoiceTotals() {
    if (this.loaded) {

      return _.isEmpty(this.payment.invoices && this.payment.invoices.data) ? null : _.chain(this.payment.invoices.data)
        .reduce(
          (sum, v, k) => (sum.add(v.amount), sum),
          this.$fn.numeral(0)
        )
        .value()
    }
    return null;
  }

  get invoiceTotalsDiff() {
    if (this.loaded) {

      return this.invoiceTotals != null
        ? this.$fn.numeral(this.payment.amount).difference(this.invoiceTotals.value())
        : null
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_3343864469950044_script_5738_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3343864469950044_script_5738, alias=undefined
    _.chain($event.data.modalResult.value)
      .map(this.createInvoicePaymentData)
      .filter(p => !_.some(this.payment.invoices.data, { invoice_id: p.invoice_id }))
      .each(p => this.payment.invoices.data.push(p))
      .value()
  }

  async act_3343864469950044_script_5738_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3343864469950044_script_5738, alias=undefined
    return {
      value: () => this.act_3343864469950044_script_5738_getActionArgs_value($event),
    }
  }

  async evh_3343864469950044_close_5737_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_5737, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_3343864469950044_emit_5740_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3343864469950044_emit_5740, alias=undefined
    return {
      event: "recalculate",
    }
  }

  async evh_3343864469950044_close_5739_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3343864469950044_close_5739, alias=undefined
    return $event.data.modalResult.ok && this.autocalcAmount
  }

  async act_3343864469950044_showModal_5736_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3343864469950044_showModal_5736, alias=addInvoice
    return {
      name: "InvoiceBrowser",
      props: {
        size: "full-screen",
        company_id: this.payment.account && this.payment.account.owner_id,
        client_id: this.payment.partner && this.payment.partner.id,
        multiselect: true,
      }
      ,
    }
  }

  async evh_3343864469950044_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_emit_5742_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_5742, alias=undefined
    return {
      event: "recalculate",
      value: $event.data.result,
    }
  }

  async evh_5962862420439144_saveCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_script_5744_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5962862420439144_script_5744, alias=undefined
    _.remove(this.payment.invoices.data, { invoice_id: $event.data.row.invoice_id })
  }

  async act_5962862420439144_script_5744_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5962862420439144_script_5744, alias=undefined
    return {
      value: () => this.act_5962862420439144_script_5744_getActionArgs_value($event),
    }
  }

  async evh_5962862420439144_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_emit_5746_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5962862420439144_emit_5746, alias=undefined
    return {
      event: "recalculate",
    }
  }

  async evh_5962862420439144_deleteCompleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_deleteCompleted.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5734: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5733: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_3343864469950044_script_5738: actions.ScriptAction;
    evh_3343864469950044_close_5737: actions.EventHandlerImpl;
    act_3343864469950044_emit_5740: actions.EmitAction;
    evh_3343864469950044_close_5739: actions.EventHandlerImpl;
    act_3343864469950044_showModal_5736: actions.ShowModalAction;
    evh_3343864469950044_click_5735: actions.EventHandlerImpl;
    evh_3343864469950044_click: actions.EventHandlerGroup;
    act_5962862420439144_emit_5742: actions.EmitAction;
    evh_5962862420439144_saveCompleted_5741: actions.EventHandlerImpl;
    evh_5962862420439144_saveCompleted: actions.EventHandlerGroup;
    act_5962862420439144_script_5744: actions.ScriptAction;
    evh_5962862420439144_delete_5743: actions.EventHandlerImpl;
    evh_5962862420439144_delete: actions.EventHandlerGroup;
    act_5962862420439144_emit_5746: actions.EmitAction;
    evh_5962862420439144_deleteCompleted_5745: actions.EventHandlerImpl;
    evh_5962862420439144_deleteCompleted: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5734 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5733 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5734,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5733],
      }
    );
    const act_3343864469950044_script_5738 = new actions.ScriptAction(
      {
        actionArgs: this.act_3343864469950044_script_5738_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_5737 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_script_5738,
        event: "close",
        when: this.evh_3343864469950044_close_5737_getWhen,
        displayName: "script",
      }
    );
    const act_3343864469950044_emit_5740 = new actions.EmitAction(
      {
        actionArgs: this.act_3343864469950044_emit_5740_getActionArgs,
        events: [],
      }
    );
    const evh_3343864469950044_close_5739 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_emit_5740,
        event: "close",
        when: this.evh_3343864469950044_close_5739_getWhen,
        displayName: "emit",
      }
    );
    const act_3343864469950044_showModal_5736 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3343864469950044_showModal_5736_getActionArgs,
        displayName: "addInvoice",
        events: [evh_3343864469950044_close_5737, evh_3343864469950044_close_5739],
      }
    );
    const evh_3343864469950044_click_5735 = new actions.EventHandlerImpl(
      {
        action: act_3343864469950044_showModal_5736,
        event: "click",
        displayName: "addInvoice",
      }
    );
    const evh_3343864469950044_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3343864469950044_click_5735],
      }
    );
    const act_5962862420439144_emit_5742 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_5742_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_saveCompleted_5741 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_5742,
        event: "save-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_saveCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_saveCompleted_5741],
      }
    );
    const act_5962862420439144_script_5744 = new actions.ScriptAction(
      {
        actionArgs: this.act_5962862420439144_script_5744_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_delete_5743 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_script_5744,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_5962862420439144_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_delete_5743],
      }
    );
    const act_5962862420439144_emit_5746 = new actions.EmitAction(
      {
        actionArgs: this.act_5962862420439144_emit_5746_getActionArgs,
        events: [],
      }
    );
    const evh_5962862420439144_deleteCompleted_5745 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_emit_5746,
        event: "delete-completed",
        displayName: "emit",
      }
    );
    const evh_5962862420439144_deleteCompleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_deleteCompleted_5745],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5734,
      evh_7315092382398562_reload_5733,
      evh_7315092382398562_reload,
      act_3343864469950044_script_5738,
      evh_3343864469950044_close_5737,
      act_3343864469950044_emit_5740,
      evh_3343864469950044_close_5739,
      act_3343864469950044_showModal_5736,
      evh_3343864469950044_click_5735,
      evh_3343864469950044_click,
      act_5962862420439144_emit_5742,
      evh_5962862420439144_saveCompleted_5741,
      evh_5962862420439144_saveCompleted,
      act_5962862420439144_script_5744,
      evh_5962862420439144_delete_5743,
      evh_5962862420439144_delete,
      act_5962862420439144_emit_5746,
      evh_5962862420439144_deleteCompleted_5745,
      evh_5962862420439144_deleteCompleted,
      addInvoice: act_3343864469950044_showModal_5736,
    }
  }
}

Vue.component("UcPaymentInvoices", UcPaymentInvoices);

</script>