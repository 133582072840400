"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcWorksheetInvoiceLineAccountings = class UcWorksheetInvoiceLineAccountings extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux', 'b_5962862420439144_rowHeader', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_editable', 'b_5962862420439144_columns'];
    }
    beforeCreate() {
    }
    onWorksheet(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("worksheet");
    }
    onIs_readonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("isReadonly");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('query_invoice_line_accounting_for_worksheet', { parameters: { id: this.worksheet.id } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: {
                errorHandling: "local",
                initialLoadSpinner: true,
            },
            b_5962862420439144_rowHeader: false,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_editable: false,
            b_5962862420439144_columns: [{
                    name: "invoice_number",
                    header: this.$fn.gettext("Invoice Number"),
                    formatter: {
                        name: "template",
                        value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.invoice', params: {id: row.invoice_id}}\">\n\
    {{ value }}\n\
  </ItpLink>\n\
</div>\n\
",
                    },
                },
                {
                    name: "product_description",
                    header: this.$fn.gettext("Description"),
                    formatter: {
                        name: "template",
                        value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.product', params: {id: row.product_id}}\">\n\
    {{ value }}\n\
  </ItpLink>\n\
</div>\n\
",
                    },
                },
                {
                    name: "quantity",
                    type: "number",
                    header: this.$fn.gettext("Quantity"),
                    width: "150px",
                },
                {
                    name: "unit",
                    header: this.$fn.pgettext("abbreviated", "Unit"),
                },
                {
                    name: "unit_price",
                    header: this.$fn.gettext("Unit Price"),
                    type: "number",
                },
                {
                    name: "amount",
                    header: this.$fn.gettext("Amount"),
                    type: "number",
                },
                {
                    name: "expense_type",
                    header: this.$fn.gettext("Expense Type"),
                    formatter: {
                        name: "template",
                        value: "<div v-if=\"value\">\n\
  {{ value|pgettext('ExpenseType', 'Sentence') }}\n\
</div>\n\
",
                    },
                },
                {
                    name: "cost_center",
                    header: this.$fn.gettext("Cost Center"),
                },
                {
                    name: "handover_date",
                    header: this.$fn.gettext("Handover Date"),
                    type: "date",
                },
                {
                    name: "notes",
                    header: this.$fn.gettext("Notes"),
                },
            ],
        });
    }
    act_5962862420439144_script_5980_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.worksheet.id
                ? $event.data.waitFor(this.$fn.crud('work_item').upsert($event.data.row))
                : this.worksheet.work_items.push($event.data.row);
        });
    }
    act_5962862420439144_script_5980_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5980_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_save(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_save.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5982_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.worksheet.id
                ? $event.data.waitFor(this.$fn.crud('work_item').delete($event.data.row))
                : _.remove(this.worksheet.work_items, $event.data.row);
        });
    }
    act_5962862420439144_script_5982_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5982_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_5962862420439144_script_5980 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5980_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_save_5979 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5980,
            event: "save",
            displayName: "script",
        });
        const evh_5962862420439144_save = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_save_5979],
        });
        const act_5962862420439144_script_5982 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5982_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_delete_5981 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5982,
            event: "delete",
            displayName: "script",
        });
        const evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_5981],
        });
        return {
            act_5962862420439144_script_5980,
            evh_5962862420439144_save_5979,
            evh_5962862420439144_save,
            act_5962862420439144_script_5982,
            evh_5962862420439144_delete_5981,
            evh_5962862420439144_delete,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcWorksheetInvoiceLineAccountings.prototype, "worksheet", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('worksheet'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcWorksheetInvoiceLineAccountings.prototype, "onWorksheet", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcWorksheetInvoiceLineAccountings.prototype, "isReadonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('isReadonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcWorksheetInvoiceLineAccountings.prototype, "onIs_readonly", null);
UcWorksheetInvoiceLineAccountings = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcWorksheetInvoiceLineAccountings);
exports.default = UcWorksheetInvoiceLineAccountings;
vue_property_decorator_1.Vue.component("UcWorksheetInvoiceLineAccountings", UcWorksheetInvoiceLineAccountings);
