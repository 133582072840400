<template>
<ItpPage
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ContractTermEditor"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="contract"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <ItpFormGroup
          name="service"
          :label="'Service'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          v-if="!isTermReadonly"
        >
          <ItpFormSelect2
            name="client"
            v-model="item.service"
            bind-object
            :dataSource="b_5731695935601903_dataSource"
            textField="name"
            @optionSelected="evh_5731695935601903_optionSelected($event, {})"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="name"
          :label="'Name'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormInput
            name="name"
            v-model="item.name"
            required
            :readonly="isTermReadonly"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="valid_from"
          :label="'Valid from'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
        >
          <ItpFormDatetime
            name="valid_from"
            v-model="item.valid_from"
            :readonly="isTermReadonly"
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="valid_to"
          :label="'Valid to'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
        >
          <ItpFormDatetime
            name="valid_to"
            v-model="item.valid_to"
            :readonly="isTermReadonly"
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <hr>
        </hr>
        <ItpFormGroup
          :label="'Amount'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          contentClass="input-group"
        >
          <ItpFormInput
            type="number"
            name="amount"
            v-model="item.amount"
            class="col-8"
          >
          </ItpFormInput>
          <ItpFormSelect
            name="currency"
            v-model="item.currency"
            :options="currencies"
            textField="code"
            valueField="code"
            class="col-4"
          >
          </ItpFormSelect>
        </ItpFormGroup>
        <ItpFormGroup
          name="quantity"
          :label="'Quantity'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
        >
          <ItpFormInput
            type="number"
            name="quantity"
            v-model="item.quantity"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="unit"
          :label="'Unit'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
        >
          <ItpFormInput
            name="unit"
            v-model="item.unit"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="notes"
          :label="'Notes'|pgettext('ContractTerm')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormTextarea
            name="notes"
            v-model="item.notes"
          >
          </ItpFormTextarea>
        </ItpFormGroup>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Delete Term'|pgettext('Button')"
      variant="danger"
      icon="fa-times"
      :spinning="actions.delete && actions.delete.isRunning"
      class="mr-auto"
      v-if="mode === 'edit' && isDeletable"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.contract || !forms.contract.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save && actions.save.isRunning"
      v-if="isEditable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ContractTermEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getConfiguration(...args: any[]) {
    switch (this.mode) {

      case 'add':
        return {
          isEditable: true,
          isDeletable: false,
          isTermReadonly: false,
        };

      case 'edit':
        return {
          isEditable: !this.term.locked,
          isDeletable: !this.term.locked,
          isTermReadonly: this.term.locked,
        };
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Object,
  })
  term!: object;

  @Watch('term')
  onTerm(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("term")
  }

  @Prop({
    required: true,
    type: String,
  })
  mode!: string;

  @Watch('mode')
  onMode(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("mode")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  isEditable!: any;

  async $$load_isEditable() {
    return this.getConfiguration().isEditable
  }

  isDeletable!: any;

  async $$load_isDeletable() {
    return this.getConfiguration().isDeletable
  }

  isTermReadonly!: any;

  async $$load_isTermReadonly() {
    return this.getConfiguration().isTermReadonly
  }

  messages!: any;
  item!: any;

  async $$load_item() {
    return this.term || this.$fn.schemaDefaults('CreateContractTerm', {
      contract_id: this.contract.id
    })
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  services!: any;

  async $$load_services() {
    return this.$fn.fetch('suggest_product', {
      parameters: { query: '*', top: 50, skip: 0 }
    }).then(data => _.orderBy(data, [p => p.name.toLowerCase()]))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5731695935601903_dataSource!: any;
  dataMembers = ['formId', 'labelAlign', 'isEditable', 'isDeletable', 'isTermReadonly', 'messages', 'item', 'currencies', 'services', 'ux', 'b_7315092382398562_modalBindings', 'b_5731695935601903_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        labelAlign: "right",
        isEditable: null,
        isDeletable: null,
        isTermReadonly: null,
        messages: {
          add: {
            title: this.$fn.pgettext("Contract|ModalHeader", "Add term"),
          }
          ,
          edit: {
            title: this.$fn.pgettext("Contract|ModalHeader", "Edit term"),
          }
          ,
        }
        ,
        item: null,
        currencies: null,
        services: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          noPrimaryButton: true,
          closeButtonText: this.$fn.gettext("Close"),
          size: "md",
        }
        ,
        b_5731695935601903_dataSource: {
          name: "suggest_product",
        }
        ,
      },
    }
  }

  get currentObjectType() {
    if (this.loaded) {

      return "contract_term"
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.messages[this.mode].title,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6830_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6830, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_6836_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_6836, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_6834_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_6834, alias=save
    return {
      objectType: this.currentObjectType,
      op: "upsert",
      data: this.item,
      options: {
        parameters: {
          rev: this.contract.revision_number,
        }
        ,
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_5731695935601903_setData_6838_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5731695935601903_setData_6838, alias=undefined
    return {
      path: "item.name",
      value: $event.data.option.name,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5731695935601903_setData_6840_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5731695935601903_setData_6840, alias=undefined
    return {
      path: "item.service_id",
      value: $event.data.option.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5731695935601903_setData_6842_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5731695935601903_setData_6842, alias=undefined
    return {
      path: "item.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5731695935601903_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5731695935601903_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeModal_6846_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1548630417156826_closeModal_6846, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_request_6844_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1548630417156826_request_6844, alias=deleteTerm
    return {
      operation: "delete_contract_term",
      data: this.item,
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6830: actions.CloseModalAction;
    evh_7315092382398562_close_6829: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6832: actions.CloseComponentAction;
    evh_2248226175642056_close_6831: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_6836: actions.CloseModalAction;
    evh_1040167445267876_success_6835: actions.EventHandlerImpl;
    act_1040167445267876_crud_6834: actions.CRUDAction;
    evh_1040167445267876_submit_6833: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5731695935601903_setData_6838: actions.SetDataAction;
    evh_5731695935601903_optionSelected_6837: actions.EventHandlerImpl;
    act_5731695935601903_setData_6840: actions.SetDataAction;
    evh_5731695935601903_optionSelected_6839: actions.EventHandlerImpl;
    act_5731695935601903_setData_6842: actions.SetDataAction;
    evh_5731695935601903_optionSelected_6841: actions.EventHandlerImpl;
    evh_5731695935601903_optionSelected: actions.EventHandlerGroup;
    act_1548630417156826_closeModal_6846: actions.CloseModalAction;
    evh_1548630417156826_success_6845: actions.EventHandlerImpl;
    act_1548630417156826_request_6844: actions.RequestAction;
    evh_1548630417156826_clickPreventStop_6843: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_2186679009640457_closeComponent_6848: actions.CloseComponentAction;
    evh_2186679009640457_clickPreventStop_6847: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6830 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6830_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6829 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6830,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6829],
      }
    );
    const act_2248226175642056_closeComponent_6832 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6831 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6832,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6831],
      }
    );
    const act_1040167445267876_closeModal_6836 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_6836_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6835 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_6836,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_6834 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_6834_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_6835],
      }
    );
    const evh_1040167445267876_submit_6833 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_6834,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6833],
      }
    );
    const act_5731695935601903_setData_6838 = new actions.SetDataAction(
      {
        actionArgs: this.act_5731695935601903_setData_6838_getActionArgs,
        events: [],
      }
    );
    const evh_5731695935601903_optionSelected_6837 = new actions.EventHandlerImpl(
      {
        action: act_5731695935601903_setData_6838,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_5731695935601903_setData_6840 = new actions.SetDataAction(
      {
        actionArgs: this.act_5731695935601903_setData_6840_getActionArgs,
        events: [],
      }
    );
    const evh_5731695935601903_optionSelected_6839 = new actions.EventHandlerImpl(
      {
        action: act_5731695935601903_setData_6840,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_5731695935601903_setData_6842 = new actions.SetDataAction(
      {
        actionArgs: this.act_5731695935601903_setData_6842_getActionArgs,
        events: [],
      }
    );
    const evh_5731695935601903_optionSelected_6841 = new actions.EventHandlerImpl(
      {
        action: act_5731695935601903_setData_6842,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_5731695935601903_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_5731695935601903_optionSelected_6837, evh_5731695935601903_optionSelected_6839, evh_5731695935601903_optionSelected_6841],
      }
    );
    const act_1548630417156826_closeModal_6846 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1548630417156826_closeModal_6846_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6845 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeModal_6846,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1548630417156826_request_6844 = new actions.RequestAction(
      {
        actionArgs: this.act_1548630417156826_request_6844_getActionArgs,
        displayName: "deleteTerm",
        events: [evh_1548630417156826_success_6845],
      }
    );
    const evh_1548630417156826_clickPreventStop_6843 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_request_6844,
        event: "click.prevent.stop",
        displayName: "deleteTerm",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6843],
      }
    );
    const act_2186679009640457_closeComponent_6848 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_6847 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeComponent_6848,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_6847],
      }
    );
    return {
      act_7315092382398562_closeModal_6830,
      evh_7315092382398562_close_6829,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6832,
      evh_2248226175642056_close_6831,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_6836,
      evh_1040167445267876_success_6835,
      act_1040167445267876_crud_6834,
      evh_1040167445267876_submit_6833,
      evh_1040167445267876_submit,
      act_5731695935601903_setData_6838,
      evh_5731695935601903_optionSelected_6837,
      act_5731695935601903_setData_6840,
      evh_5731695935601903_optionSelected_6839,
      act_5731695935601903_setData_6842,
      evh_5731695935601903_optionSelected_6841,
      evh_5731695935601903_optionSelected,
      act_1548630417156826_closeModal_6846,
      evh_1548630417156826_success_6845,
      act_1548630417156826_request_6844,
      evh_1548630417156826_clickPreventStop_6843,
      evh_1548630417156826_clickPreventStop,
      act_2186679009640457_closeComponent_6848,
      evh_2186679009640457_clickPreventStop_6847,
      evh_2186679009640457_clickPreventStop,
      save: act_1040167445267876_crud_6834,
      deleteTerm: act_1548630417156826_request_6844,
    }
  }
}

Vue.component("ContractTermEditor", ContractTermEditor);

</script>