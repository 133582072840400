"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let DateRangeSelector = class DateRangeSelector extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'mode', 'options', 'ux'];
    }
    beforeCreate() {
        this.$$cache_mode = new core_1.ComponentValueCache(this, "mode", () => this.$$load_mode_cache_key());
    }
    $$load_mode_cache_key() {
        return this.name + '.mode';
    }
    getCurrentWeek(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.startOfWeek(t0, { weekStartsOn: 1 }),
            null
        ];
    }
    getCurrentMonth(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.startOfMonth(t0),
            null
        ];
    }
    getCurrentYear(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.startOfYear(t0),
            null
        ];
    }
    getPreviousWeek(...args) {
        const prev_monday = this.$fn.dateFns.previousMonday(this.$fn.dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }));
        return [
            prev_monday,
            this.$fn.dateFns.endOfWeek(prev_monday)
        ];
    }
    getPreviousMonth(...args) {
        const start = this.$fn.dateFns.sub(this.getCurrentMonth()[0], { months: 1 });
        return [
            start,
            this.$fn.dateFns.endOfMonth(start),
        ];
    }
    getPreviousYear(...args) {
        const start = this.$fn.dateFns.sub(this.getCurrentYear()[0], { years: 1 });
        return [
            start,
            this.$fn.dateFns.endOfYear(start),
        ];
    }
    getPreviousSevenDays(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 7 }),
            null,
        ];
    }
    getPreviousThirtyDays(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 30 }),
            null,
        ];
    }
    getPreviousSixtyDays(...args) {
        const t0 = new Date();
        return [
            this.$fn.dateFns.sub(t0, { days: 60 }),
            null,
        ];
    }
    onName(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("name");
    }
    onFrom(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("from");
    }
    onTo(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("to");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return { start: this.from, end: this.to };
        });
    }
    $$load_options() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    key: "custom",
                    name: this.$fn.pgettext("DateRangeSelector", "Custom"),
                },
                {
                    key: "current_week",
                    name: this.$fn.pgettext("DateRangeSelector", "Current week"),
                    value: this.getCurrentWeek(),
                },
                {
                    key: "current_month",
                    name: this.$fn.pgettext("DateRangeSelector", "Current month"),
                    value: this.getCurrentMonth(),
                },
                {
                    key: "current_year",
                    name: this.$fn.pgettext("DateRangeSelector", "Current year"),
                    value: this.getCurrentYear(),
                },
                {
                    key: "previous_week",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous week"),
                    value: this.getPreviousWeek(),
                },
                {
                    key: "previous_month",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous month"),
                    value: this.getPreviousMonth(),
                },
                {
                    key: "previous_year",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous year"),
                    value: this.getPreviousYear(),
                },
                {
                    key: "previous_seven_days",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous seven days"),
                    value: this.getPreviousSevenDays(),
                },
                {
                    key: "previous_thirty_days",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous thirty days"),
                    value: this.getPreviousThirtyDays(),
                },
                {
                    key: "previous_60_days",
                    name: this.$fn.pgettext("DateRangeSelector", "Previous sixty days"),
                    value: this.getPreviousSixtyDays(),
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            mode: "custom",
            options: null,
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_setData_1616_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.start",
                value: $event.data.option.value
                    ? ($event.data.option.value[0] ? this.$fn.formatDate($event.data.option.value[0], 'yyyy-MM-dd') : null)
                    : this.item.start,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_6466705138206980_setData_1618_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.end",
                value: $event.data.option.value
                    ? ($event.data.option.value[1] ? this.$fn.formatDate($event.data.option.value[1], 'yyyy-MM-dd') : null)
                    : this.item.end,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_6466705138206980_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_3943151158818434_emit_1620_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "changed",
                value: [this.item.start, this.item.end],
            };
        });
    }
    evh_3943151158818434_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3943151158818434_input.executeFromDOM(this, event, scope);
        });
    }
    act_4207795475682514_emit_1622_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "changed",
                value: [this.item.start, this.item.end],
            };
        });
    }
    evh_4207795475682514_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4207795475682514_input.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1614 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1613 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1614,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1613],
        });
        const act_6466705138206980_setData_1616 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_1616_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_optionSelected_1615 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_1616,
            event: "optionSelected",
            displayName: "setData",
        });
        const act_6466705138206980_setData_1618 = new core_1.actions.SetDataAction({
            actionArgs: this.act_6466705138206980_setData_1618_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_optionSelected_1617 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_setData_1618,
            event: "optionSelected",
            displayName: "setData",
        });
        const evh_6466705138206980_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_optionSelected_1615, evh_6466705138206980_optionSelected_1617],
        });
        const act_3943151158818434_emit_1620 = new core_1.actions.EmitAction({
            actionArgs: this.act_3943151158818434_emit_1620_getActionArgs,
            events: [],
        });
        const evh_3943151158818434_input_1619 = new core_1.actions.EventHandlerImpl({
            action: act_3943151158818434_emit_1620,
            event: "input",
            displayName: "emit",
        });
        const evh_3943151158818434_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3943151158818434_input_1619],
        });
        const act_4207795475682514_emit_1622 = new core_1.actions.EmitAction({
            actionArgs: this.act_4207795475682514_emit_1622_getActionArgs,
            events: [],
        });
        const evh_4207795475682514_input_1621 = new core_1.actions.EventHandlerImpl({
            action: act_4207795475682514_emit_1622,
            event: "input",
            displayName: "emit",
        });
        const evh_4207795475682514_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4207795475682514_input_1621],
        });
        return {
            act_7315092382398562_reloadComponentData_1614,
            evh_7315092382398562_reload_1613,
            evh_7315092382398562_reload,
            act_6466705138206980_setData_1616,
            evh_6466705138206980_optionSelected_1615,
            act_6466705138206980_setData_1618,
            evh_6466705138206980_optionSelected_1617,
            evh_6466705138206980_optionSelected,
            act_3943151158818434_emit_1620,
            evh_3943151158818434_input_1619,
            evh_3943151158818434_input,
            act_4207795475682514_emit_1622,
            evh_4207795475682514_input_1621,
            evh_4207795475682514_input,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], DateRangeSelector.prototype, "name", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('name'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DateRangeSelector.prototype, "onName", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], DateRangeSelector.prototype, "from", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('from'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DateRangeSelector.prototype, "onFrom", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], DateRangeSelector.prototype, "to", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('to'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DateRangeSelector.prototype, "onTo", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], DateRangeSelector.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DateRangeSelector.prototype, "onSize", null);
DateRangeSelector = __decorate([
    (0, vue_property_decorator_1.Component)()
], DateRangeSelector);
exports.default = DateRangeSelector;
vue_property_decorator_1.Vue.component("DateRangeSelector", DateRangeSelector);
