<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="3"
    name="protocol_status"
    :label="'Status'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
  >
    <UcStatusBadge
      :status="item.protocol_status"
    >
    </UcStatusBadge>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="3"
    name="handover_date"
    :label="'Handover date'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormDatetime
      name="handover_date"
      v-model="item.handover_date"
      required
      :readonly="item.isReadonly"
    >
    </ItpFormDatetime>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="10"
    name="meter_readings"
    :label="'Paragraphs'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    v-if="loaded && item.paragraphs"
  >
    <div
      :key="index"
      v-for="(paragraph, index) in item.paragraphs"
    >
      <ItpFormTextarea
        type="number"
        name="paragraph_text"
        v-model="paragraph.text"
        required
        :readonly="item.isReadonly"
        class="w-100"
      >
      </ItpFormTextarea>
    </div>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="2"
    contentColsMd="10"
    name="meter_readings"
    :label="'Meter reading'|pgettext('RentalHandover')"
    :labelAlign="labelAlign"
    v-if="loaded && item.meter_readings && item.meter_readings.length"
  >
    <table
      class="table table-condensed"
    >
      <tbody>
        <tr
          :key="reading.serial_number"
          v-for="reading in item.meter_readings"
        >
          <td
            class="col-sm-7"
            style="word-break:break-all;"
          >
            <ItpBox>
              <UcMeterTypeIcon
                :type="reading.meter_type"
                class="mr-2"
              >
              </UcMeterTypeIcon>
              <ItpText
                :text="reading.serial_number"
                wrap
              >
              </ItpText>
            </ItpBox>
            <ItpText
              :text="reading.description"
              wrap
            >
            </ItpText>
            <ItpLink
              :text="'Remove'|gettext"
              @click.prevent.stop="evh_1759621513499166_clickPreventStop($event, {reading})"
            >
            </ItpLink>
          </td>
          <td
            class="col-sm-5"
          >
            <div
              class="input-group"
            >
              <ItpFormInput
                mask="number"
                unmask="typed"
                min="0"
                name="value"
                v-model="reading.value"
                :readonly="item.isReadonly"
              >
              </ItpFormInput>
              <DIV
                class="input-group-append"
              >
                <ItpFormSelect
                  name="unit"
                  v-model="reading.unit"
                  :options="units[reading.meter_type]"
                  :readonly="item.isReadonly"
                >
                </ItpFormSelect>
              </DIV>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ItpFormGroup>
  <UcAttachmentsList
    :parent="item"
    :readonly="item.isReadonly"
    v-if="!item.isNew"
  >
  </UcAttachmentsList>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetHandoverProtocol extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    type: Object,
  })
  form!: object;

  @Watch('form')
  onForm(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("form")
  }

  units!: any;

  async $$load_units() {
    return this.$fn.fetch('get_meter_type_units')
  }

  ux!: any;
  dataMembers = ['units', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        units: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_1759621513499166_script_6604_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1759621513499166_script_6604, alias=undefined
    this.item.meter_readings = _.pull(this.item.meter_readings, $event.scope.reading).slice()
  }

  async act_1759621513499166_script_6604_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1759621513499166_script_6604, alias=undefined
    return {
      value: () => this.act_1759621513499166_script_6604_getActionArgs_value($event),
    }
  }

  async evh_1759621513499166_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1759621513499166_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6602: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6601: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_1759621513499166_script_6604: actions.ScriptAction;
    evh_1759621513499166_clickPreventStop_6603: actions.EventHandlerImpl;
    evh_1759621513499166_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6602 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6601 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6602,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6601],
      }
    );
    const act_1759621513499166_script_6604 = new actions.ScriptAction(
      {
        actionArgs: this.act_1759621513499166_script_6604_getActionArgs,
        events: [],
      }
    );
    const evh_1759621513499166_clickPreventStop_6603 = new actions.EventHandlerImpl(
      {
        action: act_1759621513499166_script_6604,
        event: "click.prevent.stop",
        displayName: "script",
      }
    );
    const evh_1759621513499166_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1759621513499166_clickPreventStop_6603],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6602,
      evh_7315092382398562_reload_6601,
      evh_7315092382398562_reload,
      act_1759621513499166_script_6604,
      evh_1759621513499166_clickPreventStop_6603,
      evh_1759621513499166_clickPreventStop,
    }
  }
}

Vue.component("FieldsetHandoverProtocol", FieldsetHandoverProtocol);

</script>