"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MainMenu = class MainMenu extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get displayTestBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'test');
        }
        return null;
    }
    get displayDevBadge() {
        if (this.loaded) {
            return _.includes(this.$app.document.location.hostname, 'local');
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8512678877908410_showModal_1254_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "outgoing",
                },
            };
        });
    }
    evh_8512678877908410_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8512678877908410_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5794187517758435_showModal_1256_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateInvoice",
                props: {
                    invoiceKind: "incoming",
                },
            };
        });
    }
    evh_5794187517758435_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5794187517758435_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8440793417118356_showModal_1258_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionKind: "cash_book",
                    transactionType: "deposit",
                },
            };
        });
    }
    evh_8440793417118356_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8440793417118356_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6201675214105229_showModal_1260_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionKind: "cash_book",
                    transactionType: "payment",
                },
            };
        });
    }
    evh_6201675214105229_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6201675214105229_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6373990564425123_showModal_1262_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Payment",
                props: {
                    transactionKind: "bank",
                },
            };
        });
    }
    evh_6373990564425123_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6373990564425123_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8819772728062230_showModal_1264_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AccountStatementReport",
            };
        });
    }
    evh_8819772728062230_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8819772728062230_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1886107574314164_showModal_1266_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "general",
                },
            };
        });
    }
    evh_1886107574314164_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1886107574314164_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7260361660686930_showModal_1268_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MCreateFixedAsset",
                props: {
                    assetType: "waste_bin",
                },
            };
        });
    }
    evh_7260361660686930_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7260361660686930_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_5449511595610967_showModal_1270_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateMeter",
            };
        });
    }
    evh_5449511595610967_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5449511595610967_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_8050584411731670_showModal_1272_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "MeterDataExchange",
            };
        });
    }
    evh_8050584411731670_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8050584411731670_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_7807097906137782_showModal_1274_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ReplaceMeter",
            };
        });
    }
    evh_7807097906137782_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7807097906137782_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2338624680052783_showModal_1276_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateContract",
            };
        });
    }
    evh_2338624680052783_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2338624680052783_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2596033535867385_showModal_1278_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Worksheet",
                props: {
                    size: "lg",
                },
            };
        });
    }
    evh_2596033535867385_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2596033535867385_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2660996057968802_showModal_1280_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProduct",
            };
        });
    }
    evh_2660996057968802_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2660996057968802_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_492569660089023_showModal_1282_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateRental",
            };
        });
    }
    evh_492569660089023_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_492569660089023_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_4498221786869554_showModal_1284_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateProperty",
            };
        });
    }
    evh_4498221786869554_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4498221786869554_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_523998568575128_showModal_1286_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateCompany",
                props: {
                    role: "partner",
                },
            };
        });
    }
    evh_523998568575128_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_523998568575128_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_6790509420457885_showModal_1288_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateCompany",
                props: {
                    role: "own",
                },
            };
        });
    }
    evh_6790509420457885_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6790509420457885_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_298118714321807_script_1290_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$app.toggleConfirmPageClose($event);
        });
    }
    act_298118714321807_script_1290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_298118714321807_script_1290_getActionArgs_value($event),
            };
        });
    }
    evh_298118714321807_change(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_298118714321807_change.executeFromDOM(this, event, scope);
        });
    }
    act_7106235911078350_logout_1292_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {};
        });
    }
    evh_7106235911078350_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7106235911078350_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_2145520102685417_showModal_1294_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "AboutModal",
            };
        });
    }
    evh_2145520102685417_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2145520102685417_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1252 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1251 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1252,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1251],
        });
        const act_8512678877908410_showModal_1254 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8512678877908410_showModal_1254_getActionArgs,
            events: [],
        });
        const evh_8512678877908410_clickPrevent_1253 = new core_1.actions.EventHandlerImpl({
            action: act_8512678877908410_showModal_1254,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8512678877908410_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8512678877908410_clickPrevent_1253],
        });
        const act_5794187517758435_showModal_1256 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5794187517758435_showModal_1256_getActionArgs,
            events: [],
        });
        const evh_5794187517758435_clickPrevent_1255 = new core_1.actions.EventHandlerImpl({
            action: act_5794187517758435_showModal_1256,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5794187517758435_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5794187517758435_clickPrevent_1255],
        });
        const act_8440793417118356_showModal_1258 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8440793417118356_showModal_1258_getActionArgs,
            events: [],
        });
        const evh_8440793417118356_clickPrevent_1257 = new core_1.actions.EventHandlerImpl({
            action: act_8440793417118356_showModal_1258,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8440793417118356_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8440793417118356_clickPrevent_1257],
        });
        const act_6201675214105229_showModal_1260 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6201675214105229_showModal_1260_getActionArgs,
            events: [],
        });
        const evh_6201675214105229_clickPrevent_1259 = new core_1.actions.EventHandlerImpl({
            action: act_6201675214105229_showModal_1260,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6201675214105229_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6201675214105229_clickPrevent_1259],
        });
        const act_6373990564425123_showModal_1262 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6373990564425123_showModal_1262_getActionArgs,
            events: [],
        });
        const evh_6373990564425123_clickPrevent_1261 = new core_1.actions.EventHandlerImpl({
            action: act_6373990564425123_showModal_1262,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6373990564425123_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6373990564425123_clickPrevent_1261],
        });
        const act_8819772728062230_showModal_1264 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8819772728062230_showModal_1264_getActionArgs,
            events: [],
        });
        const evh_8819772728062230_clickPrevent_1263 = new core_1.actions.EventHandlerImpl({
            action: act_8819772728062230_showModal_1264,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8819772728062230_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8819772728062230_clickPrevent_1263],
        });
        const act_1886107574314164_showModal_1266 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_1886107574314164_showModal_1266_getActionArgs,
            events: [],
        });
        const evh_1886107574314164_clickPrevent_1265 = new core_1.actions.EventHandlerImpl({
            action: act_1886107574314164_showModal_1266,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_1886107574314164_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1886107574314164_clickPrevent_1265],
        });
        const act_7260361660686930_showModal_1268 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7260361660686930_showModal_1268_getActionArgs,
            events: [],
        });
        const evh_7260361660686930_clickPrevent_1267 = new core_1.actions.EventHandlerImpl({
            action: act_7260361660686930_showModal_1268,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7260361660686930_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7260361660686930_clickPrevent_1267],
        });
        const act_5449511595610967_showModal_1270 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5449511595610967_showModal_1270_getActionArgs,
            events: [],
        });
        const evh_5449511595610967_clickPrevent_1269 = new core_1.actions.EventHandlerImpl({
            action: act_5449511595610967_showModal_1270,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_5449511595610967_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5449511595610967_clickPrevent_1269],
        });
        const act_8050584411731670_showModal_1272 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8050584411731670_showModal_1272_getActionArgs,
            events: [],
        });
        const evh_8050584411731670_clickPrevent_1271 = new core_1.actions.EventHandlerImpl({
            action: act_8050584411731670_showModal_1272,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8050584411731670_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8050584411731670_clickPrevent_1271],
        });
        const act_7807097906137782_showModal_1274 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7807097906137782_showModal_1274_getActionArgs,
            events: [],
        });
        const evh_7807097906137782_clickPrevent_1273 = new core_1.actions.EventHandlerImpl({
            action: act_7807097906137782_showModal_1274,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_7807097906137782_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7807097906137782_clickPrevent_1273],
        });
        const act_2338624680052783_showModal_1276 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2338624680052783_showModal_1276_getActionArgs,
            events: [],
        });
        const evh_2338624680052783_clickPrevent_1275 = new core_1.actions.EventHandlerImpl({
            action: act_2338624680052783_showModal_1276,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2338624680052783_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2338624680052783_clickPrevent_1275],
        });
        const act_2596033535867385_showModal_1278 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2596033535867385_showModal_1278_getActionArgs,
            events: [],
        });
        const evh_2596033535867385_clickPrevent_1277 = new core_1.actions.EventHandlerImpl({
            action: act_2596033535867385_showModal_1278,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2596033535867385_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2596033535867385_clickPrevent_1277],
        });
        const act_2660996057968802_showModal_1280 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2660996057968802_showModal_1280_getActionArgs,
            events: [],
        });
        const evh_2660996057968802_clickPrevent_1279 = new core_1.actions.EventHandlerImpl({
            action: act_2660996057968802_showModal_1280,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2660996057968802_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2660996057968802_clickPrevent_1279],
        });
        const act_492569660089023_showModal_1282 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_492569660089023_showModal_1282_getActionArgs,
            events: [],
        });
        const evh_492569660089023_clickPrevent_1281 = new core_1.actions.EventHandlerImpl({
            action: act_492569660089023_showModal_1282,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_492569660089023_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_492569660089023_clickPrevent_1281],
        });
        const act_4498221786869554_showModal_1284 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_4498221786869554_showModal_1284_getActionArgs,
            events: [],
        });
        const evh_4498221786869554_clickPrevent_1283 = new core_1.actions.EventHandlerImpl({
            action: act_4498221786869554_showModal_1284,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_4498221786869554_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4498221786869554_clickPrevent_1283],
        });
        const act_523998568575128_showModal_1286 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_523998568575128_showModal_1286_getActionArgs,
            events: [],
        });
        const evh_523998568575128_clickPrevent_1285 = new core_1.actions.EventHandlerImpl({
            action: act_523998568575128_showModal_1286,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_523998568575128_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_523998568575128_clickPrevent_1285],
        });
        const act_6790509420457885_showModal_1288 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6790509420457885_showModal_1288_getActionArgs,
            events: [],
        });
        const evh_6790509420457885_clickPrevent_1287 = new core_1.actions.EventHandlerImpl({
            action: act_6790509420457885_showModal_1288,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_6790509420457885_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6790509420457885_clickPrevent_1287],
        });
        const act_298118714321807_script_1290 = new core_1.actions.ScriptAction({
            actionArgs: this.act_298118714321807_script_1290_getActionArgs,
            events: [],
        });
        const evh_298118714321807_change_1289 = new core_1.actions.EventHandlerImpl({
            action: act_298118714321807_script_1290,
            event: "change",
            displayName: "script",
        });
        const evh_298118714321807_change = new core_1.actions.EventHandlerGroup({
            handlers: [evh_298118714321807_change_1289],
        });
        const act_7106235911078350_logout_1292 = new core_1.actions.LogoutAction({
            actionArgs: this.act_7106235911078350_logout_1292_getActionArgs,
            events: [],
        });
        const evh_7106235911078350_clickPrevent_1291 = new core_1.actions.EventHandlerImpl({
            action: act_7106235911078350_logout_1292,
            event: "click.prevent",
            displayName: "logout",
        });
        const evh_7106235911078350_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7106235911078350_clickPrevent_1291],
        });
        const act_2145520102685417_showModal_1294 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2145520102685417_showModal_1294_getActionArgs,
            events: [],
        });
        const evh_2145520102685417_clickPrevent_1293 = new core_1.actions.EventHandlerImpl({
            action: act_2145520102685417_showModal_1294,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_2145520102685417_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2145520102685417_clickPrevent_1293],
        });
        return {
            act_7315092382398562_reloadComponentData_1252,
            evh_7315092382398562_reload_1251,
            evh_7315092382398562_reload,
            act_8512678877908410_showModal_1254,
            evh_8512678877908410_clickPrevent_1253,
            evh_8512678877908410_clickPrevent,
            act_5794187517758435_showModal_1256,
            evh_5794187517758435_clickPrevent_1255,
            evh_5794187517758435_clickPrevent,
            act_8440793417118356_showModal_1258,
            evh_8440793417118356_clickPrevent_1257,
            evh_8440793417118356_clickPrevent,
            act_6201675214105229_showModal_1260,
            evh_6201675214105229_clickPrevent_1259,
            evh_6201675214105229_clickPrevent,
            act_6373990564425123_showModal_1262,
            evh_6373990564425123_clickPrevent_1261,
            evh_6373990564425123_clickPrevent,
            act_8819772728062230_showModal_1264,
            evh_8819772728062230_clickPrevent_1263,
            evh_8819772728062230_clickPrevent,
            act_1886107574314164_showModal_1266,
            evh_1886107574314164_clickPrevent_1265,
            evh_1886107574314164_clickPrevent,
            act_7260361660686930_showModal_1268,
            evh_7260361660686930_clickPrevent_1267,
            evh_7260361660686930_clickPrevent,
            act_5449511595610967_showModal_1270,
            evh_5449511595610967_clickPrevent_1269,
            evh_5449511595610967_clickPrevent,
            act_8050584411731670_showModal_1272,
            evh_8050584411731670_clickPrevent_1271,
            evh_8050584411731670_clickPrevent,
            act_7807097906137782_showModal_1274,
            evh_7807097906137782_clickPrevent_1273,
            evh_7807097906137782_clickPrevent,
            act_2338624680052783_showModal_1276,
            evh_2338624680052783_clickPrevent_1275,
            evh_2338624680052783_clickPrevent,
            act_2596033535867385_showModal_1278,
            evh_2596033535867385_clickPrevent_1277,
            evh_2596033535867385_clickPrevent,
            act_2660996057968802_showModal_1280,
            evh_2660996057968802_clickPrevent_1279,
            evh_2660996057968802_clickPrevent,
            act_492569660089023_showModal_1282,
            evh_492569660089023_clickPrevent_1281,
            evh_492569660089023_clickPrevent,
            act_4498221786869554_showModal_1284,
            evh_4498221786869554_clickPrevent_1283,
            evh_4498221786869554_clickPrevent,
            act_523998568575128_showModal_1286,
            evh_523998568575128_clickPrevent_1285,
            evh_523998568575128_clickPrevent,
            act_6790509420457885_showModal_1288,
            evh_6790509420457885_clickPrevent_1287,
            evh_6790509420457885_clickPrevent,
            act_298118714321807_script_1290,
            evh_298118714321807_change_1289,
            evh_298118714321807_change,
            act_7106235911078350_logout_1292,
            evh_7106235911078350_clickPrevent_1291,
            evh_7106235911078350_clickPrevent,
            act_2145520102685417_showModal_1294,
            evh_2145520102685417_clickPrevent_1293,
            evh_2145520102685417_clickPrevent,
        };
    }
};
MainMenu = __decorate([
    (0, vue_property_decorator_1.Component)()
], MainMenu);
exports.default = MainMenu;
vue_property_decorator_1.Vue.component("MainMenu", MainMenu);
