var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    [
      _c("ItpBox", { attrs: { direction: "row", "align-items": "top" } }, [
        _c(
          "div",
          { staticClass: "mr-2" },
          [
            _c("UcInvoiceTypeBadge", {
              attrs: {
                value: _vm.option.invoice_type,
                kind: _vm.option.invoice_kind,
                abbreviated: ""
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c("strong", [_vm._v(_vm._s(_vm.option.entry_number))]),
          _vm._v("\n    · "),
          _c("strong", [_vm._v(_vm._s(_vm.option.invoice_number))]),
          _vm._v(" "),
          _vm.option.client_name
            ? _c("span", [_vm._v("· " + _vm._s(_vm.option.client_name))])
            : _vm._e(),
          _vm._v(" "),
          _vm.option.description
            ? _c("span", [
                _c("br"),
                _vm._v(_vm._s(_vm.option.description) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(
            "\n    " +
              _vm._s(_vm._f("date")(_vm.option.received_date)) +
              "\n    · " +
              _vm._s(_vm._f("number")(_vm.option.amount.amount)) +
              " " +
              _vm._s(_vm.option.amount.currency) +
              "\n  "
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }