var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "status",
            label: _vm._f("pgettext")("Type", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              autofocus: !_vm.isReadonly,
              name: "status",
              options: _vm.invoiceTypes,
              valueField: "value",
              textField: "text",
              readonly: _vm.isReadonly,
              required: ""
            },
            model: {
              value: _vm.invoice.invoice_type,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "invoice_type", $$v)
              },
              expression: "invoice.invoice_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "reference_invoice",
            label: _vm._f("pgettext")("Reference Invoice", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: _vm.isReferenceInvoiceRequired
          }
        },
        [
          _vm.invoice.invoice_type
            ? _c(
                "div",
                { attrs: { slot: "description" }, slot: "description" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.messages.invoiceTypeHelp[_vm.invoice.invoice_type]
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("ItpFormSelect2", {
            attrs: {
              name: "reference_invoice",
              dataSource: function() {
                return {
                  name: "suggest_invoice",
                  parameters: {
                    invoice_kind: _vm.invoice.invoice_kind,
                    parent_invoice_type: _vm.invoice.invoice_type,
                    client_id: _vm.invoice.client ? _vm.invoice.client.id : null
                  }
                }
              },
              valueField: "id",
              textField: "name",
              "bind-object": "",
              readonly: _vm.isReadonly,
              disabled: !_vm.invoice.invoice_type,
              clearable: "",
              "use-option-template-for-selected-option": "",
              optionTemplate:
                '<UcSuggestInvoiceOption :option="option" hide-invoice-kind></UcSuggestInvoiceOption>'
            },
            on: {
              optionSelected: function($event) {
                return _vm.evh_4186377094414664_optionSelected($event, {})
              }
            },
            model: {
              value: _vm.invoice.reference_invoice,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "reference_invoice", $$v)
              },
              expression: "invoice.reference_invoice"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-4" }),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "client",
            label: _vm._f("pgettext")("Own company", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "company",
              "bind-object": "",
              dataSource: _vm.b_6917646200545441_dataSource,
              required: "",
              disabled: _vm.preventChangeCompany,
              optionTemplate:
                "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
              "use-option-template-for-selected-option": ""
            },
            on: {
              selected: function($event) {
                return _vm.evh_6917646200545441_selected($event, {})
              }
            },
            model: {
              value: _vm.invoice.company,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "company", $$v)
              },
              expression: "invoice.company"
            }
          }),
          _vm._v(" "),
          _vm.invoice.company
            ? _c("UcInvoicePartyPanel", {
                staticClass: "mt-2 p-2 bg-light",
                attrs: { slot: "description", item: _vm.invoice.company },
                slot: "description"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "client",
            label: _vm._f("gettext")("Client"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "client",
              "bind-object": "",
              dataSource: _vm.b_6424600534600827_dataSource,
              required: "",
              disabled: _vm.preventChangeClient,
              optionTemplate:
                "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
              "use-option-template-for-selected-option": ""
            },
            on: {
              selected: function($event) {
                return _vm.evh_6424600534600827_selected($event, {})
              }
            },
            model: {
              value: _vm.invoice.client,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "client", $$v)
              },
              expression: "invoice.client"
            }
          }),
          _vm._v(" "),
          _vm.invoice.client
            ? _c("UcInvoicePartyPanel", {
                staticClass: "mt-2 p-2 bg-light",
                attrs: { slot: "description", item: _vm.invoice.client },
                slot: "description"
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-4" }),
      _vm._v(" "),
      _vm.isIncomingInvoice
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "invoice_number",
                label: _vm._f("gettext")("Invoice Number"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign,
                required: ""
              }
            },
            [
              _vm.duplicateInvoiceNumber
                ? _c(
                    "div",
                    { attrs: { slot: "description" }, slot: "description" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.messages.duplicateInvoiceNumber) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("ItpFormInput", {
                attrs: {
                  name: "invoice_number",
                  readonly: _vm.isReadonly,
                  placeholder: _vm._f("pgettext")(
                    "Enter invoice number",
                    "Invoice/invoice_number/placeholder"
                  ),
                  required: ""
                },
                on: {
                  blur: function($event) {
                    return _vm.evh_7106714423180045_blur($event, {})
                  }
                },
                model: {
                  value: _vm.invoice.invoice_number,
                  callback: function($$v) {
                    _vm.$set(_vm.invoice, "invoice_number", $$v)
                  },
                  expression: "invoice.invoice_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "invoice_date",
            label: _vm._f("gettext")("Invoice Date"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: {
              name: "invoice_date",
              readonly: _vm.isReadonly,
              required: ""
            },
            model: {
              value: _vm.invoice.invoice_date,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "invoice_date", $$v)
              },
              expression: "invoice.invoice_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "fulfillment_date",
            label: _vm._f("gettext")("Fulfillment Date"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: {
              name: "fulfillment_date",
              readonly: _vm.isReadonly,
              required: ""
            },
            model: {
              value: _vm.invoice.fulfillment_date,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "fulfillment_date", $$v)
              },
              expression: "invoice.fulfillment_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "due_date",
            label: _vm._f("pgettext")("Due Date", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "due_date", readonly: _vm.isReadonly, required: "" },
            model: {
              value: _vm.invoice.due_date,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "due_date", $$v)
              },
              expression: "invoice.due_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-4" }),
      _vm._v(" "),
      _vm.isIncomingInvoice
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "status",
                label: _vm._f("pgettext")("Status", "Invoice"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign,
                required: ""
              }
            },
            [
              _c("ItpFormSelect", {
                attrs: {
                  name: "status",
                  options: _vm.incomingInvoiceStatuses,
                  valueField: "value",
                  textField: "text",
                  readonly: _vm.isReadonly,
                  required: ""
                },
                model: {
                  value: _vm.invoice.status,
                  callback: function($$v) {
                    _vm.$set(_vm.invoice, "status", $$v)
                  },
                  expression: "invoice.status"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "reference_date",
            label: _vm._f("pgettext")("Reference Date", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormDatetime", {
            attrs: { name: "reference_date", readonly: _vm.isReadonly },
            model: {
              value: _vm.invoice.reference_date,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "reference_date", $$v)
              },
              expression: "invoice.reference_date"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.invoice.billing_period
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "billing_period",
                label: _vm._f("gettext")("Billing Period"),
                contentClass: "form-row",
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormDatetime", {
                staticClass: "col-6",
                attrs: {
                  name: "billing_period_start",
                  readonly: _vm.isReadonly,
                  placeholder: _vm._f("pgettext")("From", "BillingPeriod")
                },
                model: {
                  value: _vm.invoice.billing_period.start,
                  callback: function($$v) {
                    _vm.$set(_vm.invoice.billing_period, "start", $$v)
                  },
                  expression: "invoice.billing_period.start"
                }
              }),
              _vm._v(" "),
              _c("ItpFormDatetime", {
                staticClass: "col-6",
                attrs: {
                  name: "billing_period_end",
                  readonly: _vm.isReadonly,
                  placeholder: _vm._f("pgettext")("To", "BillingPeriod")
                },
                model: {
                  value: _vm.invoice.billing_period.end,
                  callback: function($$v) {
                    _vm.$set(_vm.invoice.billing_period, "end", $$v)
                  },
                  expression: "invoice.billing_period.end"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "currency",
            label: _vm._f("pgettext")("Currency", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "currency",
              options: _vm.currencies,
              valueField: "code",
              textField: "code",
              readonly: _vm.isReadonly
            },
            model: {
              value: _vm.invoice.currency,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "currency", $$v)
              },
              expression: "invoice.currency"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "currency",
            label: _vm._f("pgettext")("Exchange rate", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: {
              type: "number",
              name: "exchange_rate",
              readonly: _vm.isReadonly
            },
            model: {
              value: _vm.invoice.exchange_rate,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "exchange_rate", $$v)
              },
              expression: "invoice.exchange_rate"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "language",
            label: _vm._f("gettext")("Language"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "language",
              options: _vm.languages,
              readonly: _vm.isReadonly
            },
            model: {
              value: _vm.invoice.language,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "language", $$v)
              },
              expression: "invoice.language"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "expense_type",
            label: _vm._f("gettext")("Expense Type"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "expense_type",
              options: _vm.expenseTypes,
              readonly: _vm.isReadonly,
              valueField: "name",
              textField: "name"
            },
            model: {
              value: _vm.invoice.expense_type,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "expense_type", $$v)
              },
              expression: "invoice.expense_type"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "payment_method",
            label: _vm._f("pgettext")("Payment Method", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "4",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect", {
            attrs: {
              name: "payment_method",
              options: _vm.paymentMethods,
              readonly: _vm.isReadonly,
              required: ""
            },
            model: {
              value: _vm.invoice.payment_method,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "payment_method", $$v)
              },
              expression: "invoice.payment_method"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            labelColsMd: "4",
            contentColsMd: "8",
            name: "tags",
            label: _vm._f("pgettext")("Categories", "Invoice"),
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "tags",
              multiple: "",
              taggable: "",
              options: _vm.tags
            },
            model: {
              value: _vm.invoice.tags,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "tags", $$v)
              },
              expression: "invoice.tags"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-4" }),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "reference_number",
            label: _vm._f("pgettext")("Reference Number", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "reference_number", readonly: _vm.isReadonly },
            model: {
              value: _vm.invoice.reference_number,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "reference_number", $$v)
              },
              expression: "invoice.reference_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "order_number",
            label: _vm._f("pgettext")("Order Number", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "order_number", readonly: _vm.isReadonly },
            model: {
              value: _vm.invoice.order_number,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "order_number", $$v)
              },
              expression: "invoice.order_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "file_number",
            label: _vm._f("pgettext")("File Number", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormInput", {
            attrs: { name: "file_number", readonly: _vm.isReadonly },
            model: {
              value: _vm.invoice.file_number,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "file_number", $$v)
              },
              expression: "invoice.file_number"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-4" }),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "notes",
            label: _vm._f("pgettext")("Notes", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: { name: "notes", readonly: _vm.isReadonly },
            model: {
              value: _vm.invoice.notes,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "notes", $$v)
              },
              expression: "invoice.notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          staticClass: "col",
          attrs: {
            name: "internal_notes",
            label: _vm._f("pgettext")("Internal Notes", "Invoice"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign
          }
        },
        [
          _c("ItpFormTextarea", {
            attrs: {
              name: "internal_notes",
              readonly: _vm.isReadonly,
              rows: "4"
            },
            model: {
              value: _vm.invoice.internal_notes,
              callback: function($$v) {
                _vm.$set(_vm.invoice, "internal_notes", $$v)
              },
              expression: "invoice.internal_notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }