<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceReferenceSearch"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="search"
      :id="formId"
    >
      <DIV
        class="form-row"
      >
        <ItpSelect2
          name="selectedReference"
          v-model="selectedReference"
          :options="referenceTypes"
          valueField="target"
          textField="text"
          :clearable="b_7789867021953041_clearable"
          bind-object
          class="col-4"
          @selected="evh_7789867021953041_selected($event, {})"
        >
        </ItpSelect2>
        <ItpFormSelect2
          name="search"
          v-model="searchTerm"
          :placeholder="'Search...'|pgettext('Search Placeholder')"
          :dataSource="selectedReference && selectedReference.dataSource"
          :options="items"
          optionTemplate="<InvoiceReferenceSearchOption :option=option>
</InvoiceReferenceSearchOption>"
          use-option-template-for-selected-option
          class="col-8"
          @optionSelected="evh_3468003412020058_optionSelected($event, {})"
        >
        </ItpFormSelect2>
      </DIV>
      <b-list-group
        class="mt-3"
      >
        <b-list-group-item
          :key="item.id"
          v-for="item in selectedItems"
        >
          <ItpBox
            alignItems="start"
          >
            <InvoiceReferenceItem
              :item="item"
              no-link
            >
            </InvoiceReferenceItem>
            <button
              data-dismiss="modal"
              aria-label="Close"
              class="close ml-auto mr-1"
              @click="evh_6049458597007392_click($event, {item})"
            >
              <span aria-hidden="true">&times;</span>

            </button>
          </ItpBox>
        </b-list-group-item>
      </b-list-group>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Add references'|pgettext('InvoiceReferenceSearch/Submit')"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submitReferences && actions.submitReferences.isRunning"
      :disabled="!selectedItems.length"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Cancel'|gettext"
      variant="default"
      :disabled="(actions.search && actions.search.isRunning) || (actions.submitReferences && actions.submitReferences.isRunning)"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceReferenceSearch extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedReferenceType = new ComponentValueCache(this, "selectedReferenceType", "InvoiceReferenceSearchModal.selectedReferenceType");
  }

  onReferenceSelected(...args: any[]) {
    const { option, value } = args[0];
    if (option) {
      this.selectedItems.push(option);

      if (option.rental_id && option.rental_name) {
        this.selectedItems.push({
          object: 'rental',
          id: option.rental_id,
          name: option.rental_name
        });
      }

      if (option.client_id && option.client_name) {
        this.selectedItems.push({
          object: 'company',
          id: option.client_id,
          name: option.client_name
        });
      }
      if (option.properties) {
        _.each(option.properties, p => {
          if (p.object == 'rental_property' && !p.inactive && p.property_unit) {
            this.selectedItems.push({
              object: 'property_unit',
              id: p.property_unit.id,
              name: p.property_unit.full_name || p.property_unit.full_code
            });
          }
        })
      }
    }
  }

  @Prop({
    required: true,
    type: String,
  })
  invoiceId!: string;

  @Watch('invoiceId')
  onInvoice_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceId")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  searchTerm!: any;
  items!: any;
  selectedItems!: any;
  referenceTypes!: any;

  async $$load_referenceTypes() {
    return [
      {
        type: 'contract',
        text: this.$fn.gettext('Contracts'),
        target: 'contracts',
        dataSource: {
          name: 'suggest_contract',
          parameters: {}
        },
        template: '',
      },
      {
        type: 'rental',
        text: this.$fn.gettext('Rentals'),
        target: 'rentals',
        dataSource: {
          name: 'suggest_rental',
          parameters: {}
        },
      },
      {
        type: 'client',
        text: this.$fn.gettext('Clients'),
        target: 'tenants',
        dataSource: {
          name: 'suggest_company',
          parameters: {
            role: 'partner'
          }
        },
      },
      {
        type: 'property',
        text: this.$fn.gettext('Properties'),
        target: 'properties',
        dataSource: {
          name: 'suggest_property',
          parameters: {
            types: 'property,unit'
          }
        },
      },
    ]
  }

  selectedReferenceType!: any;
  selectedReference!: any;

  async $$load_selectedReference() {
    return _.find(this.referenceTypes, { type: this.selectedReferenceType }) || this.referenceTypes[0]
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7789867021953041_clearable!: any;
  dataMembers = ['formId', 'searchTerm', 'items', 'selectedItems', 'referenceTypes', 'selectedReferenceType', 'selectedReference', 'ux', 'b_7315092382398562_modalBindings', 'b_7789867021953041_clearable'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        searchTerm: null,
        items: [
        ]
        ,
        selectedItems: [
        ]
        ,
        referenceTypes: null,
        selectedReferenceType: null,
        selectedReference: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_7789867021953041_clearable: false,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Invoice", "Search Reference"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6144_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6144, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_setData_6148_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_6148, alias=undefined
    return {
      path: "searchTerm",
      value: null,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_7789867021953041_setData_6150_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_6150, alias=undefined
    return {
      path: "items",
      value: [
      ]
      ,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_7789867021953041_setData_6152_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_6152, alias=undefined
    return {
      path: "selectedReferenceType",
      value: $event.data.type,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7789867021953041_selected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_selected.executeFromDOM(this, event, scope);
  }

  async act_3468003412020058_script_6154_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3468003412020058_script_6154, alias=undefined
    this.onReferenceSelected($event.data)
  }

  async act_3468003412020058_script_6154_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3468003412020058_script_6154, alias=undefined
    return {
      value: () => this.act_3468003412020058_script_6154_getActionArgs_value($event),
    }
  }

  async evh_3468003412020058_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3468003412020058_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_6049458597007392_setData_6156_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6049458597007392_setData_6156, alias=undefined
    return {
      path: "selectedItems",
      value: (_.remove(this.selectedItems, $event.scope.item), this.selectedItems.slice()),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6049458597007392_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6049458597007392_click.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeComponent_6160_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1548630417156826_closeComponent_6160, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_request_6158_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1548630417156826_request_6158, alias=submitReferences
    return {
      operation: "create_invoice_reference",
      data: _.map(this.selectedItems, p => ({ reference_id: p.id })),
      parameters: {
        invoice_id: this.invoiceId,
      }
      ,
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6144: actions.CloseModalAction;
    evh_7315092382398562_close_6143: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6146: actions.CloseComponentAction;
    evh_2248226175642056_close_6145: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_7789867021953041_setData_6148: actions.SetDataAction;
    evh_7789867021953041_selected_6147: actions.EventHandlerImpl;
    act_7789867021953041_setData_6150: actions.SetDataAction;
    evh_7789867021953041_selected_6149: actions.EventHandlerImpl;
    act_7789867021953041_setData_6152: actions.SetDataAction;
    evh_7789867021953041_selected_6151: actions.EventHandlerImpl;
    evh_7789867021953041_selected: actions.EventHandlerGroup;
    act_3468003412020058_script_6154: actions.ScriptAction;
    evh_3468003412020058_optionSelected_6153: actions.EventHandlerImpl;
    evh_3468003412020058_optionSelected: actions.EventHandlerGroup;
    act_6049458597007392_setData_6156: actions.SetDataAction;
    evh_6049458597007392_click_6155: actions.EventHandlerImpl;
    evh_6049458597007392_click: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_6160: actions.CloseComponentAction;
    evh_1548630417156826_success_6159: actions.EventHandlerImpl;
    act_1548630417156826_request_6158: actions.RequestAction;
    evh_1548630417156826_clickPreventStop_6157: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6162: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_6161: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6144 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6144_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6143 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6144,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6143],
      }
    );
    const act_2248226175642056_closeComponent_6146 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6145 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6146,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6145],
      }
    );
    const act_7789867021953041_setData_6148 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_6148_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_selected_6147 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_6148,
        event: "selected",
        displayName: "setData",
      }
    );
    const act_7789867021953041_setData_6150 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_6150_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_selected_6149 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_6150,
        event: "selected",
        displayName: "setData",
      }
    );
    const act_7789867021953041_setData_6152 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_6152_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_selected_6151 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_6152,
        event: "selected",
        displayName: "setData",
      }
    );
    const evh_7789867021953041_selected = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_selected_6147, evh_7789867021953041_selected_6149, evh_7789867021953041_selected_6151],
      }
    );
    const act_3468003412020058_script_6154 = new actions.ScriptAction(
      {
        actionArgs: this.act_3468003412020058_script_6154_getActionArgs,
        events: [],
      }
    );
    const evh_3468003412020058_optionSelected_6153 = new actions.EventHandlerImpl(
      {
        action: act_3468003412020058_script_6154,
        event: "optionSelected",
        displayName: "script",
      }
    );
    const evh_3468003412020058_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_3468003412020058_optionSelected_6153],
      }
    );
    const act_6049458597007392_setData_6156 = new actions.SetDataAction(
      {
        actionArgs: this.act_6049458597007392_setData_6156_getActionArgs,
        events: [],
      }
    );
    const evh_6049458597007392_click_6155 = new actions.EventHandlerImpl(
      {
        action: act_6049458597007392_setData_6156,
        event: "click",
        displayName: "setData",
      }
    );
    const evh_6049458597007392_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_6049458597007392_click_6155],
      }
    );
    const act_1548630417156826_closeComponent_6160 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1548630417156826_closeComponent_6160_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6159 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_6160,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_1548630417156826_request_6158 = new actions.RequestAction(
      {
        actionArgs: this.act_1548630417156826_request_6158_getActionArgs,
        displayName: "submitReferences",
        events: [evh_1548630417156826_success_6159],
      }
    );
    const evh_1548630417156826_clickPreventStop_6157 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_request_6158,
        event: "click.prevent.stop",
        displayName: "submitReferences",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6157],
      }
    );
    const act_1419464017721962_closeComponent_6162 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_6161 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6162,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6161],
      }
    );
    return {
      act_7315092382398562_closeModal_6144,
      evh_7315092382398562_close_6143,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6146,
      evh_2248226175642056_close_6145,
      evh_2248226175642056_close,
      act_7789867021953041_setData_6148,
      evh_7789867021953041_selected_6147,
      act_7789867021953041_setData_6150,
      evh_7789867021953041_selected_6149,
      act_7789867021953041_setData_6152,
      evh_7789867021953041_selected_6151,
      evh_7789867021953041_selected,
      act_3468003412020058_script_6154,
      evh_3468003412020058_optionSelected_6153,
      evh_3468003412020058_optionSelected,
      act_6049458597007392_setData_6156,
      evh_6049458597007392_click_6155,
      evh_6049458597007392_click,
      act_1548630417156826_closeComponent_6160,
      evh_1548630417156826_success_6159,
      act_1548630417156826_request_6158,
      evh_1548630417156826_clickPreventStop_6157,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeComponent_6162,
      evh_1419464017721962_clickPreventStop_6161,
      evh_1419464017721962_clickPreventStop,
      submitReferences: act_1548630417156826_request_6158,
    }
  }
}

Vue.component("InvoiceReferenceSearch", InvoiceReferenceSearch);

</script>