<template>
<ItpButton
  :icon="synced ? 'fluent:cloud-sync-complete-16-filled' : 'material-symbols:cloud-sync-outline-rounded'"
  :class="synced ? 'bg-green-200' : 'bg-yellow-200'"
  :tooltip="tooltipMessage"
  :spinning="actions.sync.isRunning"
  variant="light"
  :disabled="synced"
  :size="size"
  @click.prevent.stop="evh_7315092382398562_clickPreventStop($event, {})"
>
</ItpButton>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class BtnSyncResource extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  resource!: object;

  @Watch('resource')
  onResource(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("resource")
  }

  @Prop({
    type: String,
    default: "Adatok visszaírása az IntegroRent rendszerébe?",
  })
  confirmMessage!: string;

  @Watch('confirmMessage')
  onConfirm_message(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("confirmMessage")
  }

  @Prop({
    type: String,
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  @Prop({
    type: String,
    default: "irent",
  })
  target!: string;

  @Watch('target')
  onTarget(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("target")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          text: this.$fn.pgettext("BtnDeleteResource", "Delete"),
        }
        ,
        ux: null,
      },
    }
  }

  get synced() {
    if (this.loaded) {

      return !!(this.resource.sync_status || this.resource.external_key)
    }
    return null;
  }

  get tooltipMessage() {
    if (this.loaded) {

      return this.synced ?
        `Szinkronizálva [${this.resource.external_key}]` :
        'Szinkronizálás'
    }
    return null;
  }

  async act_7315092382398562_emit_1598_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7315092382398562_emit_1598, alias=undefined
    return {
      event: "success",
    }
  }

  async act_7315092382398562_request_1596_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7315092382398562_request_1596, alias=sync
    return {
      operation: 'sync_' + this.resource.object,
      data: { id: this.resource.id, target: this.target || 'irent' },
    }
  }

  async act_7315092382398562_request_1596_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7315092382398562_request_1596, alias=sync
    return {
      header: "IntegroRent szinkronizálás",
      message: this.confirmMessage,
      btnOk: "Szinkronizálás",
      btnCancel: "Mégsem",
      severity: "warning",
    }
  }

  async evh_7315092382398562_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_emit_1598: actions.EmitAction;
    evh_7315092382398562_success_1597: actions.EventHandlerImpl;
    act_7315092382398562_request_1596: actions.RequestAction;
    evh_7315092382398562_clickPreventStop_1595: actions.EventHandlerImpl;
    evh_7315092382398562_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_emit_1598 = new actions.EmitAction(
      {
        actionArgs: this.act_7315092382398562_emit_1598_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_success_1597 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_emit_1598,
        event: "success",
        displayName: "emit",
      }
    );
    const act_7315092382398562_request_1596 = new actions.RequestAction(
      {
        actionArgs: this.act_7315092382398562_request_1596_getActionArgs,
        confirm: this.act_7315092382398562_request_1596_getConfirm,
        displayName: "sync",
        events: [evh_7315092382398562_success_1597],
      }
    );
    const evh_7315092382398562_clickPreventStop_1595 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_request_1596,
        event: "click.prevent.stop",
        displayName: "sync",
      }
    );
    const evh_7315092382398562_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_clickPreventStop_1595],
      }
    );
    return {
      act_7315092382398562_emit_1598,
      evh_7315092382398562_success_1597,
      act_7315092382398562_request_1596,
      evh_7315092382398562_clickPreventStop_1595,
      evh_7315092382398562_clickPreventStop,
      sync: act_7315092382398562_request_1596,
    }
  }
}

Vue.component("BtnSyncResource", BtnSyncResource);

</script>