"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let Job = class Job extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['resource', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_resource() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_a_job', { parameters: { id: this.oid }, asResource: true });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resource: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.$fn.pgettext("Job|Title", "Job"),
                    this.resource.name,
                    this.resource.id,
                ],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5614_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8564662037462133_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_5622_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "jobs",
            };
        });
    }
    evh_8564662037462133_deleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
        });
    }
    evh_1040167445267876_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_1548630417156826_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5614 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5614_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5613 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5614,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5613],
        });
        const act_2248226175642056_closeComponent_5616 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_5615 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_5616,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5615],
        });
        const act_8564662037462133_reloadComponentData_5618 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8564662037462133_reload_5617 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadComponentData_5618,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8564662037462133_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_reload_5617],
        });
        const act_8564662037462133_closeModal_5620 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_8564662037462133_deleted_5619 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_closeModal_5620,
            event: "deleted",
            displayName: "closeModal",
        });
        const act_8564662037462133_reloadSlickgrid_5622 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_5622_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_deleted_5621 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_5622,
            event: "deleted",
            displayName: "reloadSlickgrid",
        });
        const evh_8564662037462133_deleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_deleted_5619, evh_8564662037462133_deleted_5621],
        });
        const act_1040167445267876_reloadComponentData_5624 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_1040167445267876_reload_5623 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadComponentData_5624,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_1040167445267876_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_reload_5623],
        });
        const act_1548630417156826_closeComponent_5626 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1548630417156826_clickPreventStop_5625 = new core_1.actions.EventHandlerImpl({
            action: act_1548630417156826_closeComponent_5626,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1548630417156826_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1548630417156826_clickPreventStop_5625],
        });
        return {
            act_7315092382398562_closeModal_5614,
            evh_7315092382398562_close_5613,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_5616,
            evh_2248226175642056_close_5615,
            evh_2248226175642056_close,
            act_8564662037462133_reloadComponentData_5618,
            evh_8564662037462133_reload_5617,
            evh_8564662037462133_reload,
            act_8564662037462133_closeModal_5620,
            evh_8564662037462133_deleted_5619,
            act_8564662037462133_reloadSlickgrid_5622,
            evh_8564662037462133_deleted_5621,
            evh_8564662037462133_deleted,
            act_1040167445267876_reloadComponentData_5624,
            evh_1040167445267876_reload_5623,
            evh_1040167445267876_reload,
            act_1548630417156826_closeComponent_5626,
            evh_1548630417156826_clickPreventStop_5625,
            evh_1548630417156826_clickPreventStop,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: String,
    }),
    __metadata("design:type", String)
], Job.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Job.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], Job.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Job.prototype, "onSize", null);
Job = __decorate([
    (0, vue_property_decorator_1.Component)()
], Job);
exports.default = Job;
vue_property_decorator_1.Vue.component("Job", Job);
