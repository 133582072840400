<template>
<ItpModal
  compact
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="FileSelectorModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <b-card
    no-body
  >
    <b-tabs
      card
    >
      <b-tab
        :title="'Upload'|gettext"
      >
        <b-card-body>
          <DIV
            class="row mb-3"
          >
            <b-form-file
              :multiple="multiple"
              v-model="selectedFiles"
              :placeholder="'Choose a file or drop it here'|gettext"
              :drop-placeholder="'Drop file here'|gettext"
              lang="hu"
              class="col mr-3"
            >
            </b-form-file>
            <ItpButton
              :disabled="!selectedFiles"
              :text="'Upload'|gettext"
              icon="fa-upload"
              :spinning="actions.submit.isRunning"
              class="col-auto"
              @click="evh_7786837829063213_click($event, {})"
            >
            </ItpButton>
          </DIV>
        </b-card-body>
      </b-tab>
      <b-tab
        :title="'Scanned Documents'|gettext"
      >
        <b-card-body>
          <DIV
            class="row mb-3"
          >
            <p
              class="text-center w-100"
            >
              {{ 'There are no documents in the scan folder.'|gettext }}
            </p>
          </DIV>
        </b-card-body>
      </b-tab>
    </b-tabs>
  </b-card>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FileSelectorModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  multiple!: boolean;

  @Watch('multiple')
  onMultiple(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("multiple")
  }

  @Prop({
    required: true,
    type: String,
  })
  operation!: string;

  @Watch('operation')
  onOperation(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("operation")
  }

  @Prop({
    type: Object,
    default: {},
  })
  payload!: object;

  @Watch('payload')
  onPayload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payload")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  selectedFiles!: any;
  ux!: any;
  dataMembers = ['selectedFiles', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        selectedFiles: null,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.gettext("Select files"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1650_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1650, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_7786837829063213_closeModal_1654_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7786837829063213_closeModal_1654, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_7786837829063213_request_1652_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7786837829063213_request_1652, alias=submit
    return {
      operation: this.operation,
      data: _.extend(this.payload, { file: this.selectedFiles }),
      contentType: "multipart/form-data",
    }
  }

  async evh_7786837829063213_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7786837829063213_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1650: actions.CloseModalAction;
    evh_7315092382398562_close_1649: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_7786837829063213_closeModal_1654: actions.CloseModalAction;
    evh_7786837829063213_success_1653: actions.EventHandlerImpl;
    act_7786837829063213_request_1652: actions.RequestAction;
    evh_7786837829063213_click_1651: actions.EventHandlerImpl;
    evh_7786837829063213_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1650 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1650_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1649 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1650,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1649],
      }
    );
    const act_7786837829063213_closeModal_1654 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7786837829063213_closeModal_1654_getActionArgs,
        events: [],
      }
    );
    const evh_7786837829063213_success_1653 = new actions.EventHandlerImpl(
      {
        action: act_7786837829063213_closeModal_1654,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_7786837829063213_request_1652 = new actions.RequestAction(
      {
        actionArgs: this.act_7786837829063213_request_1652_getActionArgs,
        displayName: "submit",
        events: [evh_7786837829063213_success_1653],
      }
    );
    const evh_7786837829063213_click_1651 = new actions.EventHandlerImpl(
      {
        action: act_7786837829063213_request_1652,
        event: "click",
        displayName: "submit",
      }
    );
    const evh_7786837829063213_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_7786837829063213_click_1651],
      }
    );
    return {
      act_7315092382398562_closeModal_1650,
      evh_7315092382398562_close_1649,
      evh_7315092382398562_close,
      act_7786837829063213_closeModal_1654,
      evh_7786837829063213_success_1653,
      act_7786837829063213_request_1652,
      evh_7786837829063213_click_1651,
      evh_7786837829063213_click,
      submit: act_7786837829063213_request_1652,
    }
  }
}

Vue.component("FileSelectorModal", FileSelectorModal);

</script>