var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { alignItems: "center" } },
    [
      _vm.noLink || !_vm.linkStyleUnderline
        ? _c("ItpText", {
            staticClass: "mr-2",
            attrs: { strong: !_vm.noStrong, text: _vm.item.serial_number }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.noLink && _vm.linkStyleUnderline
        ? _c(
            "ItpLink",
            {
              attrs: {
                to: { name: "app.product", params: { id: _vm.item.id } }
              }
            },
            [
              _c("ItpText", {
                staticClass: "mr-2",
                attrs: { strong: !_vm.noStrong, text: _vm.item.serial_number }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.noLink && _vm.linkStyleIcon && !_vm.linkStyleUnderline
        ? _c("ItpLink", {
            attrs: {
              icon: _vm.$config.externalLinkIcon,
              to: { name: "app.product", params: { id: _vm.item.id } }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }