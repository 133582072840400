<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Bank accounts'|pgettext('CompanyBankAccounts') }}
      </strong>
      <ItpButton
        :text="'Add bank account...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!parent.isReadOnly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <b-list-group-item
        v-if="!items || !items.length"
      >
        <span>
          {{ 'No bank accounts have been added yet.'|gettext }}
        </span>
      </b-list-group-item>
      <b-list-group-item
        :key="item.id"
        class="d-flex flex-column"
        v-for="item in _.orderBy(items, ['created_at'], ['desc'])"
      >
        <ItpBox
          spacing="pb-1"
        >
          <UcCompanyBankAccountCard
            :item="item"
            class="mb-2"
          >
          </UcCompanyBankAccountCard>
          <b-dropdown
            size="sm"
            toggle-class="btn-default-borderless"
            no-caret
            variant="none"
            class="ml-auto mb-auto"
            v-if="!item.locked"
          >
            <ItpIcon
              fa="ellipsis-v"
              class="mx-1"
              slot="button-content"
            >
            </ItpIcon>
            <b-dropdown-item-button
              @click.prevent.stop="evh_294572930540096_clickPreventStop($event, {item})"
            >
              <ItpText
                :text="'Edit'|pgettext('Command')"
              >
              </ItpText>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="!item.locked"
              @click.prevent.stop="evh_3365375699802249_clickPreventStop($event, {item})"
            >
              <ItpText
                :text="'Delete'|pgettext('Command')"
              >
              </ItpText>
            </b-dropdown-item-button>
          </b-dropdown>
        </ItpBox>
        <DIV
          class="d-flex align-items-end"
        >
          <ItpText
            muted
            small
          >
            {{ (item.created_at || item.created_at)|datetime }}
          </ItpText>
          <small
            class="text-muted text-nowrap mx-2"
          >
            {{ (item.updated_by || item.created_by)|username }}
          </small>
          <DIV
            class="ml-2 d-flex align-self-end flex-grow-1 justify-content-end"
          >
            <ItpIcon
              :title="'Locked'|gettext"
              name="fa-lock"
              class="text-muted"
              v-if="item.locked"
            >
            </ItpIcon>
          </DIV>
        </DIV>
      </b-list-group-item>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompanyBankAccounts extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get items() {
    if (this.loaded) {

      return this.parent.bank_accounts.data
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_6574_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6574, alias=create
    return {
      name: "CompanyBankAccount",
      props: {
        parent: this.parent,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_294572930540096_showModal_6576_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_294572930540096_showModal_6576, alias=undefined
    return {
      name: "CompanyBankAccount",
      props: {
        parent: this.parent,
        item: $event.scope.item,
      }
      ,
    }
  }

  async evh_294572930540096_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_294572930540096_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3365375699802249_rest_6578_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_3365375699802249_rest_6578, alias=undefined
    return {
      method: "delete",
      container: this.parent.bank_accounts,
      resource: $event.scope.item,
    }
  }

  async act_3365375699802249_rest_6578_getConfirm($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_3365375699802249_rest_6578, alias=undefined
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_3365375699802249_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3365375699802249_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6572: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6571: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_showModal_6574: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6573: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_294572930540096_showModal_6576: actions.ShowModalAction;
    evh_294572930540096_clickPreventStop_6575: actions.EventHandlerImpl;
    evh_294572930540096_clickPreventStop: actions.EventHandlerGroup;
    act_3365375699802249_rest_6578: actions.RestAction;
    evh_3365375699802249_clickPreventStop_6577: actions.EventHandlerImpl;
    evh_3365375699802249_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6572 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6571 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6572,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6571],
      }
    );
    const act_8320016629450276_showModal_6574 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6574_getActionArgs,
        displayName: "create",
        events: [],
      }
    );
    const evh_8320016629450276_clickPreventStop_6573 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6574,
        event: "click.prevent.stop",
        displayName: "create",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6573],
      }
    );
    const act_294572930540096_showModal_6576 = new actions.ShowModalAction(
      {
        actionArgs: this.act_294572930540096_showModal_6576_getActionArgs,
        events: [],
      }
    );
    const evh_294572930540096_clickPreventStop_6575 = new actions.EventHandlerImpl(
      {
        action: act_294572930540096_showModal_6576,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_294572930540096_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_294572930540096_clickPreventStop_6575],
      }
    );
    const act_3365375699802249_rest_6578 = new actions.RestAction(
      {
        actionArgs: this.act_3365375699802249_rest_6578_getActionArgs,
        confirm: this.act_3365375699802249_rest_6578_getConfirm,
        events: [],
      }
    );
    const evh_3365375699802249_clickPreventStop_6577 = new actions.EventHandlerImpl(
      {
        action: act_3365375699802249_rest_6578,
        event: "click.prevent.stop",
        displayName: "rest",
      }
    );
    const evh_3365375699802249_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3365375699802249_clickPreventStop_6577],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6572,
      evh_7315092382398562_reload_6571,
      evh_7315092382398562_reload,
      act_8320016629450276_showModal_6574,
      evh_8320016629450276_clickPreventStop_6573,
      evh_8320016629450276_clickPreventStop,
      act_294572930540096_showModal_6576,
      evh_294572930540096_clickPreventStop_6575,
      evh_294572930540096_clickPreventStop,
      act_3365375699802249_rest_6578,
      evh_3365375699802249_clickPreventStop_6577,
      evh_3365375699802249_clickPreventStop,
      create: act_8320016629450276_showModal_6574,
    }
  }
}

Vue.component("UcCompanyBankAccounts", UcCompanyBankAccounts);

</script>