<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceLineMeterReadingSelector"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded && services"
  >
    <BListGroup
      v-if="loaded"
    >
      <BListGroupItem
        v-if="!services.length"
      >
        <span>
          {{ 'No rental services.'|pgettext('MeterRentalServices') }}
        </span>
      </BListGroupItem>
      <BListGroupItem
        :key="item.id"
        class="d-flex flex-column"
        v-if="services && services.length"
        v-for="item in services"
      >
        <ItpPanel
          collapsible
          initialState="collapsed"
        >
          <DIV
            class="w-100"
            slot="caption"
          >
            <ItpBox
              alignItems="start"
              class="w-100"
            >
              <UcInactive
                :value="item.inactive"
              >
              </UcInactive>
              <ItpBox
                direction="column"
                class="w-100"
              >
                <ItpBox
                  class="W-100"
                >
                  <ItpBox
                    class="col-md-5"
                  >
                    <ItpText
                      :strikethrough="isServiceStale(item)"
                      class="font-weight-bold mr-2"
                    >
                        {{ item.service && item.service.name }} ({{ item.billing_unit }})
                    </ItpText>
                  </ItpBox>
                  <ItpBox
                    class="col-md-4"
                  >
                    <UcValidityPeriod
                      :from="item.valid_from"
                      :to="item.valid_to"
                      class="pl-3"
                    >
                    </UcValidityPeriod>
                  </ItpBox>
                  <ItpBox
                    alignItems="center"
                    direction="column"
                    class="col-md-3"
                  >
                    <ItpBadge
                      :title="'Billing multiplier'|gettext"
                      no-box-shadow
                      variant="light"
                      class="mx-3"
                    >
                      <ItpIcon
                        name="fa-times"
                      >
                      </ItpIcon>
                      <ItpText
                        :text="item.meter_factor"
                      >
                      </ItpText>
                    </ItpBadge>
                    <ItpBadge
                      variant="warning"
                      :text="'Non billable'|pgettext('RentalService')"
                      no-box-shadow
                      class="mx-3 mt-2"
                      v-if="item.non_billable"
                    >
                    </ItpBadge>
                  </ItpBox>
                </ItpBox>
                <ItpBox
                  class="W-100"
                >
                  <ItpText
                    :text="item.notes"
                    pre
                    v-if="item.notes"
                  >
                  </ItpText>
                </ItpBox>
                <ItpBox>
                  <UcCompactMeterDisplay
                    :payload="item.meter"
                  >
                  </UcCompactMeterDisplay>
                </ItpBox>
              </ItpBox>
            </ItpBox>
            <ul
              class="list-group my-2"
              v-if="item.consumptions && item.consumptions.length"
            >
              <li
                :key="cons.$key"
                :class="'list-group-item list-group-item-' + (item.inactive ? 'warning' : 'primary')"
                v-for="cons in item.consumptions"
              >
                <ItpBox
                  direction="row"
                >
                  <DIV>
                    <strong class="mr-2">{{ cons.from_amount }} {{ cons.from_unit }}</strong>
<small class="mr-2">({{ cons.from_date|date }})</small>
-
<strong class="mr-2">{{ cons.to_amount }} {{ cons.to_unit }}</strong>
<small class="mr-2">({{ cons.to_date|date }})</small>
=
<strong>{{ cons.diff }} {{ cons.to_unit }}</strong>

                  </DIV>
                  <ItpButton
                    size="sm"
                    class="ml-auto"
                    @click.prevent.stop="evh_1969262983629788_clickPreventStop($event, {item, cons})"
                  >
                    Select
                  </ItpButton>
                </ItpBox>
              </li>
            </ul>
          </DIV>
          <table
            class="table table-condensed table-sm"
          >
            <thead>
              <tr>
                <th>
                </th>
                <th>
                  {{ 'Value'|pgettext('InvoiceLineMeterReadingSelector|ColHead') }}
                </th>
                <th>
                  {{ 'Diff'|pgettext('InvoiceLineMeterReadingSelector|ColHead') }}
                </th>
                <th>
                  {{ 'Date'|pgettext('InvoiceLineMeterReadingSelector|ColHead') }}
                </th>
                <th>
                  {{ 'Notes'|pgettext('InvoiceLineMeterReadingSelector|ColHead') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="reading.id"
                v-for="reading in item.readings"
              >
                <td>
                  <BFormCheckbox
                    :name="reading.id"
                    v-model="reading.selected"
                    :id="reading.id"
                    @change="evh_7391797828980767_change($event, {item, reading})"
                  >
                  </BFormCheckbox>
                </td>
                <td>
                  <strong>{{ reading.value }} {{ reading.unit }}</strong>

                </td>
                <td
                  class="text-muted"
                >
                  {{ reading.diff }} {{ reading.unit }}

                </td>
                <td>
                  {{ reading.reading_date | date }} <small>{{ reading.reading_time | time }}</small>

                </td>
                <td>
                  {{ reading.notes }}

                </td>
              </tr>
            </tbody>
          </table>
        </ItpPanel>
      </BListGroupItem>
    </BListGroup>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceLineMeterReadingSelector extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getPayload(...args: any[]) {
    const [data] = args
    const fields = this.target && this.targetConfig[this.target].fieldsToSubmit
    return fields ? _.pick(data, fields) : data;
  }

  isServiceStale(...args: any[]) {
    const [service] = args;
    return service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today
  }

  onCheckChanged(...args: any[]) {
    const [service] = args;

    const selection = _
      .chain(service.readings)
      .filter({ selected: true })
      .reverse()
      .value();

    const consumptions = [];

    selection.forEach((item, index) => {
      const nextItem = selection[index + 1];
      if (nextItem == null) {
        return;
      }

      consumptions.push({
        $key: item.id + nextItem.id,
        meter: service.meter,
        from_date: item.reading_date,
        from_amount: item.value,
        from_unit: item.unit,
        to_date: nextItem.reading_date,
        to_amount: nextItem.value,
        to_unit: nextItem.unit,
        billing_multiplier: service.meter_factor,
        diff: nextItem.value - item.value,
      })
    })

    service.consumptions = consumptions;
  }

  @Prop({
    required: true,
    type: String,
  })
  rental!: string;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  @Prop({
    type: Boolean,
  })
  multiple!: boolean;

  @Watch('multiple')
  onMultiple(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("multiple")
  }

  @Prop({
    type: String,
  })
  serviceId!: string;

  @Watch('serviceId')
  onService_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("serviceId")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  services!: any;

  async $$load_services() {
    return this.$fn.fetch('query_meter_readings', {
      parameters: {
        rental: this.rental,
        service_id: this.serviceId
      },
      asResource: true
    })
      .then(data => _
        .chain(data.services)
        .sortBy(['valid_from', 'inactive'], ['desc', 'asc'])
        .map(svc => (
          svc.readings = _.reverse(svc.readings).map((p, i) => (p.selected = (i < 2), p)),
          this.onCheckChanged(svc),
          svc
        ))
        .value()
      )
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['services', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        services: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("InvoiceLineMeterReadingSelector|Title", "Choose readings"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5434_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5434, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1969262983629788_closeModal_5438_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1969262983629788_closeModal_5438, alias=undefined
    return {
      result: {
        value: $event.scope.cons,
      }
      ,
    }
  }

  async evh_1969262983629788_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1969262983629788_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7391797828980767_script_5440_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7391797828980767_script_5440, alias=undefined
    this.onCheckChanged($event.scope.item)
  }

  async act_7391797828980767_script_5440_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7391797828980767_script_5440, alias=undefined
    return {
      value: () => this.act_7391797828980767_script_5440_getActionArgs_value($event),
    }
  }

  async evh_7391797828980767_change(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7391797828980767_change.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeModal_5442_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1548630417156826_closeModal_5442, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
      }
      ,
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5434: actions.CloseModalAction;
    evh_7315092382398562_close_5433: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5436: actions.CloseComponentAction;
    evh_2248226175642056_close_5435: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1969262983629788_closeModal_5438: actions.CloseModalAction;
    evh_1969262983629788_clickPreventStop_5437: actions.EventHandlerImpl;
    evh_1969262983629788_clickPreventStop: actions.EventHandlerGroup;
    act_7391797828980767_script_5440: actions.ScriptAction;
    evh_7391797828980767_change_5439: actions.EventHandlerImpl;
    evh_7391797828980767_change: actions.EventHandlerGroup;
    act_1548630417156826_closeModal_5442: actions.CloseModalAction;
    evh_1548630417156826_clickPreventStop_5441: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5434 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5434_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5433 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5434,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5433],
      }
    );
    const act_2248226175642056_closeComponent_5436 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5435 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5436,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5435],
      }
    );
    const act_1969262983629788_closeModal_5438 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1969262983629788_closeModal_5438_getActionArgs,
        events: [],
      }
    );
    const evh_1969262983629788_clickPreventStop_5437 = new actions.EventHandlerImpl(
      {
        action: act_1969262983629788_closeModal_5438,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1969262983629788_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1969262983629788_clickPreventStop_5437],
      }
    );
    const act_7391797828980767_script_5440 = new actions.ScriptAction(
      {
        actionArgs: this.act_7391797828980767_script_5440_getActionArgs,
        events: [],
      }
    );
    const evh_7391797828980767_change_5439 = new actions.EventHandlerImpl(
      {
        action: act_7391797828980767_script_5440,
        event: "change",
        displayName: "script",
      }
    );
    const evh_7391797828980767_change = new actions.EventHandlerGroup(
      {
        handlers: [evh_7391797828980767_change_5439],
      }
    );
    const act_1548630417156826_closeModal_5442 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1548630417156826_closeModal_5442_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_5441 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeModal_5442,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_5441],
      }
    );
    return {
      act_7315092382398562_closeModal_5434,
      evh_7315092382398562_close_5433,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5436,
      evh_2248226175642056_close_5435,
      evh_2248226175642056_close,
      act_1969262983629788_closeModal_5438,
      evh_1969262983629788_clickPreventStop_5437,
      evh_1969262983629788_clickPreventStop,
      act_7391797828980767_script_5440,
      evh_7391797828980767_change_5439,
      evh_7391797828980767_change,
      act_1548630417156826_closeModal_5442,
      evh_1548630417156826_clickPreventStop_5441,
      evh_1548630417156826_clickPreventStop,
    }
  }
}

Vue.component("InvoiceLineMeterReadingSelector", InvoiceLineMeterReadingSelector);

</script>