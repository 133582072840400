"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "wasteBinReadings",
    path: "/waste-bin-readings",
    component: "WasteBinReadings"
});
let WasteBinReadings = class WasteBinReadings extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'dsParams', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_2248226175642056_contextMenu'];
    }
    beforeCreate() {
        this.$$cache_dsParams = new core_1.ComponentValueCache(this, "dsParams", "WasteBinReadings.dsParams");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            dsParams: {
                include_worksheets: false,
            },
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "timestamp",
                    name: this.$fn.pgettext("WasteBinReadings", "Reading Time"),
                    type: "command",
                    formatter: {
                        name: "chain",
                        formatters: [{
                                name: "datetime",
                            },
                            {
                                name: "link",
                                href: "#",
                                className: "command-link",
                            },
                        ],
                    },
                },
                {
                    field: "waste_bin_name",
                    name: this.$fn.pgettext("WasteBinReadings", "Bin Name"),
                    formatter: {
                        name: "routeLink",
                        to: "fixedAsset",
                        params: {
                            id: "waste_bin_id",
                        },
                    },
                },
                {
                    field: "waste_bin_number",
                    name: this.$fn.pgettext("WasteBinReadings", "Bin Number"),
                },
                {
                    field: "waste_bin_capacity",
                    name: this.$fn.pgettext("WasteBinReadings", "Capacity (liters)"),
                },
                {
                    field: "state",
                    name: this.$fn.pgettext("WasteBinReadings", "State"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "WasteBinReadingStates",
                                conversion: "StartCase",
                            },
                            {
                                name: "badge",
                                variantmap: {
                                    empty: "success",
                                    full: "warning",
                                },
                                variantMapSource: "fieldValue",
                            },
                        ],
                    },
                },
                {
                    field: "rental_name",
                    name: this.$fn.pgettext("WasteBinReadings", "Rental"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "rental",
                        params: {
                            id: "rental_id",
                        },
                    },
                },
                {
                    field: "client_number",
                    name: this.$fn.pgettext("WasteBinReadings", "Client Number"),
                    formatter: {
                        name: "routeLink",
                        to: "company",
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "client_name",
                    name: this.$fn.pgettext("WasteBinReadings", "Client Name"),
                    formatter: {
                        name: "routeLink",
                        to: "company",
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "cycle",
                    name: this.$fn.pgettext("WasteBinReadings", "Cycle"),
                },
                {
                    field: "device_id",
                    name: this.$fn.pgettext("WasteBinReadings", "Device ID"),
                },
                {
                    field: "worksheets",
                    name: this.$fn.pgettext("WasteBinReadings", "Worksheets"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        separator: ", ",
                        formatterContext: "item",
                        formatters: [{
                                name: "getvalue",
                                opts: {
                                    field: "number",
                                    source: "value",
                                },
                            },
                            {
                                name: "routeLink",
                                to: "worksheet",
                                params: {
                                    id: "id",
                                },
                            },
                        ],
                    },
                },
            ],
            b_2248226175642056_contextMenu: {
                items: [{
                        label: this.$fn.gettext("Delete"),
                        command: "remove",
                    },
                ],
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Waste Bin Readings"),
                icon: this.$config.wasteBin.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1532_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_1534_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_1538_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "wasteBinReadings",
            };
        });
    }
    act_2248226175642056_request_1536_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "waste_bins/delete_reading",
                data: { identities: [$event.data.data.id] },
            };
        });
    }
    evh_2248226175642056_contextmenuRemove(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_contextmenuRemove.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_showModal_1540_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "WasteBinReadingModal",
                props: {
                    objectId: $event.data.data.id,
                },
            };
        });
    }
    evh_2248226175642056_command_1539_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'timestamp';
        });
    }
    evh_2248226175642056_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_showModal_1542_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "CreateWorksheetsFromWasteBinReadingsModal",
                props: {
                    readings: _.map(this.selectedItems, p => p.id),
                },
            };
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    act_6705964765214521_reloadSlickgrid_1546_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "wasteBinReadings",
            };
        });
    }
    act_6705964765214521_request_1544_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "waste_bins/delete_readings",
                data: { identities: this.selectedItems.map(p => p.id) },
            };
        });
    }
    act_6705964765214521_request_1544_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                header: "_(\"Delete the selected readings?\")",
                message: this.$fn.gettext("This action cannot be undone."),
                btnOk: this.$fn.gettext("Delete"),
            };
        });
    }
    evh_6705964765214521_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1532 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1532_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1531 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1532,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1531],
        });
        const act_2248226175642056_setData_1534 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_1534_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_1533 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_1534,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_1533],
        });
        const act_2248226175642056_reloadSlickgrid_1538 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_1538_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_success_1537 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_1538,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_request_1536 = new core_1.actions.RequestAction({
            actionArgs: this.act_2248226175642056_request_1536_getActionArgs,
            events: [evh_2248226175642056_success_1537],
        });
        const evh_2248226175642056_contextmenuRemove_1535 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_request_1536,
            event: "contextmenu:remove",
            displayName: "request",
        });
        const evh_2248226175642056_contextmenuRemove = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_contextmenuRemove_1535],
        });
        const act_2248226175642056_showModal_1540 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2248226175642056_showModal_1540_getActionArgs,
            displayName: "showWasteBinReadingModal",
            events: [],
        });
        const evh_2248226175642056_command_1539 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_showModal_1540,
            event: "command",
            when: this.evh_2248226175642056_command_1539_getWhen,
            displayName: "showWasteBinReadingModal",
        });
        const evh_2248226175642056_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_command_1539],
        });
        const act_8320016629450276_showModal_1542 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_1542_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_click_1541 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_1542,
            event: "click",
            displayName: "showModal",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_1541],
        });
        const act_6705964765214521_reloadSlickgrid_1546 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_6705964765214521_reloadSlickgrid_1546_getActionArgs,
            events: [],
        });
        const evh_6705964765214521_success_1545 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadSlickgrid_1546,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_6705964765214521_request_1544 = new core_1.actions.RequestAction({
            actionArgs: this.act_6705964765214521_request_1544_getActionArgs,
            confirm: this.act_6705964765214521_request_1544_getConfirm,
            displayName: "deleteItems",
            events: [evh_6705964765214521_success_1545],
        });
        const evh_6705964765214521_click_1543 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_request_1544,
            event: "click",
            displayName: "deleteItems",
        });
        const evh_6705964765214521_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_click_1543],
        });
        return {
            act_7315092382398562_closeModal_1532,
            evh_7315092382398562_close_1531,
            evh_7315092382398562_close,
            act_2248226175642056_setData_1534,
            evh_2248226175642056_selectedRowsChanged_1533,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_reloadSlickgrid_1538,
            evh_2248226175642056_success_1537,
            act_2248226175642056_request_1536,
            evh_2248226175642056_contextmenuRemove_1535,
            evh_2248226175642056_contextmenuRemove,
            act_2248226175642056_showModal_1540,
            evh_2248226175642056_command_1539,
            evh_2248226175642056_command,
            act_8320016629450276_showModal_1542,
            evh_8320016629450276_click_1541,
            evh_8320016629450276_click,
            act_6705964765214521_reloadSlickgrid_1546,
            evh_6705964765214521_success_1545,
            act_6705964765214521_request_1544,
            evh_6705964765214521_click_1543,
            evh_6705964765214521_click,
            showWasteBinReadingModal: act_2248226175642056_showModal_1540,
            deleteItems: act_6705964765214521_request_1544,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], WasteBinReadings.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], WasteBinReadings.prototype, "onSize", null);
WasteBinReadings = __decorate([
    (0, vue_property_decorator_1.Component)()
], WasteBinReadings);
exports.default = WasteBinReadings;
vue_property_decorator_1.Vue.component("WasteBinReadings", WasteBinReadings);
