<template>
<ItpBox>
  <ItpSidebar
    default-width="400"
    scroll-y
    class="mr-2"
  >
    <UcInvoiceGeneratorControls
      ref="panel"
    >
    </UcInvoiceGeneratorControls>
  </ItpSidebar>
  <DIV
    class="h-100 w-100 overflow-hidden position-relative"
  >
    <VueSlickgrid
      name="draftInvoices"
      :resizeTrigger="resizeTrigger"
      :headerTitle="'Draft Invoices'|gettext"
      :items="$fn.slickDataSource('list_draft_invoices')"
      checkbox-row-selection
      footer-totals
      export-to-csv
      :exportToCsvFilename="'draft-invoices'|pgettext('ExportFilename')"
      :columnDefaults="b_1040167445267876_columnDefaults"
      :columns="b_1040167445267876_columns"
      class="page--grid"
      ref="grid"
      @selectedRowsChanged="evh_1040167445267876_selectedRowsChanged($event, {})"
      @command="evh_1040167445267876_command($event, {})"
    >
      <DIV
        class="flex-grow-1 d-flex"
        slot="toolbar"
      >
        <ItpButton
          icon="fa-trash"
          :text="'Delete'|gettext"
          size="sm"
          variant="light"
          :disabled="!selectedItems || !selectedItems.length"
          :spinning="actions.deleteItems.isRunning"
          class="mx-2"
          @click="evh_7789867021953041_click($event, {})"
        >
        </ItpButton>
        <JobHandlerControl
          jobType="invoice_sync"
          load-current
          :payload="() => onCreateInvoicesPayload()"
          icon="material-symbols:cloud-sync-outline-rounded"
          :text="'Create Invoices'|pgettext('DraftInvoices|Submit')"
          :disabled="!selectedItems || !selectedItems.length"
        >
        </JobHandlerControl>
        <ItpLink
          :text="'Download invoice lines'|pgettext('draftInvoices')"
          :href="$fn.urlFor('draft_invoice_lines_report')"
          button
          variant="light"
          use-auth
          class="d-inline-block mx-2"
        >
        </ItpLink>
        <PageHelp
          path="/finance/draft-invoices.html"
        >
        </PageHelp>
      </DIV>
    </VueSlickgrid>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceGenerationInvoices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };
  sse = {
    cleanup: true,
  };

  beforeCreate() {
  }

  processMessage(...args: any[]) {
    const msg = args[0];
    if (msg.event === 'InvoiceGeneratorStatus') {
      this.$refs.panel.updateStatus(msg.data);
    }
    if (msg.event === 'InvoiceCreated') {
      this.$refs.grid.dataView.addItem(msg.data);
    }
    if (msg.event === 'InvoiceUpdated') {
      this.$refs.grid.dataView.updateItem(msg.data.id, msg.data);
    }
    if (msg.event === 'InvoiceDeleted') {
      this.$refs.grid.dataView.removeItem(msg.data.id);
    }
  }

  onCreateInvoicesPayload(...args: any[]) {
    return this.$imodal
      .show({ name: 'CompleteInvoicesAlt' })
      .then(result => {
        if (!result || result.cancel || !result.value) {
          return;
        }
        return this.selectedItems.map(p => ({
          ...result.value,
          invoice_id: p.id,
        }));
      }
      )
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resizeTrigger!: any;
  selectedItems!: any;
  ts!: any;
  ux!: any;
  b_1040167445267876_columnDefaults!: any;
  b_1040167445267876_columns!: any;
  dataMembers = ['resizeTrigger', 'selectedItems', 'ts', 'ux', 'b_1040167445267876_columnDefaults', 'b_1040167445267876_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resizeTrigger: 0,
        selectedItems: [
        ]
        ,
        ts: null,
        ux: null,
        b_1040167445267876_columnDefaults: {
          sortable: true,
          filterable: true,
          resizable: true,
        }
        ,
        b_1040167445267876_columns: [{
          field: "contract_number",
          name: this.$fn.pgettext("InvoiceBrowserView", "Contract"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "contract",
            navigateOnIconClick: true,
            params: {
              id: "contract_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "contract_valid_from",
          name: this.$fn.pgettext("InvoiceBrowserView", "Contract valid from"),
          type: "date",
        }
          ,
        {
          field: "contract_valid_to",
          name: this.$fn.pgettext("InvoiceBrowserView", "Contract valid to"),
          type: "date",
        }
          ,
        {
          field: "invoice_number",
          name: this.$fn.pgettext("InvoiceBrowserView", "Invoice Number"),
          type: "command",
          formatter: {
            name: "link",
            href: "#",
          }
          ,
        }
          ,
        {
          field: "client_name",
          name: this.$fn.pgettext("InvoiceBrowserView", "Client"),
          formatter: {
            name: "routeLink",
            to: "company",
            navigateOnIconClick: true,
            params: {
              id: "client_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "rental_name",
          name: this.$fn.pgettext("InvoiceBrowserView", "Rental"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "rental",
            navigateOnIconClick: true,
            params: {
              id: "rental_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "site_name",
          name: this.$fn.pgettext("InvoiceBrowserView", "Site"),
          type: "command",
          formatter: {
            name: "routeLink",
            to: "property",
            navigateOnIconClick: true,
            params: {
              id: "site_id",
            }
            ,
          }
          ,
        }
          ,
        {
          field: "net_amount",
          name: this.$fn.pgettext("InvoiceBrowserView", "Net"),
          type: "number",
          formatter: {
            name: "number",
          }
          ,
        }
          ,
        {
          field: "vat_amount",
          name: this.$fn.pgettext("InvoiceBrowserView", "VAT"),
          type: "number",
          formatter: {
            name: "number",
          }
          ,
        }
          ,
        {
          field: "amount",
          name: this.$fn.pgettext("InvoiceBrowserView", "Amount"),
          type: "number",
          formatter: {
            name: "number",
          }
          ,
        }
          ,
        {
          field: "currency",
          name: this.$fn.pgettext("InvoiceBrowserView", "Currency"),
        }
          ,
        {
          field: "invoice_date",
          name: this.$fn.pgettext("InvoiceBrowserView", "Invoice date"),
          type: "date",
        }
          ,
        {
          field: "fulfillment_date",
          name: this.$fn.pgettext("InvoiceBrowserView", "Fulfillment date"),
          type: "date",
        }
          ,
        {
          field: "due_date",
          name: this.$fn.pgettext("InvoiceBrowserView", "Due date"),
          type: "date",
        }
          ,
        {
          field: "invoice_status",
          name: this.$fn.pgettext("InvoiceBrowserView", "Status"),
          formatter: {
            name: "chain",
            mode: "mapReduce",
            formatters: [{
              name: "gettext",
              context: "InvoiceStatus",
              conversion: "StartCase",
              filterInput: true,
            }
              ,
            {
              name: "badge",
              variantMapSource: "fieldValue",
              variantmap: {
                draft: "light",
                approved: "success",
                error: "danger",
                sync_pending: "secondary",
                sync_job_created: "primary",
                sync_in_progress: "warning",
                sync_error: "danger",
                completed: "success",
              }
              ,
            }
              ,
            ]
            ,
          }
          ,
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_1040167445267876_setData_5164_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_5164, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1040167445267876_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_showModal_5166_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_1040167445267876_showModal_5166, alias=undefined
    return {
      name: "Invoice",
      props: {
        oid: $event.data.data.id,
        modal: true,
      }
      ,
    }
  }

  async evh_1040167445267876_command_5165_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_command_5165, alias=undefined
    return $event.data.column.id === 'invoice_number'
  }

  async evh_1040167445267876_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_command.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_reloadSlickgrid_5170_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_7789867021953041_reloadSlickgrid_5170, alias=undefined
    return {
      grid: "draftInvoices",
    }
  }

  async act_7789867021953041_request_5168_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7789867021953041_request_5168, alias=deleteItems
    return {
      operation: "delete_draft_invoices",
      data: {
        invoices: this.selectedItems.map(p => p.id)
      },
    }
  }

  async evh_7789867021953041_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1040167445267876_setData_5164: actions.SetDataAction;
    evh_1040167445267876_selectedRowsChanged_5163: actions.EventHandlerImpl;
    evh_1040167445267876_selectedRowsChanged: actions.EventHandlerGroup;
    act_1040167445267876_showModal_5166: actions.ShowModalAction;
    evh_1040167445267876_command_5165: actions.EventHandlerImpl;
    evh_1040167445267876_command: actions.EventHandlerGroup;
    act_7789867021953041_reloadSlickgrid_5170: actions.ReloadSlickgridAction;
    evh_7789867021953041_success_5169: actions.EventHandlerImpl;
    act_7789867021953041_request_5168: actions.RequestAction;
    evh_7789867021953041_click_5167: actions.EventHandlerImpl;
    evh_7789867021953041_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1040167445267876_setData_5164 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_5164_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_selectedRowsChanged_5163 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_5164,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_1040167445267876_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_selectedRowsChanged_5163],
      }
    );
    const act_1040167445267876_showModal_5166 = new actions.ShowModalAction(
      {
        actionArgs: this.act_1040167445267876_showModal_5166_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_command_5165 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_showModal_5166,
        event: "command",
        when: this.evh_1040167445267876_command_5165_getWhen,
        displayName: "showModal",
      }
    );
    const evh_1040167445267876_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_command_5165],
      }
    );
    const act_7789867021953041_reloadSlickgrid_5170 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_7789867021953041_reloadSlickgrid_5170_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_5169 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_reloadSlickgrid_5170,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_7789867021953041_request_5168 = new actions.RequestAction(
      {
        actionArgs: this.act_7789867021953041_request_5168_getActionArgs,
        displayName: "deleteItems",
        events: [evh_7789867021953041_success_5169],
      }
    );
    const evh_7789867021953041_click_5167 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_request_5168,
        event: "click",
        displayName: "deleteItems",
      }
    );
    const evh_7789867021953041_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_click_5167],
      }
    );
    return {
      act_1040167445267876_setData_5164,
      evh_1040167445267876_selectedRowsChanged_5163,
      evh_1040167445267876_selectedRowsChanged,
      act_1040167445267876_showModal_5166,
      evh_1040167445267876_command_5165,
      evh_1040167445267876_command,
      act_7789867021953041_reloadSlickgrid_5170,
      evh_7789867021953041_success_5169,
      act_7789867021953041_request_5168,
      evh_7789867021953041_click_5167,
      evh_7789867021953041_click,
      deleteItems: act_7789867021953041_request_5168,
    }
  }
}

Vue.component("UcInvoiceGenerationInvoices", UcInvoiceGenerationInvoices);

</script>