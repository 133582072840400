<template>
<component
  :is="badge ? 'ItpBadge' : 'DIV'"
  :class="b_7315092382398562_class"
  :noBoxShadow="!!badge"
>
  <ItpBox
    alignItems="center"
  >
    <ItpIcon
      :name="icon"
      :title="title"
      class="mr-2"
      v-if="!noIcon"
    >
    </ItpIcon>
    <ItpText
      strong
      :text="text"
      class="mr-2"
    >
    </ItpText>
    <ItpLink
      :icon="$config.externalLinkIcon"
      :to="linkTo"
      v-if="!noLink"
    >
    </ItpLink>
  </ItpBox>
</component>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  colorCode!: string;

  @Watch('colorCode')
  onColor_code(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("colorCode")
  }

  @Prop({
    type: Boolean,
  })
  badge!: boolean;

  @Watch('badge')
  onBadge(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("badge")
  }

  @Prop({
    type: Boolean,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  @Prop({
    type: String,
  })
  title!: string;

  @Watch('title')
  onTitle(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("title")
  }

  @Prop({
    type: String,
  })
  icon!: string;

  @Watch('icon')
  onIcon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("icon")
  }

  @Prop({
    type: String,
  })
  text!: string;

  @Watch('text')
  onText(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("text")
  }

  @Prop({
    type: Object,
  })
  linkTo!: object;

  @Watch('linkTo')
  onLink_to(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("linkTo")
  }

  ux!: any;
  b_7315092382398562_class!: any;

  async $$load_b_7315092382398562_class() {
    return ["app-minicard"
      ,
      this.badge ? `bg-${this.colorCode}-100 border border-${this.colorCode}-500` : null
      ,
    ]
  }

  dataMembers = ['ux', 'b_7315092382398562_class'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_class: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("MiniCard", MiniCard);

</script>