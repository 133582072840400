<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <legend
    class="text-muted"
  >
    {{ 'General'|pgettext('Property') }}
  </legend>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="owner_id"
    :label="'Own company'|pgettext('Property')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormSelect2
      name="owner_id"
      v-model="property.owner_id"
      :dataSource="b_1040167445267876_dataSource"
      prefetch
      valueField="id"
      textField="name"
      required
      optionTemplate="<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>"
      use-option-template-for-selected-option
    >
    </ItpFormSelect2>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="name"
    :label="'Name'|pgettext('Property')"
    :labelAlign="labelAlign"
    required
  >
    <ItpFormInput
      name="name"
      v-model="property.name"
      required
      autofocus
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="4"
    name="code"
    :label="'Code'|pgettext('Property')"
    :labelAlign="labelAlign"
    :required="isPropertyUnit"
    v-if="isPropertyUnit"
  >
    <ItpFormInput
      name="code"
      v-model="property.code"
      :required="isPropertyUnit"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Description'|pgettext('Property')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="description"
      v-model="property.description"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="description"
    :label="'Type'|pgettext('Property')"
    :labelAlign="labelAlign"
  >
    <ItpFormSelect
      name="property_type"
      v-model="property.property_type"
      :options="types"
      valueField="name"
      textField="name"
    >
    </ItpFormSelect>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    contentColsMd="8"
    name="location_code"
    :label="'Location'|pgettext('Property')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="location_code"
      v-model="property.location_code"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="lot_number"
    :label="'Lot number'|pgettext('Property')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="lot_number"
      v-model="property.lot_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    name="house_number"
    :label="'Building number'|pgettext('Property')"
    :labelAlign="labelAlign"
  >
    <ItpFormInput
      name="house_number"
      v-model="property.house_number"
    >
    </ItpFormInput>
  </ItpFormGroup>
  <ItpFormGroup
    labelColsMd="4"
    :label="'Area'|pgettext('Property')"
    :labelAlign="labelAlign"
    contentClass="input-group"
  >
    <ItpFormInput
      type="number"
      name="area"
      v-model="property.area"
      class="col-8"
    >
    </ItpFormInput>
    <ItpFormSelect
      name="unit_of_area"
      v-model="property.unit_of_area"
      :options="units"
      class="col-4"
    >
    </ItpFormSelect>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetPropertyGeneralData extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  property!: object;

  @Watch('property')
  onProperty(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("property")
  }

  labelAlign!: any;
  units!: any;
  types!: any;

  async $$load_types() {
    return this.$fn.fetch('list_property_types').then(p => _.orderBy(p, ['name']))
  }

  ux!: any;
  b_1040167445267876_dataSource!: any;
  dataMembers = ['labelAlign', 'units', 'types', 'ux', 'b_1040167445267876_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        labelAlign: "right",
        units: ["ha"
          ,
          "m2"
          ,
        ]
        ,
        types: null,
        ux: null,
        b_1040167445267876_dataSource: {
          name: "suggest_company",
          parameters: {
            role: "own",
          }
          ,
        }
        ,
      },
    }
  }

  get isPropertyUnit() {
    if (this.loaded) {

      return this.property.object === 'properyt_unit'
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5914: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5913: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5914 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5913 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5914,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5913],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5914,
      evh_7315092382398562_reload_5913,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetPropertyGeneralData", FieldsetPropertyGeneralData);

</script>