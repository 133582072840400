import { render, staticRenderFns } from "./UcInvoiceStatusBadge.yaml?vue&type=template&id=cbd26b3a&component"
import script from "./UcInvoiceStatusBadge.yaml?vue&type=script&lang=ts&component"
export * from "./UcInvoiceStatusBadge.yaml?vue&type=script&lang=ts&component"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports