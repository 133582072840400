<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <table
      slot="content"
    >
      <tbody>
        <tr>
          <td>
            <ItpText
              :text="'Net'|gettext"
              spacing="p-2"
              uppercase
            >
            </ItpText>
          </td>
          <td
            class="text-right"
          >
            <ItpText
              :text="totals.subtotal|number"
              spacing="p-2"
            >
            </ItpText>
          </td>
        </tr>
        <tr>
          <td>
            <ItpText
              :text="'VAT'|gettext"
              spacing="p-2"
              small
              muted
              uppercase
            >
            </ItpText>
          </td>
        </tr>
        <tr
          v-for="tax in totals.taxes"
        >
          <td>
            <ItpText
              :text="tax.tax_code"
              spacing="p-2"
              small
              muted
              uppercase
            >
            </ItpText>
          </td>
          <td
            class="text-right"
          >
            <ItpText
              :text="tax.amount|number"
              spacing="p-2"
              muted
              small
            >
            </ItpText>
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
          >
            <hr>
            </hr>
          </td>
        </tr>
        <tr>
          <td>
            <ItpText
              :text="'Total'|gettext"
              spacing="p-2"
              strong
              uppercase
            >
            </ItpText>
          </td>
          <td
            class="text-right"
          >
            <ItpText
              :text="totals.total|number"
              spacing="p-2"
              strong
            >
            </ItpText>
          </td>
        </tr>
      </tbody>
    </table>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceTotals extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  totals!: object;

  @Watch('totals')
  onTotals(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("totals")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5542: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5541: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5542 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5541 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5542,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5541],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5542,
      evh_7315092382398562_reload_5541,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcInvoiceTotals", UcInvoiceTotals);

</script>