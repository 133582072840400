<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ resourceTitle }}
    </ItpText>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <UcInvoiceTypeBadge
    :value="invoice.invoice_type"
    :kind="invoice.invoice_kind"
  >
  </UcInvoiceTypeBadge>
  <UcInvoiceStatusBadge
    :value="invoice.status"
  >
  </UcInvoiceStatusBadge>
  <DIV
    v-if="invoice.tags && invoice.tags.length"
  >
    <span
      class="badge badge-secondary mx-1 shadow-none"
      v-for="tag in invoice.tags"
    >
      {{ tag }}
    </span>
  </DIV>
  <DIV
    :title="'Closed'|gettext"
    v-if="invoice.isReadOnly"
  >
    <ItpIcon
      :icon="$config.lockIcon"
    >
    </ItpIcon>
  </DIV>
  <ItpBox
    direction="column"
    v-if="invoice.reference_invoice"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Reference invoice'|pgettext('Invoice') }}
    </ItpText>
    <UcInvoiceMiniCard
      :item="invoice.reference_invoice"
      no-icon
    >
    </UcInvoiceMiniCard>
  </ItpBox>
  <ItpBox
    direction="column"
    v-if="invoice.referencing_invoices && invoice.referencing_invoices.length"
  >
    <ItpText
      muted
      class="mb-0"
    >
      {{ 'Referencing invoices'|pgettext('Invoice') }}
    </ItpText>
    <ItpBox>
      <UcInvoiceMiniCard
        :key="obj.id"
        :item="obj"
        no-icon
        class="mr-2"
        v-for="obj in invoice.referencing_invoices"
      >
      </UcInvoiceMiniCard>
    </ItpBox>
  </ItpBox>
  <Icon
    :icon="'flagpack:' + invoice.language"
    :title="'Invoice Language'|pgettext('Invoice')"
    v-if="!invoice.isNew"
  >
  </Icon>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <BtnSyncInvoice
      :invoice="invoice"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_8680084583475136_success($event, {})"
    >
    </BtnSyncInvoice>
    <BtnSyncResource
      :resource="invoice"
      target="irent"
      confirmMessage="A számlát csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a számlát már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @success="evh_3282321863127091_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      :icon="$config.editIcon"
      :tooltip="'Edit'|gettext"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew && !invoice.isReadOnly"
      @click.prevent.stop="evh_2386378572842701_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!invoice.isNew"
      @click.prevent.stop="evh_6305342467955186_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:printer-one"
      :href="invoice.print_url"
      :tooltip="'Open print document'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.print_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="invoice.preview_url"
      :tooltip="'Open preview'|pgettext('Invoice')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="invoice.preview_url"
    >
    </ItpButton>
    <ItpButton
      variant="light"
      icon="icon-park-outline:log"
      :tooltip="'Show log messages'|pgettext('Invoice')"
      class="ml-auto mr-2"
      v-if="invoice.log_messages"
      @click.prevent.stop="evh_6493473528837603_clickPreventStop($event, {})"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      no-caret
      class="border"
      v-if="!invoice.isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <ItpButton
        dropdown-item
        :text="'Register invoice'|pgettext('Invoice')"
        :tooltip="'Add this invoice to the invoice register'|pgettext('Invoice')"
        variant="default"
        v-if="invoiceIsRegistrable"
        @click="evh_5796712150329752_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Copy'|pgettext('BtnCopyResource')"
        :tooltip="'Create new invoice from this one'|pgettext('Invoice')"
        variant="default"
        @click="evh_4125470329773403_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        :text="'Create storno'|pgettext('Invoice')"
        :tooltip="createStornoTooltip"
        variant="default"
        :disabled="!invoiceIsReversible"
        v-if="!invoice.isNew"
        @click="evh_3785918277944093_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        dropdown-item
        variant="default"
        :text="'Create corrector'|pgettext('Invoice')"
        :tooltip="createCorrectorTooltip"
        :disabled="!invoiceIsModifiable"
        v-if="!invoice.isNew"
        @click="evh_2191919750581405_click($event, {})"
      >
      </ItpButton>
      <BtnLockResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Lock invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_3699289115122175_success($event, {})"
      >
      </BtnLockResource>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="invoice"
        :tooltip="'Delete invoice'|pgettext('Invoice')"
        v-if="!invoice.isNew && !invoice.isReadOnly"
        @success="evh_5417874158095965_success($event, {})"
      >
      </BtnDeleteResource>
      <hr>
      </hr>
      <ItpButton
        dropdown-item
        :text="'History'|pgettext('BtnResourceHistory')"
        :tooltip="'Invoice history'|pgettext('Invoice')"
        variant="default"
        @click.prevent.stop="evh_8518465037810560_clickPreventStop($event, {})"
      >
      </ItpButton>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
  })
  invoiceNumber!: string;

  @Watch('invoiceNumber')
  onInvoice_number(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoiceNumber")
  }

  @Prop({
    required: true,
    type: Object,
  })
  formContext!: object;

  @Watch('formContext')
  onForm_context(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formContext")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
          invoiceKind: {
            incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
            outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
          }
          ,
          createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
          createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
        }
        ,
        ux: null,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.messages.invoiceKind[this.invoice.invoice_kind]
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number)
    }
    return null;
  }

  get createCorrectorTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip
    }
    return null;
  }

  get createStornoTooltip() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip
    }
    return null;
  }

  get invoiceIsReversible() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.reversible.result')
    }
    return null;
  }

  get invoiceIsModifiable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.modifiable.result')
    }
    return null;
  }

  get invoiceIsRegistrable() {
    if (this.loaded) {

      return _.get(this.invoice, 'meta.registrable.result')
    }
    return null;
  }

  async act_8680084583475136_emit_5498_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8680084583475136_emit_5498, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8680084583475136_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
  }

  async act_3282321863127091_emit_5500_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3282321863127091_emit_5500, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3282321863127091_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
  }

  async act_2386378572842701_emit_5504_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2386378572842701_emit_5504, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_2386378572842701_close_5503_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2386378572842701_close_5503, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_2386378572842701_showModal_5502_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2386378572842701_showModal_5502, alias=undefined
    return {
      name: "InvoiceEditor",
      props: {
        invoice: _.cloneDeep(this.invoice),
      }
      ,
    }
  }

  async evh_2386378572842701_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6305342467955186_emit_5506_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6305342467955186_emit_5506, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_6305342467955186_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6493473528837603_showModal_5508_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_6493473528837603_showModal_5508, alias=undefined
    return {
      name: "InvoiceLogMessageViewer",
      props: {
        invoiceId: this.invoice.id,
      }
      ,
    }
  }

  async evh_6493473528837603_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5796712150329752_emit_5512_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5796712150329752_emit_5512, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_5796712150329752_close_5511_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_5796712150329752_close_5511, alias=undefined
    return $event.data.modalResult.ok
  }

  async act_5796712150329752_showModal_5510_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5796712150329752_showModal_5510, alias=registerInvoice
    return {
      name: "InvoiceRegisterEntry",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_5796712150329752_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
  }

  async act_4125470329773403_emit_5516_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4125470329773403_emit_5516, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_4125470329773403_request_5514_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_4125470329773403_request_5514, alias=createInvoicecopy
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
    }
  }

  async evh_4125470329773403_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
  }

  async act_3785918277944093_emit_5520_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3785918277944093_emit_5520, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_3785918277944093_request_5518_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_3785918277944093_request_5518, alias=createStornoInvoice
    return {
      operation: "create_invoice",
      data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
    }
  }

  async evh_3785918277944093_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
  }

  async act_2191919750581405_emit_5524_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2191919750581405_emit_5524, alias=undefined
    return {
      event: "created",
      value: $event.data.response.data,
    }
  }

  async act_2191919750581405_request_5522_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_2191919750581405_request_5522, alias=createCorrectorIvoice
    return {
      operation: "create_invoice",
      data: {
        invoice_id: this.invoice.id,
        invoice_type: 'correction',
        invoice_lines: _.map(this.formContext.selectedInvoiceLines, p => p.id).join(',')
      },
    }
  }

  async evh_2191919750581405_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2191919750581405_click.executeFromDOM(this, event, scope);
  }

  async act_3699289115122175_emit_5526_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3699289115122175_emit_5526, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_3699289115122175_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
  }

  async act_5417874158095965_emit_5528_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_5417874158095965_emit_5528, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_5417874158095965_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5417874158095965_success.executeFromDOM(this, event, scope);
  }

  async act_8518465037810560_showModal_5530_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8518465037810560_showModal_5530, alias=undefined
    return {
      name: "entityEvents",
      props: {
        entityId: this.invoice.id,
      }
      ,
    }
  }

  async evh_8518465037810560_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8518465037810560_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8680084583475136_emit_5498: actions.EmitAction;
    evh_8680084583475136_success_5497: actions.EventHandlerImpl;
    evh_8680084583475136_success: actions.EventHandlerGroup;
    act_3282321863127091_emit_5500: actions.EmitAction;
    evh_3282321863127091_success_5499: actions.EventHandlerImpl;
    evh_3282321863127091_success: actions.EventHandlerGroup;
    act_2386378572842701_emit_5504: actions.EmitAction;
    evh_2386378572842701_close_5503: actions.EventHandlerImpl;
    act_2386378572842701_showModal_5502: actions.ShowModalAction;
    evh_2386378572842701_clickPreventStop_5501: actions.EventHandlerImpl;
    evh_2386378572842701_clickPreventStop: actions.EventHandlerGroup;
    act_6305342467955186_emit_5506: actions.EmitAction;
    evh_6305342467955186_clickPreventStop_5505: actions.EventHandlerImpl;
    evh_6305342467955186_clickPreventStop: actions.EventHandlerGroup;
    act_6493473528837603_showModal_5508: actions.ShowModalAction;
    evh_6493473528837603_clickPreventStop_5507: actions.EventHandlerImpl;
    evh_6493473528837603_clickPreventStop: actions.EventHandlerGroup;
    act_5796712150329752_emit_5512: actions.EmitAction;
    evh_5796712150329752_close_5511: actions.EventHandlerImpl;
    act_5796712150329752_showModal_5510: actions.ShowModalAction;
    evh_5796712150329752_click_5509: actions.EventHandlerImpl;
    evh_5796712150329752_click: actions.EventHandlerGroup;
    act_4125470329773403_emit_5516: actions.EmitAction;
    evh_4125470329773403_success_5515: actions.EventHandlerImpl;
    act_4125470329773403_request_5514: actions.RequestAction;
    evh_4125470329773403_click_5513: actions.EventHandlerImpl;
    evh_4125470329773403_click: actions.EventHandlerGroup;
    act_3785918277944093_emit_5520: actions.EmitAction;
    evh_3785918277944093_success_5519: actions.EventHandlerImpl;
    act_3785918277944093_request_5518: actions.RequestAction;
    evh_3785918277944093_click_5517: actions.EventHandlerImpl;
    evh_3785918277944093_click: actions.EventHandlerGroup;
    act_2191919750581405_emit_5524: actions.EmitAction;
    evh_2191919750581405_success_5523: actions.EventHandlerImpl;
    act_2191919750581405_request_5522: actions.RequestAction;
    evh_2191919750581405_click_5521: actions.EventHandlerImpl;
    evh_2191919750581405_click: actions.EventHandlerGroup;
    act_3699289115122175_emit_5526: actions.EmitAction;
    evh_3699289115122175_success_5525: actions.EventHandlerImpl;
    evh_3699289115122175_success: actions.EventHandlerGroup;
    act_5417874158095965_emit_5528: actions.EmitAction;
    evh_5417874158095965_success_5527: actions.EventHandlerImpl;
    evh_5417874158095965_success: actions.EventHandlerGroup;
    act_8518465037810560_showModal_5530: actions.ShowModalAction;
    evh_8518465037810560_clickPreventStop_5529: actions.EventHandlerImpl;
    evh_8518465037810560_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8680084583475136_emit_5498 = new actions.EmitAction(
      {
        actionArgs: this.act_8680084583475136_emit_5498_getActionArgs,
        events: [],
      }
    );
    const evh_8680084583475136_success_5497 = new actions.EventHandlerImpl(
      {
        action: act_8680084583475136_emit_5498,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_8680084583475136_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8680084583475136_success_5497],
      }
    );
    const act_3282321863127091_emit_5500 = new actions.EmitAction(
      {
        actionArgs: this.act_3282321863127091_emit_5500_getActionArgs,
        events: [],
      }
    );
    const evh_3282321863127091_success_5499 = new actions.EventHandlerImpl(
      {
        action: act_3282321863127091_emit_5500,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3282321863127091_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3282321863127091_success_5499],
      }
    );
    const act_2386378572842701_emit_5504 = new actions.EmitAction(
      {
        actionArgs: this.act_2386378572842701_emit_5504_getActionArgs,
        events: [],
      }
    );
    const evh_2386378572842701_close_5503 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_emit_5504,
        event: "close",
        when: this.evh_2386378572842701_close_5503_getWhen,
        displayName: "emit",
      }
    );
    const act_2386378572842701_showModal_5502 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2386378572842701_showModal_5502_getActionArgs,
        events: [evh_2386378572842701_close_5503],
      }
    );
    const evh_2386378572842701_clickPreventStop_5501 = new actions.EventHandlerImpl(
      {
        action: act_2386378572842701_showModal_5502,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_2386378572842701_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2386378572842701_clickPreventStop_5501],
      }
    );
    const act_6305342467955186_emit_5506 = new actions.EmitAction(
      {
        actionArgs: this.act_6305342467955186_emit_5506_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_6305342467955186_clickPreventStop_5505 = new actions.EventHandlerImpl(
      {
        action: act_6305342467955186_emit_5506,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6305342467955186_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6305342467955186_clickPreventStop_5505],
      }
    );
    const act_6493473528837603_showModal_5508 = new actions.ShowModalAction(
      {
        actionArgs: this.act_6493473528837603_showModal_5508_getActionArgs,
        events: [],
      }
    );
    const evh_6493473528837603_clickPreventStop_5507 = new actions.EventHandlerImpl(
      {
        action: act_6493473528837603_showModal_5508,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_6493473528837603_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6493473528837603_clickPreventStop_5507],
      }
    );
    const act_5796712150329752_emit_5512 = new actions.EmitAction(
      {
        actionArgs: this.act_5796712150329752_emit_5512_getActionArgs,
        events: [],
      }
    );
    const evh_5796712150329752_close_5511 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_emit_5512,
        event: "close",
        when: this.evh_5796712150329752_close_5511_getWhen,
        displayName: "emit",
      }
    );
    const act_5796712150329752_showModal_5510 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5796712150329752_showModal_5510_getActionArgs,
        displayName: "registerInvoice",
        events: [evh_5796712150329752_close_5511],
      }
    );
    const evh_5796712150329752_click_5509 = new actions.EventHandlerImpl(
      {
        action: act_5796712150329752_showModal_5510,
        event: "click",
        displayName: "registerInvoice",
      }
    );
    const evh_5796712150329752_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_5796712150329752_click_5509],
      }
    );
    const act_4125470329773403_emit_5516 = new actions.EmitAction(
      {
        actionArgs: this.act_4125470329773403_emit_5516_getActionArgs,
        events: [],
      }
    );
    const evh_4125470329773403_success_5515 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_emit_5516,
        event: "success",
        displayName: "emit",
      }
    );
    const act_4125470329773403_request_5514 = new actions.RequestAction(
      {
        actionArgs: this.act_4125470329773403_request_5514_getActionArgs,
        displayName: "createInvoicecopy",
        events: [evh_4125470329773403_success_5515],
      }
    );
    const evh_4125470329773403_click_5513 = new actions.EventHandlerImpl(
      {
        action: act_4125470329773403_request_5514,
        event: "click",
        displayName: "createInvoicecopy",
      }
    );
    const evh_4125470329773403_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4125470329773403_click_5513],
      }
    );
    const act_3785918277944093_emit_5520 = new actions.EmitAction(
      {
        actionArgs: this.act_3785918277944093_emit_5520_getActionArgs,
        events: [],
      }
    );
    const evh_3785918277944093_success_5519 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_emit_5520,
        event: "success",
        displayName: "emit",
      }
    );
    const act_3785918277944093_request_5518 = new actions.RequestAction(
      {
        actionArgs: this.act_3785918277944093_request_5518_getActionArgs,
        displayName: "createStornoInvoice",
        events: [evh_3785918277944093_success_5519],
      }
    );
    const evh_3785918277944093_click_5517 = new actions.EventHandlerImpl(
      {
        action: act_3785918277944093_request_5518,
        event: "click",
        displayName: "createStornoInvoice",
      }
    );
    const evh_3785918277944093_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3785918277944093_click_5517],
      }
    );
    const act_2191919750581405_emit_5524 = new actions.EmitAction(
      {
        actionArgs: this.act_2191919750581405_emit_5524_getActionArgs,
        events: [],
      }
    );
    const evh_2191919750581405_success_5523 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_emit_5524,
        event: "success",
        displayName: "emit",
      }
    );
    const act_2191919750581405_request_5522 = new actions.RequestAction(
      {
        actionArgs: this.act_2191919750581405_request_5522_getActionArgs,
        displayName: "createCorrectorIvoice",
        events: [evh_2191919750581405_success_5523],
      }
    );
    const evh_2191919750581405_click_5521 = new actions.EventHandlerImpl(
      {
        action: act_2191919750581405_request_5522,
        event: "click",
        displayName: "createCorrectorIvoice",
      }
    );
    const evh_2191919750581405_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2191919750581405_click_5521],
      }
    );
    const act_3699289115122175_emit_5526 = new actions.EmitAction(
      {
        actionArgs: this.act_3699289115122175_emit_5526_getActionArgs,
        events: [],
      }
    );
    const evh_3699289115122175_success_5525 = new actions.EventHandlerImpl(
      {
        action: act_3699289115122175_emit_5526,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_3699289115122175_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_3699289115122175_success_5525],
      }
    );
    const act_5417874158095965_emit_5528 = new actions.EmitAction(
      {
        actionArgs: this.act_5417874158095965_emit_5528_getActionArgs,
        events: [],
      }
    );
    const evh_5417874158095965_success_5527 = new actions.EventHandlerImpl(
      {
        action: act_5417874158095965_emit_5528,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_5417874158095965_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_5417874158095965_success_5527],
      }
    );
    const act_8518465037810560_showModal_5530 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8518465037810560_showModal_5530_getActionArgs,
        events: [],
      }
    );
    const evh_8518465037810560_clickPreventStop_5529 = new actions.EventHandlerImpl(
      {
        action: act_8518465037810560_showModal_5530,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8518465037810560_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8518465037810560_clickPreventStop_5529],
      }
    );
    return {
      act_8680084583475136_emit_5498,
      evh_8680084583475136_success_5497,
      evh_8680084583475136_success,
      act_3282321863127091_emit_5500,
      evh_3282321863127091_success_5499,
      evh_3282321863127091_success,
      act_2386378572842701_emit_5504,
      evh_2386378572842701_close_5503,
      act_2386378572842701_showModal_5502,
      evh_2386378572842701_clickPreventStop_5501,
      evh_2386378572842701_clickPreventStop,
      act_6305342467955186_emit_5506,
      evh_6305342467955186_clickPreventStop_5505,
      evh_6305342467955186_clickPreventStop,
      act_6493473528837603_showModal_5508,
      evh_6493473528837603_clickPreventStop_5507,
      evh_6493473528837603_clickPreventStop,
      act_5796712150329752_emit_5512,
      evh_5796712150329752_close_5511,
      act_5796712150329752_showModal_5510,
      evh_5796712150329752_click_5509,
      evh_5796712150329752_click,
      act_4125470329773403_emit_5516,
      evh_4125470329773403_success_5515,
      act_4125470329773403_request_5514,
      evh_4125470329773403_click_5513,
      evh_4125470329773403_click,
      act_3785918277944093_emit_5520,
      evh_3785918277944093_success_5519,
      act_3785918277944093_request_5518,
      evh_3785918277944093_click_5517,
      evh_3785918277944093_click,
      act_2191919750581405_emit_5524,
      evh_2191919750581405_success_5523,
      act_2191919750581405_request_5522,
      evh_2191919750581405_click_5521,
      evh_2191919750581405_click,
      act_3699289115122175_emit_5526,
      evh_3699289115122175_success_5525,
      evh_3699289115122175_success,
      act_5417874158095965_emit_5528,
      evh_5417874158095965_success_5527,
      evh_5417874158095965_success,
      act_8518465037810560_showModal_5530,
      evh_8518465037810560_clickPreventStop_5529,
      evh_8518465037810560_clickPreventStop,
      reload: act_6305342467955186_emit_5506,
      registerInvoice: act_5796712150329752_showModal_5510,
      createInvoicecopy: act_4125470329773403_request_5514,
      createStornoInvoice: act_3785918277944093_request_5518,
      createCorrectorIvoice: act_2191919750581405_request_5522,
    }
  }
}

Vue.component("UcInvoiceHeader", UcInvoiceHeader);

</script>