<template>
<ItpModal
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="WEmployeeModal"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      name="employee"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <UcEmployeeFieldset
        :employee="editor"
      >
      </UcEmployeeFieldset>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :form="forms.employee"
      :text="'Save'|gettext"
      type="submit"
      :spinning="actions.submit.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class WEmployeeModal extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  parent!: object;

  @Watch('parent')
  onParent(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("parent")
  }

  @Prop({
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.item || this.$fn.schemaDefaults('CreateEmployee')
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  createTitle!: any;
  editTitle!: any;
  ux!: any;
  dataMembers = ['resource', 'editor', 'createTitle', 'editTitle', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        editor: null,
        createTitle: this.$fn.pgettext("Employeees", "Add employee"),
        editTitle: this.$fn.pgettext("Employeees", "Edit employee"),
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.employeeId == null ? this.createTitle : this.editTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6582_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6582, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_6588_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_6588, alias=undefined
    return {
      result: {
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_closeComponent_6590_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_6590, alias=undefined
    return {
      result: {
      }
      ,
    }
  }

  async act_1040167445267876_rest_6586_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_6586, alias=submit
    return {
      method: "save",
      container: this.parent.employees,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6582: actions.CloseModalAction;
    evh_7315092382398562_close_6581: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6584: actions.CloseComponentAction;
    evh_2248226175642056_close_6583: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_6588: actions.CloseComponentAction;
    evh_1040167445267876_saved_6587: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_6590: actions.CloseComponentAction;
    evh_1040167445267876_deleted_6589: actions.EventHandlerImpl;
    act_1040167445267876_rest_6586: actions.RestAction;
    evh_1040167445267876_submit_6585: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_6592: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_6591: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6582 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6582_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6581 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6582,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6581],
      }
    );
    const act_2248226175642056_closeComponent_6584 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6583 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6584,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6583],
      }
    );
    const act_1040167445267876_closeComponent_6588 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_6588_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_6587 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_6588,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_closeComponent_6590 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_6590_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_deleted_6589 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_6590,
        event: "deleted",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_rest_6586 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_6586_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_saved_6587, evh_1040167445267876_deleted_6589],
      }
    );
    const evh_1040167445267876_submit_6585 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_6586,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6585],
      }
    );
    const act_1419464017721962_closeComponent_6592 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_6591 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_6592,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6591],
      }
    );
    return {
      act_7315092382398562_closeModal_6582,
      evh_7315092382398562_close_6581,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6584,
      evh_2248226175642056_close_6583,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_6588,
      evh_1040167445267876_saved_6587,
      act_1040167445267876_closeComponent_6590,
      evh_1040167445267876_deleted_6589,
      act_1040167445267876_rest_6586,
      evh_1040167445267876_submit_6585,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_6592,
      evh_1419464017721962_clickPreventStop_6591,
      evh_1419464017721962_clickPreventStop,
      submit: act_1040167445267876_rest_6586,
    }
  }
}

Vue.component("WEmployeeModal", WEmployeeModal);

</script>