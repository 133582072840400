<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpRow
    v-if="loaded"
  >
    <ItpCol
      w="12"
    >
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="5"
        name="account"
        :label="'Account'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
      >
        <UcAccountSelector
          name="account"
          cacheKey="Payment"
          v-model="payment.account"
          :accountType="payment.account.account_type"
          :readonly="isFieldReadonly('account')"
          class="mr-2"
          @input="evh_4615685607825208_input($event, {})"
        >
        </UcAccountSelector>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="transaction_title"
        :label="'Title'|pgettext('Payment')"
        :labelAlign="labelAlign"
        v-if="isFieldEnabled('transaction_title')"
      >
        <ItpFormSelect2
          name="transaction_title"
          v-model="payment.transaction_title"
          valueField="name"
          textField="name"
          :options="transactionTitles"
          taggable
          clearable
          :readonly="isFieldReadonly('transaction_title')"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="client"
        :label="'Client'|pgettext('Payment')"
        :labelAlign="labelAlign"
        v-if="isFieldEnabled('partner')"
      >
        <ItpFormSelect2
          name="client"
          v-model="payment.partner"
          bind-object
          :dataSource="b_8697745105058001_dataSource"
          optionTemplate="<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>"
          use-option-template-for-selected-option
          textField="name"
          clearable
          :readonly="isFieldReadonly('partner')"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="6"
        name="client"
        :label="'Employee'|pgettext('Payment')"
        :labelAlign="labelAlign"
        v-if="isFieldEnabled('employee')"
      >
        <ItpFormSelect2
          name="client"
          v-model="payment.employee"
          bind-object
          :options="employees"
          textField="name"
          clearable
          :readonly="isFieldReadonly('employee')"
        >
        </ItpFormSelect2>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
  <HR>
  </HR>
  <ItpRow>
    <ItpCol
      w="5"
      form
    >
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="transaction_number"
        :label="'Number'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
        v-if="isFieldEnabled('transaction_number')"
      >
        <ItpFormInput
          name="transaction_number"
          v-model="payment.transaction_number"
          :readonly="isFieldReadonly('transaction_number')"
          :placeholder="'Enter payment number'|pgettext('Payment|transaction_number|placeholder')"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="transaction_date"
        :label="'Payment Date'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
        v-if="isFieldEnabled('transaction_date')"
      >
        <ItpFormDatetime
          name="transaction_date"
          v-model="payment.transaction_date"
          :readonly="isFieldReadonly('transaction_date')"
          required
        >
        </ItpFormDatetime>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="payment_method"
        :label="'Method'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
        v-if="isFieldEnabled('payment_method')"
      >
        <ItpFormSelect
          name="payment_method"
          v-model="payment.payment_method"
          :options="paymentMethods"
          :readonly="isFieldReadonly('payment_method')"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="transaction_type"
        :label="'Payment type'|pgettext('Payment')"
        :labelAlign="labelAlign"
        required
        v-if="isFieldEnabled('transaction_type') && transactionTypes"
      >
        <ItpFormSelect
          name="transaction_type"
          v-model="payment.transaction_type"
          :options="transactionTypes"
          :readonly="isFieldReadonly('transaction_type')"
          required
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="5"
        contentColsMd="7"
        name="amount"
        :label="'Amount'|pgettext('Payment')"
        :labelAlign="labelAlign"
        :appendText="payment.currency"
        required
        v-if="isFieldEnabled('amount')"
      >
        <ItpFormInput
          autofocus
          name="amount"
          v-model="payment.amount"
          :readonly="isFieldReadonly('amount')"
          step="any"
          required
          min="0"
          mask="number"
          unmask="typed"
          scale="2"
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpCol>
    <ItpCol
      w="7"
    >
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="8"
        name="description"
        :label="'Description'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="description"
          v-model="payment.description"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="reference_number"
        :label="'Reference number'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="reference_number"
          v-model="payment.reference_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="statement_number"
        :label="'Statement number'|pgettext('Payment')"
        :labelAlign="labelAlign"
        v-if="isFieldEnabled('statement_number')"
      >
        <ItpFormInput
          name="statement_number"
          v-model="payment.statement_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        contentColsMd="4"
        name="transaction_code"
        :label="'Transaction code'|pgettext('Payment')"
        :labelAlign="labelAlign"
        v-if="isFieldEnabled('transaction_code')"
      >
        <ItpFormInput
          name="transaction_code"
          v-model="payment.transaction_code"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="4"
        name="file_number"
        :label="'File number'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="file_number"
          v-model="payment.file_number"
          :readonly="isReadOnly"
        >
        </ItpFormInput>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
  <HR>
  </HR>
  <ItpRow>
    <ItpCol
      w="12"
      form
    >
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="notes"
        :label="'Message'|pgettext('Payment')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="notes"
          v-model="payment.notes"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpCol>
  </ItpRow>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetPaymentGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  isFieldEnabled(...args: any[]) {
    const name = args[0];
    return !this.config || !this.config.fields || this.config.fields[name] != false;
  }

  isFieldReadonly(...args: any[]) {
    const name = args[0];
    return this.isReadOnly || (this.config && this.config.fields && this.config.fields[name] == 'ro');
  }

  loadEmployees(...args: any[]) {
    return this.$fn
      .fetch('list_company_employees', { parameters: { company_id: this.payment.account.owner_id } })
      .then(items => items.sort((a, b) => a.name.localeCompare(b.name)))
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  @Prop({
    type: String,
    default: "right",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  @Prop({
    required: true,
    type: Object,
  })
  config!: object;

  @Watch('config')
  onConfig(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("config")
  }

  @Prop({
    type: Object,
  })
  transactionTypes!: object;

  @Watch('transactionTypes')
  onTransaction_types(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionTypes")
  }

  transactionTitles!: any;

  async $$load_transactionTitles() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'payment' } })
      .then(data => _.map(data, p => p.name).sort((a, b) => a.localeCompare(b)))
  }

  paymentMethods!: any;

  async $$load_paymentMethods() {
    return this.$fn.fetch('list_payment_method')
      .then(data => _
        .chain(data)
        .filter(p => this.payment.account.account_type !== 'cash_book' ? !p.cash : p.cash)
        .map(p => ({
          value: p.id,
          text: this.$fn.pgettext('PaymentMethod', p.name)
        }))
        .value()
      )
  }

  employees!: any;

  async $$load_employees() {
    return this.loadEmployees()
  }

  ux!: any;
  b_8697745105058001_dataSource!: any;
  dataMembers = ['transactionTitles', 'paymentMethods', 'employees', 'ux', 'b_8697745105058001_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        transactionTitles: null,
        paymentMethods: null,
        employees: null,
        ux: null,
        b_8697745105058001_dataSource: {
          name: "suggest_company",
          parameters: {
            role: "partner",
          }
          ,
        }
        ,
      },
    }
  }

  get isReadOnly() {
    if (this.loaded) {

      return this.payment.isReadonly
    }
    return null;
  }

  get cashBookPayment() {
    if (this.loaded) {

      return this.payment.account && this.payment.account.account_type == 'cash_book'
    }
    return null;
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_4615685607825208_setData_5702_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_4615685607825208_setData_5702, alias=undefined
    return {
      path: "payment.currency",
      value: $event.data.currency,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_4615685607825208_script_5704_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4615685607825208_script_5704, alias=undefined
    this.loadEmployees().then(items => this.setData('employees', items))
  }

  async act_4615685607825208_script_5704_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4615685607825208_script_5704, alias=undefined
    return {
      value: () => this.act_4615685607825208_script_5704_getActionArgs_value($event),
    }
  }

  async act_4615685607825208_emit_5706_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4615685607825208_emit_5706, alias=undefined
    return {
      event: "accountChanged",
      value: $event.data,
    }
  }

  async evh_4615685607825208_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4615685607825208_input.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5700: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5699: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_4615685607825208_setData_5702: actions.SetDataAction;
    evh_4615685607825208_input_5701: actions.EventHandlerImpl;
    act_4615685607825208_script_5704: actions.ScriptAction;
    evh_4615685607825208_input_5703: actions.EventHandlerImpl;
    act_4615685607825208_emit_5706: actions.EmitAction;
    evh_4615685607825208_input_5705: actions.EventHandlerImpl;
    evh_4615685607825208_input: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5700 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5699 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5700,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5699],
      }
    );
    const act_4615685607825208_setData_5702 = new actions.SetDataAction(
      {
        actionArgs: this.act_4615685607825208_setData_5702_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_input_5701 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_setData_5702,
        event: "input",
        displayName: "setData",
      }
    );
    const act_4615685607825208_script_5704 = new actions.ScriptAction(
      {
        actionArgs: this.act_4615685607825208_script_5704_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_input_5703 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_script_5704,
        event: "input",
        displayName: "script",
      }
    );
    const act_4615685607825208_emit_5706 = new actions.EmitAction(
      {
        actionArgs: this.act_4615685607825208_emit_5706_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_input_5705 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_emit_5706,
        event: "input",
        displayName: "emit",
      }
    );
    const evh_4615685607825208_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_4615685607825208_input_5701, evh_4615685607825208_input_5703, evh_4615685607825208_input_5705],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5700,
      evh_7315092382398562_reload_5699,
      evh_7315092382398562_reload,
      act_4615685607825208_setData_5702,
      evh_4615685607825208_input_5701,
      act_4615685607825208_script_5704,
      evh_4615685607825208_input_5703,
      act_4615685607825208_emit_5706,
      evh_4615685607825208_input_5705,
      evh_4615685607825208_input,
    }
  }
}

Vue.component("FieldsetPaymentGeneral", FieldsetPaymentGeneral);

</script>