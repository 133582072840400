<template>
<ItpPage
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ContractServiceEditor"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="contract"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <ItpText
          strong
          :text="'Service'|pgettext('ContractServiceEditor')"
        >
        </ItpText>
        <ItpFormGroup
          name="service"
          :label="'Service'|pgettext('ContractService')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          v-if="!isServiceReadonly"
        >
          <ItpFormSelect2
            name="client"
            v-model="item.service"
            bind-object
            :options="services"
            textField="name"
            @optionSelected="evh_3054425420812800_optionSelected($event, {})"
          >
          </ItpFormSelect2>
        </ItpFormGroup>
        <ItpFormGroup
          name="name"
          :label="'Name'|pgettext('ContractService')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormInput
            name="name"
            v-model="item.name"
            required
            :readonly="isServiceReadonly"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="valid_from"
          :label="'Valid from'|pgettext('ContractService')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
        >
          <ItpFormDatetime
            name="valid_from"
            v-model="item.valid_from"
            required
            :readonly="isServiceReadonly"
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="valid_to"
          :label="'Valid to'|pgettext('ContractService')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
        >
          <ItpFormDatetime
            name="valid_to"
            v-model="item.valid_to"
            :readonly="isServiceReadonly"
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="valid_to"
          :label="'Description'|pgettext('ContractService')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
        >
          <ItpFormTextarea
            name="description"
            v-model="item.description"
            :readonly="isServiceReadonly"
            rows="5"
          >
          </ItpFormTextarea>
        </ItpFormGroup>
        <ItpButton
          :text="'Lock Service'|pgettext('Button')"
          variant="light"
          icon="fa-lock"
          :spinning="actions.lockService.isRunning"
          v-if="mode === 'editService' && !service.locked"
          @click.prevent.stop="evh_1478395556975758_clickPreventStop($event, {})"
        >
        </ItpButton>
        <hr
          v-if="displayFee"
        >
        </hr>
        <ItpText
          strong
          :text="'Fee'|pgettext('ContractServiceEditor')"
          v-if="displayFee"
        >
        </ItpText>
        <ItpFormGroup
          name="fee_valid_from"
          :label="'Valid from'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          v-if="displayFeeDates"
        >
          <ItpFormDatetime
            name="valid_from"
            v-model="feeItem.valid_from"
            required
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_valid_to"
          :label="'Valid to'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          v-if="displayFeeDates"
        >
          <ItpFormDatetime
            name="valid_to"
            v-model="feeItem.valid_to"
          >
          </ItpFormDatetime>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_valid_to"
          :label="'Amount'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          contentClass="input-group"
          v-if="displayFee"
        >
          <ItpFormInput
            type="number"
            name="fee_amount"
            v-model="feeItem.amount"
            required
            class="col-8"
          >
          </ItpFormInput>
          <ItpFormSelect
            name="currency"
            v-model="feeItem.currency"
            :options="currencies"
            textField="code"
            valueField="code"
            required
            class="col-4"
          >
          </ItpFormSelect>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_unit"
          :label="'Invoice unit'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
          v-if="displayFee"
        >
          <ItpFormInput
            name="fee_unit"
            v-model="feeItem.unit"
            required
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_quantity"
          :label="'Quantity'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
          v-if="displayFee"
        >
          <ItpFormInput
            type="number"
            name="fee_quantity"
            v-model="feeItem.quantity"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_unit_of_quantity"
          :label="'Unit of quantity'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="4"
          :labelAlign="labelAlign"
          contentClass="input-group"
          v-if="displayFee"
        >
          <ItpFormInput
            name="fee_unit_of_quantity"
            v-model="feeItem.unit_of_quantity"
          >
          </ItpFormInput>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_inflation_indexed"
          :label="'Inflation indexed'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          v-if="displayFee"
        >
          <ItpFormCheckbox
            name="fee_inflation_indexed"
            v-model="feeItem.inflation_indexed"
          >
          </ItpFormCheckbox>
        </ItpFormGroup>
        <ItpFormGroup
          name="fee_notes"
          :label="'Notes'|pgettext('ContractServiceFee')"
          labelColsMd="4"
          contentColsMd="8"
          :labelAlign="labelAlign"
          v-if="displayFee"
        >
          <ItpFormTextarea
            name="fee_notes"
            v-model="feeItem.notes"
          >
          </ItpFormTextarea>
        </ItpFormGroup>
        <ItpButton
          :text="'Lock Service Fee'|pgettext('Button')"
          variant="light"
          icon="fa-lock"
          :spinning="actions.lockServiceFee.isRunning"
          v-if="mode === 'editServiceFee' && !feeItem.locked"
          @click.prevent.stop="evh_5084302415089671_clickPreventStop($event, {})"
        >
        </ItpButton>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Delete Service'|pgettext('Button')"
      variant="danger"
      icon="fa-times"
      :spinning="actions.deleteService.isRunning"
      class="mr-auto"
      v-if="mode === 'editService' && isDeletable"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Delete Fee'|pgettext('Button')"
      variant="danger"
      icon="fa-times"
      :spinning="actions.deleteServiceFee.isRunning"
      class="mr-auto"
      v-if="mode === 'editServiceFee' && isDeletable"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Save'|pgettext('Button')"
      :disabled="!forms.contract || !forms.contract.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
      v-if="isEditable"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ContractServiceEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  getConfiguration(...args: any[]) {
    switch (this.mode) {

      case 'addService':
        return {
          isEditable: true,
          isDeletable: false,
          isServiceReadonly: false,
        };

      case 'editService':
        return {
          isEditable: !this.service.locked,
          isDeletable: !this.service.locked,
          isServiceReadonly: this.service.locked,
        };

      case 'addServiceFee':
        return {
          isEditable: true,
          isDeletable: true,
          isServiceReadonly: true,
          isServiceFeeReadonly: false
        };

      case 'editServiceFee':
        return {
          isEditable: !this.fee.locked,
          isDeletable: !this.fee.locked,
          isServiceReadonly: true,
          isServiceFeeReadonly: this.fee.locked
        };
    }
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Object,
  })
  service!: object;

  @Watch('service')
  onService(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("service")
  }

  @Prop({
    type: Object,
  })
  fee!: object;

  @Watch('fee')
  onFee(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("fee")
  }

  @Prop({
    required: true,
    type: String,
  })
  mode!: string;

  @Watch('mode')
  onMode(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("mode")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  isEditable!: any;

  async $$load_isEditable() {
    return this.getConfiguration().isEditable
  }

  isDeletable!: any;

  async $$load_isDeletable() {
    return this.getConfiguration().isDeletable
  }

  isServiceReadonly!: any;

  async $$load_isServiceReadonly() {
    return this.getConfiguration().isServiceReadonly
  }

  isServiceFeeReadonly!: any;

  async $$load_isServiceFeeReadonly() {
    return this.getConfiguration().isServiceFeeReadonly
  }

  messages!: any;
  item!: any;

  async $$load_item() {
    return this.service || this.$fn.schemaDefaults('CreateContractService', {
      valid_from: this.contract.lifecycle.valid_from,  // $fn.today(),
      contract_id: this.contract.id
    })
  }

  feeItem!: any;

  async $$load_feeItem() {
    return this.fee ||
      this.$fn.schemaDefaults('CreateContractServiceFee', {
        unit: _.get(this.item, 'service.unit'),
        contract_id: this.contract.id,
        service_id: _.get(this.item, 'id'),
      })
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  services!: any;

  async $$load_services() {
    return this.$fn.fetch('suggest_product', {
      parameters: { query: '*', top: 50, skip: 0, product_types: 'service', product_invoice_groups: 'all' }
    }).then(data => _.orderBy(data, [p => p.name.toLowerCase()]))
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'labelAlign', 'isEditable', 'isDeletable', 'isServiceReadonly', 'isServiceFeeReadonly', 'messages', 'item', 'feeItem', 'currencies', 'services', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        labelAlign: "right",
        isEditable: null,
        isDeletable: null,
        isServiceReadonly: null,
        isServiceFeeReadonly: null,
        messages: {
          addService: {
            title: this.$fn.pgettext("Contract", "Add Service"),
          }
          ,
          editService: {
            title: this.$fn.pgettext("Contract", "Edit Service"),
          }
          ,
          addServiceFee: {
            title: this.$fn.pgettext("Contract", "Add Service Fee"),
          }
          ,
          editServiceFee: {
            title: this.$fn.pgettext("Contract", "Edit Service Fee"),
          }
          ,
        }
        ,
        item: null,
        feeItem: null,
        currencies: null,
        services: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          noPrimaryButton: true,
          closeButtonText: this.$fn.gettext("Close"),
          size: "md",
        }
        ,
      },
    }
  }

  get currentObjectType() {
    if (this.loaded) {

      return this.mode.indexOf('Fee') > -1 ? 'contract_service_fee' : 'contract_service'
    }
    return null;
  }

  get displayFee() {
    if (this.loaded) {

      return this.mode !== 'editService'
    }
    return null;
  }

  get displayFeeDates() {
    if (this.loaded) {

      return this.displayFee && this.mode !== 'addService'
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.messages[this.mode].title,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_6772_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_6772, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_6778_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_6778, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_6776_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_6776, alias=save
    return {
      objectType: this.currentObjectType,
      op: "upsert",
      data: this.mode === 'addService'
        ? _.extend({}, this.item, { fees: [_.extend(this.feeItem, { valid_from: this.item.valid_from, valid_to: this.item.valid_to })] })
        : (this.currentObjectType == 'contract_service' ? this.item : this.feeItem),
      options: {
        parameters: {
          rev: this.contract.revision_number,
        }
        ,
      }
      ,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_3054425420812800_setData_6780_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3054425420812800_setData_6780, alias=undefined
    return {
      path: "item.name",
      value: $event.data.option.name,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_3054425420812800_setData_6782_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3054425420812800_setData_6782, alias=undefined
    return {
      path: "item.service_id",
      value: $event.data.option.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_3054425420812800_setData_6784_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3054425420812800_setData_6784, alias=undefined
    return {
      path: "feeItem.unit",
      value: $event.data.option.unit,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3054425420812800_optionSelected(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3054425420812800_optionSelected.executeFromDOM(this, event, scope);
  }

  async act_1478395556975758_closeModal_6788_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1478395556975758_closeModal_6788, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1478395556975758_request_6786_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1478395556975758_request_6786, alias=lockService
    return {
      operation: "update_contract_service",
      data: {
        id: this.item.id,
        etag: this.item.etag,
        locked: true
      },
    }
  }

  async evh_1478395556975758_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1478395556975758_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5084302415089671_closeModal_6792_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_5084302415089671_closeModal_6792, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_5084302415089671_request_6790_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5084302415089671_request_6790, alias=lockServiceFee
    return {
      operation: "update_contract_service_fee",
      data: {
        id: this.feeItem.id,
        etag: this.feeItem.etag,
        locked: true
      },
    }
  }

  async evh_5084302415089671_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5084302415089671_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1548630417156826_closeModal_6796_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1548630417156826_closeModal_6796, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1548630417156826_request_6794_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1548630417156826_request_6794, alias=deleteService
    return {
      operation: "delete_contract_service",
      data: this.item,
    }
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeModal_6800_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1419464017721962_closeModal_6800, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1419464017721962_request_6798_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1419464017721962_request_6798, alias=deleteServiceFee
    return {
      operation: "delete_contract_service_fee",
      data: this.feeItem,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_6772: actions.CloseModalAction;
    evh_7315092382398562_close_6771: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_6774: actions.CloseComponentAction;
    evh_2248226175642056_close_6773: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_6778: actions.CloseModalAction;
    evh_1040167445267876_success_6777: actions.EventHandlerImpl;
    act_1040167445267876_crud_6776: actions.CRUDAction;
    evh_1040167445267876_submit_6775: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_3054425420812800_setData_6780: actions.SetDataAction;
    evh_3054425420812800_optionSelected_6779: actions.EventHandlerImpl;
    act_3054425420812800_setData_6782: actions.SetDataAction;
    evh_3054425420812800_optionSelected_6781: actions.EventHandlerImpl;
    act_3054425420812800_setData_6784: actions.SetDataAction;
    evh_3054425420812800_optionSelected_6783: actions.EventHandlerImpl;
    evh_3054425420812800_optionSelected: actions.EventHandlerGroup;
    act_1478395556975758_closeModal_6788: actions.CloseModalAction;
    evh_1478395556975758_success_6787: actions.EventHandlerImpl;
    act_1478395556975758_request_6786: actions.RequestAction;
    evh_1478395556975758_clickPreventStop_6785: actions.EventHandlerImpl;
    evh_1478395556975758_clickPreventStop: actions.EventHandlerGroup;
    act_5084302415089671_closeModal_6792: actions.CloseModalAction;
    evh_5084302415089671_success_6791: actions.EventHandlerImpl;
    act_5084302415089671_request_6790: actions.RequestAction;
    evh_5084302415089671_clickPreventStop_6789: actions.EventHandlerImpl;
    evh_5084302415089671_clickPreventStop: actions.EventHandlerGroup;
    act_1548630417156826_closeModal_6796: actions.CloseModalAction;
    evh_1548630417156826_success_6795: actions.EventHandlerImpl;
    act_1548630417156826_request_6794: actions.RequestAction;
    evh_1548630417156826_clickPreventStop_6793: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
    act_1419464017721962_closeModal_6800: actions.CloseModalAction;
    evh_1419464017721962_success_6799: actions.EventHandlerImpl;
    act_1419464017721962_request_6798: actions.RequestAction;
    evh_1419464017721962_clickPreventStop_6797: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_closeComponent_6802: actions.CloseComponentAction;
    evh_5566617329548203_clickPreventStop_6801: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_6772 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_6772_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_6771 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_6772,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_6771],
      }
    );
    const act_2248226175642056_closeComponent_6774 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_6773 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_6774,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_6773],
      }
    );
    const act_1040167445267876_closeModal_6778 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_6778_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_6777 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_6778,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_6776 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_6776_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_6777],
      }
    );
    const evh_1040167445267876_submit_6775 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_6776,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_6775],
      }
    );
    const act_3054425420812800_setData_6780 = new actions.SetDataAction(
      {
        actionArgs: this.act_3054425420812800_setData_6780_getActionArgs,
        events: [],
      }
    );
    const evh_3054425420812800_optionSelected_6779 = new actions.EventHandlerImpl(
      {
        action: act_3054425420812800_setData_6780,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_3054425420812800_setData_6782 = new actions.SetDataAction(
      {
        actionArgs: this.act_3054425420812800_setData_6782_getActionArgs,
        events: [],
      }
    );
    const evh_3054425420812800_optionSelected_6781 = new actions.EventHandlerImpl(
      {
        action: act_3054425420812800_setData_6782,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const act_3054425420812800_setData_6784 = new actions.SetDataAction(
      {
        actionArgs: this.act_3054425420812800_setData_6784_getActionArgs,
        events: [],
      }
    );
    const evh_3054425420812800_optionSelected_6783 = new actions.EventHandlerImpl(
      {
        action: act_3054425420812800_setData_6784,
        event: "optionSelected",
        displayName: "setData",
      }
    );
    const evh_3054425420812800_optionSelected = new actions.EventHandlerGroup(
      {
        handlers: [evh_3054425420812800_optionSelected_6779, evh_3054425420812800_optionSelected_6781, evh_3054425420812800_optionSelected_6783],
      }
    );
    const act_1478395556975758_closeModal_6788 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1478395556975758_closeModal_6788_getActionArgs,
        events: [],
      }
    );
    const evh_1478395556975758_success_6787 = new actions.EventHandlerImpl(
      {
        action: act_1478395556975758_closeModal_6788,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1478395556975758_request_6786 = new actions.RequestAction(
      {
        actionArgs: this.act_1478395556975758_request_6786_getActionArgs,
        displayName: "lockService",
        events: [evh_1478395556975758_success_6787],
      }
    );
    const evh_1478395556975758_clickPreventStop_6785 = new actions.EventHandlerImpl(
      {
        action: act_1478395556975758_request_6786,
        event: "click.prevent.stop",
        displayName: "lockService",
      }
    );
    const evh_1478395556975758_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1478395556975758_clickPreventStop_6785],
      }
    );
    const act_5084302415089671_closeModal_6792 = new actions.CloseModalAction(
      {
        actionArgs: this.act_5084302415089671_closeModal_6792_getActionArgs,
        events: [],
      }
    );
    const evh_5084302415089671_success_6791 = new actions.EventHandlerImpl(
      {
        action: act_5084302415089671_closeModal_6792,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_5084302415089671_request_6790 = new actions.RequestAction(
      {
        actionArgs: this.act_5084302415089671_request_6790_getActionArgs,
        displayName: "lockServiceFee",
        events: [evh_5084302415089671_success_6791],
      }
    );
    const evh_5084302415089671_clickPreventStop_6789 = new actions.EventHandlerImpl(
      {
        action: act_5084302415089671_request_6790,
        event: "click.prevent.stop",
        displayName: "lockServiceFee",
      }
    );
    const evh_5084302415089671_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5084302415089671_clickPreventStop_6789],
      }
    );
    const act_1548630417156826_closeModal_6796 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1548630417156826_closeModal_6796_getActionArgs,
        events: [],
      }
    );
    const evh_1548630417156826_success_6795 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeModal_6796,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1548630417156826_request_6794 = new actions.RequestAction(
      {
        actionArgs: this.act_1548630417156826_request_6794_getActionArgs,
        displayName: "deleteService",
        events: [evh_1548630417156826_success_6795],
      }
    );
    const evh_1548630417156826_clickPreventStop_6793 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_request_6794,
        event: "click.prevent.stop",
        displayName: "deleteService",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_6793],
      }
    );
    const act_1419464017721962_closeModal_6800 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1419464017721962_closeModal_6800_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_6799 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeModal_6800,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1419464017721962_request_6798 = new actions.RequestAction(
      {
        actionArgs: this.act_1419464017721962_request_6798_getActionArgs,
        displayName: "deleteServiceFee",
        events: [evh_1419464017721962_success_6799],
      }
    );
    const evh_1419464017721962_clickPreventStop_6797 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_request_6798,
        event: "click.prevent.stop",
        displayName: "deleteServiceFee",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_6797],
      }
    );
    const act_5566617329548203_closeComponent_6802 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_6801 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_closeComponent_6802,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_6801],
      }
    );
    return {
      act_7315092382398562_closeModal_6772,
      evh_7315092382398562_close_6771,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_6774,
      evh_2248226175642056_close_6773,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_6778,
      evh_1040167445267876_success_6777,
      act_1040167445267876_crud_6776,
      evh_1040167445267876_submit_6775,
      evh_1040167445267876_submit,
      act_3054425420812800_setData_6780,
      evh_3054425420812800_optionSelected_6779,
      act_3054425420812800_setData_6782,
      evh_3054425420812800_optionSelected_6781,
      act_3054425420812800_setData_6784,
      evh_3054425420812800_optionSelected_6783,
      evh_3054425420812800_optionSelected,
      act_1478395556975758_closeModal_6788,
      evh_1478395556975758_success_6787,
      act_1478395556975758_request_6786,
      evh_1478395556975758_clickPreventStop_6785,
      evh_1478395556975758_clickPreventStop,
      act_5084302415089671_closeModal_6792,
      evh_5084302415089671_success_6791,
      act_5084302415089671_request_6790,
      evh_5084302415089671_clickPreventStop_6789,
      evh_5084302415089671_clickPreventStop,
      act_1548630417156826_closeModal_6796,
      evh_1548630417156826_success_6795,
      act_1548630417156826_request_6794,
      evh_1548630417156826_clickPreventStop_6793,
      evh_1548630417156826_clickPreventStop,
      act_1419464017721962_closeModal_6800,
      evh_1419464017721962_success_6799,
      act_1419464017721962_request_6798,
      evh_1419464017721962_clickPreventStop_6797,
      evh_1419464017721962_clickPreventStop,
      act_5566617329548203_closeComponent_6802,
      evh_5566617329548203_clickPreventStop_6801,
      evh_5566617329548203_clickPreventStop,
      save: act_1040167445267876_crud_6776,
      lockService: act_1478395556975758_request_6786,
      lockServiceFee: act_5084302415089671_request_6790,
      deleteService: act_1548630417156826_request_6794,
      deleteServiceFee: act_1419464017721962_request_6798,
    }
  }
}

Vue.component("ContractServiceEditor", ContractServiceEditor);

</script>