<template>
<ItpUsercontrol
  loading-spinner
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpDataTable
    tableId="MeterRelations"
    editable
    :value="_.orderBy(items, ['created_at'], ['desc'])"
    :rowTemplate="{ meter_id: meter.id, inactive: false, factor: 0 }"
    :columns="b_2248226175642056_columns"
    v-if="loaded"
    @save="evh_2248226175642056_save($event, {})"
    @delete="evh_2248226175642056_delete($event, {})"
  >
  </ItpDataTable>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MeterRelations extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  relationItemToPayload(...args: any[]) {
    const p = args[0]; const meter = this.meter;
    if (p.relation_type === 'sub') { p.parent_meter_id = meter.id; p.child_meter_id = p.related_meter.id; } else if (p.relation_type === 'data') { p.parent_meter_id = p.related_meter.id; p.child_meter_id = meter.id; } else if (p.relation_type === 'parent') { p.parent_meter_id = p.related_meter.id; p.child_meter_id = meter.id; } return p;
  }

  mapRelationItem(...args: any[]) {
    const p = args[0];

    if (!p) {
      return p;
    }

    const meter = this.meter;

    // the current meter is the parent, the other one is the child
    const isRelatedChild = p.child_meter_id === meter.id;

    if (isRelatedChild) {
      p.related_meter = p.parent_meter;
      p.relation_type = p.related_meter.meter_type == 'pulse' ? 'data' : 'parent';
      return p;
    }

    p.related_meter = p.child_meter;
    p.relation_type = 'sub';
    return p;
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  relationTypes!: any;
  relationTypesMap!: any;

  async $$load_relationTypesMap() {
    return _.zipObject(
      _.map(this.relationTypes, p => p.id),
      _.map(this.relationTypes, p => p.text)
    )
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_meter_relationship', { parameters: { meter_id: this.meter.id } })
      .then(items => items.map(p => this.mapRelationItem(p)))
  }

  ux!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      name: "inactive",
      header: this.$fn.gettext("Inactive"),
      width: "30px",
      textAlign: "center",
      formatter: {
        name: "checkbox",
      }
      ,
      editor: {
        name: "checkbox",
      }
      ,
    }
      ,
    {
      name: "relation_type",
      type: "select",
      header: this.$fn.gettext("Relation type"),
      width: "170px",
      options: this.relationTypes,
    }
      ,
    {
      name: "related_meter",
      type: "select2",
      header: this.$fn.gettext("Device"),
      width: "270px",
      formatter: {
        name: "template",
        value: "<div v-if=\"value\" class=\"text-left\">\n\
  <ItpLink :to=\"{ name: 'app.meter', params: row.related_meter}\">\n\
    {{ value.serial_number }}\n\
  </ItpLink>\n\
  <div v-if=\"value.deveui\">{{ value.deveui }}</div>\n\
  <div v-if=\"value.tag_id\">{{ value.tag_id }}</div>\n\
  <div v-if=\"value.description\">{{ value.description }}</div>\n\
</div>\n\
",
      }
      ,
      options: this.$fn.searchDataSource('suggest_related_meter', {
        // relation_type: row.relation_type,
        parent: this.meter.id
      }),
      optionValueField: "id",
      valueField: "id",
      textField: "serial_number",
      bindObject: true,
      optionTemplate: "<div class=\"text-left\">\n\
  {{ option.serial_number }}\n\
  <div v-if=\"option.deveui\">{{ option.deveui }}</div>\n\
  <div v-if=\"option.tag_id\">{{ option.tag_id }}</div>\n\
  <div v-if=\"option.description\">{{ option.description }}</div>\n\
</div>\n\
",
      validator: ["required"
        ,
      ]
      ,
    }
      ,
    {
      name: "slot_number",
      type: "number",
      header: this.$fn.gettext("Slot Number"),
      width: "140px",
    }
      ,
    {
      name: "offset",
      type: "number",
      header: this.$fn.gettext("Offset"),
      width: "140px",
      required: true,
    }
      ,
    {
      name: "notes",
      type: "textarea",
      width: "200px",
      header: this.$fn.gettext("Notes"),
    }
      ,
    ]
  }

  dataMembers = ['relationTypes', 'relationTypesMap', 'items', 'ux', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        relationTypes: [{
          id: "parent",
          text: this.$fn.pgettext("MeterRelationTypes", "Parent"),
        }
          ,
        {
          id: "sub",
          text: this.$fn.pgettext("MeterRelationTypes", "Submeter"),
        }
          ,
        {
          id: "data",
          text: this.$fn.pgettext("MeterRelationTypes", "Data Provider"),
        }
          ,
        ]
        ,
        relationTypesMap: null,
        items: null,
        ux: {
          errorHandling: "local",
          initialLoadSpinner: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_script_7020_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2248226175642056_script_7020, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('meter_relationship')
        .upsert(this.relationItemToPayload($event.data.row))
        .then(p => this.mapRelationItem(p))
    )
  }

  async act_2248226175642056_script_7020_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2248226175642056_script_7020, alias=undefined
    return {
      value: () => this.act_2248226175642056_script_7020_getActionArgs_value($event),
    }
  }

  async evh_2248226175642056_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_save.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_script_7022_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2248226175642056_script_7022, alias=undefined
    $event.data.waitFor(this.$fn.crud('meter_relationship').delete($event.data.row))
  }

  async act_2248226175642056_script_7022_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2248226175642056_script_7022, alias=undefined
    return {
      value: () => this.act_2248226175642056_script_7022_getActionArgs_value($event),
    }
  }

  async evh_2248226175642056_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_7018: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_7017: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_script_7020: actions.ScriptAction;
    evh_2248226175642056_save_7019: actions.EventHandlerImpl;
    evh_2248226175642056_save: actions.EventHandlerGroup;
    act_2248226175642056_script_7022: actions.ScriptAction;
    evh_2248226175642056_delete_7021: actions.EventHandlerImpl;
    evh_2248226175642056_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_7018 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_7017 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_7018,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_7017],
      }
    );
    const act_2248226175642056_script_7020 = new actions.ScriptAction(
      {
        actionArgs: this.act_2248226175642056_script_7020_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_save_7019 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_script_7020,
        event: "save",
        displayName: "script",
      }
    );
    const evh_2248226175642056_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_save_7019],
      }
    );
    const act_2248226175642056_script_7022 = new actions.ScriptAction(
      {
        actionArgs: this.act_2248226175642056_script_7022_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_delete_7021 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_script_7022,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_2248226175642056_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_delete_7021],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_7018,
      evh_7315092382398562_reload_7017,
      evh_7315092382398562_reload,
      act_2248226175642056_script_7020,
      evh_2248226175642056_save_7019,
      evh_2248226175642056_save,
      act_2248226175642056_script_7022,
      evh_2248226175642056_delete_7021,
      evh_2248226175642056_delete,
    }
  }
}

Vue.component("MeterRelations", MeterRelations);

</script>