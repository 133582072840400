<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Meter') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Type'|pgettext('Meter')"
      >
        <ItpBox
          alignItems="center"
        >
          <ItpBadge
            no-box-shadow
            variant="light"
            class="mr-2"
          >
            <UcMeterTypeIcon
              :type="meter.meter_type"
            >
            </UcMeterTypeIcon>
            <ItpText
              :text="meter.meter_type|pgettext('MeterTypes', 'Sentence')"
              class="mx-2"
            >
            </ItpText>
          </ItpBadge>
          <ItpBadge
            :title="'Unit'|pgettext('Meter')"
            no-box-shadow
            variant="light"
            class="mx-2"
          >
            <ItpText
              :text="meter.unit"
              class="pl-2"
            >
            </ItpText>
          </ItpBadge>
          <ItpBadge
            :title="'Multiplier'|pgettext('Meter')"
            no-box-shadow
            variant="light"
            class="mx-2"
          >
            <ItpIcon
              name="fa-times"
            >
            </ItpIcon>
            <ItpText
              :text="meter.multiplier|number"
              class="pl-2"
            >
            </ItpText>
          </ItpBadge>
        </ItpBox>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Main meter'|pgettext('Meter')"
        v-if="meter.main_meter"
      >
        <UcCompactMeterDisplay
          :payload="meter.main_meter"
        >
        </UcCompactMeterDisplay>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Serial number'|pgettext('Meter')"
        :content="meter.serial_number"
        :helpTooltip="'The device\'s unique serial number.'|gettext"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Smart device'|pgettext('Meter')"
        :content="'Yes'|gettext"
        v-if="meter.smart_device"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Groups'|pgettext('Meter')"
        v-if="meter.groups && meter.groups.length"
      >
        <ItpBadge
          :key="name"
          :text="name"
          class="border border-gray-dark-900 mr-2"
          v-for="name in meter.groups"
        >
        </ItpBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Status'|pgettext('Meter')"
      >
        <UcResourceStatusCard
          :resource="meter"
          @reload="evh_3818132362335583_reload($event, {})"
        >
        </UcResourceStatusCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Model name'|pgettext('Meter')"
        :content="meter.model_name"
        v-if="meter.model_name"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'DevEUI'|pgettext('Meter')"
        :content="meter.deveui"
        :helpTooltip="'64-bit globally-unique Extended Unique Identifier (EUI-64) assigned by the manufacturer, or the owner, of the end-device.'|gettext"
        v-if="meter.deveui"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Location Code'|pgettext('Meter')"
        :content="meter.tag_id"
        :helpTooltip="'Locally-unique tag ID to be used within the company.'|gettext"
        v-if="meter.tag_id"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Validity'|pgettext('Meter')"
      >
        <UcMeterValidityCard
          :item="meter"
        >
        </UcMeterValidityCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Site'|pgettext('Meter')"
        v-if="meter.site_id"
      >
        <ItpLink
          :to="{
    name: 'app.property',
    params: { id: meter.site_id }
  }"
        >
          {{ meter.site.name }}
<span :if="meter.site.description">- {{ meter.site.description }}</span>

        </ItpLink>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Description'|pgettext('Meter')"
        :content="meter.description"
        v-if="meter.description"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcMeterGeneralPanel extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  meter!: object;

  @Watch('meter')
  onMeter(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("meter")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6724_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6724, alias=undefined
    return {
      event: "saved",
      value: $event.data.modalResult.value,
    }
  }

  async evh_8320016629450276_close_6723_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6723, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_6722_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6722, alias=undefined
    return {
      name: "MeterEditor",
      props: {
        meter: this.meter.editor(),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3818132362335583_emit_6726_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3818132362335583_emit_6726, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_3818132362335583_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3818132362335583_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6720: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6719: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6724: actions.EmitAction;
    evh_8320016629450276_close_6723: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6722: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6721: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_3818132362335583_emit_6726: actions.EmitAction;
    evh_3818132362335583_reload_6725: actions.EventHandlerImpl;
    evh_3818132362335583_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6720 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6719 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6720,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6719],
      }
    );
    const act_8320016629450276_emit_6724 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6724_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_6723 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6724,
        event: "close",
        when: this.evh_8320016629450276_close_6723_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_6722 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6722_getActionArgs,
        events: [evh_8320016629450276_close_6723],
      }
    );
    const evh_8320016629450276_clickPreventStop_6721 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6722,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6721],
      }
    );
    const act_3818132362335583_emit_6726 = new actions.EmitAction(
      {
        actionArgs: this.act_3818132362335583_emit_6726_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_3818132362335583_reload_6725 = new actions.EventHandlerImpl(
      {
        action: act_3818132362335583_emit_6726,
        event: "reload",
        displayName: "emitReload",
      }
    );
    const evh_3818132362335583_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_3818132362335583_reload_6725],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6720,
      evh_7315092382398562_reload_6719,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6724,
      evh_8320016629450276_close_6723,
      act_8320016629450276_showModal_6722,
      evh_8320016629450276_clickPreventStop_6721,
      evh_8320016629450276_clickPreventStop,
      act_3818132362335583_emit_6726,
      evh_3818132362335583_reload_6725,
      evh_3818132362335583_reload,
      emitReload: act_3818132362335583_emit_6726,
    }
  }
}

Vue.component("UcMeterGeneralPanel", UcMeterGeneralPanel);

</script>