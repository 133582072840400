"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let MCompanyEditor = class MCompanyEditor extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "modal",
        };
        this.dataMembers = ['formId', 'defaultTitle', 'targetConfig', 'editor', 'ux'];
    }
    beforeCreate() {
    }
    getPayload(...args) {
        const [editor] = args;
        console.log(editor);
        return Object.assign({ id: editor.id, etag: editor.getETAG() }, editor.getChanges({ skipEmbeddedLists: true }));
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    onTarget(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("target");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_editor() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.item.editor();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            formId: null,
            defaultTitle: this.$fn.pgettext("Company", "Edit General Information"),
            targetConfig: {
                general: {
                    title: this.$fn.pgettext("Company", "Edit general data"),
                    fieldsToSubmit: ["*",
                    ],
                },
            },
            editor: null,
            ux: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1874_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_closeModal_1880_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    ok: true,
                    value: $event.data,
                },
            };
        });
    }
    act_1040167445267876_crud_1878_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "company",
                op: "upsert",
                data: this.getPayload(this.editor),
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    act_1419464017721962_closeModal_1882_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                },
            };
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1874 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1874_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1873 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1874,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1873],
        });
        const act_2248226175642056_closeModal_1876 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1875 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1876,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1875],
        });
        const act_1040167445267876_closeModal_1880 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_1880_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_1879 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_1880,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_crud_1878 = new core_1.actions.CRUDAction({
            actionArgs: this.act_1040167445267876_crud_1878_getActionArgs,
            displayName: "save",
            events: [evh_1040167445267876_success_1879],
        });
        const evh_1040167445267876_submit_1877 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_crud_1878,
            event: "submit",
            displayName: "save",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_1877],
        });
        const act_1419464017721962_closeModal_1882 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1419464017721962_closeModal_1882_getActionArgs,
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_1881 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeModal_1882,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_1881],
        });
        return {
            act_7315092382398562_closeModal_1874,
            evh_7315092382398562_close_1873,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1876,
            evh_2248226175642056_close_1875,
            evh_2248226175642056_close,
            act_1040167445267876_closeModal_1880,
            evh_1040167445267876_success_1879,
            act_1040167445267876_crud_1878,
            evh_1040167445267876_submit_1877,
            evh_1040167445267876_submit,
            act_1419464017721962_closeModal_1882,
            evh_1419464017721962_clickPreventStop_1881,
            evh_1419464017721962_clickPreventStop,
            save: act_1040167445267876_crud_1878,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], MCompanyEditor.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MCompanyEditor.prototype, "onItem", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], MCompanyEditor.prototype, "target", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('target'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MCompanyEditor.prototype, "onTarget", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], MCompanyEditor.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], MCompanyEditor.prototype, "onSize", null);
MCompanyEditor = __decorate([
    (0, vue_property_decorator_1.Component)()
], MCompanyEditor);
exports.default = MCompanyEditor;
vue_property_decorator_1.Vue.component("MCompanyEditor", MCompanyEditor);
