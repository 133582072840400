<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateProperty"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :dialogName="resourceTitle"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="CreateProperty"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetPropertyGeneralData
        :property="editor"
      >
      </FieldsetPropertyGeneralData>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="forms[formName]"
      :spinning="actions.create.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create.isRunning"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateProperty extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  resource!: any;

  async $$load_resource() {
    return this.$fn.schemaDefaults('CreateProperty')
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_property'),
      object: 'list',
      data: []
    }
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  propertyUnitTypes!: any;

  async $$load_propertyUnitTypes() {
    return this.$fn.fetch('list_property_types').then(p => _.sortBy(p, ['name']))
  }

  units!: any;
  resourceTitle!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['resource', 'editor', 'container', 'formName', 'formId', 'propertyUnitTypes', 'units', 'resourceTitle', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        resource: null,
        editor: null,
        container: null,
        formName: "meter",
        formId: null,
        propertyUnitTypes: null,
        units: ["ha"
          ,
          "m2"
          ,
        ]
        ,
        resourceTitle: this.$fn.pgettext("Property", "Property"),
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Property", "New property"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3194_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3194, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeComponent_3200_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_3200, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_3202_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_3202, alias=undefined
    return {
      location: {
        name: "app.property",
        params: {
          id: $event.data.response.data.id,
        }
        ,
      }
      ,
    }
  }

  async act_1040167445267876_reloadSlickgrid_3204_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3204, alias=undefined
    return {
      grid: "properties",
    }
  }

  async act_1040167445267876_rest_3198_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_3198, alias=create
    return {
      method: "save",
      container: this.container,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3194: actions.CloseModalAction;
    evh_7315092382398562_close_3193: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3196: actions.CloseComponentAction;
    evh_2248226175642056_close_3195: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeComponent_3200: actions.CloseComponentAction;
    evh_1040167445267876_saved_3199: actions.EventHandlerImpl;
    act_1040167445267876_navigate_3202: actions.NavigateAction;
    evh_1040167445267876_saved_3201: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3204: actions.ReloadSlickgridAction;
    evh_1040167445267876_saved_3203: actions.EventHandlerImpl;
    act_1040167445267876_rest_3198: actions.RestAction;
    evh_1040167445267876_submit_3197: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_3206: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_3205: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3194 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3194_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3193 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3194,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3193],
      }
    );
    const act_2248226175642056_closeComponent_3196 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3195 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3196,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3195],
      }
    );
    const act_1040167445267876_closeComponent_3200 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_3200_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3199 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_3200,
        event: "saved",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_navigate_3202 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_3202_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3201 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_3202,
        event: "saved",
        displayName: "navigate",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3204 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3204_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_saved_3203 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3204,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_3198 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_3198_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_saved_3199, evh_1040167445267876_saved_3201, evh_1040167445267876_saved_3203],
      }
    );
    const evh_1040167445267876_submit_3197 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_3198,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3197],
      }
    );
    const act_1419464017721962_closeComponent_3206 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_3205 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_3206,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3205],
      }
    );
    return {
      act_7315092382398562_closeModal_3194,
      evh_7315092382398562_close_3193,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3196,
      evh_2248226175642056_close_3195,
      evh_2248226175642056_close,
      act_1040167445267876_closeComponent_3200,
      evh_1040167445267876_saved_3199,
      act_1040167445267876_navigate_3202,
      evh_1040167445267876_saved_3201,
      act_1040167445267876_reloadSlickgrid_3204,
      evh_1040167445267876_saved_3203,
      act_1040167445267876_rest_3198,
      evh_1040167445267876_submit_3197,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_3206,
      evh_1419464017721962_clickPreventStop_3205,
      evh_1419464017721962_clickPreventStop,
      create: act_1040167445267876_rest_3198,
    }
  }
}

Vue.component("CreateProperty", CreateProperty);

</script>