"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "dunning-letters",
    path: "/dunning-letters",
    component: "DunningLetters"
});
let DunningLetters = class DunningLetters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['selectedItems', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns', 'b_2248226175642056_contextMenu'];
    }
    beforeCreate() {
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            selectedItems: [],
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2248226175642056_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_2248226175642056_columns: [{
                    field: "reference_number",
                    name: this.$fn.pgettext("DunningLetters", "Reference Number"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "dunningLetter",
                        params: {
                            id: "id",
                        },
                    },
                },
                {
                    field: "status",
                    name: this.$fn.pgettext("DunningLetters", "Status"),
                    formatter: {
                        name: "chain",
                        mode: "mapReduce",
                        formatters: [{
                                name: "gettext",
                                context: "DunningLetterStatus",
                                conversion: "StartCase",
                            },
                            {
                                name: "badge",
                                variantmap: {
                                    sent: "light",
                                    delivered: "success",
                                    error: "danger",
                                },
                                variantMapSource: "fieldValue",
                            },
                        ],
                    },
                },
                {
                    field: "company_name",
                    name: this.$fn.pgettext("DunningLetters", "Owner company"),
                    formatter: {
                        name: "routeLink",
                        to: "company",
                        navigateOnIconClick: true,
                        params: {
                            id: "company_id",
                        },
                    },
                },
                {
                    field: "customer_name",
                    name: this.$fn.pgettext("DunningLetters", "Partner"),
                    formatter: {
                        name: "routeLink",
                        to: "company",
                        navigateOnIconClick: true,
                        params: {
                            id: "client_id",
                        },
                    },
                },
                {
                    field: "customer_email",
                    name: this.$fn.pgettext("DunningLetters", "Client Email"),
                    formatter: {
                        name: "join",
                        separator: "; ",
                    },
                },
                {
                    field: "severity",
                    name: this.$fn.pgettext("DunningLetters", "Severity"),
                },
                {
                    field: "created_at",
                    name: this.$fn.pgettext("DunningLetters", "Created at"),
                    formatter: {
                        name: "datetime",
                    },
                },
                {
                    field: "created_by",
                    name: this.$fn.pgettext("DunningLetters", "Created by"),
                    formatter: {
                        name: "user",
                    },
                },
            ],
            b_2248226175642056_contextMenu: {
                items: [{
                        label: "Delete",
                        command: "remove",
                    },
                ],
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.pgettext("DunningLetters", "Dunning Letters"),
                icon: this.$config.dunningLetter.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5314_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_setData_5316_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2248226175642056_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_2248226175642056_reloadSlickgrid_5320_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_2248226175642056_request_5318_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_dunning_letter",
                data: { id: $event.data.data.slug_id },
            };
        });
    }
    evh_2248226175642056_contextmenuRemove(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_contextmenuRemove.executeFromDOM(this, event, scope);
        });
    }
    act_6466705138206980_showModal_5322_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "SendMails",
                props: {
                    items: this.selectedItems,
                },
            };
        });
    }
    evh_6466705138206980_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6466705138206980_click.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_script_5326_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.$refs.grid.reload();
        });
    }
    act_8320016629450276_script_5326_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_8320016629450276_script_5326_getActionArgs_value($event),
            };
        });
    }
    act_8320016629450276_request_5324_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "bulk_delete_dunning_letter",
                data: this.selectedItems.map(p => ({ id: p.id, etag: null })),
            };
        });
    }
    evh_8320016629450276_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5314 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5314_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5313 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5314,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5313],
        });
        const act_2248226175642056_setData_5316 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2248226175642056_setData_5316_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_selectedRowsChanged_5315 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_setData_5316,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_2248226175642056_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_selectedRowsChanged_5315],
        });
        const act_2248226175642056_reloadSlickgrid_5320 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_2248226175642056_reloadSlickgrid_5320_getActionArgs,
            events: [],
        });
        const evh_2248226175642056_success_5319 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_reloadSlickgrid_5320,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_2248226175642056_request_5318 = new core_1.actions.RequestAction({
            actionArgs: this.act_2248226175642056_request_5318_getActionArgs,
            events: [evh_2248226175642056_success_5319],
        });
        const evh_2248226175642056_contextmenuRemove_5317 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_request_5318,
            event: "contextmenu:remove",
            displayName: "request",
        });
        const evh_2248226175642056_contextmenuRemove = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_contextmenuRemove_5317],
        });
        const act_6466705138206980_showModal_5322 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6466705138206980_showModal_5322_getActionArgs,
            events: [],
        });
        const evh_6466705138206980_click_5321 = new core_1.actions.EventHandlerImpl({
            action: act_6466705138206980_showModal_5322,
            event: "click",
            displayName: "showModal",
        });
        const evh_6466705138206980_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6466705138206980_click_5321],
        });
        const act_8320016629450276_script_5326 = new core_1.actions.ScriptAction({
            actionArgs: this.act_8320016629450276_script_5326_getActionArgs,
            events: [],
        });
        const evh_8320016629450276_success_5325 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_script_5326,
            event: "success",
            displayName: "script",
        });
        const act_8320016629450276_request_5324 = new core_1.actions.RequestAction({
            actionArgs: this.act_8320016629450276_request_5324_getActionArgs,
            displayName: "deleteItems",
            events: [evh_8320016629450276_success_5325],
        });
        const evh_8320016629450276_click_5323 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_request_5324,
            event: "click",
            displayName: "deleteItems",
        });
        const evh_8320016629450276_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_click_5323],
        });
        return {
            act_7315092382398562_closeModal_5314,
            evh_7315092382398562_close_5313,
            evh_7315092382398562_close,
            act_2248226175642056_setData_5316,
            evh_2248226175642056_selectedRowsChanged_5315,
            evh_2248226175642056_selectedRowsChanged,
            act_2248226175642056_reloadSlickgrid_5320,
            evh_2248226175642056_success_5319,
            act_2248226175642056_request_5318,
            evh_2248226175642056_contextmenuRemove_5317,
            evh_2248226175642056_contextmenuRemove,
            act_6466705138206980_showModal_5322,
            evh_6466705138206980_click_5321,
            evh_6466705138206980_click,
            act_8320016629450276_script_5326,
            evh_8320016629450276_success_5325,
            act_8320016629450276_request_5324,
            evh_8320016629450276_click_5323,
            evh_8320016629450276_click,
            deleteItems: act_8320016629450276_request_5324,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], DunningLetters.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], DunningLetters.prototype, "onSize", null);
DunningLetters = __decorate([
    (0, vue_property_decorator_1.Component)()
], DunningLetters);
exports.default = DunningLetters;
vue_property_decorator_1.Vue.component("DunningLetters", DunningLetters);
