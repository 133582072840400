var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("General", "Company")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("gettext")("Edit..."),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              _vm.item.name
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Name", "Company"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c(
                        "ItpBox",
                        [
                          _c("ItpText", { attrs: { text: _vm.item.name } }),
                          _vm._v(" "),
                          _vm.item.is_default
                            ? _c("ItpBadge", {
                                staticClass: "ml-3",
                                attrs: {
                                  text: _vm._f("pgettext")(
                                    "Default",
                                    "Company"
                                  ),
                                  variant: "info",
                                  "no-box-shadow": ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.code
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Number", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.code
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ItpLabeledListGroupItem",
                {
                  attrs: {
                    label: _vm._f("pgettext")("Status", "Meter"),
                    labelCols: _vm.labelCols
                  }
                },
                [
                  _c("UcResourceStatusCard", {
                    attrs: { resource: _vm.item },
                    on: {
                      reload: function($event) {
                        return _vm.evh_7349226261686150_reload($event, {})
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.role === "partner" && _vm.item.relation
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Client relation", "Company"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _c("UcCompanyTypeBadge", {
                        attrs: { value: _vm.item.relation }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.emails
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Email", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.emails
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.tax_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Tax number", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.tax_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.eu_tax_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("EU tax number", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.eu_tax_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.registration_number
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Registration number",
                        "Company"
                      ),
                      labelCols: _vm.labelCols,
                      content: _vm.item.registration_number
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.notes
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Notes", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.notes
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.payment_period_days
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Payment period", "Company"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.item.payment_period_days) +
                          " " +
                          _vm._s(
                            _vm._f("pgettext")("days", "InputGroupAddon")
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.discount
                ? _c(
                    "ItpLabeledListGroupItem",
                    {
                      attrs: {
                        label: _vm._f("pgettext")("Discount", "Company"),
                        labelCols: _vm.labelCols
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.item.discount) + " %\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.is_contractor
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Contractor", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.is_natural_person
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Natural person", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.excluded_from_claims
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")(
                        "Exclude from claims",
                        "Company"
                      ),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.vat_exempt
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("VAT exempt", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm._f("gettext")("Yes")
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.groups
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("Groups", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.groups
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.item.external_key
                ? _c("ItpLabeledListGroupItem", {
                    attrs: {
                      label: _vm._f("pgettext")("External key", "Company"),
                      labelCols: _vm.labelCols,
                      content: _vm.item.external_key
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }