<template>
<div>
  <UcSuggestRentalOption
    :option="option"
    v-if="option.object === 'rental'"
  >
  </UcSuggestRentalOption>
  <UcSuggestContractOption
    :option="option"
    v-if="option.object === 'contract'"
  >
  </UcSuggestContractOption>
  <UcSuggestCompanyOption
    :option="option"
    v-if="option.object === 'company'"
  >
  </UcSuggestCompanyOption>
  <UcSuggestPropertyOption
    :option="option"
    v-if="option.object === 'property' || option.object === 'property_unit'"
  >
  </UcSuggestPropertyOption>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceReferenceSearchOption extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  option!: object;

  @Watch('option')
  onOption(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("option")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("InvoiceReferenceSearchOption", InvoiceReferenceSearchOption);

</script>