var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "DIV",
        { staticClass: "container p-0" },
        [
          _c(
            "ItpRow",
            { staticClass: "no-gutters" },
            [
              _c(
                "ItpCol",
                { attrs: { w: "6" } },
                [
                  _vm.worksheet.status
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")("Status", "Worksheet"),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("UcWorksheetStatus", {
                            staticClass: "col",
                            attrs: { strong: "", value: _vm.worksheet.status }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.company
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Own company",
                                "Worksheet"
                              ),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCompanyMiniCard", {
                            staticClass: "col",
                            attrs: {
                              item: _vm.worksheet.company,
                              "no-icon": ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.client
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")("Partner", "Worksheet"),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCompanyMiniCard", {
                            staticClass: "col",
                            attrs: { item: _vm.worksheet.client, "no-icon": "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.contractor
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Contractor",
                                "Worksheet"
                              ),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCompanyMiniCard", {
                            staticClass: "col",
                            attrs: {
                              item: _vm.worksheet.contractor,
                              "no-icon": ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.rental
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")("Rental", "Worksheet"),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("UcRentalMiniCard", {
                            staticClass: "col",
                            attrs: { "no-icon": "", item: _vm.worksheet.rental }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpCol",
                { attrs: { w: "6" } },
                [
                  _vm.worksheet.description
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Description",
                                "Worksheet"
                              ),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "col-6",
                            attrs: {
                              text: _vm.worksheet.description,
                              strong: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.worksheet_date
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")("Date", "Worksheet"),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "col-6",
                            attrs: {
                              text: _vm._f("date")(
                                _vm.worksheet.worksheet_date
                              ),
                              strong: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.start_date || _vm.worksheet.end_date
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")(
                                "Start/End",
                                "Worksheet"
                              ),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "DIV",
                            { staticClass: "col-6" },
                            [
                              _c("ItpText", {
                                attrs: {
                                  text: _vm._f("date")(
                                    _vm.worksheet.start_date
                                  ),
                                  defaultText: "n/a",
                                  strong: ""
                                }
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v("\n              ‐\n            ")
                              ]),
                              _vm._v(" "),
                              _c("ItpText", {
                                attrs: {
                                  text: _vm._f("date")(_vm.worksheet.end_date),
                                  defaultText: "n/a",
                                  strong: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.gl_account_number
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")(
                                "GL Account Number",
                                "Worksheet"
                              ),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "col-9",
                            attrs: {
                              text: _vm.worksheet.gl_account_number,
                              wrap: "",
                              strong: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.worksheet.notes
                    ? _c(
                        "ItpRow",
                        { staticClass: "no-gutters mb-2" },
                        [
                          _c("ItpText", {
                            staticClass: "col-3",
                            attrs: {
                              text: _vm._f("pgettext")("Notes", "Worksheet"),
                              muted: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpText", {
                            staticClass: "col-9",
                            attrs: { text: _vm.worksheet.notes, wrap: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }