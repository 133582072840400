"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "company",
    path: "/companies/:id",
    component: "Company",
    params: [{
            name: "id",
            prop: "oid",
        },
    ]
});
let Company = class Company extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['messages', 'tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to', 'b_8449679363704630_parameters'];
    }
    beforeCreate() {
    }
    onOid(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("oid");
    }
    onRole(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("role");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_tabManager() {
        return __awaiter(this, void 0, void 0, function* () {
            return new this.$app.utils.LazyTabManager();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_company', { parameters: { id: this.oid }, asResource: true });
        });
    }
    $$load_b_4615685607825208_to() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "app.companies",
                params: {
                    role: this.role,
                },
            };
        });
    }
    $$load_b_8449679363704630_parameters() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                company: this.item.role === 'own' ? this.item.id : null,
                partner: this.item.role === 'partner' ? this.item.id : null,
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                create: {
                    own: this.$fn.pgettext("Company|Header", "New own company"),
                    partner: this.$fn.pgettext("Company|Header", "New partner"),
                },
                parent: {
                    own: this.$fn.pgettext("Company|Header", "Companies"),
                    partner: this.$fn.pgettext("Company|Header", "Partners"),
                },
                delete: {
                    own: this.$fn.pgettext("Company|Header", "Delete own company"),
                    partner: this.$fn.pgettext("Company|Header", "Delete partner"),
                },
            },
            tabManager: null,
            item: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_modalBindings: {
                noHeader: true,
                contentClass: "p-0",
                noPrimaryButton: true,
                noCloseButton: true,
                size: "full-screen",
            },
            b_4615685607825208_to: null,
            b_8449679363704630_parameters: null,
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.oid == null && this.messages.create[this.role],
                    this.item && this.item.snapshot('name'),
                ],
                icon: this.$config.company.icons[this.role || this.item.role],
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1808_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    evh_8320016629450276_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_success.executeFromDOM(this, event, scope);
        });
    }
    evh_6705964765214521_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_6806515264385235_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6806515264385235_success.executeFromDOM(this, event, scope);
        });
    }
    evh_5477420070495986_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_6337614691287098_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_2406129194387753_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2406129194387753_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_4071170783051919_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4071170783051919_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_7713630524832656_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7713630524832656_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1808 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1808_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1807 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1808,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1807],
        });
        const act_2248226175642056_closeModal_1810 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_2248226175642056_close_1809 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeModal_1810,
            event: "close",
            displayName: "closeModal",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_1809],
        });
        const act_8320016629450276_closeComponent_1812 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_8320016629450276_success_1811 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_closeComponent_1812,
            event: "success",
            displayName: "closeComponent",
        });
        const evh_8320016629450276_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_success_1811],
        });
        const act_6705964765214521_reloadComponentData_1814 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_6705964765214521_clickPreventStop_1813 = new core_1.actions.EventHandlerImpl({
            action: act_6705964765214521_reloadComponentData_1814,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_6705964765214521_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6705964765214521_clickPreventStop_1813],
        });
        const act_6806515264385235_reloadComponentData_1816 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6806515264385235_success_1815 = new core_1.actions.EventHandlerImpl({
            action: act_6806515264385235_reloadComponentData_1816,
            event: "success",
            displayName: "reloadComponentData",
        });
        const evh_6806515264385235_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6806515264385235_success_1815],
        });
        const act_5477420070495986_reloadComponentData_1818 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_5477420070495986_reload_1817 = new core_1.actions.EventHandlerImpl({
            action: act_5477420070495986_reloadComponentData_1818,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_5477420070495986_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5477420070495986_reload_1817],
        });
        const act_6337614691287098_reloadComponentData_1820 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_6337614691287098_reload_1819 = new core_1.actions.EventHandlerImpl({
            action: act_6337614691287098_reloadComponentData_1820,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_6337614691287098_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6337614691287098_reload_1819],
        });
        const act_2406129194387753_reloadComponentData_1822 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2406129194387753_reload_1821 = new core_1.actions.EventHandlerImpl({
            action: act_2406129194387753_reloadComponentData_1822,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2406129194387753_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2406129194387753_reload_1821],
        });
        const act_4071170783051919_reloadComponentData_1824 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_4071170783051919_reload_1823 = new core_1.actions.EventHandlerImpl({
            action: act_4071170783051919_reloadComponentData_1824,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_4071170783051919_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4071170783051919_reload_1823],
        });
        const act_7713630524832656_reloadComponentData_1826 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7713630524832656_reload_1825 = new core_1.actions.EventHandlerImpl({
            action: act_7713630524832656_reloadComponentData_1826,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7713630524832656_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7713630524832656_reload_1825],
        });
        return {
            act_7315092382398562_closeModal_1808,
            evh_7315092382398562_close_1807,
            evh_7315092382398562_close,
            act_2248226175642056_closeModal_1810,
            evh_2248226175642056_close_1809,
            evh_2248226175642056_close,
            act_8320016629450276_closeComponent_1812,
            evh_8320016629450276_success_1811,
            evh_8320016629450276_success,
            act_6705964765214521_reloadComponentData_1814,
            evh_6705964765214521_clickPreventStop_1813,
            evh_6705964765214521_clickPreventStop,
            act_6806515264385235_reloadComponentData_1816,
            evh_6806515264385235_success_1815,
            evh_6806515264385235_success,
            act_5477420070495986_reloadComponentData_1818,
            evh_5477420070495986_reload_1817,
            evh_5477420070495986_reload,
            act_6337614691287098_reloadComponentData_1820,
            evh_6337614691287098_reload_1819,
            evh_6337614691287098_reload,
            act_2406129194387753_reloadComponentData_1822,
            evh_2406129194387753_reload_1821,
            evh_2406129194387753_reload,
            act_4071170783051919_reloadComponentData_1824,
            evh_4071170783051919_reload_1823,
            evh_4071170783051919_reload,
            act_7713630524832656_reloadComponentData_1826,
            evh_7713630524832656_reload_1825,
            evh_7713630524832656_reload,
            reload: act_6705964765214521_reloadComponentData_1814,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Company.prototype, "oid", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('oid'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Company.prototype, "onOid", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Company.prototype, "role", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('role'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Company.prototype, "onRole", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], Company.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Company.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "full-screen",
    }),
    __metadata("design:type", String)
], Company.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Company.prototype, "onSize", null);
Company = __decorate([
    (0, vue_property_decorator_1.Component)()
], Company);
exports.default = Company;
vue_property_decorator_1.Vue.component("Company", Company);
