<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceRegister"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <VueSlickgrid
    name="invoiceRegister"
    :headerTitle="'Invoice register'|pgettext('Invoice register')"
    :items="$fn.slickDataSource('list_invoice_register_entries', {parameters: getQueryParameters})"
    export-to-csv
    :exportToCsvFilename="'invoice-register'|pgettext('filename')"
    footer-totals
    checkbox-row-selection
    :columnDefaults="b_2248226175642056_columnDefaults"
    :columns="b_2248226175642056_columns"
    class="page--grid"
    ref="grid"
    v-if="loaded"
    @command="evh_2248226175642056_command($event, {})"
    @selectedRowsChanged="evh_2248226175642056_selectedRowsChanged($event, {})"
  >
    <DIV
      class="flex-grow-1 d-flex"
      slot="toolbar"
    >
      <ItpFormSelect2
        name="register_name"
        v-model="selectedRegister"
        bind-object
        valueField="id"
        textField="name"
        :options="registers"
        class="w-25 mx-3"
        @input="evh_6466705138206980_input($event, {})"
      >
      </ItpFormSelect2>
      <ItpButton
        :icon="$config.invoiceRegister.createEntry"
        :text="'Add entry...'|pgettext('InvoiceRegister')"
        size="sm"
        variant="light"
        class="mx-2"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpButton>
      <b-dropdown
        size="sm"
        variant="light"
        toggle-class="itp-button border"
        :tooltip="'Set status to selected items'|pgettext('InvoiceRegister')"
        :disabled="!selectedItems || !selectedItems.length || (
    actions.setStatus && actions.setStatus.isRunning
  )"
        class="mx-2"
        v-if="statuses && statuses.length"
      >
        <span
          slot="button-content"
        >
          <b-spinner
            small
            type="grow"
            class="mr-1"
            v-if="actions.setStatus.isRunning"
          >
          </b-spinner>
          <ItpText
            :text="'Set status'|pgettext('InvoiceRegister')"
            class="mx-1"
          >
          </ItpText>
        </span>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          :key="status"
          v-for="status in statuses"
          @click.prevent.stop="evh_5382094220310827_clickPreventStop($event, {status})"
        >
          <ItpText
            :text="status"
          >
          </ItpText>
        </b-dropdown-item-button>
        <hr>
        </hr>
        <b-dropdown-item-button
          :disabled="!selectedItems || !selectedItems.length"
          @click.prevent.stop="evh_8704926098283402_clickPreventStop($event, {})"
        >
          <ItpText
            :text="'Clear status'|pgettext('InvoiceRegister')"
          >
          </ItpText>
        </b-dropdown-item-button>
      </b-dropdown>
    </DIV>
  </VueSlickgrid>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoiceRegister",
    path: "/invoice-register",
    component: "InvoiceRegister"
  }
)

@Component()
export default class InvoiceRegister extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_selectedRegisterId = new ComponentValueCache(this, "selectedRegisterId", "InvoiceRegister.selectedRegisterId");
  }

  getQueryParameters(...args: any[]) {
    return {
      sort_mode: 'desc',
      register_name: this.selectedRegister ? this.selectedRegister.id : this.registers[0].id
    }
  }

  invoiceTypeBadgeClass(...args: any[]) {
    const [row] = args;
    const cc = this.$config.invoice.types.colorCodes[row.invoice_type];
    return `bg-${cc.bg} text-${cc.text}`
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  registers!: any;

  async $$load_registers() {
    return this.$fn.fetch('list_register')
      .then(data => _.filter(data, d => _.includes(d.register_type, 'incoming_invoice')))
  }

  selectedRegisterId!: any;
  selectedRegister!: any;

  async $$load_selectedRegister() {
    return _.find(this.registers, { id: this.selectedRegisterId }) || this.registers[0]
  }

  selectedItems!: any;
  statuses!: any;

  async $$load_statuses() {
    return this.$fn.fetch('get_tags', { parameters: { tag_type: 'invoice-register-status' } }).then(data => _.map(data, p => p.name))
  }

  selectedStatus!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_2248226175642056_columnDefaults!: any;
  b_2248226175642056_columns!: any;

  async $$load_b_2248226175642056_columns() {
    return [{
      field: "entry_number",
      name: this.$fn.pgettext("Invoice register", "No."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-yellow-200': !row.locked
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "ref_entry_number",
      name: this.$fn.pgettext("Invoice register", "Ref."),
      type: "command",
      formatter: [{
        name: "chain",
        formatters: [{
          name: "link",
          href: "#",
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "invoice_number",
      name: this.$fn.pgettext("Invoice register", "Invoice number"),
      formatter: [{
        name: "chain",
        formatters: [{
          name: "routeLink",
          to: "invoice",
          navigateOnIconClick: true,
          params: {
            id: "invoice_id",
          }
          ,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'bg-yellow-200': row.invoice_id != null && row.external_key == null,
            'bg-red-200': row.invoice_id == null,
            'font-weight-bold': row.invoice_id != null
          }),
        }
          ,
        ]
        ,
      }
        ,
      ]
      ,
    }
      ,
    {
      field: "received_date",
      name: this.$fn.pgettext("Invoice register", "Received date"),
      type: "date",
    }
      ,
    {
      field: "invoice_type",
      name: this.$fn.pgettext("Invoice register", "Invoice type"),
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          context: "InvoiceType",
          conversion: "StartCase",
          filterInput: true,
        }
          ,
        {
          name: "badge",
          dot: true,
          pill: true,
          badgeClass: this.invoiceTypeBadgeClass,
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "status",
      name: this.$fn.pgettext("Invoice register", "Status"),
    }
      ,
    {
      field: "categories",
      name: this.$fn.pgettext("Invoice register", "Category"),
    }
      ,
    {
      field: "client_name",
      name: this.$fn.pgettext("Invoice register", "Vendor"),
      formatter: {
        name: "routeLink",
        to: "company",
        navigateOnIconClick: true,
        params: {
          id: "client_id",
        }
        ,
      }
      ,
    }
      ,
    {
      field: "description",
      name: this.$fn.pgettext("Invoice register", "Subject"),
    }
      ,
    {
      field: "notes",
      name: this.$fn.pgettext("Invoice register", "Notes"),
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("Invoice register", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("Invoice register", "Currency"),
    }
      ,
    {
      field: "fulfillment_date",
      name: this.$fn.pgettext("Invoice register", "Fulfillment date"),
      type: "date",
    }
      ,
    {
      field: "due_date",
      name: this.$fn.pgettext("Invoice register", "Due date"),
      type: "date",
    }
      ,
    ]
  }

  dataMembers = ['registers', 'selectedRegisterId', 'selectedRegister', 'selectedItems', 'statuses', 'selectedStatus', 'ux', 'b_7315092382398562_modalBindings', 'b_2248226175642056_columnDefaults', 'b_2248226175642056_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        registers: null,
        selectedRegisterId: null,
        selectedRegister: null,
        selectedItems: [
        ]
        ,
        statuses: null,
        selectedStatus: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_2248226175642056_columnDefaults: {
          filterable: true,
          resizable: true,
        }
        ,
        b_2248226175642056_columns: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Invoice register", "Invoice register"),
        icon: this.$config.invoiceRegister.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2422_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2422, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_reloadSlickgrid_2426_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2426, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2428_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2428, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2427_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2427, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2424_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2424, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2423_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2423, alias=undefined
    return $event.data.column.id === 'entry_number'
  }

  async act_2248226175642056_reloadSlickgrid_2432_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2432, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_2248226175642056_reloadSlickgrid_2434_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2248226175642056_reloadSlickgrid_2434, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_2248226175642056_close_2433_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_2433, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_2430_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_2430, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: $event.data.data.ref_entry_id,
      }
      ,
    }
  }

  async evh_2248226175642056_command_2429_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_command_2429, alias=undefined
    return $event.data.column.id === 'ref_entry_number'
  }

  async evh_2248226175642056_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_command.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2436_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2436, alias=undefined
    return {
      path: "selectedItems",
      value: this.$refs.grid.getSelectedItems(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_selectedRowsChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_selectedRowsChanged.executeFromDOM(this, event, scope);
  }

  async act_6466705138206980_reloadSlickgrid_2438_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_6466705138206980_reloadSlickgrid_2438, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_6466705138206980_setData_2440_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_6466705138206980_setData_2440, alias=updateSelectedRegisterId
    return {
      path: "selectedRegisterId",
      value: $event.data.id,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_6466705138206980_input(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_input.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_reloadSlickgrid_2444_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2444, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8320016629450276_reloadSlickgrid_2446_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8320016629450276_reloadSlickgrid_2446, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async evh_8320016629450276_close_2445_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_2445, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_2442_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2442, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        register: this.selectedRegister,
        companyId: this.selectedRegister.company_id,
      }
      ,
    }
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  async act_5382094220310827_setData_2448_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5382094220310827_setData_2448, alias=undefined
    return {
      path: "selectedStatus",
      value: $event.scope.status,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_5382094220310827_reloadSlickgrid_2452_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_5382094220310827_reloadSlickgrid_2452, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_5382094220310827_request_2450_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_5382094220310827_request_2450, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_5382094220310827_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5382094220310827_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8704926098283402_setData_2454_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8704926098283402_setData_2454, alias=undefined
    return {
      path: "selectedStatus",
      value: null,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8704926098283402_reloadSlickgrid_2458_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8704926098283402_reloadSlickgrid_2458, alias=reloadSlickgrid
    return {
      grid: "invoiceRegister",
    }
  }

  async act_8704926098283402_request_2456_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8704926098283402_request_2456, alias=setStatus
    return {
      operation: "bulk_update_invoice_register_entry",
      data: _.map(this.selectedItems, p => ({ id: p.id, status: this.selectedStatus })),
    }
  }

  async evh_8704926098283402_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8704926098283402_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2422: actions.CloseModalAction;
    evh_7315092382398562_close_2421: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_reloadSlickgrid_2426: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2425: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2428: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2427: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2424: actions.ShowModalAction;
    evh_2248226175642056_command_2423: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2432: actions.ReloadSlickgridAction;
    evh_2248226175642056_saved_2431: actions.EventHandlerImpl;
    act_2248226175642056_reloadSlickgrid_2434: actions.ReloadSlickgridAction;
    evh_2248226175642056_close_2433: actions.EventHandlerImpl;
    act_2248226175642056_showModal_2430: actions.ShowModalAction;
    evh_2248226175642056_command_2429: actions.EventHandlerImpl;
    evh_2248226175642056_command: actions.EventHandlerGroup;
    act_2248226175642056_setData_2436: actions.SetDataAction;
    evh_2248226175642056_selectedRowsChanged_2435: actions.EventHandlerImpl;
    evh_2248226175642056_selectedRowsChanged: actions.EventHandlerGroup;
    act_6466705138206980_reloadSlickgrid_2438: actions.ReloadSlickgridAction;
    evh_6466705138206980_input_2437: actions.EventHandlerImpl;
    act_6466705138206980_setData_2440: actions.SetDataAction;
    evh_6466705138206980_input_2439: actions.EventHandlerImpl;
    evh_6466705138206980_input: actions.EventHandlerGroup;
    act_8320016629450276_reloadSlickgrid_2444: actions.ReloadSlickgridAction;
    evh_8320016629450276_saved_2443: actions.EventHandlerImpl;
    act_8320016629450276_reloadSlickgrid_2446: actions.ReloadSlickgridAction;
    evh_8320016629450276_close_2445: actions.EventHandlerImpl;
    act_8320016629450276_showModal_2442: actions.ShowModalAction;
    evh_8320016629450276_click_2441: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
    act_5382094220310827_setData_2448: actions.SetDataAction;
    evh_5382094220310827_clickPreventStop_2447: actions.EventHandlerImpl;
    act_5382094220310827_reloadSlickgrid_2452: actions.ReloadSlickgridAction;
    evh_5382094220310827_success_2451: actions.EventHandlerImpl;
    act_5382094220310827_request_2450: actions.RequestAction;
    evh_5382094220310827_clickPreventStop_2449: actions.EventHandlerImpl;
    evh_5382094220310827_clickPreventStop: actions.EventHandlerGroup;
    act_8704926098283402_setData_2454: actions.SetDataAction;
    evh_8704926098283402_clickPreventStop_2453: actions.EventHandlerImpl;
    act_8704926098283402_reloadSlickgrid_2458: actions.ReloadSlickgridAction;
    evh_8704926098283402_success_2457: actions.EventHandlerImpl;
    act_8704926098283402_request_2456: actions.RequestAction;
    evh_8704926098283402_clickPreventStop_2455: actions.EventHandlerImpl;
    evh_8704926098283402_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2422 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2422_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2421 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2422,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2421],
      }
    );
    const act_2248226175642056_reloadSlickgrid_2426 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2426_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2425 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2426,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2428 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2428_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2427 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2428,
        event: "close",
        when: this.evh_2248226175642056_close_2427_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2424 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2424_getActionArgs,
        events: [evh_2248226175642056_saved_2425, evh_2248226175642056_close_2427],
      }
    );
    const evh_2248226175642056_command_2423 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2424,
        event: "command",
        when: this.evh_2248226175642056_command_2423_getWhen,
        displayName: "showModal",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2432 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2432_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_saved_2431 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2432,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_reloadSlickgrid_2434 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2248226175642056_reloadSlickgrid_2434_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_2248226175642056_close_2433 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_reloadSlickgrid_2434,
        event: "close",
        when: this.evh_2248226175642056_close_2433_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_2248226175642056_showModal_2430 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_2430_getActionArgs,
        events: [evh_2248226175642056_saved_2431, evh_2248226175642056_close_2433],
      }
    );
    const evh_2248226175642056_command_2429 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_2430,
        event: "command",
        when: this.evh_2248226175642056_command_2429_getWhen,
        displayName: "showModal",
      }
    );
    const evh_2248226175642056_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_command_2423, evh_2248226175642056_command_2429],
      }
    );
    const act_2248226175642056_setData_2436 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2436_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_selectedRowsChanged_2435 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2436,
        event: "selectedRowsChanged",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_selectedRowsChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_selectedRowsChanged_2435],
      }
    );
    const act_6466705138206980_reloadSlickgrid_2438 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_6466705138206980_reloadSlickgrid_2438_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_6466705138206980_input_2437 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadSlickgrid_2438,
        event: "input",
        displayName: "reloadSlickgrid",
      }
    );
    const act_6466705138206980_setData_2440 = new actions.SetDataAction(
      {
        actionArgs: this.act_6466705138206980_setData_2440_getActionArgs,
        displayName: "updateSelectedRegisterId",
        events: [],
      }
    );
    const evh_6466705138206980_input_2439 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_setData_2440,
        event: "input",
        displayName: "updateSelectedRegisterId",
      }
    );
    const evh_6466705138206980_input = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_input_2437, evh_6466705138206980_input_2439],
      }
    );
    const act_8320016629450276_reloadSlickgrid_2444 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2444_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_saved_2443 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2444,
        event: "saved",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_reloadSlickgrid_2446 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8320016629450276_reloadSlickgrid_2446_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8320016629450276_close_2445 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_reloadSlickgrid_2446,
        event: "close",
        when: this.evh_8320016629450276_close_2445_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_8320016629450276_showModal_2442 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2442_getActionArgs,
        events: [evh_8320016629450276_saved_2443, evh_8320016629450276_close_2445],
      }
    );
    const evh_8320016629450276_click_2441 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2442,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2441],
      }
    );
    const act_5382094220310827_setData_2448 = new actions.SetDataAction(
      {
        actionArgs: this.act_5382094220310827_setData_2448_getActionArgs,
        events: [],
      }
    );
    const evh_5382094220310827_clickPreventStop_2447 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_setData_2448,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_5382094220310827_reloadSlickgrid_2452 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_5382094220310827_reloadSlickgrid_2452_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_5382094220310827_success_2451 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_reloadSlickgrid_2452,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_5382094220310827_request_2450 = new actions.RequestAction(
      {
        actionArgs: this.act_5382094220310827_request_2450_getActionArgs,
        displayName: "setStatus",
        events: [evh_5382094220310827_success_2451],
      }
    );
    const evh_5382094220310827_clickPreventStop_2449 = new actions.EventHandlerImpl(
      {
        action: act_5382094220310827_request_2450,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_5382094220310827_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5382094220310827_clickPreventStop_2447, evh_5382094220310827_clickPreventStop_2449],
      }
    );
    const act_8704926098283402_setData_2454 = new actions.SetDataAction(
      {
        actionArgs: this.act_8704926098283402_setData_2454_getActionArgs,
        events: [],
      }
    );
    const evh_8704926098283402_clickPreventStop_2453 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_setData_2454,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const act_8704926098283402_reloadSlickgrid_2458 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8704926098283402_reloadSlickgrid_2458_getActionArgs,
        displayName: "reloadSlickgrid",
        events: [],
      }
    );
    const evh_8704926098283402_success_2457 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_reloadSlickgrid_2458,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8704926098283402_request_2456 = new actions.RequestAction(
      {
        actionArgs: this.act_8704926098283402_request_2456_getActionArgs,
        displayName: "setStatus",
        events: [evh_8704926098283402_success_2457],
      }
    );
    const evh_8704926098283402_clickPreventStop_2455 = new actions.EventHandlerImpl(
      {
        action: act_8704926098283402_request_2456,
        event: "click.prevent.stop",
        displayName: "setStatus",
      }
    );
    const evh_8704926098283402_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8704926098283402_clickPreventStop_2453, evh_8704926098283402_clickPreventStop_2455],
      }
    );
    return {
      act_7315092382398562_closeModal_2422,
      evh_7315092382398562_close_2421,
      evh_7315092382398562_close,
      act_2248226175642056_reloadSlickgrid_2426,
      evh_2248226175642056_saved_2425,
      act_2248226175642056_reloadSlickgrid_2428,
      evh_2248226175642056_close_2427,
      act_2248226175642056_showModal_2424,
      evh_2248226175642056_command_2423,
      act_2248226175642056_reloadSlickgrid_2432,
      evh_2248226175642056_saved_2431,
      act_2248226175642056_reloadSlickgrid_2434,
      evh_2248226175642056_close_2433,
      act_2248226175642056_showModal_2430,
      evh_2248226175642056_command_2429,
      evh_2248226175642056_command,
      act_2248226175642056_setData_2436,
      evh_2248226175642056_selectedRowsChanged_2435,
      evh_2248226175642056_selectedRowsChanged,
      act_6466705138206980_reloadSlickgrid_2438,
      evh_6466705138206980_input_2437,
      act_6466705138206980_setData_2440,
      evh_6466705138206980_input_2439,
      evh_6466705138206980_input,
      act_8320016629450276_reloadSlickgrid_2444,
      evh_8320016629450276_saved_2443,
      act_8320016629450276_reloadSlickgrid_2446,
      evh_8320016629450276_close_2445,
      act_8320016629450276_showModal_2442,
      evh_8320016629450276_click_2441,
      evh_8320016629450276_click,
      act_5382094220310827_setData_2448,
      evh_5382094220310827_clickPreventStop_2447,
      act_5382094220310827_reloadSlickgrid_2452,
      evh_5382094220310827_success_2451,
      act_5382094220310827_request_2450,
      evh_5382094220310827_clickPreventStop_2449,
      evh_5382094220310827_clickPreventStop,
      act_8704926098283402_setData_2454,
      evh_8704926098283402_clickPreventStop_2453,
      act_8704926098283402_reloadSlickgrid_2458,
      evh_8704926098283402_success_2457,
      act_8704926098283402_request_2456,
      evh_8704926098283402_clickPreventStop_2455,
      evh_8704926098283402_clickPreventStop,
      reloadSlickgrid: act_8704926098283402_reloadSlickgrid_2458,
      updateSelectedRegisterId: act_6466705138206980_setData_2440,
      setStatus: act_8704926098283402_request_2456,
    }
  }
}

Vue.component("InvoiceRegister", InvoiceRegister);

</script>