<template>
<div>
  <UcCompanyMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'company'"
  >
  </UcCompanyMiniCard>
  <UcContractMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'contract'"
  >
  </UcContractMiniCard>
  <UcPropertyMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'property' || item.object == 'property_unit'"
  >
  </UcPropertyMiniCard>
  <UcRentalMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'rental'"
  >
  </UcRentalMiniCard>
  <UcWorksheetMiniCard
    :item="item"
    :noLink="noLink"
    v-if="item.object === 'worksheet'"
  >
  </UcWorksheetMiniCard>
</div>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class InvoiceReferenceItem extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("InvoiceReferenceItem", InvoiceReferenceItem);

</script>