<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Terms'|pgettext('Contract') }}
      </strong>
      <ItpButton
        :text="'Add term...'|pgettext('Contract')"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <BListGroupItem
        v-if="!contract.terms || !contract.terms.length"
      >
        <ItpText>
          {{ 'No terms have been added yet.'|pgettext('Contract') }}
        </ItpText>
      </BListGroupItem>
      <BListGroupItem
        :key="term.id"
        v-for="term in filterItems(contract.terms)"
      >
        <ItpBox
          alignItems="center"
        >
          <ItpText
            :text="term.name"
            :strikethrough="isTermStale(term)"
            class="font-weight-bold"
          >
          </ItpText>
          <ItpIcon
            :title="'Locked'|gettext"
            name="fa-lock"
            class="px-3"
            v-if="term.locked"
          >
          </ItpIcon>
          <DIV>
            {{ term.valid_from|date }}
<span v-if="term.valid_from && term.valid_to">-</span>
{{ term.valid_to|date }}

          </DIV>
          <ItpBox>
            <ItpText>
              <ItpText class="px-3">
  <span v-if="term.quantity != null">
    {{ term.quantity|number }} x
  </span>
  <span v-if="term.amount != null"> 
    {{ term.amount|number }}
  </span>
  <span v-if="term.currency"> 
    {{ term.currency }}
  </span>
  <span v-if="term.unit"> 
    / {{ term.unit }}
  </span>
</ItpText>

            </ItpText>
            <ItpText
              :text="term.notes"
              pre
              v-if="term.notes"
            >
            </ItpText>
          </ItpBox>
          <ItpButton
            icon="fa-pen"
            :tooltip="'Edit'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_3577443900630089_clickPreventStop($event, {term})"
          >
          </ItpButton>
        </ItpBox>
        <ItpBox>
          <ItpText
            :text="term.description"
            pre
          >
          </ItpText>
        </ItpBox>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractTerms extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  filterItems(...args: any[]) {
    return _.sortBy(args[0], p => this.isTermStale(p) ? "_" + p.name : p.name)
  }

  isTermStale(...args: any[]) {
    const [term] = args;
    return term.valid_to && this.$fn.parseISODate(term.valid_to) < this.today
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  today!: any;

  async $$load_today() {
    return new Date()
  }

  displayHelper!: any;
  ux!: any;
  dataMembers = ['today', 'displayHelper', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        today: null,
        displayHelper: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6854_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6854, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_6853_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6853, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_6852_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6852, alias=undefined
    return {
      name: "ContractTermEditor",
      props: {
        mode: "add",
        contract: this.contract,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3577443900630089_emit_6858_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3577443900630089_emit_6858, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_3577443900630089_close_6857_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3577443900630089_close_6857, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_3577443900630089_showModal_6856_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3577443900630089_showModal_6856, alias=undefined
    return {
      name: "ContractTermEditor",
      props: {
        mode: "edit",
        contract: this.contract,
        term: _.cloneDeep($event.scope.term),
      }
      ,
    }
  }

  async evh_3577443900630089_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3577443900630089_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6850: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6849: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6854: actions.EmitAction;
    evh_8320016629450276_close_6853: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6852: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6851: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_3577443900630089_emit_6858: actions.EmitAction;
    evh_3577443900630089_close_6857: actions.EventHandlerImpl;
    act_3577443900630089_showModal_6856: actions.ShowModalAction;
    evh_3577443900630089_clickPreventStop_6855: actions.EventHandlerImpl;
    evh_3577443900630089_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6850 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6849 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6850,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6849],
      }
    );
    const act_8320016629450276_emit_6854 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6854_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_8320016629450276_close_6853 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6854,
        event: "close",
        when: this.evh_8320016629450276_close_6853_getWhen,
        displayName: "emitReload",
      }
    );
    const act_8320016629450276_showModal_6852 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6852_getActionArgs,
        events: [evh_8320016629450276_close_6853],
      }
    );
    const evh_8320016629450276_clickPreventStop_6851 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6852,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6851],
      }
    );
    const act_3577443900630089_emit_6858 = new actions.EmitAction(
      {
        actionArgs: this.act_3577443900630089_emit_6858_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_3577443900630089_close_6857 = new actions.EventHandlerImpl(
      {
        action: act_3577443900630089_emit_6858,
        event: "close",
        when: this.evh_3577443900630089_close_6857_getWhen,
        displayName: "emitReload",
      }
    );
    const act_3577443900630089_showModal_6856 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3577443900630089_showModal_6856_getActionArgs,
        events: [evh_3577443900630089_close_6857],
      }
    );
    const evh_3577443900630089_clickPreventStop_6855 = new actions.EventHandlerImpl(
      {
        action: act_3577443900630089_showModal_6856,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3577443900630089_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3577443900630089_clickPreventStop_6855],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6850,
      evh_7315092382398562_reload_6849,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6854,
      evh_8320016629450276_close_6853,
      act_8320016629450276_showModal_6852,
      evh_8320016629450276_clickPreventStop_6851,
      evh_8320016629450276_clickPreventStop,
      act_3577443900630089_emit_6858,
      evh_3577443900630089_close_6857,
      act_3577443900630089_showModal_6856,
      evh_3577443900630089_clickPreventStop_6855,
      evh_3577443900630089_clickPreventStop,
      emitReload: act_3577443900630089_emit_6858,
    }
  }
}

Vue.component("UcContractTerms", UcContractTerms);

</script>