"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceHeader = class UcInvoiceHeader extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    onForm_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formId");
    }
    onInvoice_number(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoiceNumber");
    }
    onForm_context(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("formContext");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                newTransaction: this.$fn.pgettext("Invoice", "New Invoice"),
                invoiceKind: {
                    incoming: this.$fn.pgettext("InvoiceKind", "Incoming Invoice"),
                    outgoing: this.$fn.pgettext("InvoiceKind", "Outgoing Invoice"),
                },
                createCorrectorTooltip: this.$fn.pgettext("Invoice", "Create corrector invoice"),
                createStornoTooltip: this.$fn.pgettext("Invoice", "Create storno invoice"),
            },
            ux: null,
        });
    }
    get resourceTitle() {
        if (this.loaded) {
            return this.messages.invoiceKind[this.invoice.invoice_kind];
        }
        return null;
    }
    get resourceKey() {
        if (this.loaded) {
            return this.invoiceNumber || (this.invoice.isNew ? this.messages.newTransaction : this.invoice.invoice_number);
        }
        return null;
    }
    get createCorrectorTooltip() {
        if (this.loaded) {
            return _.get(this.invoice, 'meta.modifiable.message') || this.messages.createCorrectorTooltip;
        }
        return null;
    }
    get createStornoTooltip() {
        if (this.loaded) {
            return _.get(this.invoice, 'meta.reversible.message') || this.messages.createStornoTooltip;
        }
        return null;
    }
    get invoiceIsReversible() {
        if (this.loaded) {
            return _.get(this.invoice, 'meta.reversible.result');
        }
        return null;
    }
    get invoiceIsModifiable() {
        if (this.loaded) {
            return _.get(this.invoice, 'meta.modifiable.result');
        }
        return null;
    }
    get invoiceIsRegistrable() {
        if (this.loaded) {
            return _.get(this.invoice, 'meta.registrable.result');
        }
        return null;
    }
    act_8680084583475136_emit_5498_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8680084583475136_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8680084583475136_success.executeFromDOM(this, event, scope);
        });
    }
    act_3282321863127091_emit_5500_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3282321863127091_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3282321863127091_success.executeFromDOM(this, event, scope);
        });
    }
    act_2386378572842701_emit_5504_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_2386378572842701_close_5503_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_2386378572842701_showModal_5502_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceEditor",
                props: {
                    invoice: _.cloneDeep(this.invoice),
                },
            };
        });
    }
    evh_2386378572842701_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2386378572842701_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6305342467955186_emit_5506_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_6305342467955186_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6305342467955186_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_6493473528837603_showModal_5508_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceLogMessageViewer",
                props: {
                    invoiceId: this.invoice.id,
                },
            };
        });
    }
    evh_6493473528837603_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_6493473528837603_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_5796712150329752_emit_5512_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_5796712150329752_close_5511_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_5796712150329752_showModal_5510_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceRegisterEntry",
                props: {
                    invoice: this.invoice,
                },
            };
        });
    }
    evh_5796712150329752_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5796712150329752_click.executeFromDOM(this, event, scope);
        });
    }
    act_4125470329773403_emit_5516_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "created",
                value: $event.data.response.data,
            };
        });
    }
    act_4125470329773403_request_5514_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_invoice",
                data: { invoice_id: this.invoice.id, invoice_type: 'copy' },
            };
        });
    }
    evh_4125470329773403_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4125470329773403_click.executeFromDOM(this, event, scope);
        });
    }
    act_3785918277944093_emit_5520_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "created",
                value: $event.data.response.data,
            };
        });
    }
    act_3785918277944093_request_5518_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_invoice",
                data: { invoice_id: this.invoice.id, invoice_type: 'storno' },
            };
        });
    }
    evh_3785918277944093_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3785918277944093_click.executeFromDOM(this, event, scope);
        });
    }
    act_2191919750581405_emit_5524_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "created",
                value: $event.data.response.data,
            };
        });
    }
    act_2191919750581405_request_5522_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_invoice",
                data: {
                    invoice_id: this.invoice.id,
                    invoice_type: 'correction',
                    invoice_lines: _.map(this.formContext.selectedInvoiceLines, p => p.id).join(',')
                },
            };
        });
    }
    evh_2191919750581405_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2191919750581405_click.executeFromDOM(this, event, scope);
        });
    }
    act_3699289115122175_emit_5526_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3699289115122175_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3699289115122175_success.executeFromDOM(this, event, scope);
        });
    }
    act_5417874158095965_emit_5528_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "deleted",
            };
        });
    }
    evh_5417874158095965_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5417874158095965_success.executeFromDOM(this, event, scope);
        });
    }
    act_8518465037810560_showModal_5530_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "entityEvents",
                props: {
                    entityId: this.invoice.id,
                },
            };
        });
    }
    evh_8518465037810560_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8518465037810560_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_8680084583475136_emit_5498 = new core_1.actions.EmitAction({
            actionArgs: this.act_8680084583475136_emit_5498_getActionArgs,
            events: [],
        });
        const evh_8680084583475136_success_5497 = new core_1.actions.EventHandlerImpl({
            action: act_8680084583475136_emit_5498,
            event: "success",
            displayName: "emit",
        });
        const evh_8680084583475136_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8680084583475136_success_5497],
        });
        const act_3282321863127091_emit_5500 = new core_1.actions.EmitAction({
            actionArgs: this.act_3282321863127091_emit_5500_getActionArgs,
            events: [],
        });
        const evh_3282321863127091_success_5499 = new core_1.actions.EventHandlerImpl({
            action: act_3282321863127091_emit_5500,
            event: "success",
            displayName: "emit",
        });
        const evh_3282321863127091_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3282321863127091_success_5499],
        });
        const act_2386378572842701_emit_5504 = new core_1.actions.EmitAction({
            actionArgs: this.act_2386378572842701_emit_5504_getActionArgs,
            events: [],
        });
        const evh_2386378572842701_close_5503 = new core_1.actions.EventHandlerImpl({
            action: act_2386378572842701_emit_5504,
            event: "close",
            when: this.evh_2386378572842701_close_5503_getWhen,
            displayName: "emit",
        });
        const act_2386378572842701_showModal_5502 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_2386378572842701_showModal_5502_getActionArgs,
            events: [evh_2386378572842701_close_5503],
        });
        const evh_2386378572842701_clickPreventStop_5501 = new core_1.actions.EventHandlerImpl({
            action: act_2386378572842701_showModal_5502,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_2386378572842701_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2386378572842701_clickPreventStop_5501],
        });
        const act_6305342467955186_emit_5506 = new core_1.actions.EmitAction({
            actionArgs: this.act_6305342467955186_emit_5506_getActionArgs,
            displayName: "reload",
            events: [],
        });
        const evh_6305342467955186_clickPreventStop_5505 = new core_1.actions.EventHandlerImpl({
            action: act_6305342467955186_emit_5506,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_6305342467955186_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6305342467955186_clickPreventStop_5505],
        });
        const act_6493473528837603_showModal_5508 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_6493473528837603_showModal_5508_getActionArgs,
            events: [],
        });
        const evh_6493473528837603_clickPreventStop_5507 = new core_1.actions.EventHandlerImpl({
            action: act_6493473528837603_showModal_5508,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_6493473528837603_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_6493473528837603_clickPreventStop_5507],
        });
        const act_5796712150329752_emit_5512 = new core_1.actions.EmitAction({
            actionArgs: this.act_5796712150329752_emit_5512_getActionArgs,
            events: [],
        });
        const evh_5796712150329752_close_5511 = new core_1.actions.EventHandlerImpl({
            action: act_5796712150329752_emit_5512,
            event: "close",
            when: this.evh_5796712150329752_close_5511_getWhen,
            displayName: "emit",
        });
        const act_5796712150329752_showModal_5510 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_5796712150329752_showModal_5510_getActionArgs,
            displayName: "registerInvoice",
            events: [evh_5796712150329752_close_5511],
        });
        const evh_5796712150329752_click_5509 = new core_1.actions.EventHandlerImpl({
            action: act_5796712150329752_showModal_5510,
            event: "click",
            displayName: "registerInvoice",
        });
        const evh_5796712150329752_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5796712150329752_click_5509],
        });
        const act_4125470329773403_emit_5516 = new core_1.actions.EmitAction({
            actionArgs: this.act_4125470329773403_emit_5516_getActionArgs,
            events: [],
        });
        const evh_4125470329773403_success_5515 = new core_1.actions.EventHandlerImpl({
            action: act_4125470329773403_emit_5516,
            event: "success",
            displayName: "emit",
        });
        const act_4125470329773403_request_5514 = new core_1.actions.RequestAction({
            actionArgs: this.act_4125470329773403_request_5514_getActionArgs,
            displayName: "createInvoicecopy",
            events: [evh_4125470329773403_success_5515],
        });
        const evh_4125470329773403_click_5513 = new core_1.actions.EventHandlerImpl({
            action: act_4125470329773403_request_5514,
            event: "click",
            displayName: "createInvoicecopy",
        });
        const evh_4125470329773403_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4125470329773403_click_5513],
        });
        const act_3785918277944093_emit_5520 = new core_1.actions.EmitAction({
            actionArgs: this.act_3785918277944093_emit_5520_getActionArgs,
            events: [],
        });
        const evh_3785918277944093_success_5519 = new core_1.actions.EventHandlerImpl({
            action: act_3785918277944093_emit_5520,
            event: "success",
            displayName: "emit",
        });
        const act_3785918277944093_request_5518 = new core_1.actions.RequestAction({
            actionArgs: this.act_3785918277944093_request_5518_getActionArgs,
            displayName: "createStornoInvoice",
            events: [evh_3785918277944093_success_5519],
        });
        const evh_3785918277944093_click_5517 = new core_1.actions.EventHandlerImpl({
            action: act_3785918277944093_request_5518,
            event: "click",
            displayName: "createStornoInvoice",
        });
        const evh_3785918277944093_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3785918277944093_click_5517],
        });
        const act_2191919750581405_emit_5524 = new core_1.actions.EmitAction({
            actionArgs: this.act_2191919750581405_emit_5524_getActionArgs,
            events: [],
        });
        const evh_2191919750581405_success_5523 = new core_1.actions.EventHandlerImpl({
            action: act_2191919750581405_emit_5524,
            event: "success",
            displayName: "emit",
        });
        const act_2191919750581405_request_5522 = new core_1.actions.RequestAction({
            actionArgs: this.act_2191919750581405_request_5522_getActionArgs,
            displayName: "createCorrectorIvoice",
            events: [evh_2191919750581405_success_5523],
        });
        const evh_2191919750581405_click_5521 = new core_1.actions.EventHandlerImpl({
            action: act_2191919750581405_request_5522,
            event: "click",
            displayName: "createCorrectorIvoice",
        });
        const evh_2191919750581405_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2191919750581405_click_5521],
        });
        const act_3699289115122175_emit_5526 = new core_1.actions.EmitAction({
            actionArgs: this.act_3699289115122175_emit_5526_getActionArgs,
            events: [],
        });
        const evh_3699289115122175_success_5525 = new core_1.actions.EventHandlerImpl({
            action: act_3699289115122175_emit_5526,
            event: "success",
            displayName: "emit",
        });
        const evh_3699289115122175_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3699289115122175_success_5525],
        });
        const act_5417874158095965_emit_5528 = new core_1.actions.EmitAction({
            actionArgs: this.act_5417874158095965_emit_5528_getActionArgs,
            events: [],
        });
        const evh_5417874158095965_success_5527 = new core_1.actions.EventHandlerImpl({
            action: act_5417874158095965_emit_5528,
            event: "success",
            displayName: "emit",
        });
        const evh_5417874158095965_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5417874158095965_success_5527],
        });
        const act_8518465037810560_showModal_5530 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8518465037810560_showModal_5530_getActionArgs,
            events: [],
        });
        const evh_8518465037810560_clickPreventStop_5529 = new core_1.actions.EventHandlerImpl({
            action: act_8518465037810560_showModal_5530,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8518465037810560_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8518465037810560_clickPreventStop_5529],
        });
        return {
            act_8680084583475136_emit_5498,
            evh_8680084583475136_success_5497,
            evh_8680084583475136_success,
            act_3282321863127091_emit_5500,
            evh_3282321863127091_success_5499,
            evh_3282321863127091_success,
            act_2386378572842701_emit_5504,
            evh_2386378572842701_close_5503,
            act_2386378572842701_showModal_5502,
            evh_2386378572842701_clickPreventStop_5501,
            evh_2386378572842701_clickPreventStop,
            act_6305342467955186_emit_5506,
            evh_6305342467955186_clickPreventStop_5505,
            evh_6305342467955186_clickPreventStop,
            act_6493473528837603_showModal_5508,
            evh_6493473528837603_clickPreventStop_5507,
            evh_6493473528837603_clickPreventStop,
            act_5796712150329752_emit_5512,
            evh_5796712150329752_close_5511,
            act_5796712150329752_showModal_5510,
            evh_5796712150329752_click_5509,
            evh_5796712150329752_click,
            act_4125470329773403_emit_5516,
            evh_4125470329773403_success_5515,
            act_4125470329773403_request_5514,
            evh_4125470329773403_click_5513,
            evh_4125470329773403_click,
            act_3785918277944093_emit_5520,
            evh_3785918277944093_success_5519,
            act_3785918277944093_request_5518,
            evh_3785918277944093_click_5517,
            evh_3785918277944093_click,
            act_2191919750581405_emit_5524,
            evh_2191919750581405_success_5523,
            act_2191919750581405_request_5522,
            evh_2191919750581405_click_5521,
            evh_2191919750581405_click,
            act_3699289115122175_emit_5526,
            evh_3699289115122175_success_5525,
            evh_3699289115122175_success,
            act_5417874158095965_emit_5528,
            evh_5417874158095965_success_5527,
            evh_5417874158095965_success,
            act_8518465037810560_showModal_5530,
            evh_8518465037810560_clickPreventStop_5529,
            evh_8518465037810560_clickPreventStop,
            reload: act_6305342467955186_emit_5506,
            registerInvoice: act_5796712150329752_showModal_5510,
            createInvoicecopy: act_4125470329773403_request_5514,
            createStornoInvoice: act_3785918277944093_request_5518,
            createCorrectorIvoice: act_2191919750581405_request_5522,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceHeader.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceHeader.prototype, "onInvoice", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: function () {
            return this.$fn.randomElementId();
        },
    }),
    __metadata("design:type", String)
], UcInvoiceHeader.prototype, "formId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceHeader.prototype, "onForm_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcInvoiceHeader.prototype, "invoiceNumber", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoiceNumber'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceHeader.prototype, "onInvoice_number", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceHeader.prototype, "formContext", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('formContext'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceHeader.prototype, "onForm_context", null);
UcInvoiceHeader = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceHeader);
exports.default = UcInvoiceHeader;
vue_property_decorator_1.Vue.component("UcInvoiceHeader", UcInvoiceHeader);
