var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        "no-header": "",
        contentClass: "p-0",
        "no-primary-button": "",
        "no-close-button": "",
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "Company",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "MaterialTabs",
            {
              attrs: { closeButton: _vm.modal, tabManager: _vm.tabManager },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              }
            },
            [
              _c(
                "ItpBox",
                {
                  staticClass: "mr-3",
                  attrs: { slot: "tabs-start", alignItems: "center" },
                  slot: "tabs-start"
                },
                [
                  _c(
                    "b-breadcrumb",
                    { staticClass: "m-0 bg-white" },
                    [
                      _c("b-breadcrumb-item", {
                        attrs: {
                          text: _vm.messages.parent[_vm.item.role || _vm.role],
                          to: _vm.b_4615685607825208_to
                        }
                      }),
                      _vm._v(" "),
                      _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                        _c("strong", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.item.snapshot("name")) +
                              "\n          "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("BtnDeleteResource", {
                    staticClass: "mr-2",
                    attrs: {
                      resource: _vm.item,
                      "no-text": "",
                      dialogHeader:
                        _vm.messages.delete[_vm.item.role || _vm.role]
                    },
                    on: {
                      success: function($event) {
                        return _vm.evh_8320016629450276_success($event, {})
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("ItpButton", {
                    staticClass: "px-3",
                    attrs: {
                      variant: "default",
                      icon: "fa-sync-alt",
                      text: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6705964765214521_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.loaded
                    ? _c("BtnSyncResource", {
                        staticClass: "ml-auto mr-2",
                        attrs: { resource: _vm.item, size: "sm" },
                        on: {
                          success: function($event) {
                            return _vm.evh_6806515264385235_success($event, {})
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container float-left",
                  attrs: { title: _vm._f("gettext")("General") }
                },
                [
                  _c(
                    "ItpRow",
                    [
                      _c(
                        "ItpCol",
                        { attrs: { w: "6" } },
                        [
                          _c("UcCompanyGeneral", {
                            staticClass: "pb-4",
                            attrs: { item: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_5477420070495986_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UcContactList", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_6337614691287098_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UcEmployeeList", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_2406129194387753_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpCol",
                        { attrs: { w: "6" } },
                        [
                          _c("UcAddressList", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_4071170783051919_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCompanyBankAccounts", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded },
                            on: {
                              reload: function($event) {
                                return _vm.evh_7713630524832656_reload(
                                  $event,
                                  {}
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("UcCommentsList", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded }
                          }),
                          _vm._v(" "),
                          _c("UcAttachmentsList", {
                            staticClass: "pb-4",
                            attrs: { parent: _vm.item, reload: _vm.lastLoaded }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.role === "own"
                ? _c(
                    "b-tab",
                    {
                      staticClass: "container-fluid h-100",
                      attrs: {
                        title: _vm._f("pgettext")("Accounts", "Company")
                      }
                    },
                    [
                      _vm.tabManager.visited(1)
                        ? _c("AccountsView", {
                            staticClass: "h-100",
                            attrs: {
                              companyId: _vm.item.id,
                              reload: _vm.lastLoaded
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: { title: _vm._f("pgettext")("Invoices", "Company") }
                },
                [
                  _vm.tabManager.visited(2)
                    ? _c("InvoiceLinesView", {
                        staticClass: "h-100",
                        attrs: {
                          companyId:
                            _vm.item.role === "own" ? _vm.item.id : null,
                          partnerId:
                            _vm.item.role === "partner" ? _vm.item.id : null,
                          oid: _vm.item.id,
                          reload: _vm.lastLoaded
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: { title: _vm._f("pgettext")("Payments", "Company") }
                },
                [
                  _vm.tabManager.visited(3)
                    ? _c("PaymentsView", {
                        staticClass: "h-100",
                        attrs: {
                          parameters: _vm.b_8449679363704630_parameters,
                          reload: _vm.lastLoaded
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: { title: _vm._f("pgettext")("Statement", "Company") }
                },
                [
                  _vm.tabManager.visited(4)
                    ? _c("UcCompanyStatement", {
                        staticClass: "h-100",
                        attrs: { oid: _vm.item.id, reload: _vm.lastLoaded }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }