<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Numberings"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-auto h-100 p-3"
  >
    <ItpBox
      direction="column"
    >
      <ItpBox>
        <ItpButton
          :text="'Add numbering...'|pgettext('Numberings')"
          @click="evh_4615685607825208_click($event, {})"
        >
        </ItpButton>
        <ItpButton
          :text="'Reload'|pgettext('Numberings')"
          @click="evh_2095089031469157_click($event, {})"
        >
        </ItpButton>
      </ItpBox>
      <ItpDataTable
        tableId="Settings.Numberings"
        :editable="b_8320016629450276_editable"
        no-create-row
        no-delete-row
        :value="_.orderBy(items, ['key', 'description'])"
        :columns="b_8320016629450276_columns"
        class="h-100"
        ref="table"
        v-if="loaded"
        @save="evh_8320016629450276_save($event, {})"
        @delete="evh_8320016629450276_delete($event, {})"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpDataTable>
    </ItpBox>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.numberings",
    path: "numberings",
    component: "Numberings"
  }
)

@Component()
export default class Numberings extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_numbering')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8320016629450276_editable!: any;
  b_8320016629450276_columns!: any;
  dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings', 'b_8320016629450276_editable', 'b_8320016629450276_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_8320016629450276_editable: false,
        b_8320016629450276_columns: [{
          name: "key",
          header: this.$fn.pgettext("Numbering", "Key"),
          type: "link",
          width: "120px",
        }
          ,
        {
          name: "resource_type",
          header: this.$fn.pgettext("Numbering", "Resource Type"),
          width: "120px",
        }
          ,
        {
          name: "description",
          header: this.$fn.pgettext("Numbering", "Description"),
          width: "160px",
        }
          ,
        {
          name: "format",
          header: this.$fn.pgettext("Numbering", "Format"),
          width: "260px",
        }
          ,
        {
          name: "increment",
          type: "number",
          header: this.$fn.pgettext("Numbering", "Increment"),
          width: "110px",
        }
          ,
        {
          name: "next_value",
          type: "number",
          header: this.$fn.pgettext("Numbering", "Next value"),
          width: "110px",
        }
          ,
        {
          name: "next_number",
          type: "number",
          header: this.$fn.pgettext("Numbering", "Next Number"),
          width: "110px",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_2920_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2920, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_4615685607825208_showModal_2922_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4615685607825208_showModal_2922, alias=undefined
    return {
      name: "Numbering",
    }
  }

  async evh_4615685607825208_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4615685607825208_click.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_script_2926_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8320016629450276_script_2926, alias=undefined
    $event.data.waitFor(
      this.$fn.crud('numbering').upsert({
        ...$event.data.row,
        start: $event.data.row.lastvalue
      })
    )
  }

  async act_8320016629450276_script_2926_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8320016629450276_script_2926, alias=undefined
    return {
      value: () => this.act_8320016629450276_script_2926_getActionArgs_value($event),
    }
  }

  async evh_8320016629450276_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_save.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_script_2928_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8320016629450276_script_2928, alias=undefined
    $event.data.waitFor(this.$fn.crud('numbering').delete($event.data.row))
  }

  async act_8320016629450276_script_2928_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8320016629450276_script_2928, alias=undefined
    return {
      value: () => this.act_8320016629450276_script_2928_getActionArgs_value($event),
    }
  }

  async evh_8320016629450276_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_delete.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_2930_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_2930, alias=undefined
    return {
      name: "Numbering",
      props: {
        oid: $event.data.row.id,
      }
      ,
    }
  }

  async evh_8320016629450276_click_2929_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_click_2929, alias=undefined
    return $event.data.col.name === 'key'
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2920: actions.CloseModalAction;
    evh_7315092382398562_close_2919: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_4615685607825208_showModal_2922: actions.ShowModalAction;
    evh_4615685607825208_click_2921: actions.EventHandlerImpl;
    evh_4615685607825208_click: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_2924: actions.ReloadComponentDataAction;
    evh_2095089031469157_click_2923: actions.EventHandlerImpl;
    evh_2095089031469157_click: actions.EventHandlerGroup;
    act_8320016629450276_script_2926: actions.ScriptAction;
    evh_8320016629450276_save_2925: actions.EventHandlerImpl;
    evh_8320016629450276_save: actions.EventHandlerGroup;
    act_8320016629450276_script_2928: actions.ScriptAction;
    evh_8320016629450276_delete_2927: actions.EventHandlerImpl;
    evh_8320016629450276_delete: actions.EventHandlerGroup;
    act_8320016629450276_showModal_2930: actions.ShowModalAction;
    evh_8320016629450276_click_2929: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2920 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2920_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2919 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2920,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2919],
      }
    );
    const act_4615685607825208_showModal_2922 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4615685607825208_showModal_2922_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_click_2921 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_showModal_2922,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_4615685607825208_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4615685607825208_click_2921],
      }
    );
    const act_2095089031469157_reloadComponentData_2924 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2095089031469157_click_2923 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_2924,
        event: "click",
        displayName: "reloadComponentData",
      }
    );
    const evh_2095089031469157_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_click_2923],
      }
    );
    const act_8320016629450276_script_2926 = new actions.ScriptAction(
      {
        actionArgs: this.act_8320016629450276_script_2926_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_save_2925 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_script_2926,
        event: "save",
        displayName: "script",
      }
    );
    const evh_8320016629450276_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_save_2925],
      }
    );
    const act_8320016629450276_script_2928 = new actions.ScriptAction(
      {
        actionArgs: this.act_8320016629450276_script_2928_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_delete_2927 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_script_2928,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_8320016629450276_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_delete_2927],
      }
    );
    const act_8320016629450276_showModal_2930 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_2930_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_2929 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_2930,
        event: "click",
        when: this.evh_8320016629450276_click_2929_getWhen,
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_2929],
      }
    );
    return {
      act_7315092382398562_closeModal_2920,
      evh_7315092382398562_close_2919,
      evh_7315092382398562_close,
      act_4615685607825208_showModal_2922,
      evh_4615685607825208_click_2921,
      evh_4615685607825208_click,
      act_2095089031469157_reloadComponentData_2924,
      evh_2095089031469157_click_2923,
      evh_2095089031469157_click,
      act_8320016629450276_script_2926,
      evh_8320016629450276_save_2925,
      evh_8320016629450276_save,
      act_8320016629450276_script_2928,
      evh_8320016629450276_delete_2927,
      evh_8320016629450276_delete,
      act_8320016629450276_showModal_2930,
      evh_8320016629450276_click_2929,
      evh_8320016629450276_click,
    }
  }
}

Vue.component("Numberings", Numberings);

</script>