<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="AccountStatementReport"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    stretch
    v-if="loaded"
  >
    <ItpBox
      direction="column"
      class="h-100 w-100"
    >
      <DIV
        class="border-bottom"
      >
        <ItpForm
          :id="formId"
          name="payment"
          class="pb-3"
          ref="mainForm"
        >
          <ItpBox>
            <UcAccountSelector
              v-model="item.account"
              accountType="cash_book"
              class="mr-2"
            >
            </UcAccountSelector>
            <DateRangeSelector
              name="paymentFilters"
              :from="item.date_from"
              :to="item.date_to"
              class="mr-2"
              @changed="evh_8527427120829392_changed($event, {})"
            >
            </DateRangeSelector>
            <DIV>
              <ItpButton
                :text="'Display...'|pgettext('Button')"
                icon="ri:barcode-box-line"
                :disabled="connector.loading"
                :spinning="connector.loading"
                @click="evh_1729682679878258_click($event, {})"
              >
              </ItpButton>
            </DIV>
          </ItpBox>
        </ItpForm>
      </DIV>
      <ItpPdfViewer
        :file="file && file.file"
        class="flex-fill"
      >
      </ItpPdfViewer>
    </ItpBox>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class AccountStatementReport extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "AccountStatementReport.item");
    this.$$cache_responseType = new ComponentValueCache(this, "responseType", "AccountStatementReport.responseType");
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Array,
    default: null,
  })
  invoices!: array;

  @Watch('invoices')
  onInvoices(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoices")
  }

  @Prop({
    type: String,
    default: null,
  })
  transactionType!: string;

  @Watch('transactionType')
  onTransaction_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionType")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  accounts!: any;

  async $$load_accounts() {
    return this.$fn.fetch('list_account')
      .then(resp => _.orderBy(resp.data, ['inactive', 'account_type', 'name'], ['asc', 'desc', 'asc']))
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  connector!: any;

  async $$load_connector() {
    return this.$fn.resourceProxy({
      url: () => this.$fn.urlFor('report_account_statement', { ...this.item, account: this.item.account.id }),
      useAuth: true,
    }).on('file', args => this.setData('file', args))
  }

  item!: any;

  async $$load_item() {
    return {
      date_from: null,
      date_to: null,
      account: this.accounts ? this.accounts[0] : null,
    }
  }

  file!: any;
  responseType!: any;
  responseTypes!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['accounts', 'formId', 'connector', 'item', 'file', 'responseType', 'responseTypes', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        accounts: null,
        formId: null,
        connector: null,
        item: null,
        file: null,
        responseType: "json",
        responseTypes: ["json"
          ,
          "pdf"
          ,
        ]
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "full-screen",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("AccountStatementReport/title", "Account Statement Report"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_2962_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2962, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_8527427120829392_setData_2966_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8527427120829392_setData_2966, alias=undefined
    return {
      path: "item.date_from",
      value: $event.data[0],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_8527427120829392_setData_2968_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_8527427120829392_setData_2968, alias=undefined
    return {
      path: "item.date_to",
      value: $event.data[1],
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_8527427120829392_changed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8527427120829392_changed.executeFromDOM(this, event, scope);
  }

  async act_1729682679878258_script_2970_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1729682679878258_script_2970, alias=undefined
    this.withErrorHandler(this.connector.load())
  }

  async act_1729682679878258_script_2970_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1729682679878258_script_2970, alias=undefined
    return {
      value: () => this.act_1729682679878258_script_2970_getActionArgs_value($event),
    }
  }

  async evh_1729682679878258_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1729682679878258_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2962: actions.CloseModalAction;
    evh_7315092382398562_close_2961: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_2964: actions.CloseModalAction;
    evh_2248226175642056_close_2963: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8527427120829392_setData_2966: actions.SetDataAction;
    evh_8527427120829392_changed_2965: actions.EventHandlerImpl;
    act_8527427120829392_setData_2968: actions.SetDataAction;
    evh_8527427120829392_changed_2967: actions.EventHandlerImpl;
    evh_8527427120829392_changed: actions.EventHandlerGroup;
    act_1729682679878258_script_2970: actions.ScriptAction;
    evh_1729682679878258_click_2969: actions.EventHandlerImpl;
    evh_1729682679878258_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2962 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2962_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2961 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2962,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2961],
      }
    );
    const act_2248226175642056_closeModal_2964 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2963 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_2964,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2963],
      }
    );
    const act_8527427120829392_setData_2966 = new actions.SetDataAction(
      {
        actionArgs: this.act_8527427120829392_setData_2966_getActionArgs,
        events: [],
      }
    );
    const evh_8527427120829392_changed_2965 = new actions.EventHandlerImpl(
      {
        action: act_8527427120829392_setData_2966,
        event: "changed",
        displayName: "setData",
      }
    );
    const act_8527427120829392_setData_2968 = new actions.SetDataAction(
      {
        actionArgs: this.act_8527427120829392_setData_2968_getActionArgs,
        events: [],
      }
    );
    const evh_8527427120829392_changed_2967 = new actions.EventHandlerImpl(
      {
        action: act_8527427120829392_setData_2968,
        event: "changed",
        displayName: "setData",
      }
    );
    const evh_8527427120829392_changed = new actions.EventHandlerGroup(
      {
        handlers: [evh_8527427120829392_changed_2965, evh_8527427120829392_changed_2967],
      }
    );
    const act_1729682679878258_script_2970 = new actions.ScriptAction(
      {
        actionArgs: this.act_1729682679878258_script_2970_getActionArgs,
        events: [],
      }
    );
    const evh_1729682679878258_click_2969 = new actions.EventHandlerImpl(
      {
        action: act_1729682679878258_script_2970,
        event: "click",
        displayName: "script",
      }
    );
    const evh_1729682679878258_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1729682679878258_click_2969],
      }
    );
    return {
      act_7315092382398562_closeModal_2962,
      evh_7315092382398562_close_2961,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_2964,
      evh_2248226175642056_close_2963,
      evh_2248226175642056_close,
      act_8527427120829392_setData_2966,
      evh_8527427120829392_changed_2965,
      act_8527427120829392_setData_2968,
      evh_8527427120829392_changed_2967,
      evh_8527427120829392_changed,
      act_1729682679878258_script_2970,
      evh_1729682679878258_click_2969,
      evh_1729682679878258_click,
    }
  }
}

Vue.component("AccountStatementReport", AccountStatementReport);

</script>