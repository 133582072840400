<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="CreateProduct"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :dialogName="resourceTitle"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      schema="CreateProduct"
      :id="formId"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetProductGeneral
        :product="editor"
      >
      </FieldsetProductGeneral>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpBox
      alignItems="center"
    >
      <ItpFormGroup
        labelColsMd="4"
        name="sync"
      >
        <ItpFormCheckbox
          name="sync"
          :label="'IntegroRent sync'|pgettext('Product')"
          v-model="editor.sync"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
    </ItpBox>
    <ItpButton
      :text="'Save'|pgettext('Button')"
      type="submit"
      :form="forms[formName]"
      :spinning="actions.create.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      :disabled="actions.create.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CreateProduct extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
    this.$$cache_item = new ComponentValueCache(this, "item", "Product.create");
  }

  @Prop({
    type: Boolean,
  })
  preventDefaultOnClose!: boolean;

  @Watch('preventDefaultOnClose')
  onPrevent_default_on_close(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("preventDefaultOnClose")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.$fn.schemaDefaults('CreateProductCommand', { sync: true })
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  resource!: any;

  async $$load_resource() {
    return new this.$fn.Resource(this.item)
  }

  editor!: any;

  async $$load_editor() {
    return this.resource.editor()
  }

  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_product'),
      object: 'list',
      data: []
    }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['item', 'formName', 'formId', 'resource', 'editor', 'container', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        formName: "meter",
        formId: null,
        resource: null,
        editor: null,
        container: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get resourceTitle() {
    if (this.loaded) {

      return this.$fn.pgettext("Product", "Product")
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Product", "New product"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3084_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3084, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_3090_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3090, alias=undefined
    return {
      path: "item",
      value: this.editor.getData(),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_closeComponent_3092_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1040167445267876_closeComponent_3092, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_navigate_3094_getActionArgs($event: actions.ActionEvent): Promise<actions.NavigateActionArgs> {
    // parameterResolver name=act_1040167445267876_navigate_3094, alias=undefined
    return {
      location: {
        name: "app.product",
        params: {
          id: $event.data.response.data.id,
        }
        ,
      }
      ,
    }
  }

  async evh_1040167445267876_created_3093_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_created_3093, alias=undefined
    return !this.preventDefaultOnClose
  }

  async act_1040167445267876_reloadSlickgrid_3096_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3096, alias=undefined
    return {
      grid: "products",
    }
  }

  async evh_1040167445267876_created_3095_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1040167445267876_created_3095, alias=undefined
    return !this.preventDefaultOnClose
  }

  async act_1040167445267876_rest_3088_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1040167445267876_rest_3088, alias=create
    return {
      method: "save",
      container: this.container,
      resource: this.editor,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3084: actions.CloseModalAction;
    evh_7315092382398562_close_3083: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3086: actions.CloseModalAction;
    evh_2248226175642056_close_3085: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_setData_3090: actions.SetDataAction;
    evh_1040167445267876_before_3089: actions.EventHandlerImpl;
    act_1040167445267876_closeComponent_3092: actions.CloseComponentAction;
    evh_1040167445267876_created_3091: actions.EventHandlerImpl;
    act_1040167445267876_navigate_3094: actions.NavigateAction;
    evh_1040167445267876_created_3093: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3096: actions.ReloadSlickgridAction;
    evh_1040167445267876_created_3095: actions.EventHandlerImpl;
    act_1040167445267876_rest_3088: actions.RestAction;
    evh_1040167445267876_submit_3087: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_2186679009640457_closeComponent_3098: actions.CloseComponentAction;
    evh_2186679009640457_clickPreventStop_3097: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3084 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3084_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3083 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3084,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3083],
      }
    );
    const act_2248226175642056_closeModal_3086 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3085 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3086,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3085],
      }
    );
    const act_1040167445267876_setData_3090 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_3090_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_before_3089 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_3090,
        event: "before",
        displayName: "setData",
      }
    );
    const act_1040167445267876_closeComponent_3092 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1040167445267876_closeComponent_3092_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_created_3091 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeComponent_3092,
        event: "created",
        displayName: "closeComponent",
      }
    );
    const act_1040167445267876_navigate_3094 = new actions.NavigateAction(
      {
        actionArgs: this.act_1040167445267876_navigate_3094_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_created_3093 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_navigate_3094,
        event: "created",
        when: this.evh_1040167445267876_created_3093_getWhen,
        displayName: "navigate",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3096 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3096_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_created_3095 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3096,
        event: "created",
        when: this.evh_1040167445267876_created_3095_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_rest_3088 = new actions.RestAction(
      {
        actionArgs: this.act_1040167445267876_rest_3088_getActionArgs,
        displayName: "create",
        events: [evh_1040167445267876_before_3089, evh_1040167445267876_created_3091, evh_1040167445267876_created_3093, evh_1040167445267876_created_3095],
      }
    );
    const evh_1040167445267876_submit_3087 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_rest_3088,
        event: "submit",
        displayName: "create",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3087],
      }
    );
    const act_2186679009640457_closeComponent_3098 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_3097 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeComponent_3098,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_3097],
      }
    );
    return {
      act_7315092382398562_closeModal_3084,
      evh_7315092382398562_close_3083,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_3086,
      evh_2248226175642056_close_3085,
      evh_2248226175642056_close,
      act_1040167445267876_setData_3090,
      evh_1040167445267876_before_3089,
      act_1040167445267876_closeComponent_3092,
      evh_1040167445267876_created_3091,
      act_1040167445267876_navigate_3094,
      evh_1040167445267876_created_3093,
      act_1040167445267876_reloadSlickgrid_3096,
      evh_1040167445267876_created_3095,
      act_1040167445267876_rest_3088,
      evh_1040167445267876_submit_3087,
      evh_1040167445267876_submit,
      act_2186679009640457_closeComponent_3098,
      evh_2186679009640457_clickPreventStop_3097,
      evh_2186679009640457_clickPreventStop,
      create: act_1040167445267876_rest_3088,
    }
  }
}

Vue.component("CreateProduct", CreateProduct);

</script>