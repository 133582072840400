<template>
<ItpBox
  alignItems="center"
  class="items-spacing w-100"
  v-if="loaded"
>
  <ItpBox
    direction="column"
  >
    <ItpBox>
      <ItpText
        muted
        class="mb-0"
      >
        {{ config.resourceTitle }}
      </ItpText>
      <UcPaymentTransactionTypeSymbol
        :transactionType="payment.transaction_type"
        class="mx-2"
        v-if="payment.transaction_type"
      >
      </UcPaymentTransactionTypeSymbol>
    </ItpBox>
    <ItpText
      tag="h4"
      :text="resourceKey"
      class="mb-0"
    >
    </ItpText>
  </ItpBox>
  <DIV
    class="btn-toolbar ml-auto"
  >
    <ItpButton
      icon="fa-sync-alt"
      :tooltip="'Reload'|gettext"
      :spinning="actions.reload.isRunning"
      variant="light"
      class="ml-auto mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_1040167445267876_clickPreventStop($event, {})"
    >
    </ItpButton>
    <BtnSyncResource
      :resource="payment"
      target="irent"
      confirmMessage="A tranzakciót csak egyszer lehet felölteni a régi rendszerbe,
feltöltés után a tranzakciót már nem lehet módosítani."
      class="ml-auto mr-2"
      v-if="!payment.isNew"
      @success="evh_4186377094414664_success($event, {})"
    >
    </BtnSyncResource>
    <ItpButton
      variant="light"
      icon="icon-park-outline:preview-open"
      :href="payment.meta.preview_url"
      :tooltip="'Open preview'|pgettext('Payment')"
      target="_blank"
      use-auth
      show-spinner
      class="ml-auto mr-2"
      v-if="payment.meta && payment.meta.preview_url"
    >
    </ItpButton>
    <b-dropdown
      right
      variant="light"
      class="border"
      v-if="!isNew"
    >
      <ItpIcon
        fa="bars"
        class="mx-1"
        slot="button-content"
      >
      </ItpIcon>
      <BtnDeleteResource
        no-icon
        dropdown-item
        :resource="payment"
        :tooltip="'Delete payment'|pgettext('Payment')"
        :disabled="payment.isReadonly"
        v-if="!isNew"
        @success="evh_1506888955587522_success($event, {})"
      >
      </BtnDeleteResource>
    </b-dropdown>
  </DIV>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcPaymentHeader extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  payment!: object;

  @Watch('payment')
  onPayment(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payment")
  }

  @Prop({
    required: true,
    type: Object,
  })
  config!: object;

  @Watch('config')
  onConfig(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("config")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  get isNew() {
    if (this.loaded) {

      return !this.payment.id
    }
    return null;
  }

  get isPettyCash() {
    if (this.loaded) {

      return this.payment.transaction_kind === 'cash_book'
    }
    return null;
  }

  get resourceKey() {
    if (this.loaded) {

      return this.isNew ? this.config.newTransaction : this.payment.snapshot('transaction_number')
    }
    return null;
  }

  async act_1040167445267876_emit_5714_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1040167445267876_emit_5714, alias=reload
    return {
      event: "reload",
    }
  }

  async evh_1040167445267876_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_4186377094414664_emit_5716_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_4186377094414664_emit_5716, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_4186377094414664_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4186377094414664_success.executeFromDOM(this, event, scope);
  }

  async act_1506888955587522_emit_5718_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_1506888955587522_emit_5718, alias=undefined
    return {
      event: "deleted",
    }
  }

  async evh_1506888955587522_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1506888955587522_success.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_1040167445267876_emit_5714: actions.EmitAction;
    evh_1040167445267876_clickPreventStop_5713: actions.EventHandlerImpl;
    evh_1040167445267876_clickPreventStop: actions.EventHandlerGroup;
    act_4186377094414664_emit_5716: actions.EmitAction;
    evh_4186377094414664_success_5715: actions.EventHandlerImpl;
    evh_4186377094414664_success: actions.EventHandlerGroup;
    act_1506888955587522_emit_5718: actions.EmitAction;
    evh_1506888955587522_success_5717: actions.EventHandlerImpl;
    evh_1506888955587522_success: actions.EventHandlerGroup;
  }

  getActions() {
    const act_1040167445267876_emit_5714 = new actions.EmitAction(
      {
        actionArgs: this.act_1040167445267876_emit_5714_getActionArgs,
        displayName: "reload",
        events: [],
      }
    );
    const evh_1040167445267876_clickPreventStop_5713 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_emit_5714,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_1040167445267876_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_clickPreventStop_5713],
      }
    );
    const act_4186377094414664_emit_5716 = new actions.EmitAction(
      {
        actionArgs: this.act_4186377094414664_emit_5716_getActionArgs,
        events: [],
      }
    );
    const evh_4186377094414664_success_5715 = new actions.EventHandlerImpl(
      {
        action: act_4186377094414664_emit_5716,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_4186377094414664_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_4186377094414664_success_5715],
      }
    );
    const act_1506888955587522_emit_5718 = new actions.EmitAction(
      {
        actionArgs: this.act_1506888955587522_emit_5718_getActionArgs,
        events: [],
      }
    );
    const evh_1506888955587522_success_5717 = new actions.EventHandlerImpl(
      {
        action: act_1506888955587522_emit_5718,
        event: "success",
        displayName: "emit",
      }
    );
    const evh_1506888955587522_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_1506888955587522_success_5717],
      }
    );
    return {
      act_1040167445267876_emit_5714,
      evh_1040167445267876_clickPreventStop_5713,
      evh_1040167445267876_clickPreventStop,
      act_4186377094414664_emit_5716,
      evh_4186377094414664_success_5715,
      evh_4186377094414664_success,
      act_1506888955587522_emit_5718,
      evh_1506888955587522_success_5717,
      evh_1506888955587522_success,
      reload: act_1040167445267876_emit_5714,
    }
  }
}

Vue.component("UcPaymentHeader", UcPaymentHeader);

</script>