<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'Services and Fees'|pgettext('Contract') }}
      </strong>
      <ItpButton
        :text="'Add service...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <BListGroupItem
        v-if="!contract.services || !contract.services.length"
      >
        <ItpText>
          {{ 'No services have been added yet.'|pgettext('Contract') }}
        </ItpText>
      </BListGroupItem>
      <BListGroupItem
        :key="service.id"
        v-for="service in filterItems(contract.services)"
      >
        <ItpBox
          alignItems="center"
        >
          <ItpText
            :text="service.name"
            :strikethrough="isServiceStale(service)"
            class="font-weight-bold"
          >
          </ItpText>
          <ItpIcon
            :title="'Locked'|gettext"
            name="fa-lock"
            class="px-3"
            v-if="service.locked"
          >
          </ItpIcon>
          <UcProductInvoiceGroupLabel
            :value="service.service.invoice_group_id"
            class="mx-3"
            v-if="service.service.invoice_group_id"
          >
          </UcProductInvoiceGroupLabel>
          <DIV>
            {{ service.valid_from|date }} <span v-if="service.valid_to">-</span>
{{ service.valid_to|date }}

          </DIV>
          <ItpButton
            icon="fa-pen"
            :tooltip="'Edit'|gettext"
            variant="light"
            size="sm"
            class="ml-auto"
            v-if="!readonly"
            @click.prevent.stop="evh_3577443900630089_clickPreventStop($event, {service})"
          >
          </ItpButton>
        </ItpBox>
        <ItpBox>
          <ItpText
            :text="service.description"
            pre
          >
          </ItpText>
        </ItpBox>
        <UcContractServiceFees
          :contract="contract"
          :service="service"
          :reload="lastLoaded"
          :readonly="readonly"
          @reload="evh_6027561141562583_reload($event, {service})"
        >
        </UcContractServiceFees>
      </BListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractServices extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  filterItems(...args: any[]) {
    return _.sortBy(args[0], p => this.isServiceStale(p) ? "_" + p.name : p.name)
  }

  isServiceStale(...args: any[]) {
    const [service] = args;
    return service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today
  }

  @Prop({
    required: true,
    type: Object,
  })
  contract!: object;

  @Watch('contract')
  onContract(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("contract")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  today!: any;

  async $$load_today() {
    return new Date()
  }

  displayHelper!: any;
  ux!: any;
  dataMembers = ['today', 'displayHelper', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        today: null,
        displayHelper: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_6822_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_6822, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_6821_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_6821, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_8320016629450276_showModal_6820_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_6820, alias=undefined
    return {
      name: "ContractServiceEditor",
      props: {
        mode: "addService",
        contract: this.contract,
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_3577443900630089_emit_6826_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_3577443900630089_emit_6826, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_3577443900630089_close_6825_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_3577443900630089_close_6825, alias=undefined
    return !!$event.data.modalResult.ok
  }

  async act_3577443900630089_showModal_6824_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_3577443900630089_showModal_6824, alias=undefined
    return {
      name: "ContractServiceEditor",
      props: {
        mode: "editService",
        contract: this.contract,
        service: _.cloneDeep($event.scope.service),
      }
      ,
    }
  }

  async evh_3577443900630089_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3577443900630089_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_6027561141562583_emit_6828_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_6027561141562583_emit_6828, alias=emitReload
    return {
      event: "reload",
    }
  }

  async evh_6027561141562583_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6027561141562583_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6818: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6817: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_6822: actions.EmitAction;
    evh_8320016629450276_close_6821: actions.EventHandlerImpl;
    act_8320016629450276_showModal_6820: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_6819: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_3577443900630089_emit_6826: actions.EmitAction;
    evh_3577443900630089_close_6825: actions.EventHandlerImpl;
    act_3577443900630089_showModal_6824: actions.ShowModalAction;
    evh_3577443900630089_clickPreventStop_6823: actions.EventHandlerImpl;
    evh_3577443900630089_clickPreventStop: actions.EventHandlerGroup;
    act_6027561141562583_emit_6828: actions.EmitAction;
    evh_6027561141562583_reload_6827: actions.EventHandlerImpl;
    evh_6027561141562583_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6818 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6817 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6818,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6817],
      }
    );
    const act_8320016629450276_emit_6822 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_6822_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_8320016629450276_close_6821 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_6822,
        event: "close",
        when: this.evh_8320016629450276_close_6821_getWhen,
        displayName: "emitReload",
      }
    );
    const act_8320016629450276_showModal_6820 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_6820_getActionArgs,
        events: [evh_8320016629450276_close_6821],
      }
    );
    const evh_8320016629450276_clickPreventStop_6819 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_6820,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_6819],
      }
    );
    const act_3577443900630089_emit_6826 = new actions.EmitAction(
      {
        actionArgs: this.act_3577443900630089_emit_6826_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_3577443900630089_close_6825 = new actions.EventHandlerImpl(
      {
        action: act_3577443900630089_emit_6826,
        event: "close",
        when: this.evh_3577443900630089_close_6825_getWhen,
        displayName: "emitReload",
      }
    );
    const act_3577443900630089_showModal_6824 = new actions.ShowModalAction(
      {
        actionArgs: this.act_3577443900630089_showModal_6824_getActionArgs,
        events: [evh_3577443900630089_close_6825],
      }
    );
    const evh_3577443900630089_clickPreventStop_6823 = new actions.EventHandlerImpl(
      {
        action: act_3577443900630089_showModal_6824,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_3577443900630089_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3577443900630089_clickPreventStop_6823],
      }
    );
    const act_6027561141562583_emit_6828 = new actions.EmitAction(
      {
        actionArgs: this.act_6027561141562583_emit_6828_getActionArgs,
        displayName: "emitReload",
        events: [],
      }
    );
    const evh_6027561141562583_reload_6827 = new actions.EventHandlerImpl(
      {
        action: act_6027561141562583_emit_6828,
        event: "reload",
        displayName: "emitReload",
      }
    );
    const evh_6027561141562583_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6027561141562583_reload_6827],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6818,
      evh_7315092382398562_reload_6817,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_6822,
      evh_8320016629450276_close_6821,
      act_8320016629450276_showModal_6820,
      evh_8320016629450276_clickPreventStop_6819,
      evh_8320016629450276_clickPreventStop,
      act_3577443900630089_emit_6826,
      evh_3577443900630089_close_6825,
      act_3577443900630089_showModal_6824,
      evh_3577443900630089_clickPreventStop_6823,
      evh_3577443900630089_clickPreventStop,
      act_6027561141562583_emit_6828,
      evh_6027561141562583_reload_6827,
      evh_6027561141562583_reload,
      emitReload: act_6027561141562583_emit_6828,
    }
  }
}

Vue.component("UcContractServices", UcContractServices);

</script>