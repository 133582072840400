"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceReferences = class UcInvoiceReferences extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onInvoice(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("invoice");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    get items() {
        if (this.loaded) {
            return this.invoice.references.data;
        }
        return null;
    }
    act_4044930568678877_emit_6174_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_4044930568678877_crud_6172_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "invoice_reference",
                op: "delete",
                data: {
                    invoice_id: this.invoice.id,
                    etag: this.invoice.etag,
                    reference_type: $event.scope.item.object,
                    reference_id: $event.scope.item.id,
                },
            };
        });
    }
    act_4044930568678877_crud_6172_getConfirm($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.gettext("Please confirm deleting the reference."),
                btnOk: this.$fn.gettext("Delete reference"),
                severity: "warning",
            };
        });
    }
    evh_4044930568678877_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4044930568678877_click.executeFromDOM(this, event, scope);
        });
    }
    act_8358196963355853_emit_6178_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8358196963355853_close_6177_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult && !!$event.data.modalResult.ok;
        });
    }
    act_8358196963355853_showModal_6176_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceReferenceSearch",
                props: {
                    invoiceId: this.invoice.id,
                },
            };
        });
    }
    evh_8358196963355853_clickPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8358196963355853_clickPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_4044930568678877_emit_6174 = new core_1.actions.EmitAction({
            actionArgs: this.act_4044930568678877_emit_6174_getActionArgs,
            events: [],
        });
        const evh_4044930568678877_success_6173 = new core_1.actions.EventHandlerImpl({
            action: act_4044930568678877_emit_6174,
            event: "success",
            displayName: "emit",
        });
        const act_4044930568678877_crud_6172 = new core_1.actions.CRUDAction({
            actionArgs: this.act_4044930568678877_crud_6172_getActionArgs,
            confirm: this.act_4044930568678877_crud_6172_getConfirm,
            events: [evh_4044930568678877_success_6173],
        });
        const evh_4044930568678877_click_6171 = new core_1.actions.EventHandlerImpl({
            action: act_4044930568678877_crud_6172,
            event: "click",
            displayName: "crud",
        });
        const evh_4044930568678877_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4044930568678877_click_6171],
        });
        const act_8358196963355853_emit_6178 = new core_1.actions.EmitAction({
            actionArgs: this.act_8358196963355853_emit_6178_getActionArgs,
            events: [],
        });
        const evh_8358196963355853_close_6177 = new core_1.actions.EventHandlerImpl({
            action: act_8358196963355853_emit_6178,
            event: "close",
            when: this.evh_8358196963355853_close_6177_getWhen,
            displayName: "emit",
        });
        const act_8358196963355853_showModal_6176 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8358196963355853_showModal_6176_getActionArgs,
            events: [evh_8358196963355853_close_6177],
        });
        const evh_8358196963355853_clickPrevent_6175 = new core_1.actions.EventHandlerImpl({
            action: act_8358196963355853_showModal_6176,
            event: "click.prevent",
            displayName: "showModal",
        });
        const evh_8358196963355853_clickPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8358196963355853_clickPrevent_6175],
        });
        return {
            act_4044930568678877_emit_6174,
            evh_4044930568678877_success_6173,
            act_4044930568678877_crud_6172,
            evh_4044930568678877_click_6171,
            evh_4044930568678877_click,
            act_8358196963355853_emit_6178,
            evh_8358196963355853_close_6177,
            act_8358196963355853_showModal_6176,
            evh_8358196963355853_clickPrevent_6175,
            evh_8358196963355853_clickPrevent,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], UcInvoiceReferences.prototype, "invoice", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('invoice'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceReferences.prototype, "onInvoice", null);
UcInvoiceReferences = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceReferences);
exports.default = UcInvoiceReferences;
vue_property_decorator_1.Vue.component("UcInvoiceReferences", UcInvoiceReferences);
