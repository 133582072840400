<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpBox
    direction="column"
  >
    <ItpText
      strong
      :text="item.name"
      spacing="mb-2"
    >
    </ItpText>
    <ItpBox
      spacing="mb-2"
      alignItems="center"
      v-if="item.job_title"
    >
      <Icon
        icon="mdi:work-outline"
        :title="'Job title'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.job_title"
        v-if="item.job_title"
      >
      </ItpText>
    </ItpBox>
    <ItpBox
      spacing="mb-2"
      alignItems="center"
      v-if="item.phone"
    >
      <Icon
        icon="ic:baseline-phone"
        :title="'Phone Number'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.phone"
      >
      </ItpText>
    </ItpBox>
    <ItpBox
      spacing="mb-2"
      alignItems="center"
      v-if="item.mobile"
    >
      <Icon
        icon="mdi:cellphone-basic"
        :title="'Mobile'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.mobile"
      >
      </ItpText>
    </ItpBox>
    <ItpBox
      spacing="mb-2"
      alignItems="center"
      v-if="item.fax"
    >
      <Icon
        icon="mdi:fax"
        :title="'Fax'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.fax"
      >
      </ItpText>
    </ItpBox>
    <ItpBox
      spacing="mb-2"
      alignItems="center"
      v-if="item.email"
    >
      <Icon
        icon="mdi:at"
        :title="'Email'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.email"
      >
      </ItpText>
    </ItpBox>
    <ItpBox
      v-if="item.notes"
    >
      <Icon
        icon="solar:notes-linear"
        :title="'Notes'|pgettext('Contact')"
        class="mr-2"
      >
      </Icon>
      <ItpText
        :text="item.notes"
        pre
      >
      </ItpText>
    </ItpBox>
  </ItpBox>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContactCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6532: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6531: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6532 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6531 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6532,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6531],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6532,
      evh_7315092382398562_reload_6531,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("UcContactCard", UcContactCard);

</script>