var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceLineGroup",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _vm.loaded
                ? _c(
                    "ItpForm",
                    {
                      attrs: {
                        id: _vm.formId,
                        name: _vm.formName,
                        autofocus: "first"
                      },
                      on: {
                        submit: function($event) {
                          return _vm.evh_1040167445267876_submit($event, {})
                        }
                      }
                    },
                    [
                      _c(
                        "ItpCol",
                        [
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "description",
                                label: _vm._f("pgettext")(
                                  "Description",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormInput", {
                                attrs: {
                                  name: "description",
                                  readonly: _vm.item.readonly,
                                  placeholder: _vm._f("pgettext")(
                                    "Enter group description",
                                    "InvoiceLineGroup/description/placeholder"
                                  )
                                },
                                model: {
                                  value: _vm.item.description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "description", $$v)
                                  },
                                  expression: "item.description"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "expense_type",
                                label: _vm._f("pgettext")(
                                  "Expense Type",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "expense_type",
                                  readonly: _vm.item.readonly,
                                  clearable: "",
                                  options: _vm.expenseTypes,
                                  valueField: "name",
                                  textField: "name"
                                },
                                model: {
                                  value: _vm.item.expense_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "expense_type", $$v)
                                  },
                                  expression: "item.expense_type"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "rental",
                                label: _vm._f("pgettext")(
                                  "Rental",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "rental",
                                  "bind-object": "",
                                  readonly: _vm.item.readonly,
                                  clearable: "",
                                  dataSource: _vm.b_7307096920626688_dataSource,
                                  optionTemplate:
                                    "<UcSuggestRentalOption :option=option>\n</UcSuggestRentalOption>",
                                  "use-option-template-for-selected-option": ""
                                },
                                model: {
                                  value: _vm.item.rental,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "rental", $$v)
                                  },
                                  expression: "item.rental"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "property",
                                label: _vm._f("pgettext")(
                                  "Property",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "property",
                                  "bind-object": "",
                                  readonly: _vm.item.readonly,
                                  clearable: "",
                                  dataSource: _vm.b_3119842801924129_dataSource,
                                  optionTemplate:
                                    "<UcSuggestPropertyOption :option=option>\n</UcSuggestPropertyOption>",
                                  "use-option-template-for-selected-option": ""
                                },
                                model: {
                                  value: _vm.item.property,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "property", $$v)
                                  },
                                  expression: "item.property"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "worksheet",
                                label: _vm._f("pgettext")(
                                  "Worksheet",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "worksheet",
                                  "bind-object": "",
                                  readonly: _vm.item.readonly,
                                  clearable: "",
                                  dataSource: _vm.b_4994395583501828_dataSource,
                                  optionTemplate:
                                    "<UcSuggestWorksheetOption :option=option>\n</UcSuggestWorksheetOption>",
                                  "use-option-template-for-selected-option": ""
                                },
                                model: {
                                  value: _vm.item.worksheet,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "worksheet", $$v)
                                  },
                                  expression: "item.worksheet"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "contractor",
                                label: _vm._f("pgettext")(
                                  "Contractor",
                                  "InvoiceLineGroup"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "contractor",
                                  "bind-object": "",
                                  readonly: _vm.item.readonly,
                                  clearable: "",
                                  dataSource: _vm.b_2260153975480357_dataSource,
                                  optionTemplate:
                                    "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
                                  "use-option-template-for-selected-option": ""
                                },
                                model: {
                                  value: _vm.item.contractor,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "contractor", $$v)
                                  },
                                  expression: "item.contractor"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              !_vm.item.isNew && !_vm.invoice.readonly && !_vm.item.readonly
                ? _c("BtnDeleteResource", {
                    staticClass: "mr-auto",
                    attrs: {
                      resource: _vm.item,
                      variant: "danger",
                      size: "md"
                    },
                    on: {
                      success: function($event) {
                        return _vm.evh_1548630417156826_success($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  form: _vm.forms[_vm.formName] && _vm.forms[_vm.formName].id,
                  icon: "fa-save",
                  text: _vm._f("gettext")("Save"),
                  type: "submit",
                  variant: "primary",
                  spinning: _vm.actions.submit.isRunning,
                  disabled:
                    !_vm.forms[_vm.formName] ||
                    !_vm.forms[_vm.formName].submittable
                }
              }),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: {
                  text: _vm._f("gettext")("Close"),
                  variant: "default",
                  disabled: _vm.actions.submit.isRunning
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }