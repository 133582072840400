<template>
<ItpBox
  alignItems="center"
>
  <ItpIcon
    :name="$config.invoiceRegister.icon"
    spacing="mr-3"
    :title="'Invoice register'|pgettext('InvoiceRegister')"
    class="mr-2"
    v-if="!noIcon"
  >
  </ItpIcon>
  <ItpText
    strong
    :text="item.entry_number"
    class="mr-2"
  >
  </ItpText>
  <ItpLink
    :icon="$config.externalLinkIcon"
    @click.prevent.stop="evh_8358196963355853_clickPreventStop($event, {})"
  >
  </ItpLink>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcInvoiceRegisterEntryMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async act_8358196963355853_showModal_5594_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8358196963355853_showModal_5594, alias=undefined
    return {
      name: "InvoiceRegisterEntry",
      props: {
        oid: this.item.id,
      }
      ,
    }
  }

  async evh_8358196963355853_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8358196963355853_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8358196963355853_showModal_5594: actions.ShowModalAction;
    evh_8358196963355853_clickPreventStop_5593: actions.EventHandlerImpl;
    evh_8358196963355853_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8358196963355853_showModal_5594 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8358196963355853_showModal_5594_getActionArgs,
        events: [],
      }
    );
    const evh_8358196963355853_clickPreventStop_5593 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_showModal_5594,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8358196963355853_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8358196963355853_clickPreventStop_5593],
      }
    );
    return {
      act_8358196963355853_showModal_5594,
      evh_8358196963355853_clickPreventStop_5593,
      evh_8358196963355853_clickPreventStop,
    }
  }
}

Vue.component("UcInvoiceRegisterEntryMiniCard", UcInvoiceRegisterEntryMiniCard);

</script>