var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpButton", {
    attrs: {
      variant: "light",
      icon: _vm.$config.reloadIcon,
      text: _vm._f("pgettext")("Load", "Button"),
      title: _vm._f("pgettext")("Load data", "Button|Title"),
      spinning: _vm.action.isRunning
    },
    on: {
      click: function($event) {
        $event.preventDefault()
        $event.stopPropagation()
        return _vm.evh_7315092382398562_clickPreventStop($event, {})
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }