<template>
<MiniCard
  :colorCode="$config.company.colors[effectiveRole]"
  :badge="badge"
  :noIcon="noIcon"
  :title="messages.title[effectiveRole]"
  :icon="$config.company.icons[effectiveRole]"
  :text="item.name"
  :linkTo="{
    name: 'app.company',
    params: {
      id: item.id
    }
  }"
>
</MiniCard>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompanyMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: String,
  })
  role!: string;

  @Watch('role')
  onRole(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("role")
  }

  @Prop({
    type: Boolean,
  })
  badge!: boolean;

  @Watch('badge')
  onBadge(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("badge")
  }

  @Prop({
    type: Boolean,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  messages!: any;
  ux!: any;
  dataMembers = ['messages', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          title: {
            own: "P(\"Company\", \"Own company\")",
            partner: "P(\"Company\", \"Partner\")",
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get effectiveRole() {
    if (this.loaded) {

      return this.role || this.item.role
    }
    return null;
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcCompanyMiniCard", UcCompanyMiniCard);

</script>