var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { direction: "column" } },
    [
      _vm.items && !_vm.items.length
        ? _c("DIV", [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm._f("pgettext")(
                    "No references added.",
                    "InvoiceReferences"
                  )
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.items && _vm.items.length
        ? _c(
            "ItpBox",
            { attrs: { direction: "column" } },
            _vm._l(_vm.items, function(item) {
              return _c(
                "ItpBox",
                {
                  key: item.id,
                  staticClass:
                    "app-invoice-reference-item border rounded rounded-lg p-2 mb-2 w-100 overflow-hidden"
                },
                [
                  _c("InvoiceReferenceItem", {
                    staticClass: "w-100 overflow-hidden",
                    attrs: { item: item }
                  }),
                  _vm._v(" "),
                  !_vm.invoice.isReadOnly
                    ? _c("ItpLink", {
                        staticClass: "ml-2 ml-auto",
                        attrs: { icon: "fa-times" },
                        on: {
                          click: function($event) {
                            return _vm.evh_4044930568678877_click($event, {
                              item: item
                            })
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.invoice.isReadOnly
        ? _c("ItpLink", {
            staticClass: "ml-auto",
            attrs: {
              icon: _vm.$config.addIcon,
              text: _vm._f("gettext")("Add reference")
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.evh_8358196963355853_clickPrevent($event, {})
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }