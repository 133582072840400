<template>
<DIV>
  <UcInvoiceKindBadge v-if="!hideInvoiceKind" :value="option.invoice_kind"></UcInvoiceKindBadge>
<UcInvoiceTypeBadge :value="option.invoice_type" :kind="option.invoice_kind" abbreviated></UcInvoiceTypeBadge>
&nbsp;<strong>{{ option.invoice_number }}</strong>
&middot;&nbsp;{{ option.invoice_date | date }}
<span v-if="option.client">&middot;&nbsp;{{ option.client.name }}</span>

</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcSuggestInvoiceOption extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  option!: object;

  @Watch('option')
  onOption(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("option")
  }

  @Prop({
    type: Boolean,
  })
  hideInvoiceKind!: boolean;

  @Watch('hideInvoiceKind')
  onHide_invoice_kind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("hideInvoiceKind")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcSuggestInvoiceOption", UcSuggestInvoiceOption);

</script>