var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MiniCard", {
    attrs: {
      colorCode: _vm.$config.rental.colorCode,
      badge: _vm.badge,
      noIcon: _vm.noIcon,
      title: _vm._f("pgettext")("Rental", "Rental"),
      icon: _vm.$config.rental.icon,
      text: _vm.item.name,
      linkTo: {
        name: "app.rental",
        params: { id: _vm.item.id }
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }