"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcMeterRentalServices = class UcMeterRentalServices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['items', 'ux'];
    }
    beforeCreate() {
    }
    sortItems(...args) {
        return _.sortBy(args[0], p => (!this.isServiceStale(p) ? "_" + p.service.name : p.service.name).toLowerCase() + p.id);
    }
    isServiceStale(...args) {
        const [service] = args;
        return service.inactive ||
            (service.valid_to && this.$fn.parseISODate(service.valid_to) < this.today);
    }
    onMeter(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meter");
    }
    $$load_items() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_meter_rental_services', { parameters: { meter_id: this.meter.id } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            items: null,
            ux: null,
        });
    }
    act_3343864469950044_emit_7034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3343864469950044_close_7033_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.value;
        });
    }
    act_3343864469950044_showModal_7032_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalService",
                props: {
                    meter: this.meter,
                    assignToRental: true,
                    preventEditMeter: true,
                },
            };
        });
    }
    evh_3343864469950044_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_7989141091456081_emit_7038_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_7989141091456081_close_7037_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_7989141091456081_showModal_7036_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "RentalService",
                props: {
                    oid: $event.scope.item.id,
                    mode: "edit",
                    meter: this.meter,
                    assignToRental: true,
                    preventEditMeter: true,
                    preventEditRental: true,
                },
            };
        });
    }
    evh_7989141091456081_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7989141091456081_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_3343864469950044_emit_7034 = new core_1.actions.EmitAction({
            actionArgs: this.act_3343864469950044_emit_7034_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_7033 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_emit_7034,
            event: "close",
            when: this.evh_3343864469950044_close_7033_getWhen,
            displayName: "emit",
        });
        const act_3343864469950044_showModal_7032 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_7032_getActionArgs,
            events: [evh_3343864469950044_close_7033],
        });
        const evh_3343864469950044_clickPreventStop_7031 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_7032,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3343864469950044_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickPreventStop_7031],
        });
        const act_7989141091456081_emit_7038 = new core_1.actions.EmitAction({
            actionArgs: this.act_7989141091456081_emit_7038_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_7989141091456081_close_7037 = new core_1.actions.EventHandlerImpl({
            action: act_7989141091456081_emit_7038,
            event: "close",
            when: this.evh_7989141091456081_close_7037_getWhen,
            displayName: "emitReload",
        });
        const act_7989141091456081_showModal_7036 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7989141091456081_showModal_7036_getActionArgs,
            events: [evh_7989141091456081_close_7037],
        });
        const evh_7989141091456081_clickPreventStop_7035 = new core_1.actions.EventHandlerImpl({
            action: act_7989141091456081_showModal_7036,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_7989141091456081_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7989141091456081_clickPreventStop_7035],
        });
        return {
            act_3343864469950044_emit_7034,
            evh_3343864469950044_close_7033,
            act_3343864469950044_showModal_7032,
            evh_3343864469950044_clickPreventStop_7031,
            evh_3343864469950044_clickPreventStop,
            act_7989141091456081_emit_7038,
            evh_7989141091456081_close_7037,
            act_7989141091456081_showModal_7036,
            evh_7989141091456081_clickPreventStop_7035,
            evh_7989141091456081_clickPreventStop,
            emitReload: act_7989141091456081_emit_7038,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcMeterRentalServices.prototype, "meter", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcMeterRentalServices.prototype, "onMeter", null);
UcMeterRentalServices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcMeterRentalServices);
exports.default = UcMeterRentalServices;
vue_property_decorator_1.Vue.component("UcMeterRentalServices", UcMeterRentalServices);
