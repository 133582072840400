<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="ProductPrice"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :name="formName"
      :schema="oid == null ? 'CreateProductPrice' : 'UpdateProductPrice'"
      :id="formId"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpFormGroup
        name="price"
        :label="'Price'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="price"
          v-model="item.price"
          required
          class="col-4"
        >
        </ItpFormInput>
        <ItpFormSelect
          name="currency"
          v-model="item.currency"
          :options="currencies"
          textField="code"
          valueField="code"
          required
          class="col-4"
        >
        </ItpFormSelect>
      </ItpFormGroup>
      <ItpFormGroup
        name="quantity_from"
        :label="'From quantity'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
        contentClass="input-group"
      >
        <ItpFormInput
          type="number"
          name="quantity_from"
          v-model="item.quantity"
          class="col-4"
        >
        </ItpFormInput>
        <ItpFormInput
          name="quantity_from_unit"
          v-model="product.unit"
          required
          readonly
          class="col-4"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        name="valid_from"
        :label="'Validity'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
        contentClass="form-row"
      >
        <div
          class="col"
        >
          <ItpFormDatetime
            type="date"
            name="valid_from"
            :placeholder="'Valid from'|pgettext('InputPlaceholder')"
            v-model="item.valid_from"
            :readonly="isReadOnly"
            required
          >
          </ItpFormDatetime>
        </div>
        <div
          class="col"
        >
          <ItpFormDatetime
            type="date"
            name="valid_to"
            :placeholder="'Valid to'|pgettext('InputPlaceholder')"
            v-model="item.valid_to"
            :readonly="isReadOnly"
          >
          </ItpFormDatetime>
        </div>
      </ItpFormGroup>
      <ItpFormGroup
        name="meter_id"
        :label="'Client'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
      >
        <ItpFormSelect2
          name="client"
          v-model="item.partner_id"
          :dataSource="b_5074589820235598_dataSource"
          valueField="id"
          textField="name"
          required
          optionTemplate="<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>"
          use-option-template-for-selected-option
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        name="is_discount_price"
        :label="'Discount price'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="is_discount_price"
          inline
          v-model="item.is_discount_price"
          :readonly="isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <ItpFormGroup
        name="inactive"
        :label="'Inactive'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
      >
        <ItpFormCheckbox
          name="inactive"
          inline
          v-model="item.inactive"
          :readonly="isReadOnly"
        >
        </ItpFormCheckbox>
      </ItpFormGroup>
      <hr>
      </hr>
      <ItpFormGroup
        name="note"
        :label="'Notes'|pgettext('ProductPrice')"
        labelColsMd="3"
        contentColsMd="9"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="note"
          v-model="item.note"
          :readonly="isReadOnly"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpBox
      class="mr-auto"
      v-if="oid && !item.locked"
    >
      <BtnDeleteResource
        :resource="item"
        :disabled="actions.submit.isRunning"
        @success="evh_2140198529698299_success($event, {})"
      >
      </BtnDeleteResource>
      <BtnLockResource
        :resource="item"
        :disabled="actions.submit.isRunning"
        class="ml-3"
        @success="evh_2434514588044777_success($event, {})"
      >
      </BtnLockResource>
    </ItpBox>
    <ItpButton
      :text="'Save'|pgettext('ModalButton')"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.submit.isRunning"
      :disabled="!forms[formName] || !forms[formName].submittable"
      v-if="!item.locked"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|pgettext('ModalButton')"
      variant="default"
      :disabled="actions.submit.isRunning"
      @click.prevent.stop="evh_2186679009640457_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class ProductPrice extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Object,
    default: null,
  })
  product!: object;

  @Watch('product')
  onProduct(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("product")
  }

  @Prop({
    type: Boolean,
  })
  is_purchase_price!: boolean;

  @Watch('is_purchase_price')
  onIs_purchase_price(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("is_purchase_price")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.oid
      ? this.$fn.fetch('read_product_price', { parameters: { id: this.oid } })
      : this.$fn.schemaDefaults('CreateProductPriceCommand', {
        product_id: this.product.id, is_purchase_price: this.is_purchase_price
      })
  }

  currencies!: any;

  async $$load_currencies() {
    return this.$fn.fetch('list_currency')
  }

  formName!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  labelAlign!: any;
  isReadOnly!: any;

  async $$load_isReadOnly() {
    return this.item.locked
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_5074589820235598_dataSource!: any;
  dataMembers = ['item', 'currencies', 'formName', 'formId', 'labelAlign', 'isReadOnly', 'ux', 'b_7315092382398562_modalBindings', 'b_5074589820235598_dataSource'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        currencies: null,
        formName: "productService",
        formId: null,
        labelAlign: "right",
        isReadOnly: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
        b_5074589820235598_dataSource: {
          name: "suggest_company",
          parameters: {
            role: "partner",
          }
          ,
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.oid ? this.$fn.gettext('Edit Price') : this.$fn.gettext('Add Price'),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3166_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3166, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_3172_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_3172, alias=undefined
    return {
      name: this.modalName,
      result: {
        ok: true,
        value: $event.data.response.data,
      }
      ,
    }
  }

  async act_1040167445267876_request_3170_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_1040167445267876_request_3170, alias=submit
    return {
      operation: this.item.id ? 'update_product_price' : 'create_product_price',
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_2140198529698299_closeModal_3174_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2140198529698299_closeModal_3174, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_2140198529698299_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2140198529698299_success.executeFromDOM(this, event, scope);
  }

  async act_2434514588044777_closeModal_3176_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2434514588044777_closeModal_3176, alias=undefined
    return {
      result: {
        ok: true,
      }
      ,
    }
  }

  async evh_2434514588044777_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2434514588044777_success.executeFromDOM(this, event, scope);
  }

  async act_2186679009640457_closeModal_3178_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_2186679009640457_closeModal_3178, alias=undefined
    return {
      name: this.modalName,
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_2186679009640457_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2186679009640457_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3166: actions.CloseModalAction;
    evh_7315092382398562_close_3165: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_3168: actions.CloseComponentAction;
    evh_2248226175642056_close_3167: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_3172: actions.CloseModalAction;
    evh_1040167445267876_success_3171: actions.EventHandlerImpl;
    act_1040167445267876_request_3170: actions.RequestAction;
    evh_1040167445267876_submit_3169: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_2140198529698299_closeModal_3174: actions.CloseModalAction;
    evh_2140198529698299_success_3173: actions.EventHandlerImpl;
    evh_2140198529698299_success: actions.EventHandlerGroup;
    act_2434514588044777_closeModal_3176: actions.CloseModalAction;
    evh_2434514588044777_success_3175: actions.EventHandlerImpl;
    evh_2434514588044777_success: actions.EventHandlerGroup;
    act_2186679009640457_closeModal_3178: actions.CloseModalAction;
    evh_2186679009640457_clickPreventStop_3177: actions.EventHandlerImpl;
    evh_2186679009640457_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3166 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3166_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3165 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3166,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3165],
      }
    );
    const act_2248226175642056_closeComponent_3168 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3167 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_3168,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3167],
      }
    );
    const act_1040167445267876_closeModal_3172 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_3172_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_3171 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_3172,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_request_3170 = new actions.RequestAction(
      {
        actionArgs: this.act_1040167445267876_request_3170_getActionArgs,
        displayName: "submit",
        events: [evh_1040167445267876_success_3171],
      }
    );
    const evh_1040167445267876_submit_3169 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_request_3170,
        event: "submit",
        displayName: "submit",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3169],
      }
    );
    const act_2140198529698299_closeModal_3174 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2140198529698299_closeModal_3174_getActionArgs,
        events: [],
      }
    );
    const evh_2140198529698299_success_3173 = new actions.EventHandlerImpl(
      {
        action: act_2140198529698299_closeModal_3174,
        event: "success",
        displayName: "closeModal",
      }
    );
    const evh_2140198529698299_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2140198529698299_success_3173],
      }
    );
    const act_2434514588044777_closeModal_3176 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2434514588044777_closeModal_3176_getActionArgs,
        events: [],
      }
    );
    const evh_2434514588044777_success_3175 = new actions.EventHandlerImpl(
      {
        action: act_2434514588044777_closeModal_3176,
        event: "success",
        displayName: "closeModal",
      }
    );
    const evh_2434514588044777_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_2434514588044777_success_3175],
      }
    );
    const act_2186679009640457_closeModal_3178 = new actions.CloseModalAction(
      {
        actionArgs: this.act_2186679009640457_closeModal_3178_getActionArgs,
        events: [],
      }
    );
    const evh_2186679009640457_clickPreventStop_3177 = new actions.EventHandlerImpl(
      {
        action: act_2186679009640457_closeModal_3178,
        event: "click.prevent.stop",
        displayName: "closeModal",
      }
    );
    const evh_2186679009640457_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2186679009640457_clickPreventStop_3177],
      }
    );
    return {
      act_7315092382398562_closeModal_3166,
      evh_7315092382398562_close_3165,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_3168,
      evh_2248226175642056_close_3167,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_3172,
      evh_1040167445267876_success_3171,
      act_1040167445267876_request_3170,
      evh_1040167445267876_submit_3169,
      evh_1040167445267876_submit,
      act_2140198529698299_closeModal_3174,
      evh_2140198529698299_success_3173,
      evh_2140198529698299_success,
      act_2434514588044777_closeModal_3176,
      evh_2434514588044777_success_3175,
      evh_2434514588044777_success,
      act_2186679009640457_closeModal_3178,
      evh_2186679009640457_clickPreventStop_3177,
      evh_2186679009640457_clickPreventStop,
      submit: act_1040167445267876_request_3170,
    }
  }
}

Vue.component("ProductPrice", ProductPrice);

</script>