"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcInvoiceGenerationInvoiceLines = class UcInvoiceGenerationInvoiceLines extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['resizeTrigger', 'selectedItems', 'ts', 'stockColumns', 'values', 'grouping', 'ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_options'];
    }
    beforeCreate() {
    }
    formatDataColumn(...args) {
        const [r, c, value, columnDefinition, row] = args;
        if (value == null) {
            return '';
        }
        const result = ['<div>'];
        if (value.net != null) {
            const net = this.$options.filters.number(value.net);
            result.push(`<div class="mb-1"><strong>${net}</strong></div>`);
        }
        if (value.quantity != null) {
            const quantity = this.$options.filters.number(value.quantity);
            result.push(`${quantity} ${row.unit}`);
        }
        result.push('</div>');
        return result.join('');
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_values() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('view_draft_invoice_lines').then(p => _.extend(p, {
                columns: this.stockColumns.concat(p.columns.map(field => ({
                    field,
                    name: field,
                    textAlign: 'right',
                    formatter: this.formatDataColumn
                })))
            }));
        });
    }
    $$load_grouping() {
        return __awaiter(this, void 0, void 0, function* () {
            return [{
                    getter: "partner",
                    formatter: g => `<strong>${g.value}</strong>`,
                    aggregateCollapsed: false,
                    lazyTotalsCalculation: true,
                },
                {
                    getter: "rental",
                    formatter: g => `<strong>${g.value}</strong>`,
                    aggregateCollapsed: "false,",
                    lazyTotalsCalculation: true,
                },
            ];
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            resizeTrigger: 0,
            selectedItems: [],
            ts: null,
            stockColumns: [{
                    field: "partner",
                    name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Partner"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "contract",
                        navigateOnIconClick: true,
                        params: {
                            id: "partner_id",
                        },
                    },
                },
                {
                    field: "rental",
                    name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Rental"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "rental",
                        navigateOnIconClick: true,
                        params: {
                            id: "rental_id",
                        },
                    },
                },
                {
                    field: "site",
                    name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Site"),
                    width: "30px",
                },
                {
                    field: "item",
                    name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Item"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "product",
                        navigateOnIconClick: true,
                        params: {
                            id: "product_id",
                        },
                    },
                },
                {
                    field: "serial_number",
                    name: this.$fn.pgettext("InvoiceGeneration|ColumnHeader", "Serial"),
                    type: "command",
                    formatter: {
                        name: "routeLink",
                        to: "meter",
                        navigateOnIconClick: true,
                        params: {
                            id: "meter_id",
                        },
                    },
                },
            ],
            values: null,
            grouping: null,
            ux: null,
            b_7315092382398562_columnDefaults: {
                sortable: true,
                filterable: true,
                resizable: true,
            },
            b_7315092382398562_options: {
                rowHeight: 40,
                rowTopOffsetRenderType: "transform",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return;
        }
        return null;
    }
    act_7315092382398562_setData_5148_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "selectedItems",
                value: this.$refs.grid.getSelectedItems(),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_7315092382398562_selectedRowsChanged(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_selectedRowsChanged.executeFromDOM(this, event, scope);
        });
    }
    act_7315092382398562_showModal_5150_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "Invoice",
                props: {
                    oid: $event.data.data.id,
                    modal: true,
                },
            };
        });
    }
    evh_7315092382398562_command_5149_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.column.id === 'invoice_number';
        });
    }
    evh_7315092382398562_command(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
        });
    }
    act_8564662037462133_reloadSlickgrid_5154_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "draftInvoices",
            };
        });
    }
    act_8564662037462133_request_5152_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "delete_draft_invoices",
                data: {
                    invoices: this.selectedItems.map(p => p.id)
                },
            };
        });
    }
    evh_8564662037462133_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8564662037462133_click.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_setData_5148 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7315092382398562_setData_5148_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_selectedRowsChanged_5147 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_setData_5148,
            event: "selectedRowsChanged",
            displayName: "setData",
        });
        const evh_7315092382398562_selectedRowsChanged = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_selectedRowsChanged_5147],
        });
        const act_7315092382398562_showModal_5150 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_7315092382398562_showModal_5150_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_command_5149 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_showModal_5150,
            event: "command",
            when: this.evh_7315092382398562_command_5149_getWhen,
            displayName: "showModal",
        });
        const evh_7315092382398562_command = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_command_5149],
        });
        const act_8564662037462133_reloadSlickgrid_5154 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_8564662037462133_reloadSlickgrid_5154_getActionArgs,
            events: [],
        });
        const evh_8564662037462133_success_5153 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_reloadSlickgrid_5154,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_8564662037462133_request_5152 = new core_1.actions.RequestAction({
            actionArgs: this.act_8564662037462133_request_5152_getActionArgs,
            displayName: "deleteItems",
            events: [evh_8564662037462133_success_5153],
        });
        const evh_8564662037462133_click_5151 = new core_1.actions.EventHandlerImpl({
            action: act_8564662037462133_request_5152,
            event: "click",
            displayName: "deleteItems",
        });
        const evh_8564662037462133_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8564662037462133_click_5151],
        });
        return {
            act_7315092382398562_setData_5148,
            evh_7315092382398562_selectedRowsChanged_5147,
            evh_7315092382398562_selectedRowsChanged,
            act_7315092382398562_showModal_5150,
            evh_7315092382398562_command_5149,
            evh_7315092382398562_command,
            act_8564662037462133_reloadSlickgrid_5154,
            evh_8564662037462133_success_5153,
            act_8564662037462133_request_5152,
            evh_8564662037462133_click_5151,
            evh_8564662037462133_click,
            deleteItems: act_8564662037462133_request_5152,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], UcInvoiceGenerationInvoiceLines.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcInvoiceGenerationInvoiceLines.prototype, "onSize", null);
UcInvoiceGenerationInvoiceLines = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcInvoiceGenerationInvoiceLines);
exports.default = UcInvoiceGenerationInvoiceLines;
vue_property_decorator_1.Vue.component("UcInvoiceGenerationInvoiceLines", UcInvoiceGenerationInvoiceLines);
