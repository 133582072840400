var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DIV",
    [
      !_vm.hideInvoiceKind
        ? _c("UcInvoiceKindBadge", {
            attrs: { value: _vm.option.invoice_kind }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("UcInvoiceTypeBadge", {
        attrs: {
          value: _vm.option.invoice_type,
          kind: _vm.option.invoice_kind,
          abbreviated: ""
        }
      }),
      _vm._v("\n "),
      _c("strong", [_vm._v(_vm._s(_vm.option.invoice_number))]),
      _vm._v("\n· " + _vm._s(_vm._f("date")(_vm.option.invoice_date)) + "\n"),
      _vm.option.client
        ? _c("span", [_vm._v("· " + _vm._s(_vm.option.client.name))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }