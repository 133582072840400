"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateDunningLetter = class CreateDunningLetter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['numbering', 'startingReferenceNumber', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
    }
    onItems(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("items");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_numbering() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_numbering', { parameters: { id: 'sys.dunning_letter' } });
        });
    }
    $$load_startingReferenceNumber() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.numbering.info.next_value;
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            numbering: null,
            startingReferenceNumber: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "md",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create dunning letters"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_5280_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_1040167445267876_register_5286_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "resultLength",
                value: $event.data.response.data ? $event.data.response.data.length : 0,
            };
        });
    }
    act_1040167445267876_notification_5288_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                message: this.$fn.ngettext("One dunning letter created.", "${n} dunning letters created.", $event.data.resultLength),
            };
        });
    }
    act_1040167445267876_notification_5288_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.resultLength > 0;
        });
    }
    act_1040167445267876_closeModal_5290_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    value: $event.data.response.data,
                },
            };
        });
    }
    act_1040167445267876_reloadSlickgrid_5292_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "all",
            };
        });
    }
    act_1040167445267876_request_5284_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "create_dunning_letter",
                data: _.chain(this.items)
                    .groupBy(p => `${p.issuer.id}${p.client_id}`)
                    .map((items, g) => ({
                    company_id: items[0].issuer.id,
                    client_id: items[0].client_id,
                    items: _.map(items, p => ({ invoice_number: p.invoice_number }))
                }))
                    .value(),
                parameters: {
                    "starting-reference-number": this.startingReferenceNumber,
                },
            };
        });
    }
    evh_1040167445267876_submit(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
        });
    }
    evh_1419464017721962_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_5280 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_5280_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_5279 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_5280,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_5279],
        });
        const act_2248226175642056_closeComponent_5282 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_5281 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_5282,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_5281],
        });
        const act_1040167445267876_register_5286 = new core_1.actions.RegisterAction({
            actionArgs: this.act_1040167445267876_register_5286_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5285 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_register_5286,
            event: "success",
            displayName: "register",
        });
        const act_1040167445267876_notification_5288 = new core_1.actions.NotificationAction({
            actionArgs: this.act_1040167445267876_notification_5288_getActionArgs,
            when: this.act_1040167445267876_notification_5288_getWhen,
            events: [],
        });
        const evh_1040167445267876_success_5287 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_notification_5288,
            event: "success",
            displayName: "notification",
        });
        const act_1040167445267876_closeModal_5290 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_1040167445267876_closeModal_5290_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5289 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_closeModal_5290,
            event: "success",
            displayName: "closeModal",
        });
        const act_1040167445267876_reloadSlickgrid_5292 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_1040167445267876_reloadSlickgrid_5292_getActionArgs,
            events: [],
        });
        const evh_1040167445267876_success_5291 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_reloadSlickgrid_5292,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_1040167445267876_request_5284 = new core_1.actions.RequestAction({
            actionArgs: this.act_1040167445267876_request_5284_getActionArgs,
            displayName: "submit",
            events: [evh_1040167445267876_success_5285, evh_1040167445267876_success_5287, evh_1040167445267876_success_5289, evh_1040167445267876_success_5291],
        });
        const evh_1040167445267876_submit_5283 = new core_1.actions.EventHandlerImpl({
            action: act_1040167445267876_request_5284,
            event: "submit",
            displayName: "submit",
        });
        const evh_1040167445267876_submit = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1040167445267876_submit_5283],
        });
        const act_1419464017721962_closeComponent_5294 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_1419464017721962_clickPreventStop_5293 = new core_1.actions.EventHandlerImpl({
            action: act_1419464017721962_closeComponent_5294,
            event: "click.prevent.stop",
            displayName: "closeComponent",
        });
        const evh_1419464017721962_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1419464017721962_clickPreventStop_5293],
        });
        return {
            act_7315092382398562_closeModal_5280,
            evh_7315092382398562_close_5279,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_5282,
            evh_2248226175642056_close_5281,
            evh_2248226175642056_close,
            act_1040167445267876_register_5286,
            evh_1040167445267876_success_5285,
            act_1040167445267876_notification_5288,
            evh_1040167445267876_success_5287,
            act_1040167445267876_closeModal_5290,
            evh_1040167445267876_success_5289,
            act_1040167445267876_reloadSlickgrid_5292,
            evh_1040167445267876_success_5291,
            act_1040167445267876_request_5284,
            evh_1040167445267876_submit_5283,
            evh_1040167445267876_submit,
            act_1419464017721962_closeComponent_5294,
            evh_1419464017721962_clickPreventStop_5293,
            evh_1419464017721962_clickPreventStop,
            submit: act_1040167445267876_request_5284,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        default: null,
    }),
    __metadata("design:type", typeof (_a = typeof list !== "undefined" && list) === "function" ? _a : Object)
], CreateDunningLetter.prototype, "items", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('items'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateDunningLetter.prototype, "onItems", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], CreateDunningLetter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateDunningLetter.prototype, "onSize", null);
CreateDunningLetter = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateDunningLetter);
exports.default = CreateDunningLetter;
vue_property_decorator_1.Vue.component("CreateDunningLetter", CreateDunningLetter);
