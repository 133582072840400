<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpForm
    :id="formId"
    :name="formName"
    :readonly="item.isReadOnly"
    class="row"
    v-if="loaded"
    @submit="evh_2248226175642056_submit($event, {})"
  >
    <ItpCol>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="delivery_modes"
        :label="'Object type'|pgettext('TextTemplate')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormSelect2
          name="object_type"
          v-model="item.object_type"
          :options="objectTypes"
          valueField="value"
          textField="name"
          required
        >
        </ItpFormSelect2>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="name"
        :label="'Name'|pgettext('TextTemplate')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormInput
          name="name"
          v-model="item.name"
          required
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="description"
        :label="'Description'|pgettext('TextTemplate')"
        :labelAlign="labelAlign"
      >
        <ItpFormInput
          name="description"
          v-model="item.description"
        >
        </ItpFormInput>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="condition"
        :label="'Condition'|pgettext('TextTemplate')"
        :labelAlign="labelAlign"
      >
        <ItpFormTextarea
          name="condition"
          v-model="item.condition"
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <ItpFormGroup
        labelColsMd="2"
        contentColsMd="10"
        name="condition"
        :label="'Template'|pgettext('TextTemplate')"
        :labelAlign="labelAlign"
        required
      >
        <ItpFormTextarea
          name="template"
          monospace
          v-model="item.template"
          required
        >
        </ItpFormTextarea>
      </ItpFormGroup>
      <DIV>
        <ItpButton
          :text="'Save'|pgettext('Button')"
          type="submit"
          :form="formId"
          variant="primary"
          icon="fa-save"
          :spinning="actions.save && actions.save.isRunning"
          :disabled="!forms[formName] || !forms[formName].submittable"
          class="ml-auto"
        >
        </ItpButton>
      </DIV>
    </ItpCol>
  </ItpForm>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class TextTemplate extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  objectTypes!: any;
  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  formName!: any;
  labelAlign!: any;
  container!: any;

  async $$load_container() {
    return {
      self: this.$fn.urlFor('create_object_by_type', { object_type: 'text-templates' }),
      object: 'list',
      data: []
    }
  }

  item!: any;

  async $$load_item() {
    return this.oid ?
      this.$fn.fetch('get_object_by_type', { parameters: { object_type: 'text-templates', id: this.oid }, asResource: true }) :
      new this.$fn.Resource({
        name: null,
        object_type: null,
        condition: null,
        template: null,
        description: null
      })
  }

  ux!: any;
  dataMembers = ['objectTypes', 'formId', 'formName', 'labelAlign', 'container', 'item', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        objectTypes: [{
          value: "invoice",
          name: this.$fn.pgettext("TextTemplate|Type", "Invoice"),
        }
          ,
        {
          value: "invoice_line",
          name: this.$fn.pgettext("TextTemplate|Type", "Invoice line"),
        }
          ,
        ]
        ,
        formId: null,
        formName: "textTemplate",
        labelAlign: "right",
        container: null,
        item: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_emit_2866_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_2248226175642056_emit_2866, alias=undefined
    return {
      event: "saved",
      value: $event.data.response.data,
    }
  }

  async act_2248226175642056_rest_2864_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_2248226175642056_rest_2864, alias=save
    return {
      method: "save",
      container: this.container,
      resource: this.item,
    }
  }

  async evh_2248226175642056_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_submit.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2862: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2861: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_emit_2866: actions.EmitAction;
    evh_2248226175642056_saved_2865: actions.EventHandlerImpl;
    act_2248226175642056_rest_2864: actions.RestAction;
    evh_2248226175642056_submit_2863: actions.EventHandlerImpl;
    evh_2248226175642056_submit: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2862 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2861 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2862,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2861],
      }
    );
    const act_2248226175642056_emit_2866 = new actions.EmitAction(
      {
        actionArgs: this.act_2248226175642056_emit_2866_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_saved_2865 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_emit_2866,
        event: "saved",
        displayName: "emit",
      }
    );
    const act_2248226175642056_rest_2864 = new actions.RestAction(
      {
        actionArgs: this.act_2248226175642056_rest_2864_getActionArgs,
        displayName: "save",
        events: [evh_2248226175642056_saved_2865],
      }
    );
    const evh_2248226175642056_submit_2863 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_rest_2864,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_2248226175642056_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_submit_2863],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2862,
      evh_7315092382398562_reload_2861,
      evh_7315092382398562_reload,
      act_2248226175642056_emit_2866,
      evh_2248226175642056_saved_2865,
      act_2248226175642056_rest_2864,
      evh_2248226175642056_submit_2863,
      evh_2248226175642056_submit,
      save: act_2248226175642056_rest_2864,
    }
  }
}

Vue.component("TextTemplate", TextTemplate);

</script>