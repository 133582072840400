"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcSuggestInvoiceOption = exports.UcInvoiceTypeBadgeAlt = exports.UcInvoiceTypeBadge = exports.UcInvoiceStatusBadge = exports.UcInvoiceMiniCard = exports.UcInvoiceKindBadge = void 0;
const UcInvoiceKindBadge_yaml_component_1 = require("./UcInvoiceKindBadge.yaml?component");
exports.UcInvoiceKindBadge = UcInvoiceKindBadge_yaml_component_1.default;
const UcInvoiceMiniCard_yaml_component_1 = require("./UcInvoiceMiniCard.yaml?component");
exports.UcInvoiceMiniCard = UcInvoiceMiniCard_yaml_component_1.default;
const UcInvoiceStatusBadge_yaml_component_1 = require("./UcInvoiceStatusBadge.yaml?component");
exports.UcInvoiceStatusBadge = UcInvoiceStatusBadge_yaml_component_1.default;
const UcInvoiceTypeBadge_yaml_component_1 = require("./UcInvoiceTypeBadge.yaml?component");
exports.UcInvoiceTypeBadge = UcInvoiceTypeBadge_yaml_component_1.default;
const UcInvoiceTypeBadgeAlt_yaml_component_1 = require("./UcInvoiceTypeBadgeAlt.yaml?component");
exports.UcInvoiceTypeBadgeAlt = UcInvoiceTypeBadgeAlt_yaml_component_1.default;
const UcSuggestInvoiceOption_yaml_component_1 = require("./UcSuggestInvoiceOption.yaml?component");
exports.UcSuggestInvoiceOption = UcSuggestInvoiceOption_yaml_component_1.default;
