"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let InvoicesFilters = class InvoicesFilters extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['item', 'ux', 'b_2248226175642056_options'];
    }
    beforeCreate() {
        this.$$cache_item = new core_1.ComponentValueCache(this, "item", "InvoicesFilters");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                date_from: null,
                date_to: null,
                unpaid: false,
                kind: this.kind,
                date_type: "invoice_date",
            };
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            ux: null,
            b_2248226175642056_options: [{
                    value: "invoice_date",
                    text: this.$fn.pgettext("Invoice", "Invoice date"),
                },
                {
                    value: "fulfillment_date",
                    text: this.$fn.pgettext("Invoice", "Fulfillment date"),
                },
                {
                    value: "due_date",
                    text: this.$fn.pgettext("Invoice", "Due date"),
                },
            ],
        });
    }
    act_5962862420439144_setData_5586_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_from",
                value: $event.data[0],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_5962862420439144_setData_5588_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item.date_to",
                value: $event.data[1],
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_5962862420439144_changed(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_changed.executeFromDOM(this, event, scope);
        });
    }
    act_1048678296148443_emit_5590_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "submit",
                value: Object.assign(Object.assign({}, this.item), { kind: this.kind }),
            };
        });
    }
    evh_1048678296148443_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1048678296148443_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_5962862420439144_setData_5586 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5962862420439144_setData_5586_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_changed_5585 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_setData_5586,
            event: "changed",
            displayName: "setData",
        });
        const act_5962862420439144_setData_5588 = new core_1.actions.SetDataAction({
            actionArgs: this.act_5962862420439144_setData_5588_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_changed_5587 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_setData_5588,
            event: "changed",
            displayName: "setData",
        });
        const evh_5962862420439144_changed = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_changed_5585, evh_5962862420439144_changed_5587],
        });
        const act_1048678296148443_emit_5590 = new core_1.actions.EmitAction({
            actionArgs: this.act_1048678296148443_emit_5590_getActionArgs,
            events: [],
        });
        const evh_1048678296148443_clickPreventStop_5589 = new core_1.actions.EventHandlerImpl({
            action: act_1048678296148443_emit_5590,
            event: "click.prevent.stop",
            displayName: "emit",
        });
        const evh_1048678296148443_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1048678296148443_clickPreventStop_5589],
        });
        return {
            act_5962862420439144_setData_5586,
            evh_5962862420439144_changed_5585,
            act_5962862420439144_setData_5588,
            evh_5962862420439144_changed_5587,
            evh_5962862420439144_changed,
            act_1048678296148443_emit_5590,
            evh_1048678296148443_clickPreventStop_5589,
            evh_1048678296148443_clickPreventStop,
        };
    }
};
InvoicesFilters = __decorate([
    (0, vue_property_decorator_1.Component)()
], InvoicesFilters);
exports.default = InvoicesFilters;
vue_property_decorator_1.Vue.component("InvoicesFilters", InvoicesFilters);
