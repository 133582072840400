import CompanyBankAccount from './CompanyBankAccount.yaml?component';
import UcCompanyBankAccountCard from './UcCompanyBankAccountCard.yaml?component';
import UcCompanyBankAccountFieldset from './UcCompanyBankAccountFieldset.yaml?component';
import UcCompanyBankAccounts from './UcCompanyBankAccounts.yaml?component';

export {
  CompanyBankAccount,
  UcCompanyBankAccountCard,
  UcCompanyBankAccountFieldset,
  UcCompanyBankAccounts
};
