var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpBox",
    { attrs: { alignItems: "center" } },
    [
      !_vm.noIcon
        ? _c("ItpIcon", {
            staticClass: "mr-2",
            attrs: {
              name: _vm.$config.invoice.icon,
              spacing: "mr-3",
              title: _vm._f("pgettext")("Invoice", "Invoice")
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ItpText", {
        staticClass: "mr-2",
        attrs: { strong: "", text: _vm.item.invoice_number }
      }),
      _vm._v(" "),
      _c("UcInvoiceTypeBadge", {
        staticClass: "mr-2",
        attrs: {
          value: _vm.item.invoice_type,
          kind: _vm.item.invoice_kind,
          abbreviated: ""
        }
      }),
      _vm._v(" "),
      _c("ItpLink", {
        attrs: {
          icon: _vm.$config.externalLinkIcon,
          to: {
            name: "app.invoice",
            params: { id: _vm.item.id }
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }