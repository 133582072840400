<template>
<ItpBox
  alignItems="center"
>
  <ItpIcon
    :name="$config.contract.icon"
    :title="'Contract'|pgettext('Contract')"
    class="mr-2"
    v-if="!noIcon"
  >
  </ItpIcon>
  <ItpText
    strong
    :text="item.contract_number"
    class="mr-2"
  >
  </ItpText>
  <ItpLink
    :icon="$config.externalLinkIcon"
    :to="{
    name: 'app.contract',
    params: {id: item.id}
  }"
    v-if="!noLink"
  >
  </ItpLink>
  <UcContractTypeBadge
    :value="item.contract_type"
    class="mx-3"
    v-if="item.contract_type"
  >
  </UcContractTypeBadge>
  <UcContractStatusBadge
    :value="item.status"
    v-if="item.status"
  >
  </UcContractStatusBadge>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcContractMiniCard extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  noIcon!: boolean;

  @Watch('noIcon')
  onNo_icon(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noIcon")
  }

  @Prop({
    type: Boolean,
  })
  noLink!: boolean;

  @Watch('noLink')
  onNo_link(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("noLink")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcContractMiniCard", UcContractMiniCard);

</script>