<template>
<ItpPage
  card
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="DunningLetter"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    :color="$config.dunningLetter.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <strong
      class="text-muted mr-2"
      v-if="!isModalComponent && item"
    >
      {{ 'Dunning letter'|gettext }} - {{ item.reference_number }}
    </strong>
    <div
      class="btn-toolbar flex-nowrap"
    >
      <ItpButton
        variant="light"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reloadData.isRunning"
        class="mr-2"
        @click.prevent.stop="evh_3943151158818434_clickPreventStop($event, {})"
      >
      </ItpButton>
      <ItpButton
        type="submit"
        form="u1w012sjv6"
        variant="primary"
        icon="fa-save"
        :text="'Save'|gettext"
        :spinning="actions.save.isRunning"
        :disabled="!forms.dunningLetter || !forms.dunningLetter.submittable"
        class="mr-2"
      >
      </ItpButton>
      <ItpButton
        variant="danger"
        icon="fa-trash"
        :text="'Delete'|gettext"
        :spinning="actions.delete.isRunning"
        class="mr-2"
        v-if="item.is_deletable"
        @click.prevent.stop="evh_8518026866742051_clickPreventStop($event, {})"
      >
      </ItpButton>
      <b-dropdown
        no-caret
        class="mr-2"
        ref="sendMailDropdown"
      >
        <DIV
          class="text-nowrap"
          slot="button-content"
        >
          <ItpIcon
            name="fa-envelope"
            class="mr-2"
          >
          </ItpIcon>
          <span>
            {{ 'Send email'|gettext }}
          </span>
          <ItpIcon
            name="fa-caret-down"
            class="ml-2"
          >
          </ItpIcon>
        </DIV>
        <b-dropdown-form
          :style="b_7495010493811270_style"
        >
          <ItpFormGroup
            name="email"
            :label="'Recipient'|gettext"
            :description="'Use semicolon to separate multiple addresses.'|gettext"
          >
            <ItpFormInput
              type="text"
              name="email"
              :placeholder="'Email address'|gettext"
              size="md"
              v-model="sendmail.email"
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="template"
            :label="'Template'|gettext"
          >
            <ItpFormSelect
              name="template"
              size="md"
              v-model="sendmail.template"
              required
              :options="emailTemplates"
              valueField="id"
              textField="name"
            >
            </ItpFormSelect>
          </ItpFormGroup>
          <JobHandlerControl
            jobType="dunning_letters:email"
            :payload="() => ({
    id: item.id,
    template: sendmail.template,
    mail_to: _
      .chain(_.split(sendmail.email, ';'))
      .map(_.trim)
      .filter(p => !!p)
      .value()
  })"
            :text="'Send'|pgettext('DunningLetter|Submit')"
            icon="fa-envelope"
            block
            @completed="evh_2492577004902623_completed($event, {})"
          >
          </JobHandlerControl>
        </b-dropdown-form>
      </b-dropdown>
      <b-dropdown
        no-caret
        class="mr-2"
        ref="generatePdfDropdown"
      >
        <DIV
          class="text-nowrap"
          slot="button-content"
        >
          <ItpIcon
            name="fa-file-pdf"
            class="mr-2"
          >
          </ItpIcon>
          <span>
            {{ 'Generate document'|gettext }}
          </span>
          <ItpIcon
            name="fa-caret-down"
            class="ml-2"
          >
          </ItpIcon>
        </DIV>
        <b-dropdown-form
          :style="b_6865942025371558_style"
        >
          <ItpFormGroup
            name="template"
            :label="'Template'|gettext"
          >
            <ItpFormSelect
              name="template"
              :placeholder="'Template'|gettext"
              size="md"
              v-model="printdoc.template"
              required
              :options="htmlTemplates"
              valueField="id"
              textField="name"
            >
            </ItpFormSelect>
          </ItpFormGroup>
          <JobHandlerControl
            jobType="dunning_letters:pdf_generation"
            :payload="() => ({ id: item.id, template: printdoc.template })"
            :text="'Generate document'|pgettext('DunningLetter|Submit')"
            icon="fa-file-pdf"
            block
            @completed="evh_4810867692521497_completed($event, {})"
          >
          </JobHandlerControl>
        </b-dropdown-form>
      </b-dropdown>
    </div>
    <ItpResourceMeta
      :item="item"
      class="ml-auto"
      v-if="item"
    >
    </ItpResourceMeta>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <DIV
      class="row"
    >
      <DIV
        class="col-7"
      >
        <ItpForm
          name="dunningLetter"
          id="u1w012sjv6"
          v-if="item && item.id"
          @submit="evh_7789867021953041_submit($event, {})"
        >
          <div
            class="row"
          >
            <ItpFormGroup
              name="status"
              :label="'Status'|gettext"
              class="col-md-4"
            >
              <ItpFormSelect
                name="status"
                size="md"
                v-model="item.status"
                :options="b_3273883411533724_options"
              >
              </ItpFormSelect>
            </ItpFormGroup>
            <ItpFormGroup
              name="severity"
              :label="'Severity'|gettext"
              class="col-md-2"
            >
              <ItpFormInput
                name="severity"
                size="md"
                type="number"
                min="1"
                max="3"
                v-model="item.severity"
              >
              </ItpFormInput>
            </ItpFormGroup>
          </div>
          <ItpFormGroup
            name="notes"
            :label="'Notes'|gettext"
          >
            <ItpFormTextarea
              name="notes"
              size="md"
              v-model="item.notes"
            >
            </ItpFormTextarea>
          </ItpFormGroup>
          <ItpFormGroup
            name="name"
            :label="'Client'|gettext"
          >
            <ItpFormInput
              name="name"
              size="md"
              v-model="item.customer_name"
              readonly
            >
            </ItpFormInput>
          </ItpFormGroup>
          <ItpFormGroup
            name="address"
            :label="'Address'|gettext"
          >
            <ItpFormInput
              name="address"
              size="md"
              v-model="item.customer_data.address"
              readonly
            >
            </ItpFormInput>
          </ItpFormGroup>
          <DIV
            class="row"
          >
            <ItpFormGroup
              cols-md="4"
            >
              <ItpFormInput
                name="zipcode"
                size="md"
                v-model="item.customer_data.zipcode"
                readonly
              >
              </ItpFormInput>
            </ItpFormGroup>
            <ItpFormGroup
              cols-md="8"
            >
              <ItpFormInput
                name="city"
                size="md"
                v-model="item.customer_data.city"
                readonly
              >
              </ItpFormInput>
            </ItpFormGroup>
          </DIV>
        </ItpForm>
        <DIV
          class="form-group"
        >
          <label>
            {{ 'Invoices'|gettext }}
          </label>
          <ItpTable
            :rows="item.items.length > 1
  ? item.items.concat([{
      $rowClass: 'font-weight-bold text-muted',
      invoice_number: 'Total',
      invoice_data: {
        amount: $fn._.sumBy(item.items, p => p.invoice_data.amount),
        paid: $fn._.sumBy(item.items, p => p.invoice_data.paid),
        debt: $fn._.sumBy(item.items, p => p.invoice_data.debt)
      }
    }])
  : item.items"
            :columns="b_2771751501651205_columns"
            v-if="item"
          >
          </ItpTable>
        </DIV>
      </DIV>
      <DIV
        class="col-5"
      >
        <div
          class="container-fluid"
          v-if="!item.jobs.length"
        >
          <div class="text-center mt-2" role="alert">
  {{ 'No events'|gettext }}
</div>

        </div>
        <div
          class="container-fluid"
          v-if="item.jobs.length"
        >
          <div
  v-for="job in $fn._.orderBy(item.jobs, ['number'], ['desc'])"
  :key="job.id"
  class="row"
>
  <div class="col-4 text-right pb-5 text-nowrap text-truncate">
    <div  class="text-nowrap">
      #{{ job.number }}
      <ItpIcon
        v-if="job.job_type==='email'"
        name="fa-envelope">
      </ItpIcon>

      <ItpIcon
        v-if="job.job_type==='document'"
        name="fa-file-pdf">
      </ItpIcon>
    </div>

    <div
      class="text-muted text-nowrap"
      :title="job.created_at | datetime"
    >
      <ItpIcon name="fa-clock"></ItpIcon>
      {{ job.created_at | datetime({ distanceToNow: true }) }}
    </div>

    <div class="text-muted text-nowrap">
      {{ job.created_by | username }}
    </div>
  </div>

  <div class="col-8 border-left pb-5 text-nowrap text-truncate">
    <div>
      <!--span :class="jobStatusBadgeClass(job.status)">
        {{ job.status|pgettext('TaskRunStatus') }}
      </span-->

      <span
        class="ml-1 text-muted text-nowrap"
        v-if="job.updated_at"
        :title="job.updated_at | datetime"
      >
        {{ job.updated_at | datetime({ distanceToNow: true }) }}
      </span>
    </div>

    <div
      v-if="job.job_type === 'email'"
      class="text-truncate"
      :title="job.mail_to"
    >
      <span class="text-muted mr-1">
        {{ 'To'|pgettext('email') }}:
      </span>
      {{ $fn._.isArray(job.mail_to) ? job.mail_to.join('; ') : job.mail_to }}
    </div>

    <div
      v-if="job.job_type === 'email' && job.subject"
      class="text-truncate"
      :title="job.subject"
    >
      <span class="text-muted mr-1">
        {{ 'Subject'|pgettext('email') }}:
      </span>
      {{ job.subject }}
    </div>

    <div
      v-if="job.job_type === 'email' && job.tracking"
      :title="job.tracking.timestamp | datetime"
    >
      <ItpIcon name="fa-eye"></ItpIcon>
      <span class="ml-2">
        {{ job.tracking.timestamp | datetime({ distanceToNow: true }) }}
      </span>
    </div>

    <hr
      v-if="job.log_url || job.pdf_url || job.html_url"
      class="my-2"
    />

    <div v-if="job.status === 'failed'">
      <ItpLink
        :href="job.log_url"
        icon="fa-file-alt"
        :text="$i18n._('Error log')"
        :tooltip="job.log_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>
    </div>

    <div v-if="job.status === 'completed'">
      <ItpLink
        v-if="job.pdf_url"
        :href="job.pdf_url"
        icon="fa-file-pdf"
        text="PDF"
        :tooltip="job.pdf_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>

      <ItpLink
        v-if="job.html_url"
        :href="job.html_url"
        icon="fa-file-code"
        text="HTML"
        :tooltip="job.html_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>
    </div>
  </div>
</div>

        </div>
      </DIV>
    </DIV>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="modal && loaded"
  >
    <ItpButton
      :text="'Close'|pgettext('DialogButton')"
      variant="default"
      :disabled="actions.save && actions.save.isRunning"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "dunningLetter",
    path: "/dunning-letters/:id",
    component: "DunningLetter"
  }
)

@Component()
export default class DunningLetter extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: String,
    default: null,
  })
  id!: string;

  @Watch('id')
  onId(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("id")
  }

  @Prop({
    type: Object,
  })
  payload!: object;

  @Watch('payload')
  onPayload(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("payload")
  }

  @Prop({
    type: String,
    default: "xl",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  item!: any;

  async $$load_item() {
    return this.payload || (
      this.id !== null
        ? this.$fn.fetch('read_dunning_letter', { asResource: true, parameters: { id: this.id } })
        : this.$fn.schemaDefaults('DunningLetter')
    )
  }

  templates!: any;

  async $$load_templates() {
    return this.$fn.fetch('list_template')
  }

  emailTemplates!: any;

  async $$load_emailTemplates() {
    return _.filter(this.templates, {
      object_type: 'dunning_letter',
      template_type: 'mjml'
    })
  }

  htmlTemplates!: any;

  async $$load_htmlTemplates() {
    return _.filter(this.templates, {
      object_type: 'dunning_letter',
      template_type: 'html'
    })
  }

  sendmail!: any;

  async $$load_sendmail() {
    return {
      email: _.isArray(this.item.customer_data.emails)
        ? _.join(this.item.customer_data.emails, '; ')
        : this.item.customer_data.emails,
      template: _.get(_.first(this.emailTemplates), 'id')
    }
  }

  printdoc!: any;

  async $$load_printdoc() {
    return { template: _.get(_.first(this.htmlTemplates), 'id') }
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_7495010493811270_style!: any;
  b_6865942025371558_style!: any;
  b_3273883411533724_options!: any;
  b_2771751501651205_columns!: any;
  dataMembers = ['item', 'templates', 'emailTemplates', 'htmlTemplates', 'sendmail', 'printdoc', 'ux', 'b_7315092382398562_modalBindings', 'b_7495010493811270_style', 'b_6865942025371558_style', 'b_3273883411533724_options', 'b_2771751501651205_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        item: null,
        templates: null,
        emailTemplates: null,
        htmlTemplates: null,
        sendmail: null,
        printdoc: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "xl",
        }
        ,
        b_7495010493811270_style: {
          minWidth: "250px",
        }
        ,
        b_6865942025371558_style: {
          minWidth: "250px",
        }
        ,
        b_3273883411533724_options: [{
          value: null,
          text: "-",
        }
          ,
        {
          value: "sent",
          text: this.$fn.pgettext("DunningLetterStatus", "Sent"),
        }
          ,
        {
          value: "delivered",
          text: this.$fn.pgettext("DunningLetterStatus", "Delivered"),
        }
          ,
        {
          value: "error",
          text: this.$fn.pgettext("DunningLetterStatus", "Error"),
        }
          ,
        ]
        ,
        b_2771751501651205_columns: [{
          name: this.$fn.gettext("Invoice number"),
          field: "invoice_number",
        }
          ,
        {
          name: this.$fn.gettext("Completion date"),
          field: "invoice_data.fulfillment_date",
          textAlign: "right",
          type: "date",
        }
          ,
        {
          name: this.$fn.gettext("Due date"),
          field: "invoice_data.due_date",
          textAlign: "right",
          type: "date",
        }
          ,
        {
          name: this.$fn.gettext("Amount"),
          field: "invoice_data.amount",
          textAlign: "right",
          type: "number",
        }
          ,
        {
          name: this.$fn.gettext("Paid"),
          field: "invoice_data.paid",
          textAlign: "right",
          type: "number",
        }
          ,
        {
          name: this.$fn.gettext("Debt"),
          field: "invoice_data.debt",
          textAlign: "right",
          type: "number",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: ([this.item.snapshot('reference_number') || this.$fn.gettext('Dunning Letter')]),
        icon: this.$config.dunningLetter.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5230_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5230, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_3943151158818434_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3943151158818434_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_8518026866742051_closeComponent_5238_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_8518026866742051_closeComponent_5238, alias=undefined
    return {
      result: {
        close: true,
      }
      ,
    }
  }

  async act_8518026866742051_reloadSlickgrid_5240_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8518026866742051_reloadSlickgrid_5240, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_8518026866742051_request_5236_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8518026866742051_request_5236, alias=delete
    return {
      operation: "delete_dunning_letter",
      data: this.item,
    }
  }

  async act_8518026866742051_request_5236_getConfirm($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_8518026866742051_request_5236, alias=delete
    return {
      message: this.$fn.gettext("Please confirm delete."),
      btnOk: this.$fn.gettext("Delete"),
    }
  }

  async evh_8518026866742051_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8518026866742051_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_2492577004902623_script_5242_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2492577004902623_script_5242, alias=undefined
    this.$refs.sendMailDropdown.hide(true)
  }

  async act_2492577004902623_script_5242_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2492577004902623_script_5242, alias=undefined
    return {
      value: () => this.act_2492577004902623_script_5242_getActionArgs_value($event),
    }
  }

  async act_2492577004902623_reloadSlickgrid_5246_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_2492577004902623_reloadSlickgrid_5246, alias=undefined
    return {
      grid: "all",
    }
  }

  async evh_2492577004902623_completed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2492577004902623_completed.executeFromDOM(this, event, scope);
  }

  async act_4810867692521497_script_5248_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_4810867692521497_script_5248, alias=undefined
    this.$refs.generatePdfDropdown.hide(true)
  }

  async act_4810867692521497_script_5248_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_4810867692521497_script_5248, alias=undefined
    return {
      value: () => this.act_4810867692521497_script_5248_getActionArgs_value($event),
    }
  }

  async act_4810867692521497_reloadSlickgrid_5252_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_4810867692521497_reloadSlickgrid_5252, alias=undefined
    return {
      grid: "all",
    }
  }

  async evh_4810867692521497_completed(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4810867692521497_completed.executeFromDOM(this, event, scope);
  }

  async act_7789867021953041_setData_5256_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_7789867021953041_setData_5256, alias=undefined
    return {
      path: "item",
      value: new this.$fn.Resource($event.data.response.data),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_7789867021953041_success_5255_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7789867021953041_success_5255, alias=undefined
    return !!$event.data.response.data
  }

  async act_7789867021953041_form_5258_getActionArgs($event: actions.ActionEvent): Promise<actions.FormActionArgs> {
    // parameterResolver name=act_7789867021953041_form_5258, alias=undefined
    return {
      name: "dunningLetter",
      reset: true,
    }
  }

  async act_7789867021953041_reloadSlickgrid_5260_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_7789867021953041_reloadSlickgrid_5260, alias=undefined
    return {
      grid: "all",
    }
  }

  async act_7789867021953041_request_5254_getActionArgs($event: actions.ActionEvent): Promise<actions.RequestActionArgs> {
    // parameterResolver name=act_7789867021953041_request_5254, alias=save
    return {
      operation: "update_dunning_letter",
      data: this.item,
    }
  }

  async evh_7789867021953041_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7789867021953041_submit.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5230: actions.CloseModalAction;
    evh_7315092382398562_close_5229: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_5232: actions.CloseComponentAction;
    evh_2248226175642056_close_5231: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_3943151158818434_reloadComponentData_5234: actions.ReloadComponentDataAction;
    evh_3943151158818434_clickPreventStop_5233: actions.EventHandlerImpl;
    evh_3943151158818434_clickPreventStop: actions.EventHandlerGroup;
    act_8518026866742051_closeComponent_5238: actions.CloseComponentAction;
    evh_8518026866742051_success_5237: actions.EventHandlerImpl;
    act_8518026866742051_reloadSlickgrid_5240: actions.ReloadSlickgridAction;
    evh_8518026866742051_success_5239: actions.EventHandlerImpl;
    act_8518026866742051_request_5236: actions.RequestAction;
    evh_8518026866742051_clickPreventStop_5235: actions.EventHandlerImpl;
    evh_8518026866742051_clickPreventStop: actions.EventHandlerGroup;
    act_2492577004902623_script_5242: actions.ScriptAction;
    evh_2492577004902623_completed_5241: actions.EventHandlerImpl;
    act_2492577004902623_reloadComponentData_5244: actions.ReloadComponentDataAction;
    evh_2492577004902623_completed_5243: actions.EventHandlerImpl;
    act_2492577004902623_reloadSlickgrid_5246: actions.ReloadSlickgridAction;
    evh_2492577004902623_completed_5245: actions.EventHandlerImpl;
    evh_2492577004902623_completed: actions.EventHandlerGroup;
    act_4810867692521497_script_5248: actions.ScriptAction;
    evh_4810867692521497_completed_5247: actions.EventHandlerImpl;
    act_4810867692521497_reloadComponentData_5250: actions.ReloadComponentDataAction;
    evh_4810867692521497_completed_5249: actions.EventHandlerImpl;
    act_4810867692521497_reloadSlickgrid_5252: actions.ReloadSlickgridAction;
    evh_4810867692521497_completed_5251: actions.EventHandlerImpl;
    evh_4810867692521497_completed: actions.EventHandlerGroup;
    act_7789867021953041_setData_5256: actions.SetDataAction;
    evh_7789867021953041_success_5255: actions.EventHandlerImpl;
    act_7789867021953041_form_5258: actions.FormAction;
    evh_7789867021953041_success_5257: actions.EventHandlerImpl;
    act_7789867021953041_reloadSlickgrid_5260: actions.ReloadSlickgridAction;
    evh_7789867021953041_success_5259: actions.EventHandlerImpl;
    act_7789867021953041_request_5254: actions.RequestAction;
    evh_7789867021953041_submit_5253: actions.EventHandlerImpl;
    evh_7789867021953041_submit: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_5262: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_5261: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5230 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5230_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5229 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5230,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5229],
      }
    );
    const act_2248226175642056_closeComponent_5232 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5231 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_5232,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5231],
      }
    );
    const act_3943151158818434_reloadComponentData_5234 = new actions.ReloadComponentDataAction(
      {
        displayName: "reloadData",
        events: [],
      }
    );
    const evh_3943151158818434_clickPreventStop_5233 = new actions.EventHandlerImpl(
      {
        action: act_3943151158818434_reloadComponentData_5234,
        event: "click.prevent.stop",
        displayName: "reloadData",
      }
    );
    const evh_3943151158818434_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_3943151158818434_clickPreventStop_5233],
      }
    );
    const act_8518026866742051_closeComponent_5238 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_8518026866742051_closeComponent_5238_getActionArgs,
        events: [],
      }
    );
    const evh_8518026866742051_success_5237 = new actions.EventHandlerImpl(
      {
        action: act_8518026866742051_closeComponent_5238,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const act_8518026866742051_reloadSlickgrid_5240 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8518026866742051_reloadSlickgrid_5240_getActionArgs,
        events: [],
      }
    );
    const evh_8518026866742051_success_5239 = new actions.EventHandlerImpl(
      {
        action: act_8518026866742051_reloadSlickgrid_5240,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_8518026866742051_request_5236 = new actions.RequestAction(
      {
        actionArgs: this.act_8518026866742051_request_5236_getActionArgs,
        confirm: this.act_8518026866742051_request_5236_getConfirm,
        displayName: "delete",
        events: [evh_8518026866742051_success_5237, evh_8518026866742051_success_5239],
      }
    );
    const evh_8518026866742051_clickPreventStop_5235 = new actions.EventHandlerImpl(
      {
        action: act_8518026866742051_request_5236,
        event: "click.prevent.stop",
        displayName: "delete",
      }
    );
    const evh_8518026866742051_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8518026866742051_clickPreventStop_5235],
      }
    );
    const act_2492577004902623_script_5242 = new actions.ScriptAction(
      {
        actionArgs: this.act_2492577004902623_script_5242_getActionArgs,
        events: [],
      }
    );
    const evh_2492577004902623_completed_5241 = new actions.EventHandlerImpl(
      {
        action: act_2492577004902623_script_5242,
        event: "completed",
        displayName: "script",
      }
    );
    const act_2492577004902623_reloadComponentData_5244 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2492577004902623_completed_5243 = new actions.EventHandlerImpl(
      {
        action: act_2492577004902623_reloadComponentData_5244,
        event: "completed",
        displayName: "reloadComponentData",
      }
    );
    const act_2492577004902623_reloadSlickgrid_5246 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_2492577004902623_reloadSlickgrid_5246_getActionArgs,
        events: [],
      }
    );
    const evh_2492577004902623_completed_5245 = new actions.EventHandlerImpl(
      {
        action: act_2492577004902623_reloadSlickgrid_5246,
        event: "completed",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_2492577004902623_completed = new actions.EventHandlerGroup(
      {
        handlers: [evh_2492577004902623_completed_5241, evh_2492577004902623_completed_5243, evh_2492577004902623_completed_5245],
      }
    );
    const act_4810867692521497_script_5248 = new actions.ScriptAction(
      {
        actionArgs: this.act_4810867692521497_script_5248_getActionArgs,
        events: [],
      }
    );
    const evh_4810867692521497_completed_5247 = new actions.EventHandlerImpl(
      {
        action: act_4810867692521497_script_5248,
        event: "completed",
        displayName: "script",
      }
    );
    const act_4810867692521497_reloadComponentData_5250 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_4810867692521497_completed_5249 = new actions.EventHandlerImpl(
      {
        action: act_4810867692521497_reloadComponentData_5250,
        event: "completed",
        displayName: "reloadComponentData",
      }
    );
    const act_4810867692521497_reloadSlickgrid_5252 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_4810867692521497_reloadSlickgrid_5252_getActionArgs,
        events: [],
      }
    );
    const evh_4810867692521497_completed_5251 = new actions.EventHandlerImpl(
      {
        action: act_4810867692521497_reloadSlickgrid_5252,
        event: "completed",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_4810867692521497_completed = new actions.EventHandlerGroup(
      {
        handlers: [evh_4810867692521497_completed_5247, evh_4810867692521497_completed_5249, evh_4810867692521497_completed_5251],
      }
    );
    const act_7789867021953041_setData_5256 = new actions.SetDataAction(
      {
        actionArgs: this.act_7789867021953041_setData_5256_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_5255 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_setData_5256,
        event: "success",
        when: this.evh_7789867021953041_success_5255_getWhen,
        displayName: "setData",
      }
    );
    const act_7789867021953041_form_5258 = new actions.FormAction(
      {
        actionArgs: this.act_7789867021953041_form_5258_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_5257 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_form_5258,
        event: "success",
        displayName: "form",
      }
    );
    const act_7789867021953041_reloadSlickgrid_5260 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_7789867021953041_reloadSlickgrid_5260_getActionArgs,
        events: [],
      }
    );
    const evh_7789867021953041_success_5259 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_reloadSlickgrid_5260,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_7789867021953041_request_5254 = new actions.RequestAction(
      {
        actionArgs: this.act_7789867021953041_request_5254_getActionArgs,
        displayName: "save",
        events: [evh_7789867021953041_success_5255, evh_7789867021953041_success_5257, evh_7789867021953041_success_5259],
      }
    );
    const evh_7789867021953041_submit_5253 = new actions.EventHandlerImpl(
      {
        action: act_7789867021953041_request_5254,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_7789867021953041_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_7789867021953041_submit_5253],
      }
    );
    const act_1548630417156826_closeComponent_5262 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_5261 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_5262,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_5261],
      }
    );
    return {
      act_7315092382398562_closeModal_5230,
      evh_7315092382398562_close_5229,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_5232,
      evh_2248226175642056_close_5231,
      evh_2248226175642056_close,
      act_3943151158818434_reloadComponentData_5234,
      evh_3943151158818434_clickPreventStop_5233,
      evh_3943151158818434_clickPreventStop,
      act_8518026866742051_closeComponent_5238,
      evh_8518026866742051_success_5237,
      act_8518026866742051_reloadSlickgrid_5240,
      evh_8518026866742051_success_5239,
      act_8518026866742051_request_5236,
      evh_8518026866742051_clickPreventStop_5235,
      evh_8518026866742051_clickPreventStop,
      act_2492577004902623_script_5242,
      evh_2492577004902623_completed_5241,
      act_2492577004902623_reloadComponentData_5244,
      evh_2492577004902623_completed_5243,
      act_2492577004902623_reloadSlickgrid_5246,
      evh_2492577004902623_completed_5245,
      evh_2492577004902623_completed,
      act_4810867692521497_script_5248,
      evh_4810867692521497_completed_5247,
      act_4810867692521497_reloadComponentData_5250,
      evh_4810867692521497_completed_5249,
      act_4810867692521497_reloadSlickgrid_5252,
      evh_4810867692521497_completed_5251,
      evh_4810867692521497_completed,
      act_7789867021953041_setData_5256,
      evh_7789867021953041_success_5255,
      act_7789867021953041_form_5258,
      evh_7789867021953041_success_5257,
      act_7789867021953041_reloadSlickgrid_5260,
      evh_7789867021953041_success_5259,
      act_7789867021953041_request_5254,
      evh_7789867021953041_submit_5253,
      evh_7789867021953041_submit,
      act_1548630417156826_closeComponent_5262,
      evh_1548630417156826_clickPreventStop_5261,
      evh_1548630417156826_clickPreventStop,
      reloadData: act_3943151158818434_reloadComponentData_5234,
      delete: act_8518026866742051_request_5236,
      save: act_7789867021953041_request_5254,
    }
  }
}

Vue.component("DunningLetter", DunningLetter);

</script>