<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <div
    class="container-fluid"
    v-if="loaded && items.length"
  >
    <div
  v-for="job in items"
  :key="job.id"
  class="row"
>
  <div class="col-4 text-right pb-5 text-nowrap text-truncate">
    <div  class="text-nowrap">
      #{{ job.number }}
      <ItpIcon
        v-if="job.job_type==='email'"
        name="fa-envelope">
      </ItpIcon>

      <ItpIcon
        v-if="job.job_type==='document'"
        name="fa-file-pdf">
      </ItpIcon>
    </div>

    <div
      class="text-muted text-nowrap"
      :title="job.created_at | datetime"
    >
      <ItpIcon name="fa-clock"></ItpIcon>
      {{ job.created_at | datetime({ distanceToNow: true }) }}
    </div>

    <div class="text-muted text-nowrap">
      {{ job.created_by | username }}
    </div>
  </div>

  <div class="col-8 border-left pb-5 text-nowrap text-truncate">
    <div>
      <!--span :class="jobStatusBadgeClass(job.status)">
        {{ job.status|pgettext('TaskRunStatus') }}
      </span-->

      <span
        class="ml-1 text-muted text-nowrap"
        v-if="job.updated_at"
        :title="job.updated_at | datetime"
      >
        {{ job.updated_at | datetime({ distanceToNow: true }) }}
      </span>
    </div>

    <div
      v-if="job.job_type === 'email'"
      class="text-truncate"
      :title="job.mail_to"
    >
      <span class="text-muted mr-1">
        {{ 'To'|pgettext('email') }}:
      </span>
      {{ $fn._.isArray(job.mail_to) ? job.mail_to.join('; ') : job.mail_to }}
    </div>

    <div
      v-if="job.job_type === 'email' && job.subject"
      class="text-truncate"
      :title="job.subject"
    >
      <span class="text-muted mr-1">
        {{ 'Subject'|pgettext('email') }}:
      </span>
      {{ job.subject }}
    </div>

    <div
      v-if="job.job_type === 'email' && job.tracking"
      :title="job.tracking.timestamp | datetime"
    >
      <ItpIcon name="fa-eye"></ItpIcon>
      <span class="ml-2">
        {{ job.tracking.timestamp | datetime({ distanceToNow: true }) }}
      </span>
    </div>

    <hr
      v-if="job.log_url || job.pdf_url || job.html_url"
      class="my-2"
    />

    <div v-if="job.status === 'Errored'">
      <ItpLink
        :href="job.log_url"
        icon="fa-file-alt"
        :text="$i18n._('Error log')"
        :tooltip="job.log_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>
    </div>

    <div v-if="job.status === 'Completed'">
      <ItpLink
        v-if="job.pdf_url"
        :href="job.pdf_url"
        icon="fa-file-pdf"
        text="PDF"
        :tooltip="job.pdf_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>

      <ItpLink
        v-if="job.html_url"
        :href="job.html_url"
        icon="fa-file-code"
        text="HTML"
        :tooltip="job.html_filename"
        target="_blank"
        class="d-block"
        use-auth
      ></ItpLink>
    </div>
  </div>
</div>

  </div>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class DunningLetterJobs extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('browse_a_job', {
      parameters: {
        oid: this.item.id,
        job_type: 'dunning_letters:pdf_generation,dunning_letters:emails'
      }
    }).then(d => _.orderBy(d, ['number'], ['desc']))
  }

  ux!: any;
  dataMembers = ['items', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_5298: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_5297: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_5298 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_5297 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_5298,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_5297],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_5298,
      evh_7315092382398562_reload_5297,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("DunningLetterJobs", DunningLetterJobs);

</script>