var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "VueSlickgrid",
        {
          ref: "grid",
          attrs: {
            name: "payments",
            headerTitle: _vm._f("pgettext")("Payments", "PaymentsView"),
            items: _vm.getItems,
            "checkbox-row-selection": "",
            headerShowColumnSelectorButton:
              _vm.b_2248226175642056_headerShowColumnSelectorButton,
            "header-show-reload-button": "",
            "footer-totals": "",
            "export-to-csv": "",
            exportToCsvFilename: _vm._f("pgettext")(
              "payments",
              "ExportFilename"
            ),
            columnDefaults: _vm.b_2248226175642056_columnDefaults,
            columns: _vm.b_2248226175642056_columns
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_2248226175642056_selectedRowsChanged($event, {})
            },
            command: function($event) {
              return _vm.evh_2248226175642056_command($event, {})
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }