var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.values && _vm.grouping
    ? _c(
        "VueSlickgrid",
        {
          ref: "grid",
          attrs: {
            name: "draftInvoiceLines",
            resizeTrigger: _vm.resizeTrigger,
            headerTitle: _vm._f("gettext")("Draft invoice lines"),
            items: _vm.values.rows,
            "checkbox-row-selection": "",
            "footer-totals": "",
            "export-to-csv": "",
            exportToCsvFilename: _vm._f("pgettext")(
              "draft-invoice-lines",
              "ExportFilename"
            ),
            columnDefaults: _vm.b_7315092382398562_columnDefaults,
            columns: _vm.values.columns,
            options: _vm.b_7315092382398562_options,
            grouping: _vm.grouping
          },
          on: {
            selectedRowsChanged: function($event) {
              return _vm.evh_7315092382398562_selectedRowsChanged($event, {})
            },
            command: function($event) {
              return _vm.evh_7315092382398562_command($event, {})
            }
          }
        },
        [
          _c(
            "DIV",
            {
              staticClass: "flex-grow-1 d-flex",
              attrs: { slot: "toolbar" },
              slot: "toolbar"
            },
            [
              _c("ItpButton", {
                staticClass: "mx-2",
                attrs: {
                  icon: "fa-trash",
                  text: _vm._f("gettext")("Delete"),
                  size: "sm",
                  variant: "light",
                  disabled: !_vm.selectedItems || !_vm.selectedItems.length,
                  spinning: _vm.actions.deleteItems.isRunning
                },
                on: {
                  click: function($event) {
                    return _vm.evh_8564662037462133_click($event, {})
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }