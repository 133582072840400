<template>
<ItpModal
  no-primary-button
  :closeButtonText="'Close'|gettext"
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="MRentalGeneralDataEditor"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="documentTitle"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="rental"
      class="row"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <ItpCol>
        <FieldsetRentalGeneralData
          :rental="rental"
          :formId="formId"
        >
        </FieldsetRentalGeneralData>
      </ItpCol>
    </ItpForm>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save changes'|pgettext('Button')"
      :disabled="!forms.rental || !forms.rental.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
    >
    </ItpButton>
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpModal>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MRentalGeneralDataEditor extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "modal",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  rental!: object;

  @Watch('rental')
  onRental(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("rental")
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  ux!: any;
  dataMembers = ['formId', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Rental", "Edit General Information"),
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_7070_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_7070, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_closeModal_7076_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_1040167445267876_closeModal_7076, alias=undefined
    return {
      result: {
        ok: true,
        value: $event.data,
      }
      ,
    }
  }

  async act_1040167445267876_crud_7074_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_7074, alias=save
    return {
      objectType: "rental",
      op: "upsert",
      data: this.rental,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_closeComponent_7078_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseComponentActionArgs> {
    // parameterResolver name=act_1419464017721962_closeComponent_7078, alias=undefined
    return {
      result: {
        cancel: true,
      }
      ,
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_7070: actions.CloseModalAction;
    evh_7315092382398562_close_7069: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_7072: actions.CloseComponentAction;
    evh_2248226175642056_close_7071: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_1040167445267876_closeModal_7076: actions.CloseModalAction;
    evh_1040167445267876_success_7075: actions.EventHandlerImpl;
    act_1040167445267876_crud_7074: actions.CRUDAction;
    evh_1040167445267876_submit_7073: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_1419464017721962_closeComponent_7078: actions.CloseComponentAction;
    evh_1419464017721962_clickPreventStop_7077: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_7070 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_7070_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_7069 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_7070,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_7069],
      }
    );
    const act_2248226175642056_closeComponent_7072 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_7071 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_7072,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_7071],
      }
    );
    const act_1040167445267876_closeModal_7076 = new actions.CloseModalAction(
      {
        actionArgs: this.act_1040167445267876_closeModal_7076_getActionArgs,
        events: [],
      }
    );
    const evh_1040167445267876_success_7075 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_closeModal_7076,
        event: "success",
        displayName: "closeModal",
      }
    );
    const act_1040167445267876_crud_7074 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_7074_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_7075],
      }
    );
    const evh_1040167445267876_submit_7073 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_7074,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_7073],
      }
    );
    const act_1419464017721962_closeComponent_7078 = new actions.CloseComponentAction(
      {
        actionArgs: this.act_1419464017721962_closeComponent_7078_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_clickPreventStop_7077 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_closeComponent_7078,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_7077],
      }
    );
    return {
      act_7315092382398562_closeModal_7070,
      evh_7315092382398562_close_7069,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_7072,
      evh_2248226175642056_close_7071,
      evh_2248226175642056_close,
      act_1040167445267876_closeModal_7076,
      evh_1040167445267876_success_7075,
      act_1040167445267876_crud_7074,
      evh_1040167445267876_submit_7073,
      evh_1040167445267876_submit,
      act_1419464017721962_closeComponent_7078,
      evh_1419464017721962_clickPreventStop_7077,
      evh_1419464017721962_clickPreventStop,
      save: act_1040167445267876_crud_7074,
    }
  }
}

Vue.component("MRentalGeneralDataEditor", MRentalGeneralDataEditor);

</script>