"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcPaymentInvoices = class UcPaymentInvoices extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'autocalcAmount', 'ux', 'b_5962862420439144_paging', 'b_5962862420439144_sortable', 'b_5962862420439144_columns'];
    }
    beforeCreate() {
    }
    createInvoicePaymentData(...args) {
        const [data] = args;
        return {
            invoice_id: data.id,
            invoice_number: data.invoice_number,
            currency: data.currency,
            amount: data.unpaid,
            exchange_rate: 1,
            client: data.client,
            invoice: data
        };
    }
    onPayment(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payment");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                grossAmount: this.$fn.pgettext("Invoice", "Gross amount"),
                paidAmount: this.$fn.pgettext("Invoice", "Payments"),
            },
            autocalcAmount: true,
            ux: null,
            b_5962862420439144_paging: false,
            b_5962862420439144_sortable: false,
            b_5962862420439144_columns: [{
                    name: "invoice_number",
                    width: "210px",
                    header: this.$fn.pgettext("InvoicePayment", "Invoice"),
                    readonly: true,
                    textAlign: "left",
                    type: "template",
                    value: "<DIV>\n\
  <strong>{{ row.invoice.invoice_number }}</strong> <UcInvoiceTypeBadge :value=\"row.invoice.invoice_type\" :kind=\"row.invoice.invoice_kind\"></UcInvoiceTypeBadge><br/>\n\
  {{ row.invoice.invoice_date || date }}<br/>\n\
  <div>\n\
    <ItpText>{{ globs.grossAmount }}:</ItpText>\n\
    <strong>{{ row.invoice.amount || row.invoice.total | number }} {{ row.invoice.currency }}</strong><br/>\n\
  </div>\n\
\n\
  <div v-if=\"row.invoice.invoice_payment\">\n\
    <ItpText>{{ globs.paidAmount }}:</ItpText>\n\
    <strong>{{ row.invoice.invoice_payment | number }} {{ row.invoice.currency }}</strong><br/>\n\
  </div>\n\
  <UcCompanyMiniCard :item=\"row.invoice.client\" no-link></UcCompanyMiniCard>\n\
</DIV>\n\
",
                },
                {
                    name: "amount",
                    header: this.$fn.pgettext("InvoicePayment", "Amount"),
                    type: "number",
                    width: "110px",
                    textAlign: "right",
                    required: true,
                },
                {
                    name: "exchange_rate",
                    width: "70px",
                    header: this.$fn.pgettext("InvoicePayment", "Exch. Rate"),
                    type: "number",
                    textAlign: "right",
                    required: true,
                    noFooterTotals: true,
                },
                {
                    name: "notes",
                    width: "210px",
                    header: this.$fn.pgettext("InvoicePayment", "Notes"),
                },
            ],
        });
    }
    get invoiceTotals() {
        if (this.loaded) {
            return _.isEmpty(this.payment.invoices && this.payment.invoices.data) ? null : _.chain(this.payment.invoices.data)
                .reduce((sum, v, k) => (sum.add(v.amount), sum), this.$fn.numeral(0))
                .value();
        }
        return null;
    }
    get invoiceTotalsDiff() {
        if (this.loaded) {
            return this.invoiceTotals != null
                ? this.$fn.numeral(this.payment.amount).difference(this.invoiceTotals.value())
                : null;
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3343864469950044_script_5738_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            _.chain($event.data.modalResult.value)
                .map(this.createInvoicePaymentData)
                .filter(p => !_.some(this.payment.invoices.data, { invoice_id: p.invoice_id }))
                .each(p => this.payment.invoices.data.push(p))
                .value();
        });
    }
    act_3343864469950044_script_5738_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_3343864469950044_script_5738_getActionArgs_value($event),
            };
        });
    }
    evh_3343864469950044_close_5737_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok;
        });
    }
    act_3343864469950044_emit_5740_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "recalculate",
            };
        });
    }
    evh_3343864469950044_close_5739_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return $event.data.modalResult.ok && this.autocalcAmount;
        });
    }
    act_3343864469950044_showModal_5736_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "InvoiceBrowser",
                props: {
                    size: "full-screen",
                    company_id: this.payment.account && this.payment.account.owner_id,
                    client_id: this.payment.partner && this.payment.partner.id,
                    multiselect: true,
                },
            };
        });
    }
    evh_3343864469950044_click(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_click.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_emit_5742_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "recalculate",
                value: $event.data.result,
            };
        });
    }
    evh_5962862420439144_saveCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_saveCompleted.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_script_5744_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            _.remove(this.payment.invoices.data, { invoice_id: $event.data.row.invoice_id });
        });
    }
    act_5962862420439144_script_5744_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_5962862420439144_script_5744_getActionArgs_value($event),
            };
        });
    }
    evh_5962862420439144_delete(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_delete.executeFromDOM(this, event, scope);
        });
    }
    act_5962862420439144_emit_5746_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "recalculate",
            };
        });
    }
    evh_5962862420439144_deleteCompleted(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_5962862420439144_deleteCompleted.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5734 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5733 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5734,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5733],
        });
        const act_3343864469950044_script_5738 = new core_1.actions.ScriptAction({
            actionArgs: this.act_3343864469950044_script_5738_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_5737 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_script_5738,
            event: "close",
            when: this.evh_3343864469950044_close_5737_getWhen,
            displayName: "script",
        });
        const act_3343864469950044_emit_5740 = new core_1.actions.EmitAction({
            actionArgs: this.act_3343864469950044_emit_5740_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_close_5739 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_emit_5740,
            event: "close",
            when: this.evh_3343864469950044_close_5739_getWhen,
            displayName: "emit",
        });
        const act_3343864469950044_showModal_5736 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3343864469950044_showModal_5736_getActionArgs,
            displayName: "addInvoice",
            events: [evh_3343864469950044_close_5737, evh_3343864469950044_close_5739],
        });
        const evh_3343864469950044_click_5735 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_showModal_5736,
            event: "click",
            displayName: "addInvoice",
        });
        const evh_3343864469950044_click = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_click_5735],
        });
        const act_5962862420439144_emit_5742 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5742_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_saveCompleted_5741 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5742,
            event: "save-completed",
            displayName: "emit",
        });
        const evh_5962862420439144_saveCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_saveCompleted_5741],
        });
        const act_5962862420439144_script_5744 = new core_1.actions.ScriptAction({
            actionArgs: this.act_5962862420439144_script_5744_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_delete_5743 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_script_5744,
            event: "delete",
            displayName: "script",
        });
        const evh_5962862420439144_delete = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_delete_5743],
        });
        const act_5962862420439144_emit_5746 = new core_1.actions.EmitAction({
            actionArgs: this.act_5962862420439144_emit_5746_getActionArgs,
            events: [],
        });
        const evh_5962862420439144_deleteCompleted_5745 = new core_1.actions.EventHandlerImpl({
            action: act_5962862420439144_emit_5746,
            event: "delete-completed",
            displayName: "emit",
        });
        const evh_5962862420439144_deleteCompleted = new core_1.actions.EventHandlerGroup({
            handlers: [evh_5962862420439144_deleteCompleted_5745],
        });
        return {
            act_7315092382398562_reloadComponentData_5734,
            evh_7315092382398562_reload_5733,
            evh_7315092382398562_reload,
            act_3343864469950044_script_5738,
            evh_3343864469950044_close_5737,
            act_3343864469950044_emit_5740,
            evh_3343864469950044_close_5739,
            act_3343864469950044_showModal_5736,
            evh_3343864469950044_click_5735,
            evh_3343864469950044_click,
            act_5962862420439144_emit_5742,
            evh_5962862420439144_saveCompleted_5741,
            evh_5962862420439144_saveCompleted,
            act_5962862420439144_script_5744,
            evh_5962862420439144_delete_5743,
            evh_5962862420439144_delete,
            act_5962862420439144_emit_5746,
            evh_5962862420439144_deleteCompleted_5745,
            evh_5962862420439144_deleteCompleted,
            addInvoice: act_3343864469950044_showModal_5736,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcPaymentInvoices.prototype, "payment", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcPaymentInvoices.prototype, "onPayment", null);
UcPaymentInvoices = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcPaymentInvoices);
exports.default = UcPaymentInvoices;
vue_property_decorator_1.Vue.component("UcPaymentInvoices", UcPaymentInvoices);
