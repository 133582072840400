var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Works", "FixedAssetWorkItems")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _vm.items && _vm.items.length
                ? _c("strong", { staticClass: "mx-2" }, [
                    _vm._v(
                      "\n        (" + _vm._s(_vm.items.length) + ")\n      "
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.loaded
            ? _c(
                "BListGroup",
                [
                  !_vm.items.length
                    ? _c("BListGroupItem", [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("pgettext")(
                                  "No related items.",
                                  "FixedAssetWorkItems"
                                )
                              ) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    _vm.filterItems(
                      _vm.items,
                      _vm.displayAll,
                      _vm.displayCount
                    ),
                    function(item) {
                      return _c(
                        "BListGroupItem",
                        { key: item.id, staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "table",
                            {
                              staticClass: "table table-borderless table-sm m-0"
                            },
                            [
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c("ItpLink", {
                                        attrs: {
                                          text: item.worksheet_number,
                                          to: {
                                            name: "app.worksheet",
                                            params: { id: item.worksheet_id }
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "ItpText",
                                        {
                                          attrs: {
                                            muted: "",
                                            strong: "",
                                            spacing: "mr-4"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("date")(
                                                  item.worksheet_date
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("ItpLink", {
                                        attrs: {
                                          text: item.rental_name,
                                          to: {
                                            name: "app.rental",
                                            params: { id: item.rental_id }
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("ItpLink", {
                                        attrs: {
                                          text: item.client_name,
                                          to: {
                                            name: "app.company",
                                            params: { id: item.client_id }
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                item.worksheet_description
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "4" } },
                                        [
                                          _c("ItpText", {
                                            attrs: {
                                              text: item.worksheet_description
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", { attrs: { colspan: "4" } }, [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-borderless table-sm m-0"
                                      },
                                      [
                                        _c(
                                          "tbody",
                                          _vm._l(item.items, function(work) {
                                            return _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "ItpText",
                                                    {
                                                      attrs: {
                                                        muted: "",
                                                        strong: "",
                                                        spacing: "mr-4"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              work.work_item_date
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "ItpText",
                                                    {
                                                      attrs: {
                                                        strong: "",
                                                        spacing: "mr-3"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm._f("number")(
                                                              work.quantity
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(work.unit) +
                                                          "\n\n                        "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ]
                          )
                        ]
                      )
                    }
                  ),
                  _vm._v(" "),
                  _vm.canHideItems || _vm.canDisplayMoreItems
                    ? _c(
                        "BListGroupItem",
                        [
                          _c(
                            "ItpBox",
                            { attrs: { justifyContent: "center" } },
                            [
                              _vm.canHideItems
                                ? _c("ItpLink", {
                                    attrs: {
                                      text: _vm._f("gettext")("Show less")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.evh_7736732573189513_clickPreventStop(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.canDisplayMoreItems
                                ? _c("ItpLink", {
                                    attrs: {
                                      text: _vm._f("gettext")("Show more")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.evh_2912679705172878_clickPreventStop(
                                          $event,
                                          {}
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }