<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <ItpWidgetCard>
    <ItpBox
      alignItems="center"
      slot="header"
    >
      <strong>
        {{ 'General'|pgettext('Company') }}
      </strong>
      <ItpButton
        :text="'Edit...'|gettext"
        variant="light"
        size="sm"
        class="ml-auto"
        v-if="!readonly"
        @click.prevent.stop="evh_8320016629450276_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <BListGroup>
      <ItpLabeledListGroupItem
        :label="'Name'|pgettext('Company')"
        :labelCols="labelCols"
        v-if="item.name"
      >
        <ItpBox>
          <ItpText
            :text="item.name"
          >
          </ItpText>
          <ItpBadge
            :text="'Default'|pgettext('Company')"
            variant="info"
            no-box-shadow
            class="ml-3"
            v-if="item.is_default"
          >
          </ItpBadge>
        </ItpBox>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Number'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.code"
        v-if="item.code"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Status'|pgettext('Meter')"
        :labelCols="labelCols"
      >
        <UcResourceStatusCard
          :resource="item"
          @reload="evh_7349226261686150_reload($event, {})"
        >
        </UcResourceStatusCard>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Client relation'|pgettext('Company')"
        :labelCols="labelCols"
        v-if="item.role === 'partner' && item.relation"
      >
        <UcCompanyTypeBadge
          :value="item.relation"
        >
        </UcCompanyTypeBadge>
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Email'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.emails"
        v-if="item.emails"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Tax number'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.tax_number"
        v-if="item.tax_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'EU tax number'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.eu_tax_number"
        v-if="item.eu_tax_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Registration number'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.registration_number"
        v-if="item.registration_number"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Notes'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.notes"
        v-if="item.notes"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Payment period'|pgettext('Company')"
        :labelCols="labelCols"
        v-if="item.payment_period_days"
      >
        {{ item.payment_period_days }} {{ 'days'|pgettext('InputGroupAddon') }}
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Discount'|pgettext('Company')"
        :labelCols="labelCols"
        v-if="item.discount"
      >
        {{ item.discount }} %
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Contractor'|pgettext('Company')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="item.is_contractor"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Natural person'|pgettext('Company')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="item.is_natural_person"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Exclude from claims'|pgettext('Company')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="item.excluded_from_claims"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'VAT exempt'|pgettext('Company')"
        :labelCols="labelCols"
        :content="'Yes'|gettext"
        v-if="item.vat_exempt"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'Groups'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.groups"
        v-if="item.groups"
      >
      </ItpLabeledListGroupItem>
      <ItpLabeledListGroupItem
        :label="'External key'|pgettext('Company')"
        :labelCols="labelCols"
        :content="item.external_key"
        v-if="item.external_key"
      >
      </ItpLabeledListGroupItem>
    </BListGroup>
  </ItpWidgetCard>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompanyGeneral extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  item!: object;

  @Watch('item')
  onItem(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("item")
  }

  @Prop({
    type: Boolean,
  })
  readonly!: boolean;

  @Watch('readonly')
  onReadonly(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("readonly")
  }

  @Prop({
    type: Number,
    default: 4,
  })
  labelCols!: number;

  @Watch('labelCols')
  onLabel_cols(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelCols")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_emit_4964_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_8320016629450276_emit_4964, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_8320016629450276_close_4963_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_close_4963, alias=undefined
    return !!$event.data.modalResult.value
  }

  async act_8320016629450276_showModal_4962_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_4962, alias=undefined
    return {
      name: "MCompanyEditor",
      props: {
        target: "general",
        item: _.cloneDeep(this.item),
      }
      ,
    }
  }

  async evh_8320016629450276_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_7349226261686150_emit_4966_getActionArgs($event: actions.ActionEvent): Promise<actions.EmitActionArgs> {
    // parameterResolver name=act_7349226261686150_emit_4966, alias=undefined
    return {
      event: "reload",
    }
  }

  async evh_7349226261686150_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7349226261686150_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_4960: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_4959: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_8320016629450276_emit_4964: actions.EmitAction;
    evh_8320016629450276_close_4963: actions.EventHandlerImpl;
    act_8320016629450276_showModal_4962: actions.ShowModalAction;
    evh_8320016629450276_clickPreventStop_4961: actions.EventHandlerImpl;
    evh_8320016629450276_clickPreventStop: actions.EventHandlerGroup;
    act_7349226261686150_emit_4966: actions.EmitAction;
    evh_7349226261686150_reload_4965: actions.EventHandlerImpl;
    evh_7349226261686150_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_4960 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_4959 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_4960,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_4959],
      }
    );
    const act_8320016629450276_emit_4964 = new actions.EmitAction(
      {
        actionArgs: this.act_8320016629450276_emit_4964_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_close_4963 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_emit_4964,
        event: "close",
        when: this.evh_8320016629450276_close_4963_getWhen,
        displayName: "emit",
      }
    );
    const act_8320016629450276_showModal_4962 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_4962_getActionArgs,
        events: [evh_8320016629450276_close_4963],
      }
    );
    const evh_8320016629450276_clickPreventStop_4961 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_4962,
        event: "click.prevent.stop",
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_clickPreventStop_4961],
      }
    );
    const act_7349226261686150_emit_4966 = new actions.EmitAction(
      {
        actionArgs: this.act_7349226261686150_emit_4966_getActionArgs,
        events: [],
      }
    );
    const evh_7349226261686150_reload_4965 = new actions.EventHandlerImpl(
      {
        action: act_7349226261686150_emit_4966,
        event: "reload",
        displayName: "emit",
      }
    );
    const evh_7349226261686150_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7349226261686150_reload_4965],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_4960,
      evh_7315092382398562_reload_4959,
      evh_7315092382398562_reload,
      act_8320016629450276_emit_4964,
      evh_8320016629450276_close_4963,
      act_8320016629450276_showModal_4962,
      evh_8320016629450276_clickPreventStop_4961,
      evh_8320016629450276_clickPreventStop,
      act_7349226261686150_emit_4966,
      evh_7349226261686150_reload_4965,
      evh_7349226261686150_reload,
    }
  }
}

Vue.component("UcCompanyGeneral", UcCompanyGeneral);

</script>