var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c("UcAccountSelector", {
            staticClass: "mr-2",
            attrs: { cacheKey: "Payments" },
            model: {
              value: _vm.item.account,
              callback: function($$v) {
                _vm.$set(_vm.item, "account", $$v)
              },
              expression: "item.account"
            }
          }),
          _vm._v(" "),
          _c("DateRangeSelector", {
            staticClass: "mr-2",
            style: _vm.b_5962862420439144_style,
            attrs: {
              name: "paymentFilters",
              from: _vm.item.date_from,
              to: _vm.item.date_to
            },
            on: {
              changed: function($event) {
                return _vm.evh_5962862420439144_changed($event, {})
              }
            }
          }),
          _vm._v(" "),
          _c("BtnLoad", {
            staticClass: "mr-2",
            attrs: { action: _vm.actions.load },
            on: {
              click: function($event) {
                return _vm.evh_8358196963355853_click($event, {})
              }
            }
          }),
          _vm._v(" "),
          _c("vr"),
          _vm._v(" "),
          _c(
            "b-dropdown",
            {
              staticClass: "ml-auto border rounded",
              attrs: { text: _vm._f("gettext")("Commands"), variant: "light" }
            },
            [
              _vm.selectedAccountType && _vm.selectedAccountType == "cash_book"
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Add deposit", "Payments"),
                      icon: _vm.$config.payment.icon,
                      "dropdown-item": ""
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.evh_6424600534600827_clickPrevent($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedAccountType && _vm.selectedAccountType == "cash_book"
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("New payment", "Payments"),
                      icon: _vm.$config.payment.icon,
                      "dropdown-item": ""
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.evh_8249915319319009_clickPrevent($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedAccountType && _vm.selectedAccountType !== "cash_book"
                ? _c("ItpButton", {
                    staticClass: "mr-2",
                    attrs: {
                      text: _vm._f("pgettext")(
                        "New transaction...",
                        "Payments"
                      ),
                      icon: _vm.$config.payment.icon,
                      "dropdown-item": ""
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.evh_2276125547171722_clickPrevent($event, {})
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                staticClass: "mr-2",
                attrs: {
                  text: _vm._f("pgettext")(
                    "Import Bank Statement...",
                    "Payments"
                  ),
                  icon: _vm.$config.uploadIcon,
                  "dropdown-item": ""
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.evh_1836715606397834_clickPrevent($event, {})
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }