var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loaded && !_vm.jobData
        ? _c("ItpButton", {
            staticClass: "app-JobHandlerControl",
            attrs: {
              text: _vm.text,
              icon: _vm.icon,
              variant: _vm.variant,
              spinning: _vm.spinning,
              disabled: _vm.disabled,
              size: _vm.size,
              tooltip: _vm.tooltip,
              block: _vm.block
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.evh_7315092382398562_clickPreventStop($event, {})
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group app-JobHandlerControl" },
        [
          _vm.loaded && _vm.jobData
            ? _c(
                "b-dropdown",
                {
                  attrs: {
                    split: "",
                    size: _vm.size,
                    title: _vm.tooltip,
                    variant: _vm.variant,
                    disabled: _vm.jobData ? false : _vm.disabled,
                    "split-class": "border",
                    "toggle-class": "border"
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.evh_96700093353179_clickPreventStop($event, {})
                    },
                    "bv::dropdown::hide": function($event) {
                      return _vm.evh_96700093353179_bvDropdownHide($event, {})
                    },
                    "bv::dropdown::show": function($event) {
                      return _vm.evh_96700093353179_bvDropdownShow($event, {})
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "button-content" },
                    [
                      _c(
                        "ItpBox",
                        { attrs: { alignItems: "center" } },
                        [
                          _vm.spinning
                            ? _c("b-spinner", {
                                staticClass: "mr-1",
                                attrs: { small: "", type: "grow" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.icon
                            ? _c("ItpIcon", {
                                staticClass: "mr-1",
                                attrs: { name: _vm.icon }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.text
                            ? _c("span", { staticClass: "text-nowrap" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.text) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.jobData.status
                            ? _c("UcStatusBadge", {
                                staticClass: "ml-2",
                                attrs: { status: _vm.jobData.status }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.evh_302930856768608_clickPreventStop(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.messages.showJobTooltip) +
                          "...\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.evh_939987670462915_clickPreventStop(
                            $event,
                            {}
                          )
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.messages.reloadJobData) +
                          "\n      "
                      )
                    ]
                  )
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }