import FieldsetCompanyGeneral from './FieldsetCompanyGeneral.yaml?component';
import UcCompanyAddresses from './UcCompanyAddresses.yaml?component';
import UcCompanyGeneral from './UcCompanyGeneral.yaml?component';
import UcCompanyStatement from './UcCompanyStatement.yaml?component';

export * from './addresses';
export * from './bank-accounts';
export * from './contacts';
export * from './employees';

export {
  FieldsetCompanyGeneral,
  UcCompanyAddresses,
  UcCompanyGeneral,
  UcCompanyStatement
};
