<template>
<ItpBox
  direction="row"
>
  <ItpButton
    icon="material-symbols:cloud-sync-outline-rounded"
    :text="context.text"
    :tooltip="context.tooltip"
    :spinning="actions.sync.isRunning || isJobRunning"
    :disabled="actions.sync.isRunning || isJobRunning"
    :variant="context.variant"
    v-if="invoice.meta.sync_enabled && context"
    @click.prevent.stop="evh_2248226175642056_clickPreventStop($event, {})"
  >
  </ItpButton>
  <ItpButton
    icon="icon-park-outline:log"
    :tooltip="messages.showLogMessagesTooltip"
    variant="light"
    size="sm"
    v-if="invoice.sync_job && invoice.sync_job.id"
    @click.prevent.stop="evh_5962862420439144_clickPreventStop($event, {})"
  >
  </ItpButton>
</ItpBox>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class BtnSyncInvoice extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  beforeDestroy(...args: any[]) {
    { { this.invoiceSyncPoller && this.invoiceSyncPoller.stop() } }
  }

  checkStatus(...args: any[]) {
    if (!this.jobData) {
      console.log('JobData is null, stop polling.');
      this.invoiceSyncPoller.stop();
      return;
    }

    const status = this.jobData.status;

    if (status !== 'pending' && status !== 'running') {
      console.log(`JobStatus is ${status}, stop polling.`, this.invoiceSyncPoller);
      this.invoiceSyncPoller.stop();
      this.$emit('success')
      return;
    }

    this.$fn
      .fetch('read_a_job', { parameters: { id: this.jobData.id } })
      .then(data => this.jobData = data)
      .catch(err => {
        console.error(err);
        this.invoiceSyncPoller.stop();
      });
  }

  @Prop({
    required: true,
    type: Object,
  })
  invoice!: object;

  @Watch('invoice')
  onInvoice(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoice")
  }

  jobData!: any;
  invoiceSyncPoller!: any;

  async $$load_invoiceSyncPoller() {
    return this.$fn.poller({ id: 'InvoiceSync', callback: this.checkStatus })
  }

  messages!: any;
  statuses!: any;
  ux!: any;
  dataMembers = ['jobData', 'invoiceSyncPoller', 'messages', 'statuses', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        jobData: null,
        invoiceSyncPoller: null,
        messages: {
          createButtonText: "Számla kiállítása",
          createButtonTitle: "A számla kiállítása a számlázó szolgáltatónál",
          syncButtonTooltip: "Szinkronizálás",
          confirmSyncDialog: {
            header: "Számla szinkronizálása",
            message: "A számlát csak egyszer lehet feltölteni a szamlazz.hu rendszerébe, feltöltés után a számlát már nem lehet módosítani.",
            btnOk: "Szinkronizálás",
            btnCancel: "Mégsem",
          }
          ,
          cancelSyncDialog: {
            header: "Szinkronizálás megszakítása",
            message: "Megszakítás esetén a számla nem lesz szinkronizálva.\n\
Tartalma módosítható, szerkesztés után az új adattartalommal újra szinkronizálható.\n\
A sor feldolgozása a megaszakított elemnél szünetel.\n\
",
            btnOk: "Megszakítás",
            btnCancel: "Mégsem",
          }
          ,
          showLogMessagesTooltip: "Naplófile megtekintése.",
        }
        ,
        statuses: {
          default: {
            variant: "primary",
            text: "Számla kiállítása",
            tooltip: "A számla kiállítása a számlázó szolgáltatónál",
          }
          ,
          pending: {
            icon: "material-symbols:lock-clock-outline-sharp",
            color: "bg-yellow-200",
            variant: "warning",
            text: "Számla kiállítása folyamatban",
            tooltip: "A számla szinkronizálásra vár, ebben az állapotában nem módosítható.",
          }
          ,
          retry: {
            icon: "material-symbols:lock-clock-outline-sharp",
            color: "bg-yellow-200",
            variant: "warning",
            text: "Számla kiállítása folyamatban",
            tooltip: "A számla szinkronizálás alatt.",
          }
          ,
          processing: {
            icon: "material-symbols:cloud-sync-rounded",
            color: "bg-blue-200",
            variant: "warning",
            text: "Számla kiállítása folyamatban",
            tooltip: "A számla szinkronizálás alatt.",
          }
          ,
          failed: {
            icon: "material-symbols:sync-problem-outline-rounded",
            color: "bg-red-200",
            variant: "danger",
            text: "Számla kiállítás hiba",
            tooltip: "Hiba történt a szinkronizálás során.",
          }
          ,
          cancelled: {
            icon: "material-symbols:sync-disabled",
            color: "bg-gray-200",
            variant: "secondary",
            text: "Számla kiállítás megszakítva",
            tooltip: "A számla törölve lett a szinkronizálási sorból.",
          }
          ,
        }
        ,
        ux: null,
      },
    }
  }

  get status() {
    if (this.loaded) {

      return this.jobData ? this.jobData.status : (this.invoice.sync_job ? this.invoice.sync_job.status : null)
    }
    return null;
  }

  get context() {
    if (this.loaded) {

      return this.statuses[this.status || 'default']
    }
    return null;
  }

  get isJobRunning() {
    if (this.loaded) {

      return this.status === 'running' || this.status === 'pending'
    }
    return null;
  }

  async act_2248226175642056_setData_5378_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_5378, alias=undefined
    return {
      path: "jobData",
      value: $event.data.modalResult && $event.data.modalResult.value,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_close_5377_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_5377, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_2248226175642056_script_5380_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2248226175642056_script_5380, alias=undefined
    this.invoiceSyncPoller.start()
  }

  async act_2248226175642056_script_5380_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2248226175642056_script_5380, alias=undefined
    return {
      value: () => this.act_2248226175642056_script_5380_getActionArgs_value($event),
    }
  }

  async evh_2248226175642056_close_5379_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_2248226175642056_close_5379, alias=undefined
    return $event.data.modalResult && $event.data.modalResult.ok
  }

  async act_2248226175642056_showModal_5376_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_2248226175642056_showModal_5376, alias=sync
    return {
      name: "ConfirmSyncInvoice",
      props: {
        invoice: this.invoice,
      }
      ,
    }
  }

  async evh_2248226175642056_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5962862420439144_showModal_5382_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_5962862420439144_showModal_5382, alias=showJob
    return {
      name: "Job",
      props: {
        oid: this.invoice.sync_job.id,
      }
      ,
    }
  }

  async evh_5962862420439144_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5962862420439144_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_2248226175642056_setData_5378: actions.SetDataAction;
    evh_2248226175642056_close_5377: actions.EventHandlerImpl;
    act_2248226175642056_script_5380: actions.ScriptAction;
    evh_2248226175642056_close_5379: actions.EventHandlerImpl;
    act_2248226175642056_showModal_5376: actions.ShowModalAction;
    evh_2248226175642056_clickPreventStop_5375: actions.EventHandlerImpl;
    evh_2248226175642056_clickPreventStop: actions.EventHandlerGroup;
    act_5962862420439144_showModal_5382: actions.ShowModalAction;
    evh_5962862420439144_clickPreventStop_5381: actions.EventHandlerImpl;
    evh_5962862420439144_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_2248226175642056_setData_5378 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_5378_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_close_5377 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_5378,
        event: "close",
        when: this.evh_2248226175642056_close_5377_getWhen,
        displayName: "setData",
      }
    );
    const act_2248226175642056_script_5380 = new actions.ScriptAction(
      {
        actionArgs: this.act_2248226175642056_script_5380_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_close_5379 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_script_5380,
        event: "close",
        when: this.evh_2248226175642056_close_5379_getWhen,
        displayName: "script",
      }
    );
    const act_2248226175642056_showModal_5376 = new actions.ShowModalAction(
      {
        actionArgs: this.act_2248226175642056_showModal_5376_getActionArgs,
        displayName: "sync",
        events: [evh_2248226175642056_close_5377, evh_2248226175642056_close_5379],
      }
    );
    const evh_2248226175642056_clickPreventStop_5375 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_showModal_5376,
        event: "click.prevent.stop",
        displayName: "sync",
      }
    );
    const evh_2248226175642056_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_clickPreventStop_5375],
      }
    );
    const act_5962862420439144_showModal_5382 = new actions.ShowModalAction(
      {
        actionArgs: this.act_5962862420439144_showModal_5382_getActionArgs,
        displayName: "showJob",
        events: [],
      }
    );
    const evh_5962862420439144_clickPreventStop_5381 = new actions.EventHandlerImpl(
      {
        action: act_5962862420439144_showModal_5382,
        event: "click.prevent.stop",
        displayName: "showJob",
      }
    );
    const evh_5962862420439144_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5962862420439144_clickPreventStop_5381],
      }
    );
    return {
      act_2248226175642056_setData_5378,
      evh_2248226175642056_close_5377,
      act_2248226175642056_script_5380,
      evh_2248226175642056_close_5379,
      act_2248226175642056_showModal_5376,
      evh_2248226175642056_clickPreventStop_5375,
      evh_2248226175642056_clickPreventStop,
      act_5962862420439144_showModal_5382,
      evh_5962862420439144_clickPreventStop_5381,
      evh_5962862420439144_clickPreventStop,
      sync: act_2248226175642056_showModal_5376,
      showJob: act_5962862420439144_showModal_5382,
    }
  }
}

Vue.component("BtnSyncInvoice", BtnSyncInvoice);

</script>