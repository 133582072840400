<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="InvoiceGeneration"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <MaterialTabs
    :closeButton="modal"
    :tabManager="tabManager"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <ItpBox
      alignItems="center"
      class="mr-3"
      slot="tabs-start"
    >
      <ItpButton
        variant="default"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        class="px-3"
        @click.prevent.stop="evh_6466705138206980_clickPreventStop($event, {})"
      >
      </ItpButton>
    </ItpBox>
    <b-tab
      :title="'Invoices'|pgettext('InvoiceGeneration|TabHeader')"
      class="container-fluid h-100"
    >
      <UcInvoiceGenerationInvoices
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(0)"
      >
      </UcInvoiceGenerationInvoices>
    </b-tab>
    <b-tab
      :title="'Controlling'|pgettext('InvoiceGeneration|TabHeader')"
      class="container-fluid h-100"
    >
      <UcInvoiceGenerationControlling
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(1)"
      >
      </UcInvoiceGenerationControlling>
    </b-tab>
    <b-tab
      :title="'Invoice lines'|pgettext('InvoiceGeneration|TabHeader')"
      class="container-fluid h-100"
    >
      <UcInvoiceGenerationInvoiceLines
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(2)"
      >
      </UcInvoiceGenerationInvoiceLines>
    </b-tab>
  </MaterialTabs>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "invoiceGeneration",
    path: "/invoice-generation",
    component: "InvoiceGeneration"
  }
)

addRoute(
  {
    name: "draftInvoices",
    path: "/draft-invoices",
    component: "InvoiceGeneration"
  }
)

@Component()
export default class InvoiceGeneration extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['tabManager', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        tabManager: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          size: "md",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("InvoiceGeneration/title", "Invoice generation"),
        icon: this.$config.invoiceGeneration.icon,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_5118_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_5118, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_6466705138206980_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6466705138206980_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_5118: actions.CloseModalAction;
    evh_7315092382398562_close_5117: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_5120: actions.CloseModalAction;
    evh_2248226175642056_close_5119: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_6466705138206980_reloadComponentData_5122: actions.ReloadComponentDataAction;
    evh_6466705138206980_clickPreventStop_5121: actions.EventHandlerImpl;
    evh_6466705138206980_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_5118 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_5118_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_5117 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_5118,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_5117],
      }
    );
    const act_2248226175642056_closeModal_5120 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_5119 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_5120,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_5119],
      }
    );
    const act_6466705138206980_reloadComponentData_5122 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_6466705138206980_clickPreventStop_5121 = new actions.EventHandlerImpl(
      {
        action: act_6466705138206980_reloadComponentData_5122,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6466705138206980_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6466705138206980_clickPreventStop_5121],
      }
    );
    return {
      act_7315092382398562_closeModal_5118,
      evh_7315092382398562_close_5117,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_5120,
      evh_2248226175642056_close_5119,
      evh_2248226175642056_close,
      act_6466705138206980_reloadComponentData_5122,
      evh_6466705138206980_clickPreventStop_5121,
      evh_6466705138206980_clickPreventStop,
      reload: act_6466705138206980_reloadComponentData_5122,
    }
  }
}

Vue.component("InvoiceGeneration", InvoiceGeneration);

</script>