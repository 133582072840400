<template>
<ItpDataTable
  tableId="ProductPrices"
  editable
  paging
  :value="_.orderBy(items, ['valid_from', 'valid_to', 'created_at'], ['desc', 'desc', 'desc'])"
  :rowTemplate="schemaDefaults('CreateCompanyAddress', {
    part_id: product.id,
    valid_from: new Date().toLocaleDateString(),
  })"
  :columns="b_7315092382398562_columns"
  @save="evh_7315092382398562_save($event, {})"
  @delete="evh_7315092382398562_delete($event, {})"
>
</ItpDataTable>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class CompanyAddresses extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  owner!: object;

  @Watch('owner')
  onOwner(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("owner")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_company_address', { parameters: { 'company_id': this.owner.id } })
  }

  ux!: any;
  b_7315092382398562_columns!: any;

  async $$load_b_7315092382398562_columns() {
    return [{
      name: "locked",
      type: "lock",
    }
      ,
    {
      name: "inactive",
      header: this.$fn.gettext("Inactive"),
      width: "30px",
      textAlign: "center",
      formatter: {
        name: "checkbox",
      }
      ,
      editor: {
        name: "checkbox",
      }
      ,
    }
      ,
    {
      name: "valid_from",
      type: "date",
      header: this.$fn.gettext("Valid From"),
      required: true,
      width: "120px",
    }
      ,
    {
      name: "valid_to",
      type: "date",
      header: this.$fn.gettext("Valid To"),
      width: "120px",
    }
      ,
    {
      name: "quantity_from",
      type: "number",
      header: this.$fn.gettext("Quantity from"),
      width: "110px",
      validator: ["numeric"
        ,
      ]
      ,
    }
      ,
    {
      name: "price",
      type: "number",
      header: this.$fn.gettext("Price"),
      width: "110px",
      validator: ["required"
        ,
        "numeric"
        ,
      ]
      ,
    }
      ,
    {
      name: "currency",
      header: this.$fn.gettext("Currency"),
      width: "110px",
      required: true,
      type: "select",
      options: this.currencies,
    }
      ,
    {
      name: "is_discount_price",
      type: "checkbox",
      header: this.$fn.gettext("Discount price"),
    }
      ,
    {
      name: "is_purchase_price",
      type: "checkbox",
      header: this.$fn.gettext("Purchase price"),
    }
      ,
    {
      name: "note",
      type: "textarea",
      header: this.$fn.gettext("Note"),
      width: "200px",
    }
      ,
    ]
  }

  dataMembers = ['items', 'ux', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_columns: null,
      },
    }
  }

  async act_7315092382398562_script_4940_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_4940, alias=undefined
    $event.data.waitFor(this.$fn.crud('part_price').upsert($event.data.row))
  }

  async act_7315092382398562_script_4940_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_4940, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_4940_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_save(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_save.executeFromDOM(this, event, scope);
  }

  async act_7315092382398562_script_4942_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_7315092382398562_script_4942, alias=undefined
    $event.data.waitFor(this.$fn.crud('part_price').delete($event.data.row))
  }

  async act_7315092382398562_script_4942_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_7315092382398562_script_4942, alias=undefined
    return {
      value: () => this.act_7315092382398562_script_4942_getActionArgs_value($event),
    }
  }

  async evh_7315092382398562_delete(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_delete.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_script_4940: actions.ScriptAction;
    evh_7315092382398562_save_4939: actions.EventHandlerImpl;
    evh_7315092382398562_save: actions.EventHandlerGroup;
    act_7315092382398562_script_4942: actions.ScriptAction;
    evh_7315092382398562_delete_4941: actions.EventHandlerImpl;
    evh_7315092382398562_delete: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_script_4940 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_4940_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_save_4939 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_4940,
        event: "save",
        displayName: "script",
      }
    );
    const evh_7315092382398562_save = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_save_4939],
      }
    );
    const act_7315092382398562_script_4942 = new actions.ScriptAction(
      {
        actionArgs: this.act_7315092382398562_script_4942_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_delete_4941 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_script_4942,
        event: "delete",
        displayName: "script",
      }
    );
    const evh_7315092382398562_delete = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_delete_4941],
      }
    );
    return {
      act_7315092382398562_script_4940,
      evh_7315092382398562_save_4939,
      evh_7315092382398562_save,
      act_7315092382398562_script_4942,
      evh_7315092382398562_delete_4941,
      evh_7315092382398562_delete,
    }
  }
}

Vue.component("CompanyAddresses", CompanyAddresses);

</script>