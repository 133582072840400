<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Payment"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <UcPaymentHeader
      :payment="item"
      :config="config"
      @reload="evh_8564662037462133_reload($event, {})"
      @deleted="evh_8564662037462133_deleted($event, {})"
    >
    </UcPaymentHeader>
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpForm
      :id="formId"
      name="payment"
      ref="mainForm"
      @submit="evh_1040167445267876_submit($event, {})"
    >
      <FieldsetPaymentGeneral
        :config="config"
        :payment="item"
        :transactionTypes="transactionTypes"
        :formId="formId"
        :reload="lastLoaded"
        @accountChanged="evh_5534025912102772_accountChanged($event, {})"
      >
      </FieldsetPaymentGeneral>
    </ItpForm>
    <HR>
    </HR>
    <UcPaymentInvoices
      :payment="item"
      :reload="lastLoaded"
      @recalculate="evh_3083451129821185_recalculate($event, {})"
    >
    </UcPaymentInvoices>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    :color="$config.payment.color"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Save changes'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!isNew && !item.isReadonly"
    >
    </ItpButton>
    <ItpButton
      :text="'Complete'|pgettext('Command')"
      :disabled="!isNew && item.readonly"
      variant="success"
      icon="fa-check"
      class="mr-2"
      v-if="!isNew && !item.isReadonly"
      @click.prevent.stop="evh_1419464017721962_clickPreventStop($event, {})"
    >
    </ItpButton>
    <ItpButton
      :text="'Create payment'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      type="submit"
      :form="formId"
      variant="primary"
      icon="fa-save"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="isNew"
    >
    </ItpButton>
    <ItpButton
      :text="'Create another payment'|pgettext('Button')"
      :disabled="!forms.payment || !forms.payment.submittable"
      variant="light"
      icon="mdi:forward"
      :spinning="actions.save.isRunning"
      class="mr-2"
      v-if="!isNew"
      @click.prevent.stop="evh_5566617329548203_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Payment extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  recalculateAmountFromLines(...args: any[]) {
    const item = this.item;
    const [invoiceData] = args;

    if (invoiceData) {
      const idx = _.findIndex(item.invoices.data, { invoice_id: invoiceData.invoice_id });
      if (idx > -1) {
        _.extend(item.invoices.data[idx], invoiceData);
      }
    }

    const summa = this.$fn.numeral(0);

    if (!item.invoices || !item.invoices.data) {
      return;
    }

    for (let d of item.invoices.data) {
      summa.add(d.amount);
      console.log(d.amount, summa);
    }

    item.amount = summa.value();

    if (this.isPettyCashPayment) {
      item.amount = Math.abs(item.amount);
    }
  }

  reloadTemplate(...args: any[]) {
    this.loadData('item')
  }

  @Prop({
    type: String,
    default: null,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: Array,
    default: null,
  })
  invoices!: array;

  @Watch('invoices')
  onInvoices(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("invoices")
  }

  @Prop({
    type: String,
  })
  accountId!: string;

  @Watch('accountId')
  onAccount_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("accountId")
  }

  @Prop({
    type: String,
    default: null,
  })
  transactionKind!: string;

  @Watch('transactionKind')
  onTransaction_kind(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionKind")
  }

  @Prop({
    type: String,
    default: null,
  })
  transactionType!: string;

  @Watch('transactionType')
  onTransaction_type(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("transactionType")
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  formId!: any;

  async $$load_formId() {
    return this.$fn.randomElementId()
  }

  item!: any;

  async $$load_item() {
    return this.oid == null
      ? this.$fn.fetch(
        'get_payment_template', {
        asResource: true,
        parameters: {
          kind: this.transactionKind,
          type: this.transactionType,
          invoices: this.invoices ? this.invoices.join(',') : null,
          account_id: (this.$data.item && this.$data.item.account && this.$data.item.account.id) || this.accountId
        }
      }
      )
      : this.$fn.fetch('read_payment', { asResource: true, parameters: { id: this.oid } })
  }

  allConfig!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['formId', 'item', 'allConfig', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        formId: null,
        item: null,
        allConfig: {
          transactionTypes: {
            bank: {
              credit: this.$fn.pgettext("TransactionType", "credit"),
              debit: this.$fn.pgettext("TransactionType", "debit"),
            }
            ,
            cash_book: {
              deposit: this.$fn.pgettext("TransactionType", "deposit"),
              payment: this.$fn.pgettext("TransactionType", "payment"),
            }
            ,
            technical: {
              invoice_reconciliation: this.$fn.pgettext("TransactionType", "invoice reconciliation"),
              write_off: this.$fn.pgettext("TransactionType", "write off"),
            }
            ,
          }
          ,
          bank: {
            credit: {
              fields: {
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Bank credit"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
            debit: {
              fields: {
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Bank debit"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
          }
          ,
          cash_book: {
            deposit: {
              fields: {
                transaction_number: "ro",
                transaction_type: "ro",
                exchange_rate: false,
                statement_number: false,
                transaction_code: false,
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Cash-In"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
            payment: {
              fields: {
                transaction_number: "ro",
                transaction_type: "ro",
                exchange_rate: false,
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Cash-Out"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
          }
          ,
          technical: {
            invoice_reconciliation: {
              fields: {
                amount: false,
                exchange_rate: false,
                transaction_number: false,
                payment_method: false,
                employee: false,
                statement_number: false,
                transaction_code: false,
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Invoice reconciliation"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
            write_off: {
              fields: {
                amount: false,
                exchange_rate: false,
                transaction_number: false,
                payment_method: false,
                employee: false,
                statement_number: false,
                transaction_code: false,
              }
              ,
              resourceTitle: this.$fn.pgettext("Payment|Header", "Write off"),
              newTransaction: this.$fn.pgettext("Payment|Header", "New transaction"),
            }
            ,
          }
          ,
        }
        ,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get isDeletable() {
    if (this.loaded) {

      return this.oid != null
    }
    return null;
  }

  get isNew() {
    if (this.loaded) {

      return this.item && this.item.id == null
    }
    return null;
  }

  get isPettyCashPayment() {
    if (this.loaded) {

      return this.item.account && this.item.account.account_type === 'cash_book'
    }
    return null;
  }

  get config() {
    if (this.loaded) {

      return this.allConfig[this.item.transaction_kind][this.item.transaction_type]
    }
    return null;
  }

  get transactionTypes() {
    if (this.loaded) {

      return this.allConfig.transactionTypes[this.item.transaction_kind]
    }
    return null;
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.target ? this.targetConfig[this.target].title : this.defaultTitle,
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_3020_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3020, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8564662037462133_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_reload.executeFromDOM(this, event, scope);
  }

  async act_8564662037462133_reloadSlickgrid_3028_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_8564662037462133_reloadSlickgrid_3028, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_8564662037462133_deleted(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8564662037462133_deleted.executeFromDOM(this, event, scope);
  }

  async act_1040167445267876_setData_3032_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3032, alias=undefined
    return {
      path: "item",
      value: $event && $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async act_1040167445267876_setData_3032_getWhen($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1040167445267876_setData_3032, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_reloadSlickgrid_3034_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3034, alias=undefined
    return {
      grid: "payments",
    }
  }

  async act_1040167445267876_reloadSlickgrid_3034_getWhen($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1040167445267876_reloadSlickgrid_3034, alias=undefined
    return !!$event.data
  }

  async act_1040167445267876_crud_3030_getActionArgs($event: actions.ActionEvent): Promise<actions.CRUDActionArgs> {
    // parameterResolver name=act_1040167445267876_crud_3030, alias=save
    return {
      objectType: "payment",
      op: "upsert",
      data: this.item,
    }
  }

  async evh_1040167445267876_submit(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1040167445267876_submit.executeFromDOM(this, event, scope);
  }

  async act_5534025912102772_script_3036_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_5534025912102772_script_3036, alias=undefined
    this.reloadTemplate($event.data)
  }

  async act_5534025912102772_script_3036_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_5534025912102772_script_3036, alias=undefined
    return {
      value: () => this.act_5534025912102772_script_3036_getActionArgs_value($event),
    }
  }

  async evh_5534025912102772_accountChanged(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5534025912102772_accountChanged.executeFromDOM(this, event, scope);
  }

  async act_3083451129821185_script_3038_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3083451129821185_script_3038, alias=undefined
    this.recalculateAmountFromLines($event.data)
  }

  async act_3083451129821185_script_3038_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3083451129821185_script_3038, alias=undefined
    return {
      value: () => this.act_3083451129821185_script_3038_getActionArgs_value($event),
    }
  }

  async evh_3083451129821185_recalculate(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3083451129821185_recalculate.executeFromDOM(this, event, scope);
  }

  async act_1419464017721962_setData_3042_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_1419464017721962_setData_3042, alias=undefined
    return {
      path: "item",
      value: $event && $event.data,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_1419464017721962_success_3041_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1419464017721962_success_3041, alias=undefined
    return !!$event.data
  }

  async act_1419464017721962_reloadSlickgrid_3044_getActionArgs($event: actions.ActionEvent): Promise<actions.ReloadSlickgridActionArgs> {
    // parameterResolver name=act_1419464017721962_reloadSlickgrid_3044, alias=undefined
    return {
      grid: "payments",
    }
  }

  async evh_1419464017721962_success_3043_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_1419464017721962_success_3043, alias=undefined
    return !!$event.data
  }

  async act_1419464017721962_rest_3040_getActionArgs($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1419464017721962_rest_3040, alias=complete
    return {
      method: "save",
      container: {
        self: this.item.links.parent,
        object: 'list',
        data: []
      },
      resource: this.item,
      data: {
        etag: this.item.etag,
        complete: true,
      },
    }
  }

  async act_1419464017721962_rest_3040_getConfirm($event: actions.ActionEvent): Promise<actions.RestActionArgs> {
    // parameterResolver name=act_1419464017721962_rest_3040, alias=complete
    return {
      header: this.dialogHeader,
      message: this.$fn.gettext("Completing prevents further modifications. Please confirm this operation."),
      btnOk: this.$fn.gettext("Complete"),
      severity: "warning",
    }
  }

  async evh_1419464017721962_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1419464017721962_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async act_5566617329548203_setData_3046_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_5566617329548203_setData_3046, alias=undefined
    return {
      path: "item",
      value: this.item.clone({
        id: null,
        rev: null,
        transaction_number: this.isPettyCashPayment ? null : this.item.transaction_number,
        amount: 0,
        links: null,
        etag: null,
        invoices: { data: [] },
        preview_url: null,
        locked: false,
        notes: null,
        internal_notes: null,
      }),
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_5566617329548203_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5566617329548203_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3020: actions.CloseModalAction;
    evh_7315092382398562_close_3019: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_3022: actions.CloseModalAction;
    evh_2248226175642056_close_3021: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8564662037462133_reloadComponentData_3024: actions.ReloadComponentDataAction;
    evh_8564662037462133_reload_3023: actions.EventHandlerImpl;
    evh_8564662037462133_reload: actions.EventHandlerGroup;
    act_8564662037462133_closeModal_3026: actions.CloseModalAction;
    evh_8564662037462133_deleted_3025: actions.EventHandlerImpl;
    act_8564662037462133_reloadSlickgrid_3028: actions.ReloadSlickgridAction;
    evh_8564662037462133_deleted_3027: actions.EventHandlerImpl;
    evh_8564662037462133_deleted: actions.EventHandlerGroup;
    act_1040167445267876_setData_3032: actions.SetDataAction;
    evh_1040167445267876_success_3031: actions.EventHandlerImpl;
    act_1040167445267876_reloadSlickgrid_3034: actions.ReloadSlickgridAction;
    evh_1040167445267876_success_3033: actions.EventHandlerImpl;
    act_1040167445267876_crud_3030: actions.CRUDAction;
    evh_1040167445267876_submit_3029: actions.EventHandlerImpl;
    evh_1040167445267876_submit: actions.EventHandlerGroup;
    act_5534025912102772_script_3036: actions.ScriptAction;
    evh_5534025912102772_accountChanged_3035: actions.EventHandlerImpl;
    evh_5534025912102772_accountChanged: actions.EventHandlerGroup;
    act_3083451129821185_script_3038: actions.ScriptAction;
    evh_3083451129821185_recalculate_3037: actions.EventHandlerImpl;
    evh_3083451129821185_recalculate: actions.EventHandlerGroup;
    act_1419464017721962_setData_3042: actions.SetDataAction;
    evh_1419464017721962_success_3041: actions.EventHandlerImpl;
    act_1419464017721962_reloadSlickgrid_3044: actions.ReloadSlickgridAction;
    evh_1419464017721962_success_3043: actions.EventHandlerImpl;
    act_1419464017721962_rest_3040: actions.RestAction;
    evh_1419464017721962_clickPreventStop_3039: actions.EventHandlerImpl;
    evh_1419464017721962_clickPreventStop: actions.EventHandlerGroup;
    act_5566617329548203_setData_3046: actions.SetDataAction;
    evh_5566617329548203_clickPreventStop_3045: actions.EventHandlerImpl;
    evh_5566617329548203_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3020 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3020_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3019 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3020,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3019],
      }
    );
    const act_2248226175642056_closeModal_3022 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_3021 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_3022,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_3021],
      }
    );
    const act_8564662037462133_reloadComponentData_3024 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_reload_3023 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadComponentData_3024,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_8564662037462133_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_reload_3023],
      }
    );
    const act_8564662037462133_closeModal_3026 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_8564662037462133_deleted_3025 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_closeModal_3026,
        event: "deleted",
        displayName: "closeModal",
      }
    );
    const act_8564662037462133_reloadSlickgrid_3028 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_8564662037462133_reloadSlickgrid_3028_getActionArgs,
        events: [],
      }
    );
    const evh_8564662037462133_deleted_3027 = new actions.EventHandlerImpl(
      {
        action: act_8564662037462133_reloadSlickgrid_3028,
        event: "deleted",
        displayName: "reloadSlickgrid",
      }
    );
    const evh_8564662037462133_deleted = new actions.EventHandlerGroup(
      {
        handlers: [evh_8564662037462133_deleted_3025, evh_8564662037462133_deleted_3027],
      }
    );
    const act_1040167445267876_setData_3032 = new actions.SetDataAction(
      {
        actionArgs: this.act_1040167445267876_setData_3032_getActionArgs,
        when: this.act_1040167445267876_setData_3032_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_3031 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_setData_3032,
        event: "success",
        displayName: "setData",
      }
    );
    const act_1040167445267876_reloadSlickgrid_3034 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1040167445267876_reloadSlickgrid_3034_getActionArgs,
        when: this.act_1040167445267876_reloadSlickgrid_3034_getWhen,
        events: [],
      }
    );
    const evh_1040167445267876_success_3033 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_reloadSlickgrid_3034,
        event: "success",
        displayName: "reloadSlickgrid",
      }
    );
    const act_1040167445267876_crud_3030 = new actions.CRUDAction(
      {
        actionArgs: this.act_1040167445267876_crud_3030_getActionArgs,
        displayName: "save",
        events: [evh_1040167445267876_success_3031, evh_1040167445267876_success_3033],
      }
    );
    const evh_1040167445267876_submit_3029 = new actions.EventHandlerImpl(
      {
        action: act_1040167445267876_crud_3030,
        event: "submit",
        displayName: "save",
      }
    );
    const evh_1040167445267876_submit = new actions.EventHandlerGroup(
      {
        handlers: [evh_1040167445267876_submit_3029],
      }
    );
    const act_5534025912102772_script_3036 = new actions.ScriptAction(
      {
        actionArgs: this.act_5534025912102772_script_3036_getActionArgs,
        events: [],
      }
    );
    const evh_5534025912102772_accountChanged_3035 = new actions.EventHandlerImpl(
      {
        action: act_5534025912102772_script_3036,
        event: "accountChanged",
        displayName: "script",
      }
    );
    const evh_5534025912102772_accountChanged = new actions.EventHandlerGroup(
      {
        handlers: [evh_5534025912102772_accountChanged_3035],
      }
    );
    const act_3083451129821185_script_3038 = new actions.ScriptAction(
      {
        actionArgs: this.act_3083451129821185_script_3038_getActionArgs,
        events: [],
      }
    );
    const evh_3083451129821185_recalculate_3037 = new actions.EventHandlerImpl(
      {
        action: act_3083451129821185_script_3038,
        event: "recalculate",
        displayName: "script",
      }
    );
    const evh_3083451129821185_recalculate = new actions.EventHandlerGroup(
      {
        handlers: [evh_3083451129821185_recalculate_3037],
      }
    );
    const act_1419464017721962_setData_3042 = new actions.SetDataAction(
      {
        actionArgs: this.act_1419464017721962_setData_3042_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_3041 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_setData_3042,
        event: "success",
        when: this.evh_1419464017721962_success_3041_getWhen,
        displayName: "setData",
      }
    );
    const act_1419464017721962_reloadSlickgrid_3044 = new actions.ReloadSlickgridAction(
      {
        actionArgs: this.act_1419464017721962_reloadSlickgrid_3044_getActionArgs,
        events: [],
      }
    );
    const evh_1419464017721962_success_3043 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_reloadSlickgrid_3044,
        event: "success",
        when: this.evh_1419464017721962_success_3043_getWhen,
        displayName: "reloadSlickgrid",
      }
    );
    const act_1419464017721962_rest_3040 = new actions.RestAction(
      {
        actionArgs: this.act_1419464017721962_rest_3040_getActionArgs,
        confirm: this.act_1419464017721962_rest_3040_getConfirm,
        displayName: "complete",
        events: [evh_1419464017721962_success_3041, evh_1419464017721962_success_3043],
      }
    );
    const evh_1419464017721962_clickPreventStop_3039 = new actions.EventHandlerImpl(
      {
        action: act_1419464017721962_rest_3040,
        event: "click.prevent.stop",
        displayName: "complete",
      }
    );
    const evh_1419464017721962_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1419464017721962_clickPreventStop_3039],
      }
    );
    const act_5566617329548203_setData_3046 = new actions.SetDataAction(
      {
        actionArgs: this.act_5566617329548203_setData_3046_getActionArgs,
        events: [],
      }
    );
    const evh_5566617329548203_clickPreventStop_3045 = new actions.EventHandlerImpl(
      {
        action: act_5566617329548203_setData_3046,
        event: "click.prevent.stop",
        displayName: "setData",
      }
    );
    const evh_5566617329548203_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_5566617329548203_clickPreventStop_3045],
      }
    );
    return {
      act_7315092382398562_closeModal_3020,
      evh_7315092382398562_close_3019,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_3022,
      evh_2248226175642056_close_3021,
      evh_2248226175642056_close,
      act_8564662037462133_reloadComponentData_3024,
      evh_8564662037462133_reload_3023,
      evh_8564662037462133_reload,
      act_8564662037462133_closeModal_3026,
      evh_8564662037462133_deleted_3025,
      act_8564662037462133_reloadSlickgrid_3028,
      evh_8564662037462133_deleted_3027,
      evh_8564662037462133_deleted,
      act_1040167445267876_setData_3032,
      evh_1040167445267876_success_3031,
      act_1040167445267876_reloadSlickgrid_3034,
      evh_1040167445267876_success_3033,
      act_1040167445267876_crud_3030,
      evh_1040167445267876_submit_3029,
      evh_1040167445267876_submit,
      act_5534025912102772_script_3036,
      evh_5534025912102772_accountChanged_3035,
      evh_5534025912102772_accountChanged,
      act_3083451129821185_script_3038,
      evh_3083451129821185_recalculate_3037,
      evh_3083451129821185_recalculate,
      act_1419464017721962_setData_3042,
      evh_1419464017721962_success_3041,
      act_1419464017721962_reloadSlickgrid_3044,
      evh_1419464017721962_success_3043,
      act_1419464017721962_rest_3040,
      evh_1419464017721962_clickPreventStop_3039,
      evh_1419464017721962_clickPreventStop,
      act_5566617329548203_setData_3046,
      evh_5566617329548203_clickPreventStop_3045,
      evh_5566617329548203_clickPreventStop,
      save: act_1040167445267876_crud_3030,
      complete: act_1419464017721962_rest_3040,
    }
  }
}

Vue.component("Payment", Payment);

</script>