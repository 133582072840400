var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpWidgetCard",
        [
          _c(
            "ItpBox",
            { attrs: { slot: "header", alignItems: "center" }, slot: "header" },
            [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("pgettext")("Terms", "Contract")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c("ItpButton", {
                    staticClass: "ml-auto",
                    attrs: {
                      text: _vm._f("pgettext")("Add term...", "Contract"),
                      variant: "light",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_8320016629450276_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "BListGroup",
            [
              !_vm.contract.terms || !_vm.contract.terms.length
                ? _c(
                    "BListGroupItem",
                    [
                      _c("ItpText", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("pgettext")(
                                "No terms have been added yet.",
                                "Contract"
                              )
                            ) +
                            "\n        "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.filterItems(_vm.contract.terms), function(term) {
                return _c(
                  "BListGroupItem",
                  { key: term.id },
                  [
                    _c(
                      "ItpBox",
                      { attrs: { alignItems: "center" } },
                      [
                        _c("ItpText", {
                          staticClass: "font-weight-bold",
                          attrs: {
                            text: term.name,
                            strikethrough: _vm.isTermStale(term)
                          }
                        }),
                        _vm._v(" "),
                        term.locked
                          ? _c("ItpIcon", {
                              staticClass: "px-3",
                              attrs: {
                                title: _vm._f("gettext")("Locked"),
                                name: "fa-lock"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("DIV", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("date")(term.valid_from)) +
                              "\n"
                          ),
                          term.valid_from && term.valid_to
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                          _vm._v(
                            "\n" +
                              _vm._s(_vm._f("date")(term.valid_to)) +
                              "\n\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "ItpBox",
                          [
                            _c(
                              "ItpText",
                              [
                                _c("ItpText", { staticClass: "px-3" }, [
                                  term.quantity != null
                                    ? _c("span", [
                                        _vm._v(
                                          "\n    " +
                                            _vm._s(
                                              _vm._f("number")(term.quantity)
                                            ) +
                                            " x\n  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  term.amount != null
                                    ? _c("span", [
                                        _vm._v(
                                          " \n    " +
                                            _vm._s(
                                              _vm._f("number")(term.amount)
                                            ) +
                                            "\n  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  term.currency
                                    ? _c("span", [
                                        _vm._v(
                                          " \n    " +
                                            _vm._s(term.currency) +
                                            "\n  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  term.unit
                                    ? _c("span", [
                                        _vm._v(
                                          " \n    / " +
                                            _vm._s(term.unit) +
                                            "\n  "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            term.notes
                              ? _c("ItpText", {
                                  attrs: { text: term.notes, pre: "" }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.readonly
                          ? _c("ItpButton", {
                              staticClass: "ml-auto",
                              attrs: {
                                icon: "fa-pen",
                                tooltip: _vm._f("gettext")("Edit"),
                                variant: "light",
                                size: "sm"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.evh_3577443900630089_clickPreventStop(
                                    $event,
                                    { term: term }
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ItpBox",
                      [
                        _c("ItpText", {
                          attrs: { text: term.description, pre: "" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }