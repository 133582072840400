var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        "no-primary-button": "",
        closeButtonText: _vm._f("gettext")("Close"),
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "ContractTermEditor",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c("UcHeader", {
            attrs: {
              slot: "header",
              card: !_vm.modal,
              modal: _vm.modal,
              title: _vm.documentTitle
            },
            on: {
              close: function($event) {
                return _vm.evh_2248226175642056_close($event, {})
              }
            },
            slot: "header"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcBody",
            { attrs: { card: !_vm.modal, modal: _vm.modal } },
            [
              _c(
                "ItpForm",
                {
                  ref: "mainForm",
                  staticClass: "row",
                  attrs: { id: _vm.formId, name: "contract" },
                  on: {
                    submit: function($event) {
                      return _vm.evh_1040167445267876_submit($event, {})
                    }
                  }
                },
                [
                  _c(
                    "ItpCol",
                    [
                      !_vm.isTermReadonly
                        ? _c(
                            "ItpFormGroup",
                            {
                              attrs: {
                                name: "service",
                                label: _vm._f("pgettext")(
                                  "Service",
                                  "ContractTerm"
                                ),
                                labelColsMd: "4",
                                contentColsMd: "8",
                                labelAlign: _vm.labelAlign
                              }
                            },
                            [
                              _c("ItpFormSelect2", {
                                attrs: {
                                  name: "client",
                                  "bind-object": "",
                                  dataSource: _vm.b_5731695935601903_dataSource,
                                  textField: "name"
                                },
                                on: {
                                  optionSelected: function($event) {
                                    return _vm.evh_5731695935601903_optionSelected(
                                      $event,
                                      {}
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.item.service,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "service", $$v)
                                  },
                                  expression: "item.service"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "name",
                            label: _vm._f("pgettext")("Name", "ContractTerm"),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: {
                              name: "name",
                              required: "",
                              readonly: _vm.isTermReadonly
                            },
                            model: {
                              value: _vm.item.name,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "name", $$v)
                              },
                              expression: "item.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "valid_from",
                            label: _vm._f("pgettext")(
                              "Valid from",
                              "ContractTerm"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              name: "valid_from",
                              readonly: _vm.isTermReadonly
                            },
                            model: {
                              value: _vm.item.valid_from,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_from", $$v)
                              },
                              expression: "item.valid_from"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "valid_to",
                            label: _vm._f("pgettext")(
                              "Valid to",
                              "ContractTerm"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormDatetime", {
                            attrs: {
                              name: "valid_to",
                              readonly: _vm.isTermReadonly
                            },
                            model: {
                              value: _vm.item.valid_to,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "valid_to", $$v)
                              },
                              expression: "item.valid_to"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            label: _vm._f("pgettext")("Amount", "ContractTerm"),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign,
                            contentClass: "input-group"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            staticClass: "col-8",
                            attrs: { type: "number", name: "amount" },
                            model: {
                              value: _vm.item.amount,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "amount", $$v)
                              },
                              expression: "item.amount"
                            }
                          }),
                          _vm._v(" "),
                          _c("ItpFormSelect", {
                            staticClass: "col-4",
                            attrs: {
                              name: "currency",
                              options: _vm.currencies,
                              textField: "code",
                              valueField: "code"
                            },
                            model: {
                              value: _vm.item.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "currency", $$v)
                              },
                              expression: "item.currency"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "quantity",
                            label: _vm._f("pgettext")(
                              "Quantity",
                              "ContractTerm"
                            ),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign,
                            contentClass: "input-group"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { type: "number", name: "quantity" },
                            model: {
                              value: _vm.item.quantity,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "quantity", $$v)
                              },
                              expression: "item.quantity"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "unit",
                            label: _vm._f("pgettext")("Unit", "ContractTerm"),
                            labelColsMd: "4",
                            contentColsMd: "4",
                            labelAlign: _vm.labelAlign,
                            contentClass: "input-group"
                          }
                        },
                        [
                          _c("ItpFormInput", {
                            attrs: { name: "unit" },
                            model: {
                              value: _vm.item.unit,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "unit", $$v)
                              },
                              expression: "item.unit"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "ItpFormGroup",
                        {
                          attrs: {
                            name: "notes",
                            label: _vm._f("pgettext")("Notes", "ContractTerm"),
                            labelColsMd: "4",
                            contentColsMd: "8",
                            labelAlign: _vm.labelAlign
                          }
                        },
                        [
                          _c("ItpFormTextarea", {
                            attrs: { name: "notes" },
                            model: {
                              value: _vm.item.notes,
                              callback: function($$v) {
                                _vm.$set(_vm.item, "notes", $$v)
                              },
                              expression: "item.notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "UcFooter",
            {
              attrs: { slot: "footer", card: !_vm.modal, modal: _vm.modal },
              slot: "footer"
            },
            [
              _vm.mode === "edit" && _vm.isDeletable
                ? _c("ItpButton", {
                    staticClass: "mr-auto",
                    attrs: {
                      text: _vm._f("pgettext")("Delete Term", "Button"),
                      variant: "danger",
                      icon: "fa-times",
                      spinning:
                        _vm.actions.delete && _vm.actions.delete.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_1548630417156826_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isEditable
                ? _c("ItpButton", {
                    attrs: {
                      text: _vm._f("pgettext")("Save", "Button"),
                      disabled:
                        !_vm.forms.contract || !_vm.forms.contract.submittable,
                      type: "submit",
                      form: _vm.formId,
                      variant: "primary",
                      icon: "fa-save",
                      spinning: _vm.actions.save && _vm.actions.save.isRunning
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ItpButton", {
                attrs: { text: _vm._f("gettext")("Close"), variant: "default" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_2186679009640457_clickPreventStop($event, {})
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }