var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpPage",
    {
      attrs: {
        size: _vm.size,
        header: _vm.documentTitle,
        loaded: _vm.loaded,
        noShow: !_vm.loaded,
        modal: _vm.modal,
        "itp-cname": "InvoiceGeneration",
        modalBindings: _vm.b_7315092382398562_modalBindings
      },
      on: {
        close: function($event) {
          return _vm.evh_7315092382398562_close($event, {})
        }
      }
    },
    [
      _vm.loaded
        ? _c(
            "MaterialTabs",
            {
              attrs: { closeButton: _vm.modal, tabManager: _vm.tabManager },
              on: {
                close: function($event) {
                  return _vm.evh_2248226175642056_close($event, {})
                }
              }
            },
            [
              _c(
                "ItpBox",
                {
                  staticClass: "mr-3",
                  attrs: { slot: "tabs-start", alignItems: "center" },
                  slot: "tabs-start"
                },
                [
                  _c("ItpButton", {
                    staticClass: "px-3",
                    attrs: {
                      variant: "default",
                      icon: "fa-sync-alt",
                      text: _vm._f("gettext")("Reload"),
                      spinning: _vm.actions.reload.isRunning
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.evh_6466705138206980_clickPreventStop(
                          $event,
                          {}
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: {
                    title: _vm._f("pgettext")(
                      "Invoices",
                      "InvoiceGeneration|TabHeader"
                    )
                  }
                },
                [
                  _vm.tabManager.visited(0)
                    ? _c("UcInvoiceGenerationInvoices", {
                        staticClass: "h-100",
                        attrs: { reload: _vm.lastLoaded }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: {
                    title: _vm._f("pgettext")(
                      "Controlling",
                      "InvoiceGeneration|TabHeader"
                    )
                  }
                },
                [
                  _vm.tabManager.visited(1)
                    ? _c("UcInvoiceGenerationControlling", {
                        staticClass: "h-100",
                        attrs: { reload: _vm.lastLoaded }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  staticClass: "container-fluid h-100",
                  attrs: {
                    title: _vm._f("pgettext")(
                      "Invoice lines",
                      "InvoiceGeneration|TabHeader"
                    )
                  }
                },
                [
                  _vm.tabManager.visited(2)
                    ? _c("UcInvoiceGenerationInvoiceLines", {
                        staticClass: "h-100",
                        attrs: { reload: _vm.lastLoaded }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded ? _c("template", { slot: "footer" }) : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }