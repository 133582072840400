<template>
<DIV>
  <ItpBox direction=row align-items="top">
  <div class=mr-2>
    <UcInvoiceTypeBadge :value="option.invoice_type" :kind="option.invoice_kind" abbreviated></UcInvoiceTypeBadge>
  </div>

  <div>
    <strong>{{ option.entry_number }}</strong>
    &middot;&nbsp;<strong>{{ option.invoice_number }}</strong>
    <span v-if="option.client_name">&middot;&nbsp;{{ option.client_name }}</span>
    <span v-if="option.description">
    </br>{{ option.description }}
    </span>
    </br>
    {{ option.received_date | date }}
    &middot;&nbsp;{{ option.amount.amount | number }} {{ option.amount.currency}}
  </div>
</ItpBox>

</DIV>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcSuggestInvoiceRegisterEntryOption extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    type: Object,
  })
  option!: object;

  @Watch('option')
  onOption(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("option")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  actions!: {
  }

  getActions() {
    return {
    }
  }
}

Vue.component("UcSuggestInvoiceRegisterEntryOption", UcSuggestInvoiceRegisterEntryOption);

</script>