<template>
<ItpPage
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Registers"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <DIV
    class="overflow-auto h-100 p-3"
  >
    <ItpBox
      direction="column"
    >
      <ItpBox>
        <ItpButton
          :text="'Add register...'|pgettext('Registers')"
          @click="evh_4615685607825208_click($event, {})"
        >
        </ItpButton>
        <ItpButton
          :text="'Reload'|pgettext('Registers')"
          @click="evh_2095089031469157_click($event, {})"
        >
        </ItpButton>
      </ItpBox>
      <ItpDataTable
        tableId="Settings.Registers"
        :editable="b_8320016629450276_editable"
        no-create-row
        no-delete-row
        :value="_.orderBy(items, ['key', 'description'])"
        :columns="b_8320016629450276_columns"
        class="h-100"
        ref="table"
        v-if="loaded"
        @click="evh_8320016629450276_click($event, {})"
      >
      </ItpDataTable>
    </ItpBox>
  </DIV>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "settings.registers",
    path: "registers",
    component: "Registers"
  }
)

@Component()
export default class Registers extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_register')
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_8320016629450276_editable!: any;
  b_8320016629450276_columns!: any;
  dataMembers = ['items', 'ux', 'b_7315092382398562_modalBindings', 'b_8320016629450276_editable', 'b_8320016629450276_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "sm",
        }
        ,
        b_8320016629450276_editable: false,
        b_8320016629450276_columns: [{
          name: "name",
          header: this.$fn.pgettext("Register", "Name"),
          type: "link",
          width: "120px",
        }
          ,
        {
          name: "register_type",
          header: this.$fn.pgettext("Register", "Type"),
          width: "120px",
        }
          ,
        ]
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_3920_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_3920, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async act_4615685607825208_showModal_3922_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_4615685607825208_showModal_3922, alias=undefined
    return {
      name: "Register",
    }
  }

  async evh_4615685607825208_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4615685607825208_click.executeFromDOM(this, event, scope);
  }

  async evh_2095089031469157_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2095089031469157_click.executeFromDOM(this, event, scope);
  }

  async act_8320016629450276_showModal_3926_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_8320016629450276_showModal_3926, alias=undefined
    return {
      name: "Register",
      props: {
        oid: $event.data.row.id,
      }
      ,
    }
  }

  async evh_8320016629450276_click_3925_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_8320016629450276_click_3925, alias=undefined
    return $event.data.col.name === 'name'
  }

  async evh_8320016629450276_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_3920: actions.CloseModalAction;
    evh_7315092382398562_close_3919: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_4615685607825208_showModal_3922: actions.ShowModalAction;
    evh_4615685607825208_click_3921: actions.EventHandlerImpl;
    evh_4615685607825208_click: actions.EventHandlerGroup;
    act_2095089031469157_reloadComponentData_3924: actions.ReloadComponentDataAction;
    evh_2095089031469157_click_3923: actions.EventHandlerImpl;
    evh_2095089031469157_click: actions.EventHandlerGroup;
    act_8320016629450276_showModal_3926: actions.ShowModalAction;
    evh_8320016629450276_click_3925: actions.EventHandlerImpl;
    evh_8320016629450276_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_3920 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_3920_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_3919 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_3920,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_3919],
      }
    );
    const act_4615685607825208_showModal_3922 = new actions.ShowModalAction(
      {
        actionArgs: this.act_4615685607825208_showModal_3922_getActionArgs,
        events: [],
      }
    );
    const evh_4615685607825208_click_3921 = new actions.EventHandlerImpl(
      {
        action: act_4615685607825208_showModal_3922,
        event: "click",
        displayName: "showModal",
      }
    );
    const evh_4615685607825208_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_4615685607825208_click_3921],
      }
    );
    const act_2095089031469157_reloadComponentData_3924 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2095089031469157_click_3923 = new actions.EventHandlerImpl(
      {
        action: act_2095089031469157_reloadComponentData_3924,
        event: "click",
        displayName: "reloadComponentData",
      }
    );
    const evh_2095089031469157_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_2095089031469157_click_3923],
      }
    );
    const act_8320016629450276_showModal_3926 = new actions.ShowModalAction(
      {
        actionArgs: this.act_8320016629450276_showModal_3926_getActionArgs,
        events: [],
      }
    );
    const evh_8320016629450276_click_3925 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_showModal_3926,
        event: "click",
        when: this.evh_8320016629450276_click_3925_getWhen,
        displayName: "showModal",
      }
    );
    const evh_8320016629450276_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_click_3925],
      }
    );
    return {
      act_7315092382398562_closeModal_3920,
      evh_7315092382398562_close_3919,
      evh_7315092382398562_close,
      act_4615685607825208_showModal_3922,
      evh_4615685607825208_click_3921,
      evh_4615685607825208_click,
      act_2095089031469157_reloadComponentData_3924,
      evh_2095089031469157_click_3923,
      evh_2095089031469157_click,
      act_8320016629450276_showModal_3926,
      evh_8320016629450276_click_3925,
      evh_8320016629450276_click,
    }
  }
}

Vue.component("Registers", Registers);

</script>