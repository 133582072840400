var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      _c(
        "ItpBox",
        { attrs: { alignItems: "center" } },
        [
          _c(
            "DIV",
            [
              _c(
                "ItpBox",
                { staticClass: "mb-2" },
                [
                  _vm.fixedAsset.rental
                    ? _c("ItpText", {
                        attrs: {
                          strong: "",
                          text: _vm.fixedAsset.rental.name,
                          "no-wrap": ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fixedAsset.rental
                    ? _c("ItpLink", {
                        staticClass: "ml-2",
                        attrs: {
                          to: {
                            name: "app.rental",
                            params: { id: _vm.fixedAsset.rental.id }
                          },
                          icon: "fa-external-link-square-alt"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ItpBox",
                [
                  _vm.fixedAsset.rental && _vm.fixedAsset.rental.client_id
                    ? _c("ItpText", {
                        attrs: {
                          strong: "",
                          text: _vm.fixedAsset.rental.client_name,
                          "no-wrap": ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.fixedAsset.rental && _vm.fixedAsset.rental.client_id
                    ? _c("ItpLink", {
                        staticClass: "ml-2",
                        attrs: {
                          to: {
                            name: "app.company",
                            params: { id: _vm.fixedAsset.rental.client_id }
                          },
                          icon: "fa-external-link-square-alt"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.readonly
            ? _c("ItpButton", {
                staticClass: "ml-auto",
                attrs: {
                  text: _vm._f("pgettext")("Add to rental", "FixedAsset"),
                  variant: "light",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.evh_3343864469950044_clickPreventStop($event, {})
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }