<template>
<VueSlickgrid
  name="clientStatement"
  :headerTitle="'Statement'|pgettext('client|Statement')"
  :items="$fn.slickDataSource('browse_client_statement', {parameters: getQueryParameters})"
  checkbox-row-selection
  footer-totals
  export-to-csv
  :exportToCsvFilename="'client-statement'|pgettext('filename')"
  :columnDefaults="b_7315092382398562_columnDefaults"
  :columns="b_7315092382398562_columns"
  ref="grid"
  v-if="loaded"
  @command="evh_7315092382398562_command($event, {})"
>
</VueSlickgrid>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class UcCompanyStatement extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  getQueryParameters(...args: any[]) {
    return { client_id: this.oid }
  }

  @Prop({
    required: true,
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  ux!: any;
  b_7315092382398562_columnDefaults!: any;
  b_7315092382398562_columns!: any;

  async $$load_b_7315092382398562_columns() {
    return [{
      field: "transaction_date",
      name: this.$fn.pgettext("CompanyStatement", "Date"),
      type: "date",
    }
      ,
    {
      field: "transaction_type",
      name: this.$fn.pgettext("CompanyStatement", "Type"),
      width: "100px",
      formatter: {
        name: "chain",
        formatters: [{
          name: "gettext",
          conversion: "StartCase",
          context: "CompanyStatementType",
          filterInput: true,
        }
          ,
        {
          name: "cellclass",
          value: (row) => ({
            'font-weight-bold': true,
            'bg-red-200': row.transaction_type === 'invoice',
            'bg-green-200': row.transaction_type === 'payment',
          }),
        }
          ,
        ]
        ,
      }
      ,
    }
      ,
    {
      field: "transaction_number",
      name: this.$fn.pgettext("CompanyStatement", "Number"),
      width: "120px",
      type: "command",
      formatter: {
        name: "link",
        href: "#",
      }
      ,
    }
      ,
    {
      field: "amount",
      name: this.$fn.pgettext("CompanyStatement", "Amount"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
    }
      ,
    {
      field: "currency",
      name: this.$fn.pgettext("CompanyStatement", "Currency"),
    }
      ,
    {
      field: "balance",
      name: this.$fn.pgettext("CompanyStatement", "Balance"),
      type: "number",
      formatter: {
        name: "localestring",
        locale: "hu-HU",
      }
      ,
      noFooterTotals: true,
    }
      ,
    ]
  }

  dataMembers = ['ux', 'b_7315092382398562_columnDefaults', 'b_7315092382398562_columns'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
        b_7315092382398562_columnDefaults: {
          sortable: false,
          filterable: true,
          resizable: true,
        }
        ,
        b_7315092382398562_columns: null,
      },
    }
  }

  async act_7315092382398562_showModal_4948_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7315092382398562_showModal_4948, alias=undefined
    return {
      name: "Payment",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_7315092382398562_command_4947_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7315092382398562_command_4947, alias=undefined
    return $event.data.column.id === 'transaction_number' && $event.data.data.transaction_type === 'payment'
  }

  async act_7315092382398562_showModal_4950_getActionArgs($event: actions.ActionEvent): Promise<actions.ShowModalActionArgs> {
    // parameterResolver name=act_7315092382398562_showModal_4950, alias=undefined
    return {
      name: "Invoice",
      props: {
        modal: true,
        oid: $event.data.data.id,
      }
      ,
    }
  }

  async evh_7315092382398562_command_4949_getWhen($event: actions.ActionEvent): Promise<actions.EventHandlerImplArgs> {
    // parameterResolver name=evh_7315092382398562_command_4949, alias=undefined
    return $event.data.column.id === 'transaction_number' && $event.data.data.transaction_type === 'invoice'
  }

  async evh_7315092382398562_command(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_command.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_showModal_4948: actions.ShowModalAction;
    evh_7315092382398562_command_4947: actions.EventHandlerImpl;
    act_7315092382398562_showModal_4950: actions.ShowModalAction;
    evh_7315092382398562_command_4949: actions.EventHandlerImpl;
    evh_7315092382398562_command: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_showModal_4948 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7315092382398562_showModal_4948_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_command_4947 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_showModal_4948,
        event: "command",
        when: this.evh_7315092382398562_command_4947_getWhen,
        displayName: "showModal",
      }
    );
    const act_7315092382398562_showModal_4950 = new actions.ShowModalAction(
      {
        actionArgs: this.act_7315092382398562_showModal_4950_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_command_4949 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_showModal_4950,
        event: "command",
        when: this.evh_7315092382398562_command_4949_getWhen,
        displayName: "showModal",
      }
    );
    const evh_7315092382398562_command = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_command_4947, evh_7315092382398562_command_4949],
      }
    );
    return {
      act_7315092382398562_showModal_4948,
      evh_7315092382398562_command_4947,
      act_7315092382398562_showModal_4950,
      evh_7315092382398562_command_4949,
      evh_7315092382398562_command,
    }
  }
}

Vue.component("UcCompanyStatement", UcCompanyStatement);

</script>