"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcContractTerms = class UcContractTerms extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['today', 'displayHelper', 'ux'];
    }
    beforeCreate() {
    }
    filterItems(...args) {
        return _.sortBy(args[0], p => this.isTermStale(p) ? "_" + p.name : p.name);
    }
    isTermStale(...args) {
        const [term] = args;
        return term.valid_to && this.$fn.parseISODate(term.valid_to) < this.today;
    }
    onContract(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("contract");
    }
    onReadonly(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("readonly");
    }
    $$load_today() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Date();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            today: null,
            displayHelper: null,
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_8320016629450276_emit_6854_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_8320016629450276_close_6853_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_8320016629450276_showModal_6852_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractTermEditor",
                props: {
                    mode: "add",
                    contract: this.contract,
                },
            };
        });
    }
    evh_8320016629450276_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8320016629450276_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_3577443900630089_emit_6858_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    evh_3577443900630089_close_6857_getWhen($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return !!$event.data.modalResult.ok;
        });
    }
    act_3577443900630089_showModal_6856_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "ContractTermEditor",
                props: {
                    mode: "edit",
                    contract: this.contract,
                    term: _.cloneDeep($event.scope.term),
                },
            };
        });
    }
    evh_3577443900630089_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3577443900630089_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_6850 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_6849 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_6850,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_6849],
        });
        const act_8320016629450276_emit_6854 = new core_1.actions.EmitAction({
            actionArgs: this.act_8320016629450276_emit_6854_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_8320016629450276_close_6853 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_emit_6854,
            event: "close",
            when: this.evh_8320016629450276_close_6853_getWhen,
            displayName: "emitReload",
        });
        const act_8320016629450276_showModal_6852 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_8320016629450276_showModal_6852_getActionArgs,
            events: [evh_8320016629450276_close_6853],
        });
        const evh_8320016629450276_clickPreventStop_6851 = new core_1.actions.EventHandlerImpl({
            action: act_8320016629450276_showModal_6852,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_8320016629450276_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8320016629450276_clickPreventStop_6851],
        });
        const act_3577443900630089_emit_6858 = new core_1.actions.EmitAction({
            actionArgs: this.act_3577443900630089_emit_6858_getActionArgs,
            displayName: "emitReload",
            events: [],
        });
        const evh_3577443900630089_close_6857 = new core_1.actions.EventHandlerImpl({
            action: act_3577443900630089_emit_6858,
            event: "close",
            when: this.evh_3577443900630089_close_6857_getWhen,
            displayName: "emitReload",
        });
        const act_3577443900630089_showModal_6856 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_3577443900630089_showModal_6856_getActionArgs,
            events: [evh_3577443900630089_close_6857],
        });
        const evh_3577443900630089_clickPreventStop_6855 = new core_1.actions.EventHandlerImpl({
            action: act_3577443900630089_showModal_6856,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_3577443900630089_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3577443900630089_clickPreventStop_6855],
        });
        return {
            act_7315092382398562_reloadComponentData_6850,
            evh_7315092382398562_reload_6849,
            evh_7315092382398562_reload,
            act_8320016629450276_emit_6854,
            evh_8320016629450276_close_6853,
            act_8320016629450276_showModal_6852,
            evh_8320016629450276_clickPreventStop_6851,
            evh_8320016629450276_clickPreventStop,
            act_3577443900630089_emit_6858,
            evh_3577443900630089_close_6857,
            act_3577443900630089_showModal_6856,
            evh_3577443900630089_clickPreventStop_6855,
            evh_3577443900630089_clickPreventStop,
            emitReload: act_3577443900630089_emit_6858,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcContractTerms.prototype, "contract", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('contract'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractTerms.prototype, "onContract", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcContractTerms.prototype, "readonly", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('readonly'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcContractTerms.prototype, "onReadonly", null);
UcContractTerms = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcContractTerms);
exports.default = UcContractTerms;
vue_property_decorator_1.Vue.component("UcContractTerms", UcContractTerms);
