<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    class="form-row"
  >
    <ItpFormGroup
      name="address_type"
      :label="'Address Type'|pgettext('Address')"
      :labelAlign="labelAlign"
      required
      class="col-7"
    >
      <ItpFormSelect
        name="address_type"
        v-model="address.address_type"
        :options="addressTypes"
        required
      >
      </ItpFormSelect>
    </ItpFormGroup>
    <ItpBox
      direction="column"
      alignItems="end"
      justifyContent="end"
      class="col-5 mb-4"
    >
      <ItpFormCheckbox
        name="is_default"
        class="font-weight-bold"
        :label="'Default'|gettext"
        inline
        is-switch
        v-model="address.is_default"
        :readonly="address.isReadonly"
      >
      </ItpFormCheckbox>
    </ItpBox>
  </DIV>
  <hr>
  </hr>
  <DIV
    class="form-row"
  >
    <ItpFormGroup
      name="country"
      :label="'Country'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-7"
    >
      <ItpFormInput
        name="country"
        v-model="address.country"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="region"
      :label="'Region'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-5"
    >
      <ItpFormInput
        name="region"
        v-model="address.region"
      >
      </ItpFormInput>
    </ItpFormGroup>
  </DIV>
  <DIV
    class="form-row"
  >
    <ItpFormGroup
      name="postal_code"
      :label="'Postal Code'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-3"
    >
      <ItpFormInput
        name="postal_code"
        v-model="address.postal_code"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="city"
      :label="'City'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-6"
    >
      <ItpFormInput
        name="city"
        v-model="address.city"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="district"
      :label="'District'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-3"
    >
      <ItpFormInput
        name="district"
        v-model="address.district"
      >
      </ItpFormInput>
    </ItpFormGroup>
  </DIV>
  <hr>
  </hr>
  <DIV
    class="form-row"
  >
    <ItpFormGroup
      name="public_place"
      :label="'Public Place'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-7"
    >
      <ItpFormInput
        name="public_place"
        v-model="address.public_place"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="public_place_type"
      :label="'Public Place Type'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col-5"
    >
      <ItpFormInput
        name="public_place_type"
        v-model="address.public_place_type"
      >
      </ItpFormInput>
    </ItpFormGroup>
  </DIV>
  <DIV
    class="form-row"
  >
    <ItpFormGroup
      name="house_nr"
      :label="'House Nr'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col"
    >
      <ItpFormInput
        name="house_nr"
        v-model="address.house_nr"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="building_nr"
      :label="'Building Nr'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col"
    >
      <ItpFormInput
        name="building_nr"
        v-model="address.building_nr"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="sub_building_nr"
      :label="'Sub Building Nr'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col"
    >
      <ItpFormInput
        name="sub_building_nr"
        v-model="address.sub_building_nr"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="floor_nr"
      :label="'Floor Nr'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col"
    >
      <ItpFormInput
        name="floor_nr"
        v-model="address.floor_nr"
      >
      </ItpFormInput>
    </ItpFormGroup>
    <ItpFormGroup
      name="door_nr"
      :label="'Door Nr'|pgettext('Address')"
      :labelAlign="labelAlign"
      class="col"
    >
      <ItpFormInput
        name="door_nr"
        v-model="address.door_nr"
      >
      </ItpFormInput>
    </ItpFormGroup>
  </DIV>
  <hr>
  </hr>
  <ItpFormGroup
    name="other_details"
    :label="'Other Details'|pgettext('Address')"
    :labelAlign="labelAlign"
  >
    <ItpFormTextarea
      name="other_details"
      v-model="address.other_details"
    >
    </ItpFormTextarea>
  </ItpFormGroup>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class FieldsetAddress extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  @Prop({
    required: true,
    type: Object,
  })
  address!: object;

  @Watch('address')
  onAddress(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("address")
  }

  @Prop({
    required: true,
    type: Array,
  })
  addressTypes!: array;

  @Watch('addressTypes')
  onAddress_types(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("addressTypes")
  }

  @Prop({
    type: String,
    default: "left",
  })
  labelAlign!: string;

  @Watch('labelAlign')
  onLabel_align(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("labelAlign")
  }

  ux!: any;
  dataMembers = ['ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_6122: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_6121: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_6122 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_6121 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_6122,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_6121],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_6122,
      evh_7315092382398562_reload_6121,
      evh_7315092382398562_reload,
    }
  }
}

Vue.component("FieldsetAddress", FieldsetAddress);

</script>