"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UcCompanyBankAccounts = exports.UcCompanyBankAccountFieldset = exports.UcCompanyBankAccountCard = exports.CompanyBankAccount = void 0;
const CompanyBankAccount_yaml_component_1 = require("./CompanyBankAccount.yaml?component");
exports.CompanyBankAccount = CompanyBankAccount_yaml_component_1.default;
const UcCompanyBankAccountCard_yaml_component_1 = require("./UcCompanyBankAccountCard.yaml?component");
exports.UcCompanyBankAccountCard = UcCompanyBankAccountCard_yaml_component_1.default;
const UcCompanyBankAccountFieldset_yaml_component_1 = require("./UcCompanyBankAccountFieldset.yaml?component");
exports.UcCompanyBankAccountFieldset = UcCompanyBankAccountFieldset_yaml_component_1.default;
const UcCompanyBankAccounts_yaml_component_1 = require("./UcCompanyBankAccounts.yaml?component");
exports.UcCompanyBankAccounts = UcCompanyBankAccounts_yaml_component_1.default;
