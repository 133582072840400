"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcCompanyMiniCard = class UcCompanyMiniCard extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['messages', 'ux'];
    }
    beforeCreate() {
    }
    onItem(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("item");
    }
    onRole(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("role");
    }
    onBadge(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("badge");
    }
    onNo_icon(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noIcon");
    }
    onNo_link(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("noLink");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            messages: {
                title: {
                    own: "P(\"Company\", \"Own company\")",
                    partner: "P(\"Company\", \"Partner\")",
                },
            },
            ux: null,
        });
    }
    get effectiveRole() {
        if (this.loaded) {
            return this.role || this.item.role;
        }
        return null;
    }
    getActions() {
        return {};
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcCompanyMiniCard.prototype, "item", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('item'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcCompanyMiniCard.prototype, "onItem", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], UcCompanyMiniCard.prototype, "role", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('role'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcCompanyMiniCard.prototype, "onRole", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcCompanyMiniCard.prototype, "badge", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('badge'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcCompanyMiniCard.prototype, "onBadge", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcCompanyMiniCard.prototype, "noIcon", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noIcon'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcCompanyMiniCard.prototype, "onNo_icon", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Boolean,
    }),
    __metadata("design:type", Boolean)
], UcCompanyMiniCard.prototype, "noLink", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('noLink'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcCompanyMiniCard.prototype, "onNo_link", null);
UcCompanyMiniCard = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcCompanyMiniCard);
exports.default = UcCompanyMiniCard;
vue_property_decorator_1.Vue.component("UcCompanyMiniCard", UcCompanyMiniCard);
