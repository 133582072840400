"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let UcResourceStatusCard = class UcResourceStatusCard extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['ux'];
    }
    beforeCreate() {
    }
    onResource(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("resource");
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            ux: null,
        });
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_3343864469950044_emit_1686_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_3343864469950044_request_1684_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.resource.links.self,
                method: "patch",
                data: {
                    etag: this.resource.etag,
                    inactive: !this.resource.inactive,
                },
            };
        });
    }
    evh_3343864469950044_clickStopPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_3343864469950044_clickStopPrevent.executeFromDOM(this, event, scope);
        });
    }
    act_1889980785424776_emit_1690_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "reload",
            };
        });
    }
    act_1889980785424776_request_1688_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                url: this.resource.links.self,
                method: "patch",
                data: {
                    etag: this.resource.etag,
                    inactive: !this.resource.inactive,
                },
            };
        });
    }
    evh_1889980785424776_clickStopPrevent(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1889980785424776_clickStopPrevent.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_1682 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_1681 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_1682,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_1681],
        });
        const act_3343864469950044_emit_1686 = new core_1.actions.EmitAction({
            actionArgs: this.act_3343864469950044_emit_1686_getActionArgs,
            events: [],
        });
        const evh_3343864469950044_success_1685 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_emit_1686,
            event: "success",
            displayName: "emit",
        });
        const act_3343864469950044_request_1684 = new core_1.actions.RequestAction({
            actionArgs: this.act_3343864469950044_request_1684_getActionArgs,
            displayName: "toggleState",
            events: [evh_3343864469950044_success_1685],
        });
        const evh_3343864469950044_clickStopPrevent_1683 = new core_1.actions.EventHandlerImpl({
            action: act_3343864469950044_request_1684,
            event: "click.stop.prevent",
            displayName: "toggleState",
        });
        const evh_3343864469950044_clickStopPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_3343864469950044_clickStopPrevent_1683],
        });
        const act_1889980785424776_emit_1690 = new core_1.actions.EmitAction({
            actionArgs: this.act_1889980785424776_emit_1690_getActionArgs,
            events: [],
        });
        const evh_1889980785424776_success_1689 = new core_1.actions.EventHandlerImpl({
            action: act_1889980785424776_emit_1690,
            event: "success",
            displayName: "emit",
        });
        const act_1889980785424776_request_1688 = new core_1.actions.RequestAction({
            actionArgs: this.act_1889980785424776_request_1688_getActionArgs,
            displayName: "toggleState",
            events: [evh_1889980785424776_success_1689],
        });
        const evh_1889980785424776_clickStopPrevent_1687 = new core_1.actions.EventHandlerImpl({
            action: act_1889980785424776_request_1688,
            event: "click.stop.prevent",
            displayName: "toggleState",
        });
        const evh_1889980785424776_clickStopPrevent = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1889980785424776_clickStopPrevent_1687],
        });
        return {
            act_7315092382398562_reloadComponentData_1682,
            evh_7315092382398562_reload_1681,
            evh_7315092382398562_reload,
            act_3343864469950044_emit_1686,
            evh_3343864469950044_success_1685,
            act_3343864469950044_request_1684,
            evh_3343864469950044_clickStopPrevent_1683,
            evh_3343864469950044_clickStopPrevent,
            act_1889980785424776_emit_1690,
            evh_1889980785424776_success_1689,
            act_1889980785424776_request_1688,
            evh_1889980785424776_clickStopPrevent_1687,
            evh_1889980785424776_clickStopPrevent,
            toggleState: act_1889980785424776_request_1688,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], UcResourceStatusCard.prototype, "resource", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('resource'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], UcResourceStatusCard.prototype, "onResource", null);
UcResourceStatusCard = __decorate([
    (0, vue_property_decorator_1.Component)()
], UcResourceStatusCard);
exports.default = UcResourceStatusCard;
vue_property_decorator_1.Vue.component("UcResourceStatusCard", UcResourceStatusCard);
