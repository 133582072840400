<template>
<ItpUsercontrol
  :loading="loading"
  :reloading="reloading"
  :loaded="loaded"
  :error="error"
  @reload="evh_7315092382398562_reload($event, {})"
>
  <DIV
    :class="{ 'bg-gray-200': isMouseOver }"
    v-if="!isEditing"
    @dblclick="evh_2248226175642056_dblclick($event, {})"
    @mouseover="evh_2248226175642056_mouseover($event, {})"
    @mouseleave="evh_2248226175642056_mouseleave($event, {})"
  >
    <ItpText
      :text="textValue"
      pre
    >
    </ItpText>
  </DIV>
  <DIV
    v-if="isEditing"
  >
    <ItpFormTextarea
      name="notes"
      v-model="textValue"
      pre
    >
    </ItpFormTextarea>
    <ItpBox
      alignItems="end"
      class="mt-1 mb-2"
    >
      <ItpButton
        :text="'Save'|gettext"
        variant="primary"
        :spinning="saving"
        size="sm"
        class="ml-auto mr-1"
        @click="evh_1468474219929533_click($event, {})"
      >
      </ItpButton>
      <ItpButton
        :text="'Cancel'|gettext"
        size="sm"
        @click="evh_3601807637515735_click($event, {})"
      >
      </ItpButton>
    </ItpBox>
  </DIV>
</ItpUsercontrol>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class MultilineNotes extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "usercontrol",
  };

  beforeCreate() {
  }

  beginEdit(...args: any[]) {
    if (!this.isEditing) {
      this.isEditing = true;
      this.valueSnapshot = this.textValue;
    }
    this.isMouseOver = false;
  }

  cancelEdit(...args: any[]) {
    if (this.isEditing) {
      this.isEditing = false;
      this.textValue = this.valueSnapshot;
    }
    this.isMouseOver = false;
  }

  save(...args: any[]) {
    try {
      this.saving = true;
      this.$emit('save', {
        value: {
          text: this.textValue
        }
      });
      this.isEditing = false;
    }
    finally {
      this.saving = false;
    }
  }

  @Prop({
    required: true,
  })
  value!: any;

  @Watch('value')
  onValue(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("value")
  }

  @Prop({
    type: Boolean,
  })
  editable!: boolean;

  @Watch('editable')
  onEditable(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("editable")
  }

  isEditing!: any;
  isMouseOver!: any;
  selected!: any;
  clicked!: any;
  textValue!: any;

  async $$load_textValue() {
    return this.value && this.value.text ? this.value.text : this.value
  }

  saving!: any;
  valueSnapshot!: any;
  ux!: any;
  dataMembers = ['isEditing', 'isMouseOver', 'selected', 'clicked', 'textValue', 'saving', 'valueSnapshot', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        isEditing: false,
        isMouseOver: false,
        selected: false,
        clicked: false,
        textValue: null,
        saving: false,
        valueSnapshot: null,
        ux: null,
      },
    }
  }

  async evh_7315092382398562_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_script_2846_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_2248226175642056_script_2846, alias=undefined
    this.beginEdit()
  }

  async act_2248226175642056_script_2846_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_2248226175642056_script_2846, alias=undefined
    return {
      value: () => this.act_2248226175642056_script_2846_getActionArgs_value($event),
    }
  }

  async evh_2248226175642056_dblclick(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_dblclick.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2848_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2848, alias=undefined
    return {
      path: "isMouseOver",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_mouseover(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_mouseover.executeFromDOM(this, event, scope);
  }

  async act_2248226175642056_setData_2850_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_2248226175642056_setData_2850, alias=undefined
    return {
      path: "isMouseOver",
      value: false,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_2248226175642056_mouseleave(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_mouseleave.executeFromDOM(this, event, scope);
  }

  async act_1468474219929533_script_2852_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_1468474219929533_script_2852, alias=undefined
    this.save()
  }

  async act_1468474219929533_script_2852_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_1468474219929533_script_2852, alias=undefined
    return {
      value: () => this.act_1468474219929533_script_2852_getActionArgs_value($event),
    }
  }

  async evh_1468474219929533_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1468474219929533_click.executeFromDOM(this, event, scope);
  }

  async act_3601807637515735_script_2854_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3601807637515735_script_2854, alias=undefined
    this.cancelEdit()
  }

  async act_3601807637515735_script_2854_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3601807637515735_script_2854, alias=undefined
    return {
      value: () => this.act_3601807637515735_script_2854_getActionArgs_value($event),
    }
  }

  async evh_3601807637515735_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3601807637515735_click.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_reloadComponentData_2844: actions.ReloadComponentDataAction;
    evh_7315092382398562_reload_2843: actions.EventHandlerImpl;
    evh_7315092382398562_reload: actions.EventHandlerGroup;
    act_2248226175642056_script_2846: actions.ScriptAction;
    evh_2248226175642056_dblclick_2845: actions.EventHandlerImpl;
    evh_2248226175642056_dblclick: actions.EventHandlerGroup;
    act_2248226175642056_setData_2848: actions.SetDataAction;
    evh_2248226175642056_mouseover_2847: actions.EventHandlerImpl;
    evh_2248226175642056_mouseover: actions.EventHandlerGroup;
    act_2248226175642056_setData_2850: actions.SetDataAction;
    evh_2248226175642056_mouseleave_2849: actions.EventHandlerImpl;
    evh_2248226175642056_mouseleave: actions.EventHandlerGroup;
    act_1468474219929533_script_2852: actions.ScriptAction;
    evh_1468474219929533_click_2851: actions.EventHandlerImpl;
    evh_1468474219929533_click: actions.EventHandlerGroup;
    act_3601807637515735_script_2854: actions.ScriptAction;
    evh_3601807637515735_click_2853: actions.EventHandlerImpl;
    evh_3601807637515735_click: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_reloadComponentData_2844 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7315092382398562_reload_2843 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_reloadComponentData_2844,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7315092382398562_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_reload_2843],
      }
    );
    const act_2248226175642056_script_2846 = new actions.ScriptAction(
      {
        actionArgs: this.act_2248226175642056_script_2846_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_dblclick_2845 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_script_2846,
        event: "dblclick",
        displayName: "script",
      }
    );
    const evh_2248226175642056_dblclick = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_dblclick_2845],
      }
    );
    const act_2248226175642056_setData_2848 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2848_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_mouseover_2847 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2848,
        event: "mouseover",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_mouseover = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_mouseover_2847],
      }
    );
    const act_2248226175642056_setData_2850 = new actions.SetDataAction(
      {
        actionArgs: this.act_2248226175642056_setData_2850_getActionArgs,
        events: [],
      }
    );
    const evh_2248226175642056_mouseleave_2849 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_setData_2850,
        event: "mouseleave",
        displayName: "setData",
      }
    );
    const evh_2248226175642056_mouseleave = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_mouseleave_2849],
      }
    );
    const act_1468474219929533_script_2852 = new actions.ScriptAction(
      {
        actionArgs: this.act_1468474219929533_script_2852_getActionArgs,
        events: [],
      }
    );
    const evh_1468474219929533_click_2851 = new actions.EventHandlerImpl(
      {
        action: act_1468474219929533_script_2852,
        event: "click",
        displayName: "script",
      }
    );
    const evh_1468474219929533_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_1468474219929533_click_2851],
      }
    );
    const act_3601807637515735_script_2854 = new actions.ScriptAction(
      {
        actionArgs: this.act_3601807637515735_script_2854_getActionArgs,
        events: [],
      }
    );
    const evh_3601807637515735_click_2853 = new actions.EventHandlerImpl(
      {
        action: act_3601807637515735_script_2854,
        event: "click",
        displayName: "script",
      }
    );
    const evh_3601807637515735_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3601807637515735_click_2853],
      }
    );
    return {
      act_7315092382398562_reloadComponentData_2844,
      evh_7315092382398562_reload_2843,
      evh_7315092382398562_reload,
      act_2248226175642056_script_2846,
      evh_2248226175642056_dblclick_2845,
      evh_2248226175642056_dblclick,
      act_2248226175642056_setData_2848,
      evh_2248226175642056_mouseover_2847,
      evh_2248226175642056_mouseover,
      act_2248226175642056_setData_2850,
      evh_2248226175642056_mouseleave_2849,
      evh_2248226175642056_mouseleave,
      act_1468474219929533_script_2852,
      evh_1468474219929533_click_2851,
      evh_1468474219929533_click,
      act_3601807637515735_script_2854,
      evh_3601807637515735_click_2853,
      evh_3601807637515735_click,
    }
  }
}

Vue.component("MultilineNotes", MultilineNotes);

</script>