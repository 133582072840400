<template>
<b-nav-item-dropdown>
  <template
    slot="button-content"
  >
    {{ 'Reports'|pgettext('MainMenu') }}
  </template>
  <ItpNavItem
    v-if="dashboards && dashboards.error && dashboards.error.message"
  >
    <ItpText
      wrap
      :title="dashboards.error.message"
      :text="dashboards.error.message"
    >
    </ItpText>
  </ItpNavItem>
  <ItpNavItem
    href="#"
    :key="item.id"
    :text="item.dashboard_title"
    :spinning="item.loading"
    dropdown-item
    v-if="_.isArray(dashboards)"
    v-for="item in dashboards"
    @click.prevent="evh_8358196963355853_clickPrevent($event, {item})"
  >
  </ItpNavItem>
</b-nav-item-dropdown>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class Charts extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  openDashboard(...args: any[]) {
    const item = args[0]
    item.loading = true
    this.$fn
      .fetch('get_superset_dashboard_token', { parameters: { id: item.id } })
      .then(resp => window.open(resp.url, "_blank").focus())
      .finally(() => {
        item.loading = false
      })
  }

  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  dashboards!: any;

  async $$load_dashboards() {
    return new Promise((resolve, reject) => {
      this.$fn.fetch('list_superset_dashboards')
        .then(p => resolve(_.map(p.result, pp => ({ ...pp, loading: false }))))
        .catch(err => {
          resolve({
            error: { message: _.get(err, 'response.data.message', err.toJSON().message) }
          })
        })
    })
  }

  ux!: any;
  dataMembers = ['dashboards', 'ux'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        dashboards: null,
        ux: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: this.$fn.pgettext("Charts|Title", "Reports"),
        icon: "akar-icons:statistic-up",
      }
    }
    return null;
  }

  async act_8358196963355853_script_1562_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_8358196963355853_script_1562, alias=undefined
    this.openDashboard($event.scope.item)
  }

  async act_8358196963355853_script_1562_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_8358196963355853_script_1562, alias=undefined
    return {
      value: () => this.act_8358196963355853_script_1562_getActionArgs_value($event),
    }
  }

  async evh_8358196963355853_clickPrevent(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8358196963355853_clickPrevent.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_8358196963355853_script_1562: actions.ScriptAction;
    evh_8358196963355853_clickPrevent_1561: actions.EventHandlerImpl;
    evh_8358196963355853_clickPrevent: actions.EventHandlerGroup;
  }

  getActions() {
    const act_8358196963355853_script_1562 = new actions.ScriptAction(
      {
        actionArgs: this.act_8358196963355853_script_1562_getActionArgs,
        events: [],
      }
    );
    const evh_8358196963355853_clickPrevent_1561 = new actions.EventHandlerImpl(
      {
        action: act_8358196963355853_script_1562,
        event: "click.prevent",
        displayName: "script",
      }
    );
    const evh_8358196963355853_clickPrevent = new actions.EventHandlerGroup(
      {
        handlers: [evh_8358196963355853_clickPrevent_1561],
      }
    );
    return {
      act_8358196963355853_script_1562,
      evh_8358196963355853_clickPrevent_1561,
      evh_8358196963355853_clickPrevent,
    }
  }
}

Vue.component("Charts", Charts);

</script>