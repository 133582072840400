var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ItpUsercontrol",
    {
      attrs: {
        loading: _vm.loading,
        reloading: _vm.reloading,
        loaded: _vm.loaded,
        error: _vm.error
      },
      on: {
        reload: function($event) {
          return _vm.evh_7315092382398562_reload($event, {})
        }
      }
    },
    [
      !!_vm.noLegend
        ? _c("legend", [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("pgettext")("Tenant", "Contract")) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ItpFormGroup",
        {
          attrs: {
            name: "client",
            label: _vm._f("pgettext")("Client", "Contract"),
            labelColsMd: "4",
            contentColsMd: "8",
            labelAlign: _vm.labelAlign,
            required: ""
          }
        },
        [
          _c("ItpFormSelect2", {
            attrs: {
              name: "client",
              "bind-object": "",
              dataSource: _vm.b_1040167445267876_dataSource,
              textField: "name",
              required: "",
              optionTemplate:
                "<UcSuggestCompanyOption :option=option></UcSuggestCompanyOption>",
              "use-option-template-for-selected-option": ""
            },
            on: {
              optionSelected: function($event) {
                return _vm.evh_1040167445267876_optionSelected($event, {})
              }
            },
            model: {
              value: _vm.client,
              callback: function($$v) {
                _vm.client = $$v
              },
              expression: "client"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "head_office",
                label: _vm._f("pgettext")("Head office", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "head_office" },
                model: {
                  value: _vm.contract.counterparty.head_office,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.counterparty, "head_office", $$v)
                  },
                  expression: "contract.counterparty.head_office"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "branch_office",
                label: _vm._f("pgettext")("Branch office", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "branch_office" },
                model: {
                  value: _vm.contract.counterparty.branch_office,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.counterparty, "branch_office", $$v)
                  },
                  expression: "contract.counterparty.branch_office"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "statistical_code",
                label: _vm._f("pgettext")("Statistical code", "Company"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "statistical_code" },
                model: {
                  value: _vm.contract.counterparty.statistical_code,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.counterparty, "statistical_code", $$v)
                  },
                  expression: "contract.counterparty.statistical_code"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "registration_number",
                label: _vm._f("pgettext")("Registration number", "Company"),
                labelColsMd: "4",
                contentColsMd: "4",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "registration_number" },
                model: {
                  value: _vm.contract.counterparty.registration_number,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.contract.counterparty,
                      "registration_number",
                      $$v
                    )
                  },
                  expression: "contract.counterparty.registration_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "account_number",
                label: _vm._f("pgettext")("Account number", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormInput", {
                attrs: { name: "account_number" },
                model: {
                  value: _vm.contract.counterparty.account_number,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.counterparty, "account_number", $$v)
                  },
                  expression: "contract.counterparty.account_number"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contract.counterparty
        ? _c(
            "ItpFormGroup",
            {
              attrs: {
                name: "representative",
                label: _vm._f("pgettext")("Representative", "Company"),
                labelColsMd: "4",
                contentColsMd: "8",
                labelAlign: _vm.labelAlign
              }
            },
            [
              _c("ItpFormTextarea", {
                attrs: { name: "representative" },
                model: {
                  value: _vm.contract.counterparty.representative,
                  callback: function($$v) {
                    _vm.$set(_vm.contract.counterparty, "representative", $$v)
                  },
                  expression: "contract.counterparty.representative"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }