"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "fixedAsset",
    path: "/fixed-assets/:id",
    component: "FixedAsset",
    params: [{
            name: "id",
            prop: "fixedAssetId",
        },
    ]
});
let FixedAsset = class FixedAsset extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['item', 'formId', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];
    }
    beforeCreate() {
    }
    onFixed_asset_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("fixedAssetId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_fixed_asset', { asResource: true, parameters: { id: this.fixedAssetId } });
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            item: null,
            formId: null,
            ux: {
                initialLoadSpinner: true,
            },
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2285343489561581_to: {
                name: "app.fixedAssets",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.snapshot('name'),
                    this.$fn.gettext("Fixed Asset"),
                ],
                icon: this.$config.fixedAsset.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_1418_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2095089031469157_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_2679161326024595_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_2789477278097085_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2789477278097085_reload.executeFromDOM(this, event, scope);
        });
    }
    evh_8856043737157701_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8856043737157701_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_1418 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_1418_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_1417 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_1418,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_1417],
        });
        const act_2095089031469157_reloadComponentData_1420 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_2095089031469157_clickPreventStop_1419 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_1420,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_2095089031469157_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_clickPreventStop_1419],
        });
        const act_2679161326024595_reloadComponentData_1422 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2679161326024595_reload_1421 = new core_1.actions.EventHandlerImpl({
            action: act_2679161326024595_reloadComponentData_1422,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2679161326024595_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2679161326024595_reload_1421],
        });
        const act_2789477278097085_reloadComponentData_1424 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2789477278097085_reload_1423 = new core_1.actions.EventHandlerImpl({
            action: act_2789477278097085_reloadComponentData_1424,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2789477278097085_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2789477278097085_reload_1423],
        });
        const act_8856043737157701_reloadComponentData_1426 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_8856043737157701_reload_1425 = new core_1.actions.EventHandlerImpl({
            action: act_8856043737157701_reloadComponentData_1426,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_8856043737157701_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8856043737157701_reload_1425],
        });
        return {
            act_7315092382398562_closeModal_1418,
            evh_7315092382398562_close_1417,
            evh_7315092382398562_close,
            act_2095089031469157_reloadComponentData_1420,
            evh_2095089031469157_clickPreventStop_1419,
            evh_2095089031469157_clickPreventStop,
            act_2679161326024595_reloadComponentData_1422,
            evh_2679161326024595_reload_1421,
            evh_2679161326024595_reload,
            act_2789477278097085_reloadComponentData_1424,
            evh_2789477278097085_reload_1423,
            evh_2789477278097085_reload,
            act_8856043737157701_reloadComponentData_1426,
            evh_8856043737157701_reload_1425,
            evh_8856043737157701_reload,
            reload: act_2095089031469157_reloadComponentData_1420,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], FixedAsset.prototype, "fixedAssetId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('fixedAssetId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FixedAsset.prototype, "onFixed_asset_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], FixedAsset.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FixedAsset.prototype, "onSize", null);
FixedAsset = __decorate([
    (0, vue_property_decorator_1.Component)()
], FixedAsset);
exports.default = FixedAsset;
vue_property_decorator_1.Vue.component("FixedAsset", FixedAsset);
