"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let CreateContract = class CreateContract extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['contract_type', 'contract', 'contractTypes', 'formName', 'formId', 'labelAlign', 'currentTab', 'categories', 'ux', 'b_7315092382398562_modalBindings'];
    }
    beforeCreate() {
        this.$$cache_contract_type = new core_1.ComponentValueCache(this, "contract_type", "CreateContract.contractType");
    }
    mapDefaultContract(...args) {
        const data = args[0];
        return _.extend(data, { counterparty: {}, rental: {} });
    }
    onRental_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("rentalId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_contract() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_contract', {
                parameters: {
                    id: 'defaults',
                    type: this.$data.contract_type,
                    rental: this.rentalId
                }
            });
        });
    }
    $$load_contractTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.getEnumValuesFromSchema('ContractTypes');
        });
    }
    $$load_formId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.randomElementId();
        });
    }
    $$load_categories() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_contract_category');
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            contract_type: "rental",
            contract: null,
            contractTypes: null,
            formName: "contract",
            formId: null,
            labelAlign: "left",
            currentTab: 0,
            categories: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "lg",
            },
        });
    }
    get companyTranslation() {
        if (this.loaded) {
            return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                this.$fn.pgettext('Contract', 'Lessor') :
                this.$fn.pgettext('Contract', 'Company');
        }
        return null;
    }
    get counterpartyTranslation() {
        if (this.loaded) {
            return ['rental', 'rental_agreement', 'parking'].indexOf(this.contract.contract_type) > -1 ?
                this.$fn.pgettext('Contract', 'Tenant') :
                this.$fn.pgettext('Contract', 'Counterparty');
        }
        return null;
    }
    get document() {
        if (this.loaded) {
            return {
                title: this.$fn.gettext("Create Contract"),
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2034_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2248226175642056_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
        });
    }
    act_7789867021953041_setData_2040_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "contract",
                value: this.mapDefaultContract($event.data.response.data),
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_7789867021953041_request_2038_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                operation: "read_contract",
                parameters: {
                    id: "defaults",
                    type: $event.data.value,
                },
            };
        });
    }
    evh_7789867021953041_optionSelected(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7789867021953041_optionSelected.executeFromDOM(this, event, scope);
        });
    }
    act_2140198529698299_setData_2042_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentTab",
                value: this.currentTab - 1,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2140198529698299_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2140198529698299_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_8790374038998522_setData_2044_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "currentTab",
                value: this.currentTab + 1,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    evh_8790374038998522_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_8790374038998522_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    act_309864017738714_reloadSlickgrid_2048_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                grid: "contracts",
            };
        });
    }
    act_309864017738714_closeComponent_2050_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                result: {
                    close: true,
                },
            };
        });
    }
    act_309864017738714_navigate_2052_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                location: {
                    name: "app.contract",
                    params: {
                        id: $event.data.id,
                    },
                },
            };
        });
    }
    act_309864017738714_crud_2046_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                objectType: "contract",
                op: "upsert",
                data: this.contract,
            };
        });
    }
    evh_309864017738714_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_309864017738714_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_1589624209624921_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_1589624209624921_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2034 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2034_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2033 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2034,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2033],
        });
        const act_2248226175642056_closeComponent_2036 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_2248226175642056_close_2035 = new core_1.actions.EventHandlerImpl({
            action: act_2248226175642056_closeComponent_2036,
            event: "close",
            displayName: "closeComponent",
        });
        const evh_2248226175642056_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2248226175642056_close_2035],
        });
        const act_7789867021953041_setData_2040 = new core_1.actions.SetDataAction({
            actionArgs: this.act_7789867021953041_setData_2040_getActionArgs,
            events: [],
        });
        const evh_7789867021953041_success_2039 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_setData_2040,
            event: "success",
            displayName: "setData",
        });
        const act_7789867021953041_request_2038 = new core_1.actions.RequestAction({
            actionArgs: this.act_7789867021953041_request_2038_getActionArgs,
            events: [evh_7789867021953041_success_2039],
        });
        const evh_7789867021953041_optionSelected_2037 = new core_1.actions.EventHandlerImpl({
            action: act_7789867021953041_request_2038,
            event: "optionSelected",
            displayName: "request",
        });
        const evh_7789867021953041_optionSelected = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7789867021953041_optionSelected_2037],
        });
        const act_2140198529698299_setData_2042 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2140198529698299_setData_2042_getActionArgs,
            events: [],
        });
        const evh_2140198529698299_clickPreventStop_2041 = new core_1.actions.EventHandlerImpl({
            action: act_2140198529698299_setData_2042,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_2140198529698299_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2140198529698299_clickPreventStop_2041],
        });
        const act_8790374038998522_setData_2044 = new core_1.actions.SetDataAction({
            actionArgs: this.act_8790374038998522_setData_2044_getActionArgs,
            events: [],
        });
        const evh_8790374038998522_clickPreventStop_2043 = new core_1.actions.EventHandlerImpl({
            action: act_8790374038998522_setData_2044,
            event: "click.prevent.stop",
            displayName: "setData",
        });
        const evh_8790374038998522_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_8790374038998522_clickPreventStop_2043],
        });
        const act_309864017738714_reloadSlickgrid_2048 = new core_1.actions.ReloadSlickgridAction({
            actionArgs: this.act_309864017738714_reloadSlickgrid_2048_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2047 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_reloadSlickgrid_2048,
            event: "success",
            displayName: "reloadSlickgrid",
        });
        const act_309864017738714_closeComponent_2050 = new core_1.actions.CloseComponentAction({
            actionArgs: this.act_309864017738714_closeComponent_2050_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2049 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_closeComponent_2050,
            event: "success",
            displayName: "closeComponent",
        });
        const act_309864017738714_navigate_2052 = new core_1.actions.NavigateAction({
            actionArgs: this.act_309864017738714_navigate_2052_getActionArgs,
            events: [],
        });
        const evh_309864017738714_success_2051 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_navigate_2052,
            event: "success",
            displayName: "navigate",
        });
        const act_309864017738714_crud_2046 = new core_1.actions.CRUDAction({
            actionArgs: this.act_309864017738714_crud_2046_getActionArgs,
            displayName: "save",
            events: [evh_309864017738714_success_2047, evh_309864017738714_success_2049, evh_309864017738714_success_2051],
        });
        const evh_309864017738714_clickPreventStop_2045 = new core_1.actions.EventHandlerImpl({
            action: act_309864017738714_crud_2046,
            event: "click.prevent.stop",
            displayName: "save",
        });
        const evh_309864017738714_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_309864017738714_clickPreventStop_2045],
        });
        const act_1589624209624921_closeModal_2054 = new core_1.actions.CloseModalAction({
            events: [],
        });
        const evh_1589624209624921_clickPreventStop_2053 = new core_1.actions.EventHandlerImpl({
            action: act_1589624209624921_closeModal_2054,
            event: "click.prevent.stop",
            displayName: "closeModal",
        });
        const evh_1589624209624921_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_1589624209624921_clickPreventStop_2053],
        });
        return {
            act_7315092382398562_closeModal_2034,
            evh_7315092382398562_close_2033,
            evh_7315092382398562_close,
            act_2248226175642056_closeComponent_2036,
            evh_2248226175642056_close_2035,
            evh_2248226175642056_close,
            act_7789867021953041_setData_2040,
            evh_7789867021953041_success_2039,
            act_7789867021953041_request_2038,
            evh_7789867021953041_optionSelected_2037,
            evh_7789867021953041_optionSelected,
            act_2140198529698299_setData_2042,
            evh_2140198529698299_clickPreventStop_2041,
            evh_2140198529698299_clickPreventStop,
            act_8790374038998522_setData_2044,
            evh_8790374038998522_clickPreventStop_2043,
            evh_8790374038998522_clickPreventStop,
            act_309864017738714_reloadSlickgrid_2048,
            evh_309864017738714_success_2047,
            act_309864017738714_closeComponent_2050,
            evh_309864017738714_success_2049,
            act_309864017738714_navigate_2052,
            evh_309864017738714_success_2051,
            act_309864017738714_crud_2046,
            evh_309864017738714_clickPreventStop_2045,
            evh_309864017738714_clickPreventStop,
            act_1589624209624921_closeModal_2054,
            evh_1589624209624921_clickPreventStop_2053,
            evh_1589624209624921_clickPreventStop,
            save: act_309864017738714_crud_2046,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], CreateContract.prototype, "rentalId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('rentalId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContract.prototype, "onRental_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "lg",
    }),
    __metadata("design:type", String)
], CreateContract.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], CreateContract.prototype, "onSize", null);
CreateContract = __decorate([
    (0, vue_property_decorator_1.Component)()
], CreateContract);
exports.default = CreateContract;
vue_property_decorator_1.Vue.component("CreateContract", CreateContract);
