"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
let FieldsetPaymentGeneral = class FieldsetPaymentGeneral extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "usercontrol",
        };
        this.dataMembers = ['transactionTitles', 'paymentMethods', 'employees', 'ux', 'b_8697745105058001_dataSource'];
    }
    beforeCreate() {
    }
    isFieldEnabled(...args) {
        const name = args[0];
        return !this.config || !this.config.fields || this.config.fields[name] != false;
    }
    isFieldReadonly(...args) {
        const name = args[0];
        return this.isReadOnly || (this.config && this.config.fields && this.config.fields[name] == 'ro');
    }
    loadEmployees(...args) {
        return this.$fn
            .fetch('list_company_employees', { parameters: { company_id: this.payment.account.owner_id } })
            .then(items => items.sort((a, b) => a.name.localeCompare(b.name)));
    }
    onPayment(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("payment");
    }
    onLabel_align(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("labelAlign");
    }
    onConfig(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("config");
    }
    onTransaction_types(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("transactionTypes");
    }
    $$load_transactionTitles() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('get_tags', { parameters: { tag_type: 'payment' } })
                .then(data => _.map(data, p => p.name).sort((a, b) => a.localeCompare(b)));
        });
    }
    $$load_paymentMethods() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('list_payment_method')
                .then(data => _
                .chain(data)
                .filter(p => this.payment.account.account_type !== 'cash_book' ? !p.cash : p.cash)
                .map(p => ({
                value: p.id,
                text: this.$fn.pgettext('PaymentMethod', p.name)
            }))
                .value());
        });
    }
    $$load_employees() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.loadEmployees();
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            transactionTitles: null,
            paymentMethods: null,
            employees: null,
            ux: null,
            b_8697745105058001_dataSource: {
                name: "suggest_company",
                parameters: {
                    role: "partner",
                },
            },
        });
    }
    get isReadOnly() {
        if (this.loaded) {
            return this.payment.isReadonly;
        }
        return null;
    }
    get cashBookPayment() {
        if (this.loaded) {
            return this.payment.account && this.payment.account.account_type == 'cash_book';
        }
        return null;
    }
    evh_7315092382398562_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_reload.executeFromDOM(this, event, scope);
        });
    }
    act_4615685607825208_setData_5702_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "payment.currency",
                value: $event.data.currency,
                resolve: false,
                noTouchLastLoaded: false,
                noTakeSnapshot: false,
            };
        });
    }
    act_4615685607825208_script_5704_getActionArgs_value($event) {
        return __awaiter(this, void 0, void 0, function* () {
            this.loadEmployees().then(items => this.setData('employees', items));
        });
    }
    act_4615685607825208_script_5704_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                value: () => this.act_4615685607825208_script_5704_getActionArgs_value($event),
            };
        });
    }
    act_4615685607825208_emit_5706_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                event: "accountChanged",
                value: $event.data,
            };
        });
    }
    evh_4615685607825208_input(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_4615685607825208_input.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_reloadComponentData_5700 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7315092382398562_reload_5699 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_reloadComponentData_5700,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7315092382398562_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_reload_5699],
        });
        const act_4615685607825208_setData_5702 = new core_1.actions.SetDataAction({
            actionArgs: this.act_4615685607825208_setData_5702_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_input_5701 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_setData_5702,
            event: "input",
            displayName: "setData",
        });
        const act_4615685607825208_script_5704 = new core_1.actions.ScriptAction({
            actionArgs: this.act_4615685607825208_script_5704_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_input_5703 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_script_5704,
            event: "input",
            displayName: "script",
        });
        const act_4615685607825208_emit_5706 = new core_1.actions.EmitAction({
            actionArgs: this.act_4615685607825208_emit_5706_getActionArgs,
            events: [],
        });
        const evh_4615685607825208_input_5705 = new core_1.actions.EventHandlerImpl({
            action: act_4615685607825208_emit_5706,
            event: "input",
            displayName: "emit",
        });
        const evh_4615685607825208_input = new core_1.actions.EventHandlerGroup({
            handlers: [evh_4615685607825208_input_5701, evh_4615685607825208_input_5703, evh_4615685607825208_input_5705],
        });
        return {
            act_7315092382398562_reloadComponentData_5700,
            evh_7315092382398562_reload_5699,
            evh_7315092382398562_reload,
            act_4615685607825208_setData_5702,
            evh_4615685607825208_input_5701,
            act_4615685607825208_script_5704,
            evh_4615685607825208_input_5703,
            act_4615685607825208_emit_5706,
            evh_4615685607825208_input_5705,
            evh_4615685607825208_input,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetPaymentGeneral.prototype, "payment", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('payment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onPayment", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "right",
    }),
    __metadata("design:type", String)
], FieldsetPaymentGeneral.prototype, "labelAlign", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('labelAlign'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onLabel_align", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        required: true,
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetPaymentGeneral.prototype, "config", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('config'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onConfig", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: Object,
    }),
    __metadata("design:type", Object)
], FieldsetPaymentGeneral.prototype, "transactionTypes", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('transactionTypes'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], FieldsetPaymentGeneral.prototype, "onTransaction_types", null);
FieldsetPaymentGeneral = __decorate([
    (0, vue_property_decorator_1.Component)()
], FieldsetPaymentGeneral);
exports.default = FieldsetPaymentGeneral;
vue_property_decorator_1.Vue.component("FieldsetPaymentGeneral", FieldsetPaymentGeneral);
