<template>
<ItpPage
  no-header
  contentClass="p-0"
  no-primary-button
  no-close-button
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="Company"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <MaterialTabs
    :closeButton="modal"
    :tabManager="tabManager"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
    <ItpBox
      alignItems="center"
      class="mr-3"
      slot="tabs-start"
    >
      <b-breadcrumb
        class="m-0 bg-white"
      >
        <b-breadcrumb-item
          :text="messages.parent[item.role || role]"
          :to="b_4615685607825208_to"
        >
        </b-breadcrumb-item>
        <b-breadcrumb-item
          active
        >
          <strong>
            {{ item.snapshot('name') }}
          </strong>
        </b-breadcrumb-item>
      </b-breadcrumb>
      <BtnDeleteResource
        :resource="item"
        no-text
        :dialogHeader="messages.delete[item.role || role]"
        class="mr-2"
        @success="evh_8320016629450276_success($event, {})"
      >
      </BtnDeleteResource>
      <ItpButton
        variant="default"
        icon="fa-sync-alt"
        :text="'Reload'|gettext"
        :spinning="actions.reload.isRunning"
        class="px-3"
        @click.prevent.stop="evh_6705964765214521_clickPreventStop($event, {})"
      >
      </ItpButton>
      <BtnSyncResource
        :resource="item"
        size="sm"
        class="ml-auto mr-2"
        v-if="loaded"
        @success="evh_6806515264385235_success($event, {})"
      >
      </BtnSyncResource>
    </ItpBox>
    <b-tab
      :title="'General'|gettext"
      class="container float-left"
    >
      <ItpRow>
        <ItpCol
          w="6"
        >
          <UcCompanyGeneral
            :item="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_5477420070495986_reload($event, {})"
          >
          </UcCompanyGeneral>
          <UcContactList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_6337614691287098_reload($event, {})"
          >
          </UcContactList>
          <UcEmployeeList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_2406129194387753_reload($event, {})"
          >
          </UcEmployeeList>
        </ItpCol>
        <ItpCol
          w="6"
        >
          <UcAddressList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_4071170783051919_reload($event, {})"
          >
          </UcAddressList>
          <UcCompanyBankAccounts
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
            @reload="evh_7713630524832656_reload($event, {})"
          >
          </UcCompanyBankAccounts>
          <UcCommentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcCommentsList>
          <UcAttachmentsList
            :parent="item"
            :reload="lastLoaded"
            class="pb-4"
          >
          </UcAttachmentsList>
        </ItpCol>
      </ItpRow>
    </b-tab>
    <b-tab
      :title="'Accounts'|pgettext('Company')"
      class="container-fluid h-100"
      v-if="item.role === 'own'"
    >
      <AccountsView
        :companyId="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(1)"
      >
      </AccountsView>
    </b-tab>
    <b-tab
      :title="'Invoices'|pgettext('Company')"
      class="container-fluid h-100"
    >
      <InvoiceLinesView
        :companyId="item.role === 'own' ? item.id : null"
        :partnerId="item.role === 'partner' ? item.id : null"
        :oid="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(2)"
      >
      </InvoiceLinesView>
    </b-tab>
    <b-tab
      :title="'Payments'|pgettext('Company')"
      class="container-fluid h-100"
    >
      <PaymentsView
        :parameters="b_8449679363704630_parameters"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(3)"
      >
      </PaymentsView>
    </b-tab>
    <b-tab
      :title="'Statement'|pgettext('Company')"
      class="container-fluid h-100"
    >
      <UcCompanyStatement
        :oid="item.id"
        :reload="lastLoaded"
        class="h-100"
        v-if="tabManager.visited(4)"
      >
      </UcCompanyStatement>
    </b-tab>
  </MaterialTabs>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

addRoute(
  {
    name: "company",
    path: "/companies/:id",
    component: "Company",
    params: [{
      name: "id",
      prop: "oid",
    }
      ,
    ]

  }
)

@Component()
export default class Company extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  @Prop({
    type: String,
  })
  oid!: string;

  @Watch('oid')
  onOid(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("oid")
  }

  @Prop({
    type: String,
  })
  role!: string;

  @Watch('role')
  onRole(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("role")
  }

  @Prop({
    type: String,
    default: function() {
      return this.$fn.randomElementId()
    },
  })
  formId!: string;

  @Watch('formId')
  onForm_id(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("formId")
  }

  @Prop({
    type: String,
    default: "full-screen",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  messages!: any;
  tabManager!: any;

  async $$load_tabManager() {
    return new this.$app.utils.LazyTabManager()
  }

  item!: any;

  async $$load_item() {
    return this.$fn.fetch('read_company', { parameters: { id: this.oid }, asResource: true })
  }

  ux!: any;
  b_7315092382398562_modalBindings!: any;
  b_4615685607825208_to!: any;

  async $$load_b_4615685607825208_to() {
    return {
      name: "app.companies",
      params: {
        role: this.role,
      }
      ,
    }
  }

  b_8449679363704630_parameters!: any;

  async $$load_b_8449679363704630_parameters() {
    return {
      company: this.item.role === 'own' ? this.item.id : null,
      partner: this.item.role === 'partner' ? this.item.id : null,
    }
  }

  dataMembers = ['messages', 'tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_4615685607825208_to', 'b_8449679363704630_parameters'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        messages: {
          create: {
            own: this.$fn.pgettext("Company|Header", "New own company"),
            partner: this.$fn.pgettext("Company|Header", "New partner"),
          }
          ,
          parent: {
            own: this.$fn.pgettext("Company|Header", "Companies"),
            partner: this.$fn.pgettext("Company|Header", "Partners"),
          }
          ,
          delete: {
            own: this.$fn.pgettext("Company|Header", "Delete own company"),
            partner: this.$fn.pgettext("Company|Header", "Delete partner"),
          }
          ,
        }
        ,
        tabManager: null,
        item: null,
        ux: {
          initialLoadSpinner: true,
        }
        ,
        b_7315092382398562_modalBindings: {
          noHeader: true,
          contentClass: "p-0",
          noPrimaryButton: true,
          noCloseButton: true,
          size: "full-screen",
        }
        ,
        b_4615685607825208_to: null,
        b_8449679363704630_parameters: null,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return {
        title: [this.oid == null && this.messages.create[this.role]
          ,
        this.item && this.item.snapshot('name')
          ,
        ]
        ,
        icon: this.$config.company.icons[this.role || this.item.role],
      }
    }
    return null;
  }

  async act_7315092382398562_closeModal_1808_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_1808, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async evh_8320016629450276_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_8320016629450276_success.executeFromDOM(this, event, scope);
  }

  async evh_6705964765214521_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6705964765214521_clickPreventStop.executeFromDOM(this, event, scope);
  }

  async evh_6806515264385235_success(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6806515264385235_success.executeFromDOM(this, event, scope);
  }

  async evh_5477420070495986_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_5477420070495986_reload.executeFromDOM(this, event, scope);
  }

  async evh_6337614691287098_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_6337614691287098_reload.executeFromDOM(this, event, scope);
  }

  async evh_2406129194387753_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2406129194387753_reload.executeFromDOM(this, event, scope);
  }

  async evh_4071170783051919_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_4071170783051919_reload.executeFromDOM(this, event, scope);
  }

  async evh_7713630524832656_reload(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7713630524832656_reload.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_1808: actions.CloseModalAction;
    evh_7315092382398562_close_1807: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeModal_1810: actions.CloseModalAction;
    evh_2248226175642056_close_1809: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_8320016629450276_closeComponent_1812: actions.CloseComponentAction;
    evh_8320016629450276_success_1811: actions.EventHandlerImpl;
    evh_8320016629450276_success: actions.EventHandlerGroup;
    act_6705964765214521_reloadComponentData_1814: actions.ReloadComponentDataAction;
    evh_6705964765214521_clickPreventStop_1813: actions.EventHandlerImpl;
    evh_6705964765214521_clickPreventStop: actions.EventHandlerGroup;
    act_6806515264385235_reloadComponentData_1816: actions.ReloadComponentDataAction;
    evh_6806515264385235_success_1815: actions.EventHandlerImpl;
    evh_6806515264385235_success: actions.EventHandlerGroup;
    act_5477420070495986_reloadComponentData_1818: actions.ReloadComponentDataAction;
    evh_5477420070495986_reload_1817: actions.EventHandlerImpl;
    evh_5477420070495986_reload: actions.EventHandlerGroup;
    act_6337614691287098_reloadComponentData_1820: actions.ReloadComponentDataAction;
    evh_6337614691287098_reload_1819: actions.EventHandlerImpl;
    evh_6337614691287098_reload: actions.EventHandlerGroup;
    act_2406129194387753_reloadComponentData_1822: actions.ReloadComponentDataAction;
    evh_2406129194387753_reload_1821: actions.EventHandlerImpl;
    evh_2406129194387753_reload: actions.EventHandlerGroup;
    act_4071170783051919_reloadComponentData_1824: actions.ReloadComponentDataAction;
    evh_4071170783051919_reload_1823: actions.EventHandlerImpl;
    evh_4071170783051919_reload: actions.EventHandlerGroup;
    act_7713630524832656_reloadComponentData_1826: actions.ReloadComponentDataAction;
    evh_7713630524832656_reload_1825: actions.EventHandlerImpl;
    evh_7713630524832656_reload: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_1808 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_1808_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_1807 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_1808,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_1807],
      }
    );
    const act_2248226175642056_closeModal_1810 = new actions.CloseModalAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_1809 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeModal_1810,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_1809],
      }
    );
    const act_8320016629450276_closeComponent_1812 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_8320016629450276_success_1811 = new actions.EventHandlerImpl(
      {
        action: act_8320016629450276_closeComponent_1812,
        event: "success",
        displayName: "closeComponent",
      }
    );
    const evh_8320016629450276_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_8320016629450276_success_1811],
      }
    );
    const act_6705964765214521_reloadComponentData_1814 = new actions.ReloadComponentDataAction(
      {
        displayName: "reload",
        events: [],
      }
    );
    const evh_6705964765214521_clickPreventStop_1813 = new actions.EventHandlerImpl(
      {
        action: act_6705964765214521_reloadComponentData_1814,
        event: "click.prevent.stop",
        displayName: "reload",
      }
    );
    const evh_6705964765214521_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_6705964765214521_clickPreventStop_1813],
      }
    );
    const act_6806515264385235_reloadComponentData_1816 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6806515264385235_success_1815 = new actions.EventHandlerImpl(
      {
        action: act_6806515264385235_reloadComponentData_1816,
        event: "success",
        displayName: "reloadComponentData",
      }
    );
    const evh_6806515264385235_success = new actions.EventHandlerGroup(
      {
        handlers: [evh_6806515264385235_success_1815],
      }
    );
    const act_5477420070495986_reloadComponentData_1818 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_5477420070495986_reload_1817 = new actions.EventHandlerImpl(
      {
        action: act_5477420070495986_reloadComponentData_1818,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_5477420070495986_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_5477420070495986_reload_1817],
      }
    );
    const act_6337614691287098_reloadComponentData_1820 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_6337614691287098_reload_1819 = new actions.EventHandlerImpl(
      {
        action: act_6337614691287098_reloadComponentData_1820,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_6337614691287098_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_6337614691287098_reload_1819],
      }
    );
    const act_2406129194387753_reloadComponentData_1822 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_2406129194387753_reload_1821 = new actions.EventHandlerImpl(
      {
        action: act_2406129194387753_reloadComponentData_1822,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_2406129194387753_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_2406129194387753_reload_1821],
      }
    );
    const act_4071170783051919_reloadComponentData_1824 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_4071170783051919_reload_1823 = new actions.EventHandlerImpl(
      {
        action: act_4071170783051919_reloadComponentData_1824,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_4071170783051919_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_4071170783051919_reload_1823],
      }
    );
    const act_7713630524832656_reloadComponentData_1826 = new actions.ReloadComponentDataAction(
      {
        events: [],
      }
    );
    const evh_7713630524832656_reload_1825 = new actions.EventHandlerImpl(
      {
        action: act_7713630524832656_reloadComponentData_1826,
        event: "reload",
        displayName: "reloadComponentData",
      }
    );
    const evh_7713630524832656_reload = new actions.EventHandlerGroup(
      {
        handlers: [evh_7713630524832656_reload_1825],
      }
    );
    return {
      act_7315092382398562_closeModal_1808,
      evh_7315092382398562_close_1807,
      evh_7315092382398562_close,
      act_2248226175642056_closeModal_1810,
      evh_2248226175642056_close_1809,
      evh_2248226175642056_close,
      act_8320016629450276_closeComponent_1812,
      evh_8320016629450276_success_1811,
      evh_8320016629450276_success,
      act_6705964765214521_reloadComponentData_1814,
      evh_6705964765214521_clickPreventStop_1813,
      evh_6705964765214521_clickPreventStop,
      act_6806515264385235_reloadComponentData_1816,
      evh_6806515264385235_success_1815,
      evh_6806515264385235_success,
      act_5477420070495986_reloadComponentData_1818,
      evh_5477420070495986_reload_1817,
      evh_5477420070495986_reload,
      act_6337614691287098_reloadComponentData_1820,
      evh_6337614691287098_reload_1819,
      evh_6337614691287098_reload,
      act_2406129194387753_reloadComponentData_1822,
      evh_2406129194387753_reload_1821,
      evh_2406129194387753_reload,
      act_4071170783051919_reloadComponentData_1824,
      evh_4071170783051919_reload_1823,
      evh_4071170783051919_reload,
      act_7713630524832656_reloadComponentData_1826,
      evh_7713630524832656_reload_1825,
      evh_7713630524832656_reload,
      reload: act_6705964765214521_reloadComponentData_1814,
    }
  }
}

Vue.component("Company", Company);

</script>