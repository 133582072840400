var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ItpBadge", {
    attrs: {
      colorCode: _vm.$config.company.type.colorCodes[_vm.value],
      text: _vm._f("pgettext")(_vm.value, "CompanyRelation", "Sentence"),
      "no-box-shadow": ""
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }