<template>
<ItpPage
  card
  :size="size"
  :header="documentTitle"
  :loaded="loaded"
  :noShow="!loaded"
  :modal="modal"
  itp-cname="TextTemplates"
  :modalBindings="b_7315092382398562_modalBindings"
  @close="evh_7315092382398562_close($event, {})"
>
  <UcHeader
    :card="!modal"
    :modal="modal"
    :title="'Edit Text Templates'|pgettext('TextTemplates|header')"
    slot="header"
    v-if="loaded"
    @close="evh_2248226175642056_close($event, {})"
  >
  </UcHeader>
  <UcBody
    :card="!modal"
    :modal="modal"
    v-if="loaded"
  >
    <ItpSelect2
      :options="items"
      v-model="selectedItem"
      valueField="id"
      textField="name"
      bind-object
    >
      <template
        slot="list-footer"
      >
        <hr>
        </hr>
        <b-dropdown-item>
          <a
            href="#"
            @click="evh_3054425420812800_click($event, {})"
          >
            {{ 'Add new text template'|pgettext('TextTemplates') }}
          </a>
        </b-dropdown-item>
      </template>
    </ItpSelect2>
    <hr>
    </hr>
    <TextTemplate
      :oid="createNewItem ? null : selectedItem.id"
      class="mt-3"
      v-if="selectedItem || createNewItem"
      @saved="evh_3083451129821185_saved($event, {})"
    >
    </TextTemplate>
  </UcBody>
  <UcFooter
    :card="!modal"
    :modal="modal"
    slot="footer"
    v-if="loaded"
  >
    <ItpButton
      :text="'Close'|gettext"
      variant="default"
      @click.prevent.stop="evh_1548630417156826_clickPreventStop($event, {})"
    >
    </ItpButton>
  </UcFooter>
  <template
    slot="footer"
    v-if="loaded"
  >
  </template>
</ItpPage>
</template>

<script lang="ts">

import * as _ from 'lodash';
import Big from 'big.js';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import {
  addRoute, UserComponentMixin, ComponentValueCache, actions
} from '@integro/core';

@Component()
export default class TextTemplates extends mixins(UserComponentMixin) {
  componentMetadata = {
    kind: "page",
  };

  beforeCreate() {
  }

  onItemSaved(...args: any[]) {
    const [data] = args;

    const index = _.indexOf(this.items, { id: data.id });
    if (index > -1) {
      this.items[index] = data;
    }
    else {
      this.items.push(data);
    }
    this.selectedItem = data;
    this.createNewItem = false;
  }

  @Prop({
    type: String,
    default: "lg",
  })
  size!: string;

  @Watch('size')
  onSize(newValue: any, oldValue: any) {
    if (_.isEqual(newValue, oldValue)) return;
    this.loadDataThrottled("size")
  }

  items!: any;

  async $$load_items() {
    return this.$fn.fetch('list_object_by_type', { parameters: { object_type: 'text-templates' } })
  }

  selectedItem!: any;

  async $$load_selectedItem() {
    return this.items.length ? this.items[0] : null
  }

  createNewItem!: any;
  ux!: any;
  b_7315092382398562_modalBindings!: any;
  dataMembers = ['items', 'selectedItem', 'createNewItem', 'ux', 'b_7315092382398562_modalBindings'];

  data() {
    return {
      ...this.mixinData(),
      actions: this.getActions(),
      ...{
        items: null,
        selectedItem: null,
        createNewItem: false,
        ux: null,
        b_7315092382398562_modalBindings: {
          size: "lg",
        }
        ,
      },
    }
  }

  get document() {
    if (this.loaded) {

      return
    }
    return null;
  }

  async act_7315092382398562_closeModal_2878_getActionArgs($event: actions.ActionEvent): Promise<actions.CloseModalActionArgs> {
    // parameterResolver name=act_7315092382398562_closeModal_2878, alias=undefined
    return {
      name: this.modalName,
      result: {
        close: true,
        cancel: true,
      }
      ,
    }
  }

  async evh_7315092382398562_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
  }

  async evh_2248226175642056_close(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_2248226175642056_close.executeFromDOM(this, event, scope);
  }

  async act_3054425420812800_setData_2882_getActionArgs($event: actions.ActionEvent): Promise<actions.SetDataActionArgs> {
    // parameterResolver name=act_3054425420812800_setData_2882, alias=addNewTextTemplate
    return {
      path: "createNewItem",
      value: true,
      resolve: false,
      noTouchLastLoaded: false,
      noTakeSnapshot: false,
    }
  }

  async evh_3054425420812800_click(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3054425420812800_click.executeFromDOM(this, event, scope);
  }

  async act_3083451129821185_script_2884_getActionArgs_value($event: actions.ActionEvent) {
    // callableMethod act_3083451129821185_script_2884, alias=undefined
    this.onItemSaved($event.data)
  }

  async act_3083451129821185_script_2884_getActionArgs($event: actions.ActionEvent): Promise<actions.ScriptActionArgs> {
    // parameterResolver name=act_3083451129821185_script_2884, alias=undefined
    return {
      value: () => this.act_3083451129821185_script_2884_getActionArgs_value($event),
    }
  }

  async evh_3083451129821185_saved(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_3083451129821185_saved.executeFromDOM(this, event, scope);
  }

  async evh_1548630417156826_clickPreventStop(event: Event, scope: Dictionary<any>) {
    return await this.actions.evh_1548630417156826_clickPreventStop.executeFromDOM(this, event, scope);
  }

  actions!: {
    act_7315092382398562_closeModal_2878: actions.CloseModalAction;
    evh_7315092382398562_close_2877: actions.EventHandlerImpl;
    evh_7315092382398562_close: actions.EventHandlerGroup;
    act_2248226175642056_closeComponent_2880: actions.CloseComponentAction;
    evh_2248226175642056_close_2879: actions.EventHandlerImpl;
    evh_2248226175642056_close: actions.EventHandlerGroup;
    act_3054425420812800_setData_2882: actions.SetDataAction;
    evh_3054425420812800_click_2881: actions.EventHandlerImpl;
    evh_3054425420812800_click: actions.EventHandlerGroup;
    act_3083451129821185_script_2884: actions.ScriptAction;
    evh_3083451129821185_saved_2883: actions.EventHandlerImpl;
    evh_3083451129821185_saved: actions.EventHandlerGroup;
    act_1548630417156826_closeComponent_2886: actions.CloseComponentAction;
    evh_1548630417156826_clickPreventStop_2885: actions.EventHandlerImpl;
    evh_1548630417156826_clickPreventStop: actions.EventHandlerGroup;
  }

  getActions() {
    const act_7315092382398562_closeModal_2878 = new actions.CloseModalAction(
      {
        actionArgs: this.act_7315092382398562_closeModal_2878_getActionArgs,
        events: [],
      }
    );
    const evh_7315092382398562_close_2877 = new actions.EventHandlerImpl(
      {
        action: act_7315092382398562_closeModal_2878,
        event: "close",
        displayName: "closeModal",
      }
    );
    const evh_7315092382398562_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_7315092382398562_close_2877],
      }
    );
    const act_2248226175642056_closeComponent_2880 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_2248226175642056_close_2879 = new actions.EventHandlerImpl(
      {
        action: act_2248226175642056_closeComponent_2880,
        event: "close",
        displayName: "closeComponent",
      }
    );
    const evh_2248226175642056_close = new actions.EventHandlerGroup(
      {
        handlers: [evh_2248226175642056_close_2879],
      }
    );
    const act_3054425420812800_setData_2882 = new actions.SetDataAction(
      {
        actionArgs: this.act_3054425420812800_setData_2882_getActionArgs,
        displayName: "addNewTextTemplate",
        events: [],
      }
    );
    const evh_3054425420812800_click_2881 = new actions.EventHandlerImpl(
      {
        action: act_3054425420812800_setData_2882,
        event: "click",
        displayName: "addNewTextTemplate",
      }
    );
    const evh_3054425420812800_click = new actions.EventHandlerGroup(
      {
        handlers: [evh_3054425420812800_click_2881],
      }
    );
    const act_3083451129821185_script_2884 = new actions.ScriptAction(
      {
        actionArgs: this.act_3083451129821185_script_2884_getActionArgs,
        events: [],
      }
    );
    const evh_3083451129821185_saved_2883 = new actions.EventHandlerImpl(
      {
        action: act_3083451129821185_script_2884,
        event: "saved",
        displayName: "script",
      }
    );
    const evh_3083451129821185_saved = new actions.EventHandlerGroup(
      {
        handlers: [evh_3083451129821185_saved_2883],
      }
    );
    const act_1548630417156826_closeComponent_2886 = new actions.CloseComponentAction(
      {
        events: [],
      }
    );
    const evh_1548630417156826_clickPreventStop_2885 = new actions.EventHandlerImpl(
      {
        action: act_1548630417156826_closeComponent_2886,
        event: "click.prevent.stop",
        displayName: "closeComponent",
      }
    );
    const evh_1548630417156826_clickPreventStop = new actions.EventHandlerGroup(
      {
        handlers: [evh_1548630417156826_clickPreventStop_2885],
      }
    );
    return {
      act_7315092382398562_closeModal_2878,
      evh_7315092382398562_close_2877,
      evh_7315092382398562_close,
      act_2248226175642056_closeComponent_2880,
      evh_2248226175642056_close_2879,
      evh_2248226175642056_close,
      act_3054425420812800_setData_2882,
      evh_3054425420812800_click_2881,
      evh_3054425420812800_click,
      act_3083451129821185_script_2884,
      evh_3083451129821185_saved_2883,
      evh_3083451129821185_saved,
      act_1548630417156826_closeComponent_2886,
      evh_1548630417156826_clickPreventStop_2885,
      evh_1548630417156826_clickPreventStop,
      addNewTextTemplate: act_3054425420812800_setData_2882,
    }
  }
}

Vue.component("TextTemplates", TextTemplates);

</script>