"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("lodash");
const vue_property_decorator_1 = require("vue-property-decorator");
const vue_class_component_1 = require("vue-class-component");
const core_1 = require("@integro/core");
(0, core_1.addRoute)({
    name: "meter",
    path: "/meters/:id",
    component: "Meter",
    params: [{
            name: "id",
            prop: "meterId",
        },
    ]
});
let Meter = class Meter extends (0, vue_class_component_1.mixins)(core_1.UserComponentMixin) {
    constructor() {
        super(...arguments);
        this.componentMetadata = {
            kind: "page",
        };
        this.dataMembers = ['tabManager', 'item', 'ux', 'b_7315092382398562_modalBindings', 'b_2285343489561581_to'];
    }
    beforeCreate() {
    }
    onMeter_id(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("meterId");
    }
    onSize(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue))
            return;
        this.loadDataThrottled("size");
    }
    $$load_tabManager() {
        return __awaiter(this, void 0, void 0, function* () {
            return new this.$app.utils.LazyTabManager();
        });
    }
    $$load_item() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.$fn.fetch('read_meter', { asResource: true, parameters: { id: this.meterId } });
        });
    }
    data() {
        return Object.assign(Object.assign(Object.assign({}, this.mixinData()), { actions: this.getActions() }), {
            tabManager: null,
            item: null,
            ux: null,
            b_7315092382398562_modalBindings: {
                size: "sm",
            },
            b_2285343489561581_to: {
                name: "app.meters",
            },
        });
    }
    get document() {
        if (this.loaded) {
            return {
                title: [this.item.snapshot('serial_number'),
                    this.$fn.gettext("Meter"),
                ],
                icon: this.item ? this.$config.meterTypes[this.item.meter_type].icon : this.$config.meter.icon,
            };
        }
        return null;
    }
    act_7315092382398562_closeModal_2730_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: this.modalName,
                result: {
                    close: true,
                    cancel: true,
                },
            };
        });
    }
    evh_7315092382398562_close(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7315092382398562_close.executeFromDOM(this, event, scope);
        });
    }
    evh_2095089031469157_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2095089031469157_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_9004123373732307_success(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_9004123373732307_success.executeFromDOM(this, event, scope);
        });
    }
    act_397195341670858_showModal_2736_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                name: "entityEvents",
                props: {
                    entityId: this.item.id,
                },
            };
        });
    }
    evh_397195341670858_clickPreventStop(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_397195341670858_clickPreventStop.executeFromDOM(this, event, scope);
        });
    }
    evh_2679161326024595_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2679161326024595_reload.executeFromDOM(this, event, scope);
        });
    }
    act_2679161326024595_setData_2740_getActionArgs($event) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                path: "item",
                value: $event.data,
                resolve: false,
                noTouchLastLoaded: true,
                noTakeSnapshot: false,
            };
        });
    }
    evh_2679161326024595_saved(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_2679161326024595_saved.executeFromDOM(this, event, scope);
        });
    }
    evh_7987652045662005_reload(event, scope) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.actions.evh_7987652045662005_reload.executeFromDOM(this, event, scope);
        });
    }
    getActions() {
        const act_7315092382398562_closeModal_2730 = new core_1.actions.CloseModalAction({
            actionArgs: this.act_7315092382398562_closeModal_2730_getActionArgs,
            events: [],
        });
        const evh_7315092382398562_close_2729 = new core_1.actions.EventHandlerImpl({
            action: act_7315092382398562_closeModal_2730,
            event: "close",
            displayName: "closeModal",
        });
        const evh_7315092382398562_close = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7315092382398562_close_2729],
        });
        const act_2095089031469157_reloadComponentData_2732 = new core_1.actions.ReloadComponentDataAction({
            displayName: "reload",
            events: [],
        });
        const evh_2095089031469157_clickPreventStop_2731 = new core_1.actions.EventHandlerImpl({
            action: act_2095089031469157_reloadComponentData_2732,
            event: "click.prevent.stop",
            displayName: "reload",
        });
        const evh_2095089031469157_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2095089031469157_clickPreventStop_2731],
        });
        const act_9004123373732307_closeComponent_2734 = new core_1.actions.CloseComponentAction({
            events: [],
        });
        const evh_9004123373732307_success_2733 = new core_1.actions.EventHandlerImpl({
            action: act_9004123373732307_closeComponent_2734,
            event: "success",
            displayName: "closeComponent",
        });
        const evh_9004123373732307_success = new core_1.actions.EventHandlerGroup({
            handlers: [evh_9004123373732307_success_2733],
        });
        const act_397195341670858_showModal_2736 = new core_1.actions.ShowModalAction({
            actionArgs: this.act_397195341670858_showModal_2736_getActionArgs,
            events: [],
        });
        const evh_397195341670858_clickPreventStop_2735 = new core_1.actions.EventHandlerImpl({
            action: act_397195341670858_showModal_2736,
            event: "click.prevent.stop",
            displayName: "showModal",
        });
        const evh_397195341670858_clickPreventStop = new core_1.actions.EventHandlerGroup({
            handlers: [evh_397195341670858_clickPreventStop_2735],
        });
        const act_2679161326024595_reloadComponentData_2738 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_2679161326024595_reload_2737 = new core_1.actions.EventHandlerImpl({
            action: act_2679161326024595_reloadComponentData_2738,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_2679161326024595_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2679161326024595_reload_2737],
        });
        const act_2679161326024595_setData_2740 = new core_1.actions.SetDataAction({
            actionArgs: this.act_2679161326024595_setData_2740_getActionArgs,
            events: [],
        });
        const evh_2679161326024595_saved_2739 = new core_1.actions.EventHandlerImpl({
            action: act_2679161326024595_setData_2740,
            event: "saved",
            displayName: "setData",
        });
        const evh_2679161326024595_saved = new core_1.actions.EventHandlerGroup({
            handlers: [evh_2679161326024595_saved_2739],
        });
        const act_7987652045662005_reloadComponentData_2742 = new core_1.actions.ReloadComponentDataAction({
            events: [],
        });
        const evh_7987652045662005_reload_2741 = new core_1.actions.EventHandlerImpl({
            action: act_7987652045662005_reloadComponentData_2742,
            event: "reload",
            displayName: "reloadComponentData",
        });
        const evh_7987652045662005_reload = new core_1.actions.EventHandlerGroup({
            handlers: [evh_7987652045662005_reload_2741],
        });
        return {
            act_7315092382398562_closeModal_2730,
            evh_7315092382398562_close_2729,
            evh_7315092382398562_close,
            act_2095089031469157_reloadComponentData_2732,
            evh_2095089031469157_clickPreventStop_2731,
            evh_2095089031469157_clickPreventStop,
            act_9004123373732307_closeComponent_2734,
            evh_9004123373732307_success_2733,
            evh_9004123373732307_success,
            act_397195341670858_showModal_2736,
            evh_397195341670858_clickPreventStop_2735,
            evh_397195341670858_clickPreventStop,
            act_2679161326024595_reloadComponentData_2738,
            evh_2679161326024595_reload_2737,
            evh_2679161326024595_reload,
            act_2679161326024595_setData_2740,
            evh_2679161326024595_saved_2739,
            evh_2679161326024595_saved,
            act_7987652045662005_reloadComponentData_2742,
            evh_7987652045662005_reload_2741,
            evh_7987652045662005_reload,
            reload: act_2095089031469157_reloadComponentData_2732,
        };
    }
};
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
    }),
    __metadata("design:type", String)
], Meter.prototype, "meterId", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('meterId'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Meter.prototype, "onMeter_id", null);
__decorate([
    (0, vue_property_decorator_1.Prop)({
        type: String,
        default: "md",
    }),
    __metadata("design:type", String)
], Meter.prototype, "size", void 0);
__decorate([
    (0, vue_property_decorator_1.Watch)('size'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, Object]),
    __metadata("design:returntype", void 0)
], Meter.prototype, "onSize", null);
Meter = __decorate([
    (0, vue_property_decorator_1.Component)()
], Meter);
exports.default = Meter;
vue_property_decorator_1.Vue.component("Meter", Meter);
